    <template>
    <div>


       <!--  <dialog-gst-details 
            ref="ref_dialogGstDetails" 
            :openDialogGstTypes="fn_openDialogGstTypes" 
            :openStates="fn_openStates" 
            :updatePlaceOfSupply="fn_updatePlaceOfSupply"
            :checkAndUpdateGstType="fn_checkAndUpdateGstType"
            :openCountries="fn_openCountries"
            :updateOverseasCountry="fn_updateOverseasCountry">
        </dialog-gst-details>

        <dialog-gst-types
            ref="ref_dialogGstTypes"
            :gstTypes="gstTypes"
            :selectedGstType="Form.accountGstFieldTemp.gstType"
            :asignGstType="fn_asignGstType">
        </dialog-gst-types>

        <dialog-address 
            ref="ref_dialogAddress" 
            :openCountries="fn_openCountries" 
            :openStates="fn_openStates" 
            :updateCountry="fn_updateCountry"
            :updateState="fn_updateState"
            :saveAddress="fn_saveAddressNew">
        </dialog-address> -->

        <!-- fn_draftAction is in draft mixin -->
        <!-- <dialog-discard ref="ref_dialogDiscard" :draftAction="fn_draftAction" :save="fn_checkValidations" :hideKeepDraft="true"></dialog-discard> -->

        <!-- <dialog-draft-items ref="ref_dialogDraftItems" :draftItems="Form.draftItems" :importDraft="fn_importDraft" :removeDraftItem="fn_removeDraftItem"></dialog-draft-items> -->

        <!-- <dialog-phone-number ref="ref_dialogPhoneNumber" :phoneNumbers="Form.phoneNumberList" :setPhoneNumber="fn_setPhoneNumber"></dialog-phone-number> -->

        <!-- <dialog-opening ref="ref_dialogOpening" :amount="Form.openingAmount" :nature="Form.openingNature"></dialog-opening> -->

        <v-snackbar v-model="snackbar" :timeout="2000">{{snackbar_msg}}</v-snackbar>

        <dialog-form ref="ref_dialogForm" :closeDialog="fn_closeDialogForm"></dialog-form>

        <dialog-address ref="ref_dialogAddress" :saveAddress="fn_saveAddressNew"></dialog-address>

        <dialog-gst-details ref="ref_dialogGstDetails" :saveGstDetails="fn_saveGstDetails"></dialog-gst-details>

        <dialog-discard ref="ref_dialogDiscard" :draftAction="fn_goBack" :save="fn_validate"></dialog-discard>

        <!-- <dialog-permission ref="ref_dialogPermission"></dialog-permission> -->
    </div>
</template>

<script>
// const dialogjs =  require('../dialog').dialogjs;
// const menuItem = require('../../../../config.js').configs.menuItem;
// const predefinedListIds = require('../../../../config.js').configs.mastersPredefinedLists;
const accountGroupIds = require('../../../../config.js').configs.accountGroupIds;
// const draftObj = new Object();

import dialogAddress from './dialogs/dialog_address'
import dialogGstDetails from './dialogs/dialog_gst_details'
// import dialogGstTypes from './dialogs/dialog_gst_types'
// import dialogOpening from './dialogs/dialog_opening'

// import dialogPhoneNumber from './dialogs/dialog_phone_numbers'

import dialogDiscard from '../../../dialogs/dialog_discard'
// import dialogDraftItems from '../../../dialogs/dialog_draft_items'
import {draftMixin} from '../../../draft_mixin'

// import dialogPermission from '../../../dialogs/dialog_permission';

import dialogForm from './dialogs/dialog_form'

const transactionData = require('../../../transactions/datamodel').data.transactionData;

    export default{
        mixins: [draftMixin],
        data(){
            return{
                Form:{"name":null, "accountId":null, "groupTypeId":101, "accountGroupId":null, "accountGroupName":null, 
                    //"mobile":null, 
                    "email":null, //"phone":null, 
                    "addresses":[], 
                    // "printDetails": {"printName":null},

                    "accountGstFieldTemp":{"gstType":101, "gstNumber":null, "placeOfSupply":null, "placeOfSupplyId":null, "panNumber":null, "currency":null, "countryId":null, "country":null},

                    "gstType":null, "gstNumber":null, "placeOfSupplyId":null, "panNumber":null, "currency":null, "countryId":null, "country":null,

                    // "addressData":{"groupTypeId":103, "addressLine":null, "city":null, "state":null, "countryId":"d87bedca-5bcc-4241-8027-7e16f3b68f18", "country":"India", "pincode":null, "isBillingAddress":true, "isShippingAddress":true, "phone":null},

                    "addressData":{"addressId":null, "groupTypeId":null, "addressLine":null, "city":null, "state":null, "countryId":"d87bedca-5bcc-4241-8027-7e16f3b68f18", "country":"India", "pincode":null, "phone":null, "isSameAddress":true},

                    // "mobileNumberFields":[{"fieldName":"Mobile Number 1"}],
                    // "mobileNumberFields":[],
                    // "mobileNumbers":[],

                    // "emailFields":[{"fieldName":"Email 1"}],
                    // "emails":[],

                    "selectedState":null,
                    "selectedCountry":{"itemId":"d87bedca-5bcc-4241-8027-7e16f3b68f18", "itemName":"India"},

                    // "selectedGstType": "Registered Business - Regular",
                    "selectedAccountGroup":null,
                    "selectedGstType": {"id":101, "title":"Registered Business - Regular"},
                    "selectedGstState":null,
                    "selectedGstCountry":null,

                    "routeId":null, "routeName":null,
                    "isSystem":0,
                    "primaryMobileNumber":null,
                    "otherStates":[],
                    "draftItems":[],
                    "createdDate":null,
                    "modifiedDate":null,
                    "phoneNumberList":[],
                    "contactInfo":null,
                    "isNew":true,
                    "billingAddress":null,
                    "shippingAddress":null,
                    "openingNature":1,
                    "openingAmount":null,
                    "transactionId":null,
                    "msmeUdyamNumber":null
                },


                loading:false,

                tagId:null,

                dialogStorageId:null,

                draftStorageId:null,

                backButtonPress: false,

                gstTypes:[
                    {'id':101, 'title':'Registered Business - Regular'}, 
                    {'id':102, 'title':'Registered Business - Composition'}, 
                    {'id':103, 'title':'Unregistered Business'}, 
                    {'id':104, 'title':'Consumer'}, 
                    {'id':105, 'title':'Overseas'}, 
                    {'id':106, 'title':'Special Economic Zone'},
                    {'id':107, 'title':'Deemed Export'}
                ],

                states:null,
                countries:null,

                snackbar:false,
                snackbar_msg:null,

                unwatch:false,
                // loadedFromDraft:false,
                printFieldChanged:false,

                transactionData: JSON.parse(JSON.stringify(transactionData)),

                accountGroups:null,
                disableOnSave:false,
                openingTypes:[
                    {"itemId":1, "itemName":"To Receive"},
                    {"itemId":0, "itemName":"To Pay"}
                ],
                openingTypesOther:[
                    {"itemId":1, "itemName":"Positive"},
                    {"itemId":0, "itemName":"Negative"}
                ]
            }
        },
        watch:{
            'Form': {
                handler: function (){
                     if(!this.unwatch){
                        this.fn_saveDraft(this.draftStorageId, this.Form.name);
                     }
                     this.unwatch = false;
                 },
                 deep: true
            }
        },
        components:{
            'dialog-address': dialogAddress,
            'dialog-gst-details': dialogGstDetails,
            // 'dialog-gst-types': dialogGstTypes,
            'dialog-discard': dialogDiscard,
            // // 'dialog-draft-items': dialogDraftItems,
            // 'dialog-phone-number':dialogPhoneNumber,
            // 'dialog-permission': dialogPermission,
            // 'dialog-opening': dialogOpening,
            'dialog-form':dialogForm
        },
        methods:{

            fn_accountGroups: function(val){
              let filterObject = {};
              filterObject.search = val ? val.toLowerCase() : '';

              if(this.Form.groupTypeId == 101){
                    filterObject.accountGroupIds = [accountGroupIds.debtors.id];
                }
                else if(this.Form.groupTypeId == 102){
                    filterObject.accountGroupIds = [accountGroupIds.creditors.id];
                }
                else if(this.Form.groupTypeId == 104){
                    filterObject.accountGroupIds = [accountGroupIds.brandAndDivision.id];
                }

                this.accountGroups = null;

                filterObject.startIndex = 0;
                filterObject.limit = 1000;
              
              let self = this;
              this.getDialogItems("AccountGroupDialog", filterObject, 
                {
                  onSuccess: function (response) {
                    console.log(response);
                    self.accountGroups = JSON.parse(response);
                    // self.fn_states();
                    // self.accountGroups.unshift("header");
                  },
                  onError: function (error) {
                    console.log(error);
                  }
                }
              )
            },

            fn_states: function(val){
              let filterObject = {};
              filterObject.search = val ? val.toLowerCase() : '';
              
              let self = this;
              this.getDialogItems("StateDialog", filterObject, 
                {
                  onSuccess: function (response) {
                    console.log(response);
                    self.states = JSON.parse(response);
                    // self.accountGroups.unshift("header");
                  },
                  onError: function (error) {
                    console.log(error);
                  }
                }
              )
            },

            fn_countries: function(val){
              let filterObject = {};
              filterObject.search = val ? val.toLowerCase() : '';
              filterObject.startIndex = 0;
              filterObject.limit = 1000;
              
              let self = this;
              this.getDialogItems("CountryDialog", filterObject, 
                {
                  onSuccess: function (response) {
                    console.log(response);
                    self.countries = JSON.parse(response);
                    // self.accountGroups.unshift("header");
                  },
                  onError: function (error) {
                    console.log(error);
                  }
                }
              )
            },

            fn_closeDialogForm: function(){
                this.askToDiscard = true;
                if(this.fn_showDialogDiscard()){
                    this.$refs['ref_dialogDiscard'].openDialogWeb();
                }
                else{
                    this.fn_goBack();
                }
            },

            fn_getNameLabel: function(){
                if(this.Form.groupTypeId == 101){
                    return "Customer Name"
                }
                else if(this.Form.groupTypeId == 102){
                    return "Supplier Name"
                }
                else if(this.Form.groupTypeId == 103){
                    return "Ledger Name"
                }
                else if(this.Form.groupTypeId == 104){
                    return "Branch/Division Name"
                }
            },

            // fn_copyName: function (value) {
            //  console.log(this);
            //  if(!value && this.printDetails && !this.printDetails.printName){
            //      this.printFieldChanged = false; 
            //  }

            //  if(!this.printFieldChanged){
            //      this.Form.printDetails.printName = value;
            //  }
            // },

            fn_groupTypeChange: function(groupTypeId, fromGetData){

                // let formConfig = null;
                
                // if(localStorage.getItem("lastLedgerFormConfig")){
                //     formConfig = JSON.parse(localStorage.getItem("lastLedgerFormConfig"));    
                // }
                // this.Form.openingNature = 1;

                this.fn_accountGroups();
                
                if(groupTypeId == 101){
                    !this.Form.openingAmount ? this.Form.openingNature = 1 : '';
                    // this.fn_updateAccountGroup(accountGroupIds.debtors.id, accountGroupIds.debtors.name, false);
                    this.Form.selectedAccountGroup = {"itemId":accountGroupIds.debtors.id, "itemName":accountGroupIds.debtors.name};
                }
                else if(groupTypeId == 102){
                    !this.Form.openingAmount ? this.Form.openingNature = 0 : '';
                    // this.fn_updateAccountGroup(accountGroupIds.creditors.id, accountGroupIds.creditors.name, false);
                    this.Form.selectedAccountGroup = {"itemId":accountGroupIds.creditors.id, "itemName":accountGroupIds.creditors.name};
                }
                else if(groupTypeId == 104){
                    // this.fn_updateAccountGroup(accountGroupIds.brandAndDivision.id, accountGroupIds.brandAndDivision.name, false);
                    this.Form.selectedAccountGroup = {"itemId":accountGroupIds.brandAndDivision.id, "itemName":accountGroupIds.brandAndDivision.name};
                }
                else{
                    !this.Form.openingAmount ? this.Form.openingNature = 1 : '';
                    !fromGetData ? this.Form.selectedAccountGroup = null : '';
                    // if(formConfig){
                    //     // this.fn_updateAccountGroup(formConfig.accountGroupId, formConfig.accountGroupName, false);
                    //     this.Form.selectedAccountGroup = {"itemId":formConfig.accountGroupId, "itemName":formConfig.accountGroupName};
                    // }
                    // else{
                    //     // this.fn_updateAccountGroup(null, null, true);
                    //     this.Form.selectedAccountGroup = null;
                    // }

                    if(this.$route.query.tds){
                        this.Form.selectedAccountGroup = {"itemId":accountGroupIds.tdsPayable.id, "itemName":accountGroupIds.tdsPayable.name};
                    }
                }
                // groupTypeId != 103 ? this.fn_updateAccountGroup(null, null, true) : '';
            },

            // fn_openDialogDiscard: function(){
            //  this.fn_openDialogWeb('web', 'Discard', 'ref_dialogDiscard')
            // },

            fn_openingLabel: function(){
                if(!this.Form.openingAmount){
                    return 'Opening';
                }
                else{
                    if(this.Form.openingNature){
                        return 'Opening ('+ this.$root.fn_payOrReceive(1, this.Form.groupTypeId) + ')';
                    }
                    else{
                        return 'Opening ('+ this.$root.fn_payOrReceive(0, this.Form.groupTypeId) + ')';
                    }
                }
            },

            // fn_openDialogOpening: function(){
            //  this.fn_openDialogWeb('web', 'Opening', 'ref_dialogOpening');
            // },

            fn_setOpening: function(amount, nature){
                this.Form.openingAmount = amount ? amount : null;
                this.Form.openingNature = nature;
                this.fn_closeDialog();
            },

            // fn_openRoutes: function (platform, name, ref) {

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  //dialogObj.data = null;
            //  dialogObj.platform = platform;


            //  var self = this;

            //  var viewDialogObject = {};
            //  viewDialogObject.ToolbarTitle = "Select Beat/Area/Route";
            //  let menuItemCustom = {"MenuID":null, "Icon":null, "Text":"New", "Action":"newRoute"};
            //  viewDialogObject.TopMenu = [menuItemCustom];

            //  viewDialogObject.isBottomMenu = false;
            //  viewDialogObject.listId = predefinedListIds.routeListGUID;

            //  var paramObject = {};
            //  paramObject.defaultValue = {"id":this.Form.routeId, "name":this.Form.routeName};

            //  var self = this;
            //  dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, 'listItems', tagId, paramObject, ref);
            // },

            fn_updateRoute: function (selectedValueId, selectedValueName, isRemoved) {
                if(!isRemoved){
                    this.Form.routeId = selectedValueId;
                    this.Form.routeName = selectedValueName;
                    this.$forceUpdate();
                }
                else{
                    this.Form.routeId = null;
                    this.Form.routeName = null;
                }
                this.$forceUpdate();
            },

            // fn_addMobileNumberField: function () {

            //  let obj = {fieldName:'Mobile Number '+ (this.Form.mobileNumberFields.length+1)}

            //  this.Form.mobileNumberFields.push(obj);

            //  this.$forceUpdate();
            // },


            // fn_addEmailField: function () {

            //  let obj = {fieldName:'Email '+ (this.Form.emailFields.length+1)}

            //  this.Form.emailFields.push(obj);

            //  this.$forceUpdate();
            // },

            // fn_openDialogGstDetails: function () {
            //  this.fn_openDialogWeb('web', 'GstFields', 'ref_dialogGstDetails');
            // },

            fn_getGstTypeName: function (id) {
                for(let i=0;i<this.gstTypes.length;i++){
                    if(this.gstTypes[i].id == id){
                        return this.gstTypes[i].title;
                    }
                }
            },

            // fn_openDialogGstTypes: function(){
            //  this.fn_openDialogWeb('web', 'GstTypes', 'ref_dialogGstTypes');
            // },

            // fn_openDialogWeb: function (platform, name, ref) {

            //  let scroll_y = window.scrollY;
            //  this.fn_fixBackgroundScroll();

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  dialogObj.data = null;
            //  dialogObj.platform = platform;

            //  dialogObj.scrollPosition = {};
            //  dialogObj.scrollPosition.x = 0;
            //  dialogObj.scrollPosition.y = scroll_y;

            //  dialogjs.fn_pushDialogInStack(this.dialogStorageId, dialogObj, this);

            //  this.$refs[ref].openDialogWeb();
            // },

            // fn_closeDialogWeb: function () { 
            //  dialogjs.fn_checkAndCloseOpenDialogs(this);
            // },

            fn_addGstDetails: function(){
                // this.Form.selectedGstType = {"id":101, "title":"Registered Business - Regular"};
                this.$refs['ref_dialogGstDetails'].openDialogWeb();
            },

            fn_removeGstDetails: function () {
                this.Form.selectedGstType = null;
                this.Form.selectedGstCountry = null;
                this.Form.selectedGstState = null;
                this.Form.gstType = null;
                this.Form.gstNumber = null;
                this.Form.placeOfSupply = null;
                this.Form.placeOfSupplyId = null;
                this.Form.panNumber = null;
                this.Form.currency = null;
                this.Form.countryId = null;
                this.Form.country = null;
                // this.$forceUpdate();
                // this.fn_closeDialogWeb();
            },

            // fn_editGstDetails: function () {
            //     this.Form.selectedGstType = this.fn_getGstTypeName(this.Form.gstType);

            //     this.Form.accountGstFieldTemp.gstType = this.Form.gstType;
            //     this.Form.accountGstFieldTemp.gstNumber = this.Form.gstNumber;
            //     this.Form.accountGstFieldTemp.placeOfSupplyId = this.Form.placeOfSupplyId;
            //     this.Form.accountGstFieldTemp.placeOfSupply = this.Form.placeOfSupply;
            //     this.Form.accountGstFieldTemp.panNumber = this.Form.panNumber;
            //     this.Form.accountGstFieldTemp.currency = this.Form.currency;
            //     this.Form.accountGstFieldTemp.countryId = this.Form.countryId;
            //     this.Form.accountGstFieldTemp.country = this.Form.country;

            //     // this.fn_openDialogGstDetails();
            // },

            // fn_saveGstDetails: function (gstDetailsObj) {

            //     this.Form.gstType = gstDetailsObj.gstType;
            //     this.Form.gstNumber = gstDetailsObj.gstNumber ? gstDetailsObj.gstNumber.toUpperCase() : '';
            //     this.Form.placeOfSupplyId = gstDetailsObj.placeOfSupplyId;
            //     this.Form.placeOfSupply = gstDetailsObj.placeOfSupply;
            //     this.Form.panNumber = gstDetailsObj.panNumber ? gstDetailsObj.panNumber.toUpperCase() : '';
            //     this.Form.currency = gstDetailsObj.currency;
            //     this.Form.countryId = gstDetailsObj.countryId;
            //     this.Form.country = gstDetailsObj.country;
            //     this.$forceUpdate();

            //     // this.fn_closeDialogWeb();
            // },

            fn_saveGstDetails: function (gstDetailsObj) {
                
                this.Form.gstType = gstDetailsObj.selectedGstType ? gstDetailsObj.selectedGstType.id : null;
                this.Form.placeOfSupplyId = gstDetailsObj.selectedGstState ? gstDetailsObj.selectedGstState.itemId : null;
                this.Form.placeOfSupply = gstDetailsObj.selectedGstState ? gstDetailsObj.selectedGstState.itemName : null;
                this.Form.countryId = gstDetailsObj.selectedGstCountry ? gstDetailsObj.selectedGstCountry.itemId : null;
                this.Form.country = gstDetailsObj.selectedGstCountry ? gstDetailsObj.selectedGstCountry.itemName : null;

                this.Form.gstNumber = gstDetailsObj.gstNumber ? gstDetailsObj.gstNumber.toUpperCase() : '';
                // this.Form.placeOfSupplyId = gstDetailsObj.placeOfSupplyId;
                // this.Form.placeOfSupply = gstDetailsObj.placeOfSupply;
                this.Form.panNumber = gstDetailsObj.panNumber ? gstDetailsObj.panNumber.toUpperCase() : '';
                this.Form.currency = gstDetailsObj.currency ? gstDetailsObj.currency : null;

                if(this.Form.gstType==105){
                    this.Form.placeOfSupplyId = null;
                    this.Form.placeOfSupply = null;
                    this.Form.gstNumber = null;
                    this.Form.panNumber = null;
                }
                else{
                    this.Form.countryId = null;
                    this.Form.country = null;
                }
                // this.Form.countryId = gstDetailsObj.countryId;
                // this.Form.country = gstDetailsObj.country;
                this.$refs['ref_dialogGstDetails'].closeDialogWeb();

                // this.fn_closeDialogWeb();
            },

            fn_saveAddressNew: function(addressObj){
                if(!addressObj.addressId){
                    addressObj.addressId = this.guid();
                }
                
                if(this.Form.selectedState){
                    addressObj.stateId = this.Form.selectedState.itemId;
                    addressObj.state = this.Form.selectedState.itemName;
                }

                if(this.Form.selectedCountry){
                    // addressObj.countryId = this.Form.selectedCountry.itemId;
                    addressObj.country = this.Form.selectedCountry.itemName;
                }

                if(this.Form.addressData.groupTypeId==101){
                    this.Form.billingAddress = JSON.parse(JSON.stringify(addressObj));
                    if(addressObj.isSameAddress){
                        this.Form.shippingAddress = JSON.parse(JSON.stringify(addressObj));
                        this.Form.shippingAddress.addressId = this.guid();
                        this.Form.shippingAddress.groupTypeId = 102;
                    }
                }
                else{
                    this.Form.shippingAddress = JSON.parse(JSON.stringify(addressObj));
                }

                this.$refs['ref_dialogAddress'].closeDialogWeb();

                // this.fn_closeDialogWeb();
            },

            // fn_saveAddress: function(addressObj, type, copyData){
            // fn_saveAddress: function(addressObj){

            //     console.log(JSON.stringify(addressObj));

            //     if(!addressObj.addressId){
            //         // alert(!addressObj.addressId);
            //         // !addressObj.addressId ? addressObj.addressId = this.guid() : '';
            //         addressObj.addressId = this.guid();
            //         console.log(JSON.stringify(addressObj));
            //         this.Form.addresses.push(JSON.parse(JSON.stringify(addressObj)));

            //         if(addressObj.isSameAddress){
            //             let shippingAddressObj = JSON.parse(JSON.stringify(addressObj));
            //             shippingAddressObj.addressId = this.guid();
            //             shippingAddressObj.groupTypeId = 102;
            //             this.Form.addresses.push(shippingAddressObj);
            //         }
            //     }
            //     else{
            //         console.log(JSON.stringify(this.Form.addresses));
            //         let itemIndex = this.Form.addresses.findIndex(item=>{
            //             alert(item.groupTypeId);
            //             return addressObj.addressId == item.addressId;
            //         });

            //         console.log(JSON.stringify(addressObj));
            //         // this.Form.addresses.splice(itemIndex, 1, addressObj);
            //         this.Form.addresses[itemIndex] = JSON.parse(JSON.stringify(addressObj));


            //         // alert(addressObj.isSameAddress);
            //         if(addressObj.groupTypeId == 101 && addressObj.isSameAddress){
            //             var shippingAddressObj = JSON.parse(JSON.stringify(addressObj));
            //             shippingAddressObj.groupTypeId = 102;

            //             if(this.Form.addresses.length == 1){
            //                 shippingAddressObj.addressId = this.guid();
            //                 this.Form.addresses.push(shippingAddressObj);
            //             }
            //             else{
            //                 let itemIndex = this.Form.addresses.findIndex(item=>{
            //                     alert(item.groupTypeId);
            //                     return item.groupTypeId == 102;
            //                 });
            //                 this.Form.addresses[itemIndex] = JSON.parse(JSON.stringify(shippingAddressObj));    
            //             }
            //         }
            //     }

            //     console.log(JSON.stringify(this.Form.addresses));

            //     this.fn_assignAddress();

            // },

            fn_assignAddress: function(){
                for(let i=0; i<this.Form.addresses.length; i++){
                    if(this.Form.addresses[i].groupTypeId == 101){
                        this.Form.billingAddress = JSON.parse(JSON.stringify(this.Form.addresses[i]));
                    }
                    else{
                        this.Form.shippingAddress = JSON.parse(JSON.stringify(this.Form.addresses[i])); 
                    }
                }
            },

             fn_addAddress: function (groupTypeId) {
                this.Form.addressData = {"addressId":null, "groupTypeId":groupTypeId, "addressLine":null, "city":null, "state":null, "countryId":"d87bedca-5bcc-4241-8027-7e16f3b68f18", "country":"India", "pincode":null, "phone":null, "isSameAddress":groupTypeId == 101 ? true : false};

                this.Form.selectedState = null;

                if(this.Form.gstType == 105){
                    // this.Form.selectedCountry = {"itemId":this.Form.countryId, "itemName":this.Form.country}
                    this.Form.selectedCountry = {"itemName":this.Form.country}
                }
                else{
                    // this.Form.selectedCountry = {"itemId":"d87bedca-5bcc-4241-8027-7e16f3b68f18", "itemName":"India"};
                    this.Form.selectedCountry = {"itemName":"India"};
                }

                this.$refs['ref_dialogAddress'].openDialogWeb();
                // this.fn_openDialogAddress();
            },

            fn_editAddress: function (groupTypeId) {
                // if(addressObj.groupTypeId == 101){
                //  addressObj.isBillingAddress = true;
                //  addressObj.isShippingAddress = false;
                // }
                // else if(addressObj.groupTypeId == 102){
                //  addressObj.isBillingAddress = false;
                //  addressObj.isShippingAddress = true;
                // }
                // else{
                //  addressObj.isBillingAddress = true;
                //  addressObj.isShippingAddress = true;
                // }

                let addressObj = groupTypeId == 101 ? JSON.parse(JSON.stringify(this.Form.billingAddress)) : JSON.parse(JSON.stringify(this.Form.shippingAddress));

                console.log(addressObj);

                this.Form.addressData.isSameAddress = false;
                this.Form.addressData.addressId = addressObj.addressId;
                this.Form.addressData.addressLine = addressObj.addressLine;
                this.Form.addressData.city = addressObj.city;

                if(addressObj.country){
                    this.Form.selectedCountry = {"itemName":addressObj.country};
                }

                if(addressObj.stateId){
                    this.Form.selectedState = {"itemId":addressObj.stateId, "itemName":addressObj.state};
                }

                this.Form.addressData.groupTypeId = addressObj.groupTypeId;
                this.Form.addressData.pincode = addressObj.pincode;

                this.Form.addressData.phone = addressObj.phone;

                if(groupTypeId == 101 && this.Form.shippingAddress){
                    if(this.fn_mergeAddress(101) == this.fn_mergeAddress(102)){
                        this.Form.addressData.isSameAddress = true;
                    }
                }
                
                this.$refs['ref_dialogAddress'].openDialogWeb();
            },


            // fn_editAddress: function (addressObj) {
            //  if(addressObj.groupTypeId == 101){
            //      addressObj.isBillingAddress = true;
            //      addressObj.isShippingAddress = false;
            //  }
            //  else if(addressObj.groupTypeId == 102){
            //      addressObj.isBillingAddress = false;
            //      addressObj.isShippingAddress = true;
            //  }
            //  else{
            //      addressObj.isBillingAddress = true;
            //      addressObj.isShippingAddress = true;
            //  }

            //  this.Form.addressData.addressId = addressObj.addressId;
            //  this.Form.addressData.addressLine = addressObj.addressLine;
            //  this.Form.addressData.city = addressObj.city;
            //  this.Form.addressData.country = addressObj.country;
            //  addressObj.countryId ? this.Form.addressData.countryId = addressObj.countryId : '';
            //  this.Form.addressData.groupTypeId = addressObj.groupTypeId;
            //  this.Form.addressData.isBillingAddress = addressObj.isBillingAddress;
            //  this.Form.addressData.isShippingAddress = addressObj.isShippingAddress;
            //  this.Form.addressData.pincode = addressObj.pincode;
            //  this.Form.addressData.state = addressObj.state;
            //  addressObj.stateId ? this.Form.addressData.stateId = addressObj.stateId : '';

            //  this.Form.addressData.phone = addressObj.phone;
                
            //  this.fn_openDialogAddress();
            // },

            fn_mergeAddress: function(addressGroupTypeId){

                let addressObj = addressGroupTypeId == 101 ? this.Form.billingAddress : this.Form.shippingAddress;

                if(!addressObj){
                    return '';
                }

                let addressLine, city, state, pincode, country;

                addressObj.addressLine ? addressLine = addressObj.addressLine + ', ' : addressLine = '';
                addressObj.city ? city = addressObj.city + ', ' : city = '';
                addressObj.state ? state = addressObj.state + ', ' : state = '';
                addressObj.pincode ? pincode = addressObj.pincode + ', ' : pincode = '';
                addressObj.country ? country = addressObj.country : country = '';

                return addressLine + city + state + pincode + country;
            },

            // fn_mergeAddress: function(addressObj){

            //  let addressLine, city, state, pincode, country;

            //  addressObj.addressLine ? addressLine = addressObj.addressLine + ', ' : addressLine = '';
            //  addressObj.city ? city = addressObj.city + ', ' : city = '';
            //  addressObj.state ? state = addressObj.state + ', ' : state = '';
            //  addressObj.pincode ? pincode = addressObj.pincode + ', ' : pincode = '';
            //  addressObj.country ? country = addressObj.country : country = '';

            //  return addressLine + city + state + pincode + country;
            // },

            // fn_removeAddress: function (addressId, index) {
            //  this.Form.addresses.splice(index, 1);               
            // },

            fn_removeAddress: function (groupTypeId) {

                if(groupTypeId==101){
                    this.Form.billingAddress = null;
                }
                else{
                    this.Form.shippingAddress = null;
                }
                // let itemIndex = this.Form.addresses.findIndex(item=>{
                //  return item.groupTypeId == groupTypeId;
                // });
                // this.Form.addresses.splice(itemIndex, 1);
            },

            // fn_asignGstType: function (gstType) {
            //     this.fn_closeDialogWeb();
            //     this.Form.selectedGstType = gstType.title;
            //     this.Form.accountGstFieldTemp.gstType = gstType.id;
            // },

            // fn_checkAndUpdateGstType: function (gstDetailsObj) {
            //     this.Form.selectedGstType = this.fn_getGstTypeName(gstDetailsObj.gstType);

            //     if(gstDetailsObj.gstType!=105){
            //         this.Form.accountGstFieldTemp.currency = null;
            //         this.Form.accountGstFieldTemp.countryId = null;
            //         this.Form.accountGstFieldTemp.country = null;
            //     }
            //     else{
            //         this.Form.accountGstFieldTemp.gstNumber = null;
            //         this.Form.accountGstFieldTemp.panNumber = null;
            //         this.Form.accountGstFieldTemp.placeOfSupplyId = null;
            //         this.Form.accountGstFieldTemp.placeOfSupply = null;
            //     }
                
            //     if(gstDetailsObj.gstType==103 || gstDetailsObj.gstType==104){
            //         this.Form.accountGstFieldTemp.gstNumber = null;
            //     }

            //     this.$forceUpdate();
            //     this.fn_saveGstDetails(this.Form.accountGstFieldTemp);
            // },

            // fn_openAccountGroupsDialog: function (platform, name, ref) {

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  //dialogObj.data = null;
            //  dialogObj.platform = platform;

            //  var viewDialogObject = {};
            //  viewDialogObject.ToolbarTitle = "Select Account Group";
            //  viewDialogObject.TopMenu = [menuItem.new];
            //  viewDialogObject.isBottomMenu = false;

            //  // viewDialogObject.accountGroupIds = this.Form.groupTypeId == 101 ? [accountGroupIds.debtors.id] : this.Form.groupTypeId == 102 ? [accountGroupIds.creditors.id] : this.Form.groupTypeId == 104 ? [accountGroupIds.brandAndDivision.id] : null;    

            //  if(this.Form.groupTypeId == 101){
            //      // viewDialogObject.accountGroupIds = this.$route.query.accountgroup ? [this.$route.query.accountgroup.id] : [accountGroupIds.debtors.id];
            //      viewDialogObject.accountGroupIds = this.Form.accountGroupId ? [accountGroupIds.debtors.id, this.Form.accountGroupId] : [accountGroupIds.debtors.id];
            //  }
            //  else if(this.Form.groupTypeId == 102){
            //      viewDialogObject.accountGroupIds = [accountGroupIds.creditors.id];
            //  }
            //  else if(this.Form.groupTypeId == 104){
            //      viewDialogObject.accountGroupIds = [accountGroupIds.brandAndDivision.id];
            //  }

            //  var paramObject = {};
            //  paramObject.defaultValue = {"id":null, "name":null};

            //  if(this.Form.accountGroupId && this.Form.accountGroupName){
            //      paramObject.defaultValue = {"id":this.Form.accountGroupId, "name":this.Form.accountGroupName};
            //  }

            //  dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, 'accountgroups', tagId, paramObject, ref);
            // },

            fn_openStates: function(ref){
                this.fn_openStateDialog('android', 'State', ref);
            },

            // fn_openStateDialog: function (platform, name, ref) {

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  //dialogObj.data = null;
            //  dialogObj.platform = platform;

            //  var viewDialogObject = {};
            //  viewDialogObject.ToolbarTitle = "Select State";
            //  viewDialogObject.TopMenu = [menuItem.new];
            //  viewDialogObject.isBottomMenu = false;

            //  var paramObject = {};
            //  paramObject.defaultValue = {"id":null, "name":null};

            //  if(ref=='ref_dialogAddress_state'){
            //      if(this.Form.addressData.stateId && this.Form.addressData.state){
            //          paramObject.defaultValue = {"id":this.Form.addressData.stateId, "name":this.Form.addressData.state};
            //      }
            //  }
            //  else{
            //      if(this.Form.accountGstFieldTemp.placeOfSupplyId && this.Form.accountGstFieldTemp.placeOfSupply){
            //          paramObject.defaultValue = {"id":this.Form.accountGstFieldTemp.placeOfSupplyId, "name":this.Form.accountGstFieldTemp.placeOfSupply};
            //      }
            //  }

            //  dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, 'state', tagId, paramObject, ref);
            // },

            // fn_openCountries: function(ref, type){
            //  if(type=='address')
            //      this.fn_openCountryDialog('android', 'Country', ref, this.Form.addressData.countryId, this.Form.addressData.country);
            //  else
            //      this.fn_openCountryDialog('android', 'Country', ref, this.Form.accountGstFieldTemp.countryId, this.Form.accountGstFieldTemp.country);
            // },

            // fn_openCountryDialog: function (platform, name, ref, countryId, country) {

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  //dialogObj.data = null;
            //  dialogObj.platform = platform;

            //  var viewDialogObject = {};
            //  viewDialogObject.ToolbarTitle = "Select Country";
            //  viewDialogObject.TopMenu = [menuItem.new];
            //  viewDialogObject.isBottomMenu = false;

            //  var paramObject = {};
            //  paramObject.defaultValue = {"id":null, "name":null};

            //  if(countryId && country){
            //      paramObject.defaultValue = {"id":countryId, "name":country};
            //  }

            //  dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, 'country', tagId, paramObject, ref);
            // },

            

            // fn_onChange: function(tagId, ref, selectedValueId, selectedValueName, isRemoved){
            //  if(ref.indexOf('dialog') != -1){
            //      let key = ref.substring(0, ref.lastIndexOf('_'));
            //      let dialogRef = this.$refs[key];
        
            //      if(Array.isArray(dialogRef.$refs[ref])){
         //                 dialogRef.$refs[ref][0].$attrs.onUpdate(selectedValueId, selectedValueName, isRemoved);
         //             }
         //             else{
         //                 dialogRef.$refs[ref].$attrs.onUpdate(selectedValueId, selectedValueName, isRemoved);
         //             }
            //  }
            //  else{
            //      this.$refs[ref].$attrs.onUpdate(selectedValueId, selectedValueName, isRemoved);
            //  }
            // },

            fn_updateAccountGroup: function (selectedValueId, selectedValueName, isRemoved) {
             if(!isRemoved){
                 this.Form.accountGroupId = selectedValueId;
                 this.Form.accountGroupName = selectedValueName;
                 // this.errors.remove('account group');

                 // if(selectedValueId != this.Form.accountGroupId && this.Form.accountGroupId == accountGroupIds.runningCustomer.id){
                 //  this.Form.gstType = 104;
                 //  this.Form.placeOfSupplyId = this.$root.CurrentBusiness.StateID;
                 //  this.Form.placeOfSupply = this.$root.CurrentBusiness.State;
                 //  this.Form.gstNumber = null;
                 //  this.Form.panNumber = null;
                 //  this.Form.country = null;
                 // }
             }
             else{
                 this.Form.accountGroupId = null;
                 this.Form.accountGroupName = null;
             }
            },

            // fn_updateState: function (selectedValueId, selectedValueName, isRemoved) {
            //  if(!isRemoved){
            //      this.Form.addressData.stateId = selectedValueId;
         //         this.Form.addressData.state = selectedValueName;
            //  }
            //  else{
            //      this.Form.addressData.stateId = null;
         //         this.Form.addressData.state = null;
            //  }
            // },

            // fn_updateCountry: function (selectedValueId, selectedValueName, isRemoved) {
            //  if(!isRemoved){
            //      this.Form.addressData.countryId = selectedValueId;
         //         this.Form.addressData.country = selectedValueName;
         //         this.Form.addressData.stateId = null;
         //         this.Form.addressData.state = null;
         //         selectedValueName != 'India' ? this.fn_getOtherStates(selectedValueName) : '';
            //  }
            //  else{
            //      this.Form.addressData.countryId = null;
         //         this.Form.addressData.country = null;
            //  }
            // },

            fn_getOtherStates: function(country){
                let filterObject = {};
                filterObject.country = country;

                let self = this;
                this.getFilterData('OtherStates', null, filterObject, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            if(response){
                                self.Form.otherStates = JSON.parse(response);
                            }
                            else{
                                self.Form.otherStates = [];
                            }
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            // fn_updateOverseasCountry: function (selectedValueId, selectedValueName, isRemoved) {
            //  if(!isRemoved){
            //      this.Form.accountGstFieldTemp.countryId = selectedValueId;
         //         this.Form.accountGstFieldTemp.country = selectedValueName;
            //  }
            //  else{
            //      this.Form.accountGstFieldTemp.countryId = null;
         //         this.Form.accountGstFieldTemp.country = null;
            //  }
            // },

            // fn_updatePlaceOfSupply: function (selectedValueId, selectedValueName, isRemoved) {
            //  if(!isRemoved){
            //      this.Form.accountGstFieldTemp.placeOfSupplyId = selectedValueId;
         //         this.Form.accountGstFieldTemp.placeOfSupply = selectedValueName;
         //         this.errors.remove('gstdetails.PlaceofSupply');
            //  }
            //  else{
            //      this.Form.accountGstFieldTemp.placeOfSupplyId = null;
         //         this.Form.accountGstFieldTemp.placeOfSupply = null;
            //  }
            // },

            // fn_getBottomMenu: function (tagId) {
            //  var bottomMenu = {"BottomMenu": [menuItem.save, menuItem.reset, menuItem.contacts, menuItem.help]};
            //  this.setBottomMenu(tagId, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            // fn_closeDialog: function (tagId) {
            //  // dialogjs.fn_closeDialog(this, tagId);
            //  dialogjs.fn_checkAndCloseOpenDialogs(this);
            // },

            fn_checkValidations: function(){
                this.fn_post();
            },

            // fn_openDialogAddress: function(){
            //  this.fn_openDialogWeb('web', 'Address', 'ref_dialogAddress');
            // },

            // fn_closeAddressDialog: function () {
            //  // this.fn_closeDialogWeb();
            //  this.$refs['ref_dialogAddress'].closeDialogWeb();
            // },

            fn_saveLocal: function(dataObj){
                var self = this;
                this.postData('Ledger', dataObj, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            let status = JSON.parse(response);
                            if(status) {
                                // self.fn_cleanUp();
                                if(self.Form.openingAmount){
                                    self.fn_postOpening();
                                }
                                else{
                                    if(self.Form.transactionId){
                                        self.fn_deleteTransaction(function(status){
                                            if(status){
                                                self.fn_cleanUp();
                                            }
                                        })
                                    }
                                    else{
                                        self.fn_cleanUp();
                                    }
                                }

                                self.$root.fn_getMasterItems('ledgers', ["Ledger", "deleteAccountWithOpening"], false, true);
                            }
                            else {
                                // self.hideLoading();
                                self.disableOnSave = false;
                                self.saveBtnKey++;
                                // alert("Already exists with this name");
                                self.$eventHub.$emit('snackbarAlert', {msg:'Already exists with this name'});
                            }
                        },
                        onError: function(error){
                            self.disableOnSave = false;
                            self.saveBtnKey++;
                            console.log(error);
                            self.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong. Try again'});
                            // self.hideLoading();
                        }
                    }
                );
            },

            fn_deleteTransaction: function(callback){
                let self = this;
                let filterObject = {};
                filterObject.transactionListId = [40003];
                this.postFilterData('deleteTransaction', this.Form.transactionId, filterObject, {

                    onSuccess: function(response){
                        
                        console.log(response);

                        callback(JSON.parse(response));

                    }, 
                    onError: function(error){
                        console.log(error);
                        let status = JSON.parse(error);
                        if(status && status.itemsCount){
                            let msg = 'Unable to delete as this item already in use under [' + status.items.join(', ') + ']';
                            // alert(msg);
                            self.$eventHub.$emit('snackbarAlert', {msg:msg});
                        }
                    }
                });
            },

            fn_cleanUp: function () {
                // this.loadedFromDraft = false;

                if(this.$route.query.ls){
                    let data = {};
                    data.type = this.$route.query.type ? this.$route.query.type : 'ledger';
                    data.id = this.Form.accountId;
                    data.name = this.Form.name;
                    data.accountGroupId = this.Form.accountGroupId;
                    data.amountType = this.$route.query.type != undefined ? this.$route.query.amounttype : null;
                    data.groupId = this.$route.query.groupid != undefined ? this.$route.query.groupid : null;

                    data.itemIndex = this.$route.query.itemIndex != undefined ? this.$route.query.itemIndex : null;
                    data.sectionId = this.$route.query.sectionId != undefined ? this.$route.query.sectionId : null;
                    localStorage.setItem('newItem', JSON.stringify(data));
                    // setting up storage to close dialog if (dlg means dialog should not close) not in the query
                    // if(!this.$route.query.dlg){
                    //     localStorage.setItem('closeDialog', 1); 
                    // }
                }

                // setting up last saved configuration to remember
                // let formConfig = {};
                // formConfig.groupTypeId = this.Form.groupTypeId;
                // formConfig.accountGroupId = this.Form.accountGroupId;
                // formConfig.accountGroupName = this.Form.accountGroupName;
                // localStorage.setItem('lastLedgerFormConfig', JSON.stringify(formConfig));

                this.$eventHub.$emit('UpdateLedgerList', {});
                this.fn_goBack(1);

            },

            // fn_generateMobileNumbers: function () {
            //  this.Form.mobile = '';

            //  for(let i=0; i<this.Form.mobileNumbers.length; i++){
            //      if(!this.Form.mobileNumbers[i]){
            //          this.Form.mobileNumbers.splice(i, 1);
            //      }
            //  }

            //  if(this.Form.mobileNumbers.length){
            //      for(let index=0; index<this.Form.mobileNumbers.length; index++){
            //          if(this.Form.mobileNumbers.length-1 != index){
            //              this.Form.mobileNumbers[index] ? this.Form.mobile += this.Form.mobileNumbers[index]+', ':'';
            //          }
            //          else{
            //              this.Form.mobileNumbers[index] ? this.Form.mobile += this.Form.mobileNumbers[index]:'';
            //          }
            //      }
            //  }
            // },

            // fn_appendMobileNumbers: function (mobileNumberString) {
            //  let Numbers = [];
            //  this.Form.mobileNumbers = [];
            //  this.Form.mobileNumberFields = [];
            //  mobileNumberString.indexOf(', ') != -1 ? Numbers = mobileNumberString.split(', ') : Numbers.push(mobileNumberString);
                

            //  for(let index=0; index<Numbers.length; index++){

            //      let obj = {fieldName:'Mobile Number '+ (this.Form.mobileNumberFields.length+1)}

            //      this.Form.mobileNumberFields.push(obj);

            //      this.Form.mobileNumbers.push(Numbers[index]);
            //  }
            // },

            // fn_generateEmails: function () {
            //  this.Form.email = '';

            //  for(let i=0; i<this.Form.emails.length; i++){
            //      if(!this.Form.emails[i]){
            //          this.Form.emails.splice(i, 1);
            //      }
            //  }

            //  if(this.Form.emails.length){
            //      for(let index=0; index<this.Form.emails.length; index++){
            //          if(this.Form.emails.length-1 != index){
            //              this.Form.emails[index] ? this.Form.email += this.Form.emails[index]+', ':'';
            //          }
            //          else{
            //              this.Form.emails[index] ? this.Form.email += this.Form.emails[index]:'';
            //          }
            //      }
            //  }
            // },

            // fn_appendEmails: function (emailString) {
            //  let Emails = [];
            //  this.Form.emails = [];
            //  this.Form.emailFields = [];
            //  emailString.indexOf(',') != -1 ? Emails = emailString.split(',') : Emails.push(emailString);


            //  for(let index=0; index<Emails.length; index++){

            //      let obj = {fieldName:'Email '+ (this.Form.emailFields.length+1)}

            //      this.Form.emailFields.push(obj);
            //      this.Form.emails.push(Emails[index]);
            //  }
            // },

            fn_clearFields: function () {
                this.Form.mobile = null;
                this.Form.email = null;
                this.Form.addresses = [];
                this.Form.selectedGstType = null;
                this.Form.routeId = null;
                this.Form.routeName = null;
            },

            fn_post: function(){

                // this.showLoading("Please wait...");
                // !this.Form.accountId ? this.Form.accountId = this.guid() : '';
                if(!this.Form.accountId){
                    this.Form.accountId = this.guid();
                    // this.Form.createdDate = new Date();
                    this.Form.createdDate = this.$root.fn_getLocalDateFormat();
                }

                // assigning account group id
                this.Form.accountGroupId = this.Form.selectedAccountGroup.itemId;

                // assigning gst details
                // if(this.Form.selectedGstType){
                //     this.Form.gstType = this.Form.selectedGstType.id;
                //     this.Form.placeOfSupplyId = this.Form.selectedGstState ? this.Form.selectedGstState.itemId : null;
                //     this.Form.placeOfSupply = this.Form.selectedGstState ? this.Form.selectedGstState.itemName : null;
                //     this.Form.countryId = this.Form.selectedGstCountry ? this.Form.selectedGstCountry.itemId : null;
                //     this.Form.country = this.Form.selectedGstCountry ? this.Form.selectedGstCountry.itemName : null;    
                // }

                if(!this.Form.gstType){
					this.Form.gstType = this.Form.groupTypeId == 101 ? 104 : this.Form.groupTypeId == 102 ? 103 : null;
				}
                                

                // this.Form.modifiedDate = new Date();
                this.Form.modifiedDate = this.$root.fn_getLocalDateFormat();
                
                this.Form.name = this.Form.name.trim();

                if(this.Form.groupTypeId != 101){
                    this.Form.routeId = null;
                    this.Form.routeName = null;
                }

                if(this.Form.groupTypeId == 103){
                    this.fn_clearFields();
                }

                // if(this.Form.groupTypeId != 103){
                //  this.fn_generateMobileNumbers();
                //  this.fn_generateEmails();
                // }
                // else{
                //  this.fn_clearFields();
                // }

                // this.Form.printDetails =  JSON.stringify(this.Form.printDetails);

                if(!this.Form.gstType){
                    this.Form.gstType = this.Form.groupTypeId == 101 ? 104 : this.Form.groupTypeId == 102 ? 103 : null;
                }
                // !this.Form.gstType ? this.Form.gstType = 104 : '';

                this.Form.addresses = [];

                if(this.Form.billingAddress){
                    this.Form.addresses.push(this.Form.billingAddress);
                }

                if(this.Form.shippingAddress){
                    this.Form.addresses.push(this.Form.shippingAddress);
                }

                console.log(this.Form);
                this.fn_saveLocal(this.Form);
            },

            fn_get: function(itemId, copyData){
                var self = this;
                this.unwatch = true;
                this.Form.isNew = false;
                this.getData('Ledger', itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            
                            let item = JSON.parse(response);
                            
                            self.unwatch = true;
                            self.Form.accountId = item.accountId;
                            self.Form.groupTypeId = item.groupTypeId;
                            self.Form.name = item.name;             
                            self.Form.accountGroupId = item.accountGroupId;
                            self.Form.accountGroupName = item.accountGroupName;

                            self.Form.selectedAccountGroup = {"itemId":item.accountGroupId, "itemName":item.accountGroupName};

                            self.Form.routeId = item.routeId;
                            self.Form.routeName = item.routeName;

                            self.Form.email = item.email;
                            // self.Form.phone = item.phone;

                            self.Form.addresses = JSON.parse(JSON.stringify(item.addresses));

                            if(item.gstType){
                                // self.Form.selectedGstType = self.fn_getGstTypeName(item.gstType);
                                self.Form.gstType = item.gstType;
                                self.Form.gstNumber = item.gstNumber;
                                self.Form.placeOfSupplyId = item.placeOfSupplyId;
                                self.Form.placeOfSupply = item.placeOfSupply;
                                self.Form.panNumber = item.panNumber;
                                self.Form.currency = item.currency;
                                self.Form.countryId = item.countryId;
                                self.Form.country = item.country;

                                self.Form.selectedGstType = {"id":item.gstType, "title":self.fn_getGstTypeName(item.gstType)};
                                if(item.placeOfSupplyId && item.placeOfSupply){
                                    self.Form.selectedGstState = {"itemId":item.placeOfSupplyId, "itemName":item.placeOfSupply};    
                                }

                                if(item.countryId && item.country){
                                    self.Form.selectedGstCountry = {"itemId":item.countryId, "itemName":item.country};    
                                }
                                
                            }

                            self.Form.primaryMobileNumber = item.primaryMobileNumber;
                            
                            // item.mobile ? self.fn_appendMobileNumbers(item.mobile) : '';
                            // item.email ? self.fn_appendEmails(item.email) : '';

                            // item.printDetails ? self.Form.printDetails = JSON.parse(item.printDetails) : '';

                            self.Form.isSystem = item.isSystem;
                            self.Form.msmeUdyamNumber = item.msmeUdyamNumber;

                            if(copyData){
                                self.Form.accountId=null;
                                self.Form.isSystem = 0;
                                self.fn_saveDraft(self.draftStorageId, self.Form.name);
                                // alert("Ledger copied");
                                self.$eventHub.$emit('snackbarAlert', {msg:'Ledger copied'});

                                if(self.Form.addresses && self.Form.addresses.length){
                                    for(let i=0; i<self.Form.addresses.length; i++){
                                        self.Form.addresses[i].addressId = self.guid();
                                        delete self.Form.addresses[i].baseAccountId;
                                    }

                                    console.log(JSON.stringify(self.Form.addresses));
                                }
                            }
                            else{
                                if(item.opening){
                                    self.Form.openingAmount = Math.abs(item.opening.amount);
                                    self.Form.openingNature = item.opening.amountType;
                                    self.Form.transactionId = item.opening.transactionId;
                                }
                            }

                            self.fn_assignAddress();

                            self.fn_groupTypeChange(self.Form.groupTypeId, true);

                            self.$root.fn_copyFormObject(self.Form, self.formId);

                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            // fn_clearForm: function(){
            //  this.unwatch = true;
            //  this.Form = {"name":null, "accountId":null, "groupTypeId":101, "accountGroupId":null, "accountGroupName":null, 
            //  // "mobile":null, 
            //  "email":null, //"phone":null, 
            //  // "printDetails": {"printName":null},

            //      "accountGstFieldTemp":{"gstType":null, "gstNumber":null, "placeOfSupply":null, "placeOfSupplyId":null, "panNumber":null, "currency":null, "countryId":null, "country":null},

            //      "gstType":null, "gstNumber":null, "placeOfSupplyId":null, "panNumber":null, "currency":null, "countryId":null, "country":null,

            //      // "addressData":{"groupTypeId":103, "addressLine":null, "city":null, "state":null, "countryId":"d87bedca-5bcc-4241-8027-7e16f3b68f18", "country":"India", "pincode":null, "isBillingAddress":true, "isShippingAddress":true, "phone":null},

            //      "addressData":{"addressId":null, "groupTypeId":103, "addressLine":null, "city":null, "state":null, "countryId":"d87bedca-5bcc-4241-8027-7e16f3b68f18", "country":"India", "pincode":null, "phone":null, "isSameAddress":true},

            //      // "mobileNumberFields":[],
            //      // "mobileNumbers":[],

            //      // "emailFields":[{"fieldName":"Email 1"}],
            //      // "emails":[],

            //      "selectedGstType": "Registered Business - Regular",
            //      "routeId":null, "routeName":null,
            //      "isSystem":0,
            //      "primaryMobileNumber":null,
            //      "draftItems":[],
            //      "createdDate":null,
            //      "modifiedDate":null,
            //      "isNew":true,
            //      "billingAddress":null,
            //      "shippingAddress":null
            //  }

            //  if(this.$route.query.groupTypeId){
            //      this.Form.groupTypeId = this.$route.query.groupTypeId;
            //  }

            //  this.fn_groupTypeChange(this.Form.groupTypeId);
                
            //  this.$validator.reset();
            // },

            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //  // tagId id form future use
            //  // menuId id form future use
            //  this.backButtonPress = false;

            //  if(menuAction=='save'){
            //      this.fn_checkValidations();
            //  }
            //  else if(menuAction=='new'){
            //      this.$router.push({path:'/masters/accounts/groups/new', query:{tagId:this.guidWeb(), ls:1}})
            //  }
            //  else if(menuAction=='newRoute'){
            //      this.$router.push({path:'/masters/lists/'+predefinedListIds.routeListGUID+'/items/new', query:{tagId:this.guidWeb(), listTitle:"Beat/Area/Route", ls:1, type:'route'}});
            //  }
            //  else if(menuAction=='contacts'){
            //      this.fn_showContactList();
            //  }
            //  else if(menuAction=='reset'){
            //      this.fn_resetForm();
            //  }
            // },

            // fn_resetForm: function () {
            //  this.unwatch = true;
            //  // this.loadedFromDraft = false;

            //  if(this.Form.accountId){
            //      this.fn_get(this.Form.accountId);
            //  }
            //  else{
            //      this.fn_clearForm();
            //  }
                
            //  let self = this;
            //  this.fn_removeDraft(this.draftStorageId, function(){
            //      console.log("draft deleted");
            //      // self.showToast("Item deleted from draft");
            //  });
            // },

            // fn_showWebView: function () {
            //  var viewObject = {};
            //  viewObject.ToolbarTitle = "Account Ledger";
            //  viewObject.TopMenu = [menuItem.save];
            //  viewObject.isBottomMenu = true;

            //  var tagId = this.formId;
            //  var self = this;
            //  this.showWebView(tagId, viewObject, 
            //      {
            //          onSuccess: function(response){
            //            console.log(response);
            //          }, 
            //          onError: function(error){
            //            console.log(error);
            //          }, 
            //          // onChange: null,
            //          onMenuItemClick: function (tagId, menuId, menuAction) {
            //              self.fn_menuItemClick(tagId, menuId, menuAction)
            //          }
            //      }
            //  );
            // },

            // fn_openDialogPhoneNumbers: function(){
            //  this.fn_openDialogWeb('web', 'PhoneNumbers', 'ref_dialogPhoneNumber');
            // },

            // fn_showContactList: function () {
            //  let self = this;
            //  this.showContactList({
            //          onSuccess: function(response){
            //              console.log(response);
            //              if(response){
            //                  self.Form.contactInfo = JSON.parse(response);

            //                  // self.Form.primaryMobileNumber = contactInfo.mobileArray[0];

            //                  // contactInfo.mobileArray.splice(0, 1);

            //                  // let mobileNumbers = contactInfo.mobileArray.join(', ');
            //                  // let emails = contactInfo.emailArray.join(', ');

                                
            //                  // mobileNumbers ? self.fn_appendMobileNumbers(mobileNumbers) : '';
            //                  // emails ? self.fn_appendEmails(emails) : '';

            //                  // contactInfo.other && contactInfo.other.length ? self.Form.phone = contactInfo.other[0] : '';

            //                  self.Form.phoneNumberList = [];

            //                  if(self.Form.contactInfo.mobileArray.length == 1){
            //                      self.Form.primaryMobileNumber = self.Form.contactInfo.mobileArray[0];
            //                      self.Form.email = self.Form.contactInfo.emailArray[0];
            //                      self.Form.contactInfo.name ? self.Form.name = self.Form.contactInfo.name : '';
            //                      // self.Form.printDetails.printName = self.Form.name;
            //                  }
            //                  else if(self.Form.contactInfo.mobileArray.length > 1){
            //                      self.Form.phoneNumberList = self.Form.contactInfo.mobileArray;
            //                      self.fn_openDialogPhoneNumbers();
            //                  }

            //                  self.errors.remove('name');
            //              }
            //          }, 
            //          onError: function(error){
            //            console.log(error);
            //          }
            //      }
            //  );
            // },

            // fn_setPhoneNumber: function(phoneNumber){
            //  this.Form.primaryMobileNumber = phoneNumber;
            //  this.Form.email = this.Form.contactInfo.emailArray[0];
      //        this.Form.contactInfo.name ? this.Form.name = this.Form.contactInfo.name : '';
      //        // this.Form.printDetails.printName = this.Form.name;
            //  this.fn_closeDialog();
            // },

            fn_setNewItem: function(){
                if(localStorage.getItem('newItem')){

                    let itemData = JSON.parse(localStorage.getItem('newItem'));

                    switch (itemData.type) {
                        case "accountGroup":
                            // this.fn_updateAccountGroup(itemData.id, itemData.name, false);
                            this.Form.selectedAccountGroup = {"itemId":itemData.id, "itemName":itemData.name};
                            break;
                        case "route":
                            this.fn_updateRoute(itemData.id, itemData.name, false);
                            break;
                        default:
                            console.log("condition not matched");
                    }

                    localStorage.removeItem('newItem');
                }

                this.Form.isNew ? this.$root.fn_copyFormObject(this.Form, this.formId) : '';
            },

            fn_goBack: function(checkBackBtn){
                // !checkBackBtn ? this.backButtonPress = true : '';
                if(checkBackBtn === 1 && this.$route.query && this.$route.query.routeToDisplay){
                    // this.$router.replace({path:'/masters/accounts/ledgers/items/'+this.Form.accountId, query:{"showMenu":true}})
                    this.$router.replace({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/ledger/items/'+this.Form.accountId});
                }
                else{
                    this.$root.viaKeyboardShortCut = false;
                    this.$refs['ref_dialogForm'].closeDialogWeb();
                    localStorage.removeItem(this.draftStorageId);
                    this.backButtonPress = true;
                    setTimeout(() => {
                        if(this.nextRoute.path){
                            this.nextRoute.query ? this.$router.replace({path:this.nextRoute.path, query:this.nextRoute.query}) : this.$router.replace({path:this.nextRoute.path});
                        }
                        else{
                            this.$router.go(-1);
                        }
                    }, 50);
                }
                // this.$router.go(-1);
            },

            // fn_openPermissionDialog: function(permissionType){
         //        this.$root.permissionType = permissionType;
         //        this.fn_openDialogWeb('web', 'Permission', 'ref_dialogPermission');
         //    },

            fn_postOpening: function(){

                this.transactionData.transactionId = this.Form.transactionId;

                //only in case of new transaction
                if(!this.transactionData.transactionId){
                    this.transactionData.transactionId = this.guid();
                    this.transactionData.createdDate = new Date();
                }

                this.transactionData.modifiedDate = new Date();

                this.transactionData.partyId = this.Form.accountId;

                let amount = this.Form.openingAmount ? this.Form.openingAmount : 0;
                this.transactionData.amount = amount  //debit or credit amount
                this.transactionData.roundedAmount = Math.round(amount); //or creditTotal amount
                this.transactionData.status = 1;
                this.transactionData.transactionListId = 40003;
                this.transactionData.refDate = null;

                //subtracted 1 day from opening date as we are adding opening one day less than user selected opening date
                // let od = new Date(JSON.parse(JSON.stringify(this.openingDate)));
                let od = new Date(JSON.parse(JSON.stringify(this.$root.CurrentBusiness.OpeningDate)));
                od.setDate(od.getDate()-1);
                this.transactionData.transactionDate = new Date(od);

                let grossAmount = this.roundUpto2Decimals(amount);

                if((this.Form.groupTypeId == 101 && this.Form.openingNature == 0) || (this.Form.groupTypeId == 102 && this.Form.openingNature == 1)){
                    this.transactionData.grossAmount = -grossAmount;
                }
                else{
                    this.transactionData.grossAmount = grossAmount;
                }

                this.transactionData.onAccount = null;

                let postObj = {};
                postObj = JSON.parse(JSON.stringify(this.transactionData));

                let ledgerAccountAmount = amount;

                //Preparing Object for transaction ledger table
                let transactionLedgerObj = {};

                transactionLedgerObj.transactionLedgerId = this.guid();
                transactionLedgerObj.accountLedgerId = this.Form.accountId;
                transactionLedgerObj.orderNumber = 1;
                transactionLedgerObj.amount = amount;
                transactionLedgerObj.amountType = this.Form.openingNature;

                postObj.transactionLedgers = [];
                postObj.transactionLedgers.push(transactionLedgerObj);
                
                //Preparing Object for ledger accounts table
                let ledgerAccountObj = {};

                ledgerAccountObj.ledgerAccountId = this.guid();
                ledgerAccountObj.accountId =  this.Form.accountId;
                ledgerAccountObj.amount =  this.Form.openingNature == 1 ? ledgerAccountAmount : -ledgerAccountAmount;
                ledgerAccountObj.amountType =  this.Form.openingNature;

                postObj.ledgerAccounts = [];
                postObj.ledgerAccounts.push(ledgerAccountObj);

                postObj.sortDate = this.$moment(this.transactionData.transactionDate).format('YYYY-MM-DD');

                console.log(JSON.stringify(postObj));
                this.fn_saveOpeningLocal(postObj);
            },

            fn_saveOpeningLocal: function(dataObj){
                var self = this;
                this.postData('Transaction', dataObj, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.fn_cleanUp();
                        },
                        onError: function(error){
                            self.disableOnSave = false;
                            self.saveBtnKey++;
                            console.log(error);
                            // self.hideLoading();
                        }
                    }
                );
            },
        },
        mounted: function(){

            // var self = this;

            // if(this.$route.query && this.$route.query.tagId){//in case of new form
            //     this.formId = this.$route.query.tagId;
            //     this.draftStorageId = 'draft_master_'+this.formId;
            //     // this.dialogStorageId = 'dialog_'+this.formId;
            // }
            // else if(this.$route.params && this.$route.params.tagId){//in case of edit form
            //     this.formId = this.$route.params.tagId;
            //     this.draftStorageId = 'draft_master_'+this.formId;
            //     // this.dialogStorageId = 'dialog_'+this.formId;
            // }

            // if(localStorage.getItem(this.draftStorageId)){
            //     let dataObj = JSON.parse(localStorage.getItem(this.draftStorageId));
            //     this.Form = JSON.parse(dataObj.formData);
            // }

            // setTimeout(function(){self.fn_setNewItem()},300);

            setTimeout(() => {
                this.fn_setNewItem();
            }, 300);    

            // this.fn_setDraftData('Master', 'Ledger');
            this.fn_setDraftData('temp', 'Ledger');

            this.fn_states();
            this.fn_countries();
            this.$refs['ref_dialogForm'].openDialogWeb();

            this.$eventHub.$on('save', () => {
                this.$refs['ref_dialogForm'].fn_validateForm();
            });

            this.$eventHub.$on('escape', () => {
                this.fn_closeDialogForm();
            });
        },

        beforeRouteEnter (to, from, next) {
            next(vm => {
                // vm.enableToolbar();
                let itemId = null;
                let copyData = false;

                // to.query && to.query.copy && from.name == 'ledgerDisplay' ? copyData = true : '';
                to.query && to.query.copy ? copyData = true : '';

                // if(to.query && to.query.itemId && from.name == 'ledgerDisplay'){
                if(to.query && to.query.itemId){
                    itemId = to.query.itemId;
                }

                if(to.params && to.params.tagId){//from display view
                    itemId = to.params.tagId;
                }

                if(vm.$route.query && vm.$route.query.groupTypeId && vm.$route.query.groupTypeId != "null"){
                    vm.unwatch = true;
                    vm.Form.groupTypeId = Number(vm.$route.query.groupTypeId);
                }

                if(vm.$route.query && vm.$route.query.tagId){//in case of new form
                    vm.formId = vm.$route.query.tagId;
                    vm.draftStorageId = 'draft_master_'+vm.formId;
                }
                else if(vm.$route.params && vm.$route.params.tagId){//in case of edit form
                    vm.formId = vm.$route.params.tagId;
                    vm.draftStorageId = 'draft_master_'+vm.formId;
                }

                if(localStorage.getItem(vm.draftStorageId)){
                    let dataObj = JSON.parse(localStorage.getItem(vm.draftStorageId));
                    vm.Form = JSON.parse(dataObj.formData);
                }

                if(itemId){
                    localStorage.getItem(vm.draftStorageId) ? vm.fn_accountGroups() : vm.fn_get(itemId, copyData);
                }
                else{
                    vm.unwatch = true;
                    vm.Form.selectedAccountGroup?.itemId ? vm.fn_accountGroups() : vm.fn_groupTypeChange(vm.Form.groupTypeId);
                }

                // if(itemId){
                //     vm.fn_get(itemId, copyData);
                // }
                // else{
                //     vm.unwatch = true;
                //     vm.fn_groupTypeChange(vm.Form.groupTypeId);
                // }

                let isCalled = false;
                vm.$eventHub.$on('connected', () => {
                    setTimeout(() => {
                        if(!isCalled){
                            isCalled = true;
                            vm.disableOnSave = false;
                            vm.saveBtnKey++;
                            !vm.accountGroups ? vm.fn_accountGroups() : '';
                            !vm.states ? vm.fn_states() : '';
                            !vm.countries ? vm.fn_countries() : '';
                            itemId ? vm.fn_get(itemId, copyData) : '';
                        }

                        setTimeout(() => {
                            isCalled = false;
                        }, 3000);
                    }, 1000);
                });
            })
        },
        beforeRouteLeave (to, from, next) {
            if(this.$root.viaKeyboardShortCut){
                this.nextRoute.path = to.path;
                this.nextRoute.query = to.query;
                this.fn_closeDialogForm();
            }
            else{
                this.nextRoute.path = null;
                this.nextRoute.query = null;
                this.$eventHub.$off('save');
                this.$eventHub.$off('escape');
                this.$eventHub.$off('party');
                this.$eventHub.$off('connected');
                next();
            }
        }
    }

</script>