<template>
    <div>

        <v-toolbar :height="56" flat extended>
            <!-- <v-btn icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-btn> -->

            <v-toolbar-title>{{$route.query.inventory == 0 ? 'Expense Items' : 'Items'}}</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-menu dense v-if="$route.query.inventory!=0">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small icon v-bind="attrs" v-on="on"><v-icon>more_vert</v-icon></v-btn>
                </template>
                <v-list nopadding dense>
                    <v-list-item @click="fn_exportItems">
                        <v-icon font20 mr-4>file_download</v-icon>
                        <v-list-item-title>Export Items</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-btn icon color="primary" @click="fn_searchItem"><v-icon>autorenew</v-icon></v-btn>

            <v-btn icon @click="fn_importItems" v-if="$route.query.inventory != 0 && $root.appVersion>=117"><v-icon>upload_file</v-icon></v-btn>

            <!-- <v-btn icon @click="fn_transactionSettings" v-if="$route.query.inventory != 0"><v-icon>settings</v-icon></v-btn> -->

            <v-btn icon @click="fn_new"><v-icon>add</v-icon></v-btn>

            <v-btn icon @click="fn_openFilterNav">
                <v-icon>filter_list</v-icon>
                <span style="font-size: 48px;position: absolute;bottom: -10px;right: 6px;color: #4b77be;" v-if="selectionItems.selectedFilterItemValue != null || selectionItems.selectedSortValue">&bull;</span>
            </v-btn>

            <v-text-field grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch" slot="extension"></v-text-field>
        </v-toolbar>

        <!-- <v-toolbar flat fixed>
            <v-toolbar-side-icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-toolbar-side-icon>

            <v-toolbar-title class="white--text">{{$route.query.inventory == 0 ? 'Expense Items' : 'Items'}}</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="fn_new"><v-icon>add</v-icon></v-btn>

            <div full-width slot="extension" class="search-filter" :class="{'bottom-shadow':scrollPositionTop!=0}">
                <v-text-field clearable elevation-10  flat solo placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch"></v-text-field>

                <div v-ripple class="filter-btn" @click="fn_openDialogFilterItems">
                    <v-icon>filter_list</v-icon>
                    <span style="font-size: 48px;position: absolute;bottom: -6px;right: 6px;color: #4b77be;" v-if="selectionItems.selectedFilterItemValue != null || selectionItems.selectedSortValue">&bull;</span>
                </div>
            </div>
        </v-toolbar> -->

        <div class="data-container grey-back hasExtention" v-scroll.self="onScroll">


            <v-list grey-back three-line nopadding>

                <v-subheader class="pr-2" white-back subheader-dense>
                    Count: {{$root.numberToLocale(listItemObject.totalCount, 1)}}
                    <v-spacer></v-spacer>

                    <v-autocomplete
                        v-if="$route.query.inventory!=0"
                        class="mr-2 autocomplete-small"
                        :class="{'item-selected':selectedBrand}"
                        solo
                        dense
                        :items="brands"
                        v-model="selectedBrand"
                        return-object
                        item-value="itemId"
                        item-text="itemName"
                        placeholder="Brand"
                        hide-details
                        flat
                        outlined
                        style="width:60px;max-height:24px"
                        @input="fn_searchItem"
                        @focus="fn_listItems($event, 'brand')"
                        clearable
                      ></v-autocomplete>

                      <v-autocomplete
                        v-if="$route.query.inventory!=0"
                        class="mr-2 autocomplete-small"
                        :class="{'item-selected':selectedCompany}"
                        solo
                        dense
                        :items="companies"
                        v-model="selectedCompany"
                        return-object
                        item-value="itemId"
                        item-text="itemName"
                        placeholder="Company"
                        hide-details
                        flat
                        outlined
                        style="width:60px;max-height:24px"
                        @input="fn_searchItem"
                        @focus="fn_listItems($event, 'company')"
                        clearable
                      ></v-autocomplete>

                      <v-autocomplete
                        class="autocomplete-small"
                        :class="{'item-selected':selectedCategory}"
                        solo
                        dense
                        :items="productCategories"
                        v-model="selectedCategory"
                        return-object
                        item-value="itemId"
                        item-text="itemName"
                        placeholder="Category"
                        hide-details
                        flat
                        outlined
                        style="width:60px;max-height:24px"
                        @input="fn_searchItem"
                        @focus="fn_productCategries"
                        clearable
                      ></v-autocomplete>
                      
                </v-subheader>

                <v-divider></v-divider>

                <div white-back v-for="(item, index) in listItemObject.items" :key="index" :class="{'mt-2':index!=0}" :id="'item_'+item.productId">
                    <v-list-item v-ripple @click="fn_itemClick(item.productId, index)">

                        <v-list-item-content>
                            <v-list-item-title class="mb-1">{{item.productName}}</v-list-item-title>
                            <v-list-item-subtitle v-if="item.groupTypeId == 101">
                                Purchase Rate: &#8377; {{item.purchasePrice ? $root.numberToLocale(roundUpto2Decimals(item.purchasePrice)) : '0'}}
                            </v-list-item-subtitle>

                            <v-list-item-subtitle v-if="item.groupTypeId == 101">
                                Sale Price: &#8377; {{item.sellingPrice ? $root.numberToLocale(roundUpto2Decimals(item.sellingPrice)) : '0'}}
                            </v-list-item-subtitle>

                            <v-list-item-subtitle v-if="item.groupTypeId == 102">
                                Charges: &#8377; {{item.charges ? $root.numberToLocale(roundUpto2Decimals(item.charges)) : '0'}}
                            </v-list-item-subtitle>

                        </v-list-item-content>

                        <v-list-item-action style="padding:10px 0px" v-if="item.groupTypeId == 101">
                            <div></div>

                            <div :class="{'redcolor': item.currentStock <= 0}">
                                {{item.currentStock ? item.currentStock : 0}} {{item.unitName}}
                            </div>
                            
                        </v-list-item-action>

                    </v-list-item>

                    <!-- <template v-if="item.inventory && item.groupTypeId==101"> -->
                    <v-divider></v-divider>
                    <div right-align style="padding:2px 6px">
                        <template v-if="item.inventory && item.groupTypeId==101">
                            <v-btn nomargin icon @click="fn_adjustStock('plus', item.productId)"><v-icon font20>add_circle_outline</v-icon></v-btn>
                            <v-btn nomargin icon @click="fn_adjustStock('minus', item.productId)"><v-icon font20>remove_circle_outline</v-icon></v-btn>
                        </template>

                        <v-menu dense left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small icon v-bind="attrs" v-on="on"><v-icon>more_vert</v-icon></v-btn>
                            </template>
                            <v-list nopadding dense>
                                <v-list-item @click="fn_copy(item.productId)">
                                <v-list-item-title>Copy</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>  
                    <!-- </template> -->
                    

                </div>


            </v-list>

            <v-row class="ma-0" align-content="center" justify="center" v-if="loadMore">
              <v-col cols="6">
                <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
              </v-col>
            </v-row>

            <div center-align class="pa-6" v-if="loadTimeOut">
                <p font12 light-opacity-color>Unable to load the items!</p>
                <v-btn capitalize text color="primary" @click="fn_searchItem">
                    <v-icon left>refresh</v-icon>
                    Retry
                </v-btn>
            </div>


            <!-- <div class="display_msg" v-if="!listItemObject.items.length">There are no items</div> -->
        </div>

        <!-- <dialog-filter-items ref="ref_dialogFilterItems" type="Items" :filterData="filterData" :setFilter="fn_setFilter" :selectedFilterItemValue="selectionItems.selectedFilterItemValue" :selectedSortValue="selectionItems.selectedSortValue"></dialog-filter-items> -->

        <v-navigation-drawer v-model="filter_nav" absolute right temporary>
          <filter-items ref="ref_filterItems" type="Items" :filterData="filterData" :setFilter="fn_setFilter" :selectedFilterItemValue="selectionItems.selectedFilterItemValue" :selectedSortValue="selectionItems.selectedSortValue" :closeFilterNav="fn_closeFilterNav"></filter-items>
        </v-navigation-drawer>

        <dialog-loading ref="ref_dialogLoading" message="Please wait..."></dialog-loading>
    </div>
</template>

<script>
// var tagId = null;
// const menuItem = require('../../../../config.js').configs.menuItem;
// const bottomSheetMenu = require('../../../../config.js').configs.bottomSheetMenu;
import {masterMixin} from '../../master_mixin.js'

// const transactionForms = require('../../../../config.js').configs.transactionForms;

// const dialogjs =  require('../../../masters/forms/dialog').dialogjs;
const listIdObject = require('../../../../config.js').configs.mastersPredefinedLists;

// import dialogFilterItems from '../../../activity/dialogs/dialog_filter';

import filterItems from '../../../activity/dialogs/filter_items';
import dialogLoading from '../../../dialogs/dialog_loading.vue';

    export default{
        mixins: [masterMixin],
        data(){
            return{
                toolbarTitle:"Items",

                // iconpath:null,
                formId:null,
                dialogStorageId:null,

                selectionItems:{
                    brandId:null,
                    brandName:null,
                    categoryId:null,
                    categoryName:null,
                    companyId:null,
                    companyName:null,
                    selectedFilterItemValue:null,
                    selectedSortValue:0,
                },

                selectedBrand:null,
                selectedCompany:null,
                selectedCategory:null,

                brands:[],
                companies:[],
                productCategories:[],

                backButtonPress:false,

                filter_nav:false,
                filterData:{
                    filterItems:[
                        {"filterItemId":1, "name":"All", "value":null},
                        {"filterItemId":2, "name":"In Stock", "value":1},
                        {"filterItemId":3, "name":"Not In Stock", "value":0}
                    ],
                    sortItems:[
                        {"sortId":1, "name":"Item Name", "value":0},
                        {"sortId":2, "name":"Stock - High to Low", "value":1},
                        {"sortId":3, "name":"Stock - Low to High", "value":2}
                    ],
                }
            }
        },
        components: {
            // 'dialog-filter-items': dialogFilterItems,
            'filter-items': filterItems,
            'dialog-loading':dialogLoading
        },
        methods:{

            fn_openFilterNav: function(){
                this.filter_nav = true;
                this.$refs['ref_filterItems'].fn_setCurrentFilterValues();
            },

            fn_closeFilterNav: function(){
                this.filter_nav = false;
            },

            fn_itemClick: function (itemId, index) {
                console.log(itemId, index);
                // alert("Under Development");

                let obj = {};
                obj.itemId = null;
                obj.lastScrollPos = window.pageYOffset;
                obj.itemIndex = index + 1;
                localStorage.setItem("activity_items_state", JSON.stringify(obj));
                // tagId id form future use
                // itemName id form future use
                // this.$router.push('/masters/inventory/productandservices/items/'+itemId);
                if(this.$route.query.inventory == 0){
                    this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/product/items/'+itemId, query:{inventory:0}});
                }
                else{
                    this.$router.push('/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/product/items/'+itemId);
                }
                
            },

            fn_importItems: function(){
                this.$router.push('/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/items_import');
            },

            fn_adjustStock(type, productId){
                // let obj = {};
                // obj.itemId = null;
                // obj.lastScrollPos = window.pageYOffset;
                // obj.itemIndex = index + 1;
                // localStorage.setItem("activity_items_state", JSON.stringify(obj));

                this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+"/stockadjustment/items/new", query:{tagId:this.guidWeb(), type:type, itemid:productId}});
            },

            fn_goBack: function(){
                this.backButtonPress = true;
                this.$router.replace("/"+this.$root.CurrentBusiness.BusinessID+"/master_items");
            },

            fn_new: function(){
                // let obj = {};
                // obj.itemId = null;
                // obj.lastScrollPos = window.pageYOffset;
                // obj.itemIndex = this.listItemObject.items.length;
                // localStorage.setItem("activity_items_state", JSON.stringify(obj));

                // this.$router.push({path:'/masters/inventory/productandservices/items/new', query:{tagId:this.guidWeb(), inventory:this.$route.query.inventory != undefined ? this.$route.query.inventory : 1}});
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/product/items/new', query:{tagId:this.guidWeb(), inventory:this.$route.query.inventory != undefined ? this.$route.query.inventory : 1}});
            },

            fn_copy: function(itemId){
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/product/items/new', query:{tagId:this.guidWeb(), inventory:this.$route.query.inventory != undefined ? this.$route.query.inventory : 1, itemId:itemId, copy:true}});
            },

            fn_openCategories: function(){
                let obj = {};
                obj.functionName = 'productCategories';
                obj.toolbarTitle = 'Select Category';
                obj.itemId = this.selectionItems.categoryId;
                obj.itemName = this.selectionItems.categoryName;
                this.fn_openDialogAndroid('android', 'DialogFilter', 'ref_category', obj);
            },

            fn_openBrands: function(){
                let obj = {};
                obj.functionName = 'listItems';
                obj.toolbarTitle = 'Select Brand';
                obj.itemId = this.selectionItems.brandId;
                obj.itemName = this.selectionItems.brandName;
                this.fn_openDialogAndroid('android', 'DialogFilter', 'ref_brand', obj);
            },

            fn_openCompanies: function(){
                let obj = {};
                obj.functionName = 'listItems';
                obj.toolbarTitle = 'Select Company';
                obj.itemId = this.selectionItems.companyId;
                obj.itemName = this.selectionItems.companyName;
                this.fn_openDialogAndroid('android', 'DialogFilter', 'ref_company', obj);
            },


            // fn_getBottomMenu: function (tagId, elementId, isSystem) {
            //  let bottomMenu;

            //  if(elementId == bottomSheetMenu.toolBar.Id){
            //      bottomMenu = {"BottomMenu": [menuItem.help]};
            //  }
            //  else if(elementId == bottomSheetMenu.masterListItems.Id){
            //      bottomMenu = {"BottomMenu": [menuItem.edit, menuItem.copy]};
            //      // !Number(isSystem) ? bottomMenu.BottomMenu.push(menuItem.delete) : '';
            //  }
            //  else if(elementId == bottomSheetMenu.moreFilters.Id){
            //      menuItem.filterCategory.selectedItem = this.selectionItems.categoryId ? this.selectionItems.categoryName : null;
            //      menuItem.filterBrand.selectedItem = this.selectionItems.brandId ? this.selectionItems.brandName : null;
            //      menuItem.filterCompany.selectedItem = this.selectionItems.companyId ? this.selectionItems.companyName : null;

            //      if(this.$route.query && this.$route.query.inventory == 0){
            //          bottomMenu = {"BottomMenu": [menuItem.filterCategory]};
            //      }
            //      else{
            //          bottomMenu = {"BottomMenu": [menuItem.filterCategory, menuItem.filterBrand, menuItem.filterCompany]};
            //      }
                    
            //      if(this.selectionItems.categoryId || this.selectionItems.brandId || this.selectionItems.companyId){
            //          bottomMenu.BottomMenu.push(menuItem.clearAll);
            //      }
            //  }
                
            //  this.setBottomMenu(tagId, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            // fn_openDialogAndroid: function (platform, name, ref, obj) {

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  dialogObj.platform = platform;

            //  var viewDialogObject = {};
            //  viewDialogObject.ToolbarTitle = obj.toolbarTitle;
            //  viewDialogObject.isBottomMenu = false;

            //  if(ref == 'ref_brand'){
            //      viewDialogObject.listId = listIdObject.brandListGUID;
            //  }
            //  else if(ref == 'ref_company'){
            //      viewDialogObject.listId = listIdObject.companyListGUID;
            //  }

            //  var paramObject = {};
            //  paramObject.defaultValue = {"id":null, "name":null};

            //  if(obj.itemId && obj.itemName){
            //      paramObject.defaultValue = {"id":obj.itemId, "name":obj.itemName};
            //  }

            //  dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, obj.functionName, tagId, paramObject, ref);
                
            // },

            fn_setFilter: function(filterItemId, sortId){
                this.selectionItems.selectedFilterItemValue = filterItemId;
                this.selectionItems.selectedSortValue = sortId;
                // this.fn_closeDialog();
                this.fn_closeFilterNav();
                this.fn_searchItem();
            },

            fn_openDialogFilterItems: function(){
                this.fn_openDialogWeb('web', 'FilterItems', 'ref_dialogFilterItems');
            },

            // fn_openDialogWeb: function (platform, name, ref) {

            //  let scroll_y = window.scrollY;
            //  this.fn_fixBackgroundScroll();

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  dialogObj.data = null;
            //  dialogObj.platform = platform;

            //  dialogObj.scrollPosition = {};
            //  dialogObj.scrollPosition.x = 0;
            //  dialogObj.scrollPosition.y = scroll_y;

            //  dialogjs.fn_pushDialogInStack(this.dialogStorageId, dialogObj, this);

            //  let self = this;

            //  this.$refs[ref].openDialogWeb();
            // },

            // fn_closeDialog: function () {
            //     dialogjs.fn_checkAndCloseOpenDialogs(this);
            // },
            
            fn_onChange: function(tagId, ref, selectedValueId, selectedValueName, isRemoved){
                if(ref == 'ref_brand'){
                    this.selectionItems.brandId = !isRemoved ? selectedValueId : null;
                    this.selectionItems.brandName = !isRemoved ? selectedValueName : null;
                }
                else if(ref == 'ref_category'){
                    this.selectionItems.categoryId = !isRemoved ? selectedValueId : null;
                    this.selectionItems.categoryName = !isRemoved ? selectedValueName : null;
                }
                else if(ref == 'ref_company'){
                    this.selectionItems.companyId = !isRemoved ? selectedValueId : null;
                    this.selectionItems.companyName = !isRemoved ? selectedValueName : null;
                }

                isRemoved ? this.fn_closeDialog() : '';
                // this.fn_filterData(true);
                this.fn_searchItem();
            },


            fn_loadItems: function() {
                this.loadMore = true;
                let filterObject = {};
                filterObject.limit = 50;
                filterObject.startIndex = this.startIndex;
                filterObject.search = this.searchValue ? this.searchValue.toLowerCase() : '';

                filterObject.inventory = this.$route.query.inventory != undefined ? this.$route.query.inventory : 1;
                
                filterObject.stockType = this.selectionItems.selectedFilterItemValue;
                filterObject.sortByStock = this.selectionItems.selectedSortValue;

                // filterObject.categories = this.selectionItems.categoryId ? [this.selectionItems.categoryId] : [];
                // filterObject.brands = this.selectionItems.brandId ? [this.selectionItems.brandId] : [];
                // filterObject.companies = this.selectionItems.companyId ? [this.selectionItems.companyId] : [];

                filterObject.categories = this.selectedCategory && this.selectedCategory.itemId ? [this.selectedCategory.itemId] : [];
                filterObject.brands = this.selectedBrand && this.selectedBrand.itemId ? [this.selectedBrand.itemId] : [];
                filterObject.companies = this.selectedCompany && this.selectedCompany.itemId ? [this.selectedCompany.itemId] : [];

                let pageState = null;
                if(localStorage.getItem("activity_items_state")){
                    pageState = JSON.parse(localStorage.getItem("activity_items_state"));
                    filterObject.limit = 50 - (pageState.itemIndex % 50) + pageState.itemIndex;
                }

                localStorage.setItem('item_filter', JSON.stringify(this.selectionItems));

                this.fn_loadTimeOut();

                let self = this;
                this.getItems("ItemList", filterObject, 
                    {
                        onSuccess: function (response) {
                            self.loadTimeOut = false;
                            console.log(response);
                            let responseJson = JSON.parse(response);
                            self.listItemObject.totalCount = responseJson.totalCount;

                            for(let i in responseJson.items){
                                let itemIndex = self.listItemObject.items.findIndex(item => item.productId==responseJson.items[i].productId);

                                if(itemIndex==-1){
                                    self.listItemObject.items.push(responseJson.items[i]);  
                                }
                            }

                            console.log(pageState);
                            if(pageState && pageState.itemId){
                                setTimeout(function(){
                                    let scrollOptions = {"duration":0, "offset":-112, "easing":"linear"};
                                    self.$vuetify.goTo('#item_'+pageState.itemId, scrollOptions);
                                    localStorage.removeItem("activity_items_state");
                                },100);
                            }

                            if(pageState && !pageState.itemId){
                                setTimeout(function(){
                                    let scrollOptions = {"duration":300, "offset":0, "easing":"linear"};
                                    self.$vuetify.goTo(pageState.lastScrollPos, scrollOptions);
                                    localStorage.removeItem("activity_items_state");
                                },100);
                            }

                            self.startIndex = self.listItemObject.items.length;
                            self.loadMore = false;

                            setTimeout(function(){
                                self.showPlaceHolder = true;
                            },300);
                        },
                        onError: function (error) {
                            console.log(error);
                            self.loadMore = false;
                        }
                    }
                )
            },

            fn_searchItem: function () {
                this.startIndex = 0;
                this.listItemObject.items = [];
                this.fn_loadItems(true);
            },

            fn_listItems: function(val, type){
                console.log(val);
              let filterObject = {};
              // filterObject.search = val ? val.toLowerCase() : '';
              if(type == 'brand'){
                filterObject.listId = listIdObject.brandListGUID;
              }
              else{
                filterObject.listId = listIdObject.companyListGUID;
              }
              
              filterObject.search = '';

              filterObject.startIndex = 0;
              filterObject.limit = 1000;

              let self = this;
              this.getDialogItems("ListItemDialog", filterObject, 
                {
                  onSuccess: function (response) {
                    console.log(response);
                    if(type == 'brand'){
                        self.brands = JSON.parse(response);
                    }
                    else{
                        self.companies = JSON.parse(response);
                    }
                  },
                  onError: function (error) {
                    console.log(error);
                  }
                }
              )
            },

            fn_productCategries: function(val){
                console.log(val);
                let filterObject = {};
                // filterObject.search = val ? val.toLowerCase() : '';
                filterObject.search = '';
                filterObject.startIndex = 0;
                filterObject.limit = 1000;
                
                let self = this;
                this.getDialogItems("ProductCategoryDialog", filterObject, 
                {
                    onSuccess: function (response) {
                    console.log(response);
                    self.productCategories = JSON.parse(response);
                  },
                  onError: function (error) {
                    console.log(error);
                  }
                }
              )
            },

            fn_transactionSettings: function(){
                this.$router.push({path:'/'+ this.$root.CurrentBusiness.BusinessID + '/' + this.$route.params.listId + '/settings/transaction'})
            },

            fn_exportItems: function(){
                var self = this;
                this.$refs['ref_dialogLoading'].openDialogWeb();
                this.getData('GetExportItems', null, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            let data = JSON.parse(response);
                            if(data && data.items && data.items.length){
                                self.fn_processItems(data.items);	
                            }
                        }, 
                        onError: function(error){
                            console.log(error);
                            this.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong. Try again.'});
                            this.$refs['ref_dialogLoading'].closeDialogWeb();
                        }
                    }
                );
            },

            fn_processItems: function(items){
                let sheetArray = [];
                let headerRow = ["Name", "Code", "Type", "Charges", "GST Percentage", "Cess Percentage", "HSN Code", "Unit", "Selling Price", "Wholesale Price", "Purchase Rate", "Opening", "Category",	"Description", "Brand", "Company"]
                sheetArray.push(headerRow);
                for(let i=0; i<items.length; i++){
                    let row = [];
                    row.push(items[i].name);
                    row.push(items[i].itemCode);
                    row.push(items[i].groupTypeId == 101 ? 'Product': 'Service');
                    row.push(items[i].charges ? Number(items[i].charges) : null);
                    row.push(items[i].gstPercentage ? Number(items[i].gstPercentage) : null);
                    row.push(items[i].cessPercentage ? Number(items[i].cessPercentage) : null);
                    row.push(items[i].hsnCode);
                    row.push(items[i].unitName);
                    row.push(items[i].sellingPrice ? Number(items[i].sellingPrice) : null);
                    row.push(items[i].wholeSalePrice ? Number(items[i].wholeSalePrice) : null);
                    row.push(items[i].purchaseRate ? Number(items[i].purchaseRate) : null);
                    row.push(items[i].openingValue ? Number(items[i].openingValue): null);
                    row.push(items[i].categoryName);
                    row.push(items[i].description);
                    row.push(items[i].brandName);
                    row.push(items[i].companyName);
                    sheetArray.push(row);
                }

                const XlsxPopulate = require('xlsx-populate');
                XlsxPopulate.fromBlankAsync().then(workbook => {
                    workbook.sheet(0).cell("A1").value(sheetArray);
                    workbook.sheet(0).name('Items');
                    workbook.sheet(0).range("A1:P1").style({"fill": "92D050", "bold": true});
                    let fileName = "items_"+this.$root.fn_getCurrentDateTimeString();

                    workbook.outputAsync().then(blob=> {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.href = url;
                        a.download = fileName + ".xlsx";
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                        this.$refs['ref_dialogLoading'].closeDialogWeb();
                    });

                    
                })
            },
        },
        mounted: function(){

            // this.fn_showWebView();

            if(this.$route.query && this.$route.query.inventory == 0){
                this.toolbarTitle = "Expense Items";
            }

            // tagId = this.$route.query.tagId;
            // this.formId = this.$route.query.tagId;
            // this.dialogStorageId = 'dialog_'+this.formId;

            // window.js_globals.fn_onBackPress = this.fn_goBack;
            // window.js_globals.fn_closeClick = this.fn_closeDialog;

            if(localStorage.getItem('item_filter')){
                let data = JSON.parse(localStorage.getItem('item_filter'));
                this.selectionItems.brandId = data.brandId ? data.brandId : null;
                this.selectionItems.brandName = data.brandName ? data.brandName : null;
                this.selectionItems.categoryId = data.categoryId ? data.categoryId : null;
                this.selectionItems.categoryName = data.categoryName ? data.categoryName : null;
                this.selectionItems.companyId = data.companyId ? data.companyId : null;
                this.selectionItems.companyName = data.companyName ? data.companyName : null;

                this.selectionItems.selectedFilterItemValue = data.selectedFilterItemValue ? data.selectedFilterItemValue : null;
                this.selectionItems.selectedSortValue = data.selectedSortValue ? data.selectedSortValue : 0;
            }

            this.fn_loadItems();

             this.$eventHub.$on('UpdateItemList', dataObj => {
                console.log(dataObj);
                this.fn_searchItem();
            });
        },
        // beforeRouteLeave (to, from, next) {
        //      var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //  if(dialogData && dialogData.length){
        //      dialogjs.fn_checkAndCloseOpenDialogs(this);
        //      this.backButtonPress = false;
        //      next(false);
        //  }
        //      else {
        //          this.backButtonPress ? localStorage.removeItem('item_filter') : '';
        //          next();
        //      }
        // }
    }
</script>