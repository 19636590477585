<template>
    <div>
        <v-dialog v-model="dialog_payment_options" no-click-animation persistent scrollable content-class="half-screen-dialog" lazy transition="dialog-bottom-transition" :max-width="$root.fn_getActionPaneWidth()">

            <v-card>
                <v-toolbar :height="56" flat>
                    <v-btn icon @click="closeDialogWeb"><v-icon>close</v-icon></v-btn>

                    <!-- <v-toolbar-title class="white--text">Payment Mode</v-toolbar-title> -->
                    <v-toolbar-title class="two-line-title">
                        Payment Mode
                        <div class="toolbar-subtitle">
                            <div class="subtitle-text dark-opacity-color">Bill Amount: &#8377; {{$root.numberToLocale($parent.roundedAmount)}}</div>
                        </div>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>
                    <div>
                        <div style="float: left;margin-right: 12px;margin-top: 6px;color: rgba(0,0,0,0.57);">Split</div>
                        <v-switch class="hide-margins" hide-details v-model="$parent.Form.splitPayment" @change="fn_splitPayment"></v-switch>
                    </div>

                    <!-- <v-btn round-borders outline @click="fn_checkValidations">Done</v-btn> -->

                </v-toolbar>

                <v-divider></v-divider>

                <v-card-text full-height nopadding grey-back>
                    <v-form data-vv-scope="fields" full-width class="form-element-dialog hasBigFooter">

                        <!-- <v-subheader subheader-dense white-background @click="fn_splitPayment">
                            <v-spacer></v-spacer>
                            <v-icon>{{$parent.Form.splitPayment ? "close" : "call_split"}}</v-icon>&nbsp; {{$parent.Form.splitPayment ? "Cancel Split Payment" : "Split Payment"}}
                        </v-subheader> -->

                        <!-- <div v-for="(item, index) in $parent.Form.paymentGroups" :key="index" full-width v-if="item.accountLedgers && item.accountLedgers.length"> -->
                        <div v-for="(item, index) in $parent.Form.paymentGroups" :key="index" full-width :class="{'mb-2':fn_isOnCredit(item.accountGroupId)}">

                            <template v-if="fn_isOnCredit(item.accountGroupId)">
                                <v-subheader white-back v-if="item.accountGroupId != 'creditAccountGroup'">
                                    {{item.accountGroupName != 'Cash - In - Hand' ? item.accountGroupName : 'Cash'}}
                                    <v-spacer></v-spacer>
                                    <v-btn class="mr-0" text color="primary" icon @click="fn_newLedger(item.accountGroupId, item.accountGroupName, index)">new</v-btn>
                                </v-subheader>

                                <v-divider v-if="index!=0 && item.accountLedgers && item.accountLedgers.length"></v-divider>

                                <v-list two-line nopadding>
                                    <div v-for="(accountItem, accountIndex) in item.accountLedgers" :key="accountIndex">

                                        <template v-if="!$parent.Form.splitPayment">
                                            <v-radio-group col class="mt-0 pt-0" nopadding hide-details v-model="$parent.Form.paymentLedgerId">

                                                <v-list-item>
                                                    <v-list-item-action>
                                                        <v-radio label="" :value="accountItem.accountLedgerId"></v-radio>
                                                    </v-list-item-action>

                                                    <v-list-item-content @click="$parent.Form.paymentLedgerId = accountItem.accountLedgerId">
                                                        <v-list-item-title>{{accountItem.accountLedgerName}}</v-list-item-title>
                                                        <!-- <v-list-item-subtitle v-if="item.accountGroupId != 'creditAccountGroup'">
                                                            Balance: <span :class="[accountItem.balance < 0 ? 'redcolor' : 'greencolor']">&#8377; {{$root.numberToLocale(roundUpto2Decimals(accountItem.balance))}}</span>
                                                        </v-list-item-subtitle> -->
                                                    </v-list-item-content>
                                                    
                                                </v-list-item>

                                            </v-radio-group>
                                        </template>

                                        

                                        <template v-if="$parent.Form.splitPayment">
                                            <v-list-item v-if="accountItem.accountLedgerId == $parent.creditAccountLedgerId">

                                                <v-list-item-content>
                                                    <v-list-item-title>{{accountItem.accountLedgerName}}</v-list-item-title>
                                                    <!-- <v-list-item-subtitle v-if="item.accountGroupId != 'creditAccountGroup'">
                                                        Balance: <span :class="[accountItem.balance < 0 ? 'redcolor' : 'greencolor']">&#8377; {{accountItem.balance}}</span>
                                                    </v-list-item-subtitle> -->
                                                </v-list-item-content>
                                                
                                                <v-list-item-action style="max-width:128px">
                                                    <v-text-field 
                                                        :disabled="true"
                                                        prefix="₹"
                                                        style="margin:0"
                                                        hide-details
                                                        right-align
                                                        :value="onCreditAmount"
                                                        type="number"
                                                        label="">
                                                    </v-text-field>
                                                </v-list-item-action>

                                            </v-list-item>

                                            <v-list-item v-else>
                                            
                                                <v-list-item-action>
                                                    <!-- <v-checkbox v-model="accountItem.selected" @change="toggleAmount(accountItem, true)"></v-checkbox> -->
                                                    <v-checkbox v-model="accountItem.selected" @change="toggleAmount(accountItem)"></v-checkbox>
                                                </v-list-item-action>

                                                <!-- <v-list-item-content @click="toggleAmount(accountItem)"> -->
                                                <v-list-item-content>
                                                    <v-list-item-title>{{accountItem.accountLedgerName}}</v-list-item-title>
                                                </v-list-item-content>
                                                
                                                <v-list-item-action v-if="accountItem.selected" style="max-width:128px">
                                                    <v-text-field 
                                                        prefix="₹"
                                                        style="margin:0"
                                                        v-if="$parent.Form.splitPayment"
                                                        right-align
                                                        v-model.number="accountItem.amount"
                                                        type="number"
                                                        label="">
                                                    </v-text-field>
                                                    <!-- <ValidationProvider :name="'amount'+index+accountIndex" rules="double:2" v-slot="{ errors }">
                                                        <v-text-field 
                                                            prefix="₹"
                                                            style="margin:0"
                                                            v-if="$parent.Form.splitPayment"
                                                            right-align
                                                            v-model.number="accountItem.amount"
                                                            type="number"
                                                            label=""
                                                            :error-messages="errors"
                                                            :hide-details="!errors || !errors.length">
                                                        </v-text-field>
                                                    </ValidationProvider> -->
                                                </v-list-item-action>

                                            </v-list-item>
                                        </template>
                                        

                                    <!-- <div class="error--text font-small pa-2 full-width" v-if="errors.has('amount'+index+accountIndex)">
                                        {{ errors.first('amount'+index+accountIndex)}}
                                    </div> -->

                                </div>

                                </v-list>
                            </template>

                        </div>
                        
                    </v-form>
                    
                </v-card-text>

                <!-- <div white-background full-width nopadding style="position:fixed;bottom:0;">
                    <v-layout row wrap>

                        <v-flex xs6 sm6 md6 style="padding:6px 6px 6px 16px" v-if="$parent.Form.splitPayment">
                            <div font-medium :class="{'redcolor':totalSum>$parent.roundedAmount}" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">&#8377; {{$root.numberToLocale(totalSum)}}</div>
                            <div style="font-size:11px">Split Total</div>
                        </v-flex>
                        <v-flex xs6 sm6 md6 :class="{'xs12 sm12 md12 height48':!$parent.Form.splitPayment}">
                            <v-btn color="primary" full-height nomargin block no-round-borders noshadow @click="fn_checkValidations">Done</v-btn>
                        </v-flex>

                    </v-layout>
                </div> -->

                <v-card-actions>
                    <v-layout row wrap ma-0>

                        <v-flex height48 xs6 sm6 md6 style="padding:6px 6px 6px 16px" v-if="$parent.Form.splitPayment">
                            <div font-medium :class="{'redcolor':totalSum>$parent.roundedAmount}" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">&#8377; {{$root.numberToLocale(totalSum)}}</div>
                            <div style="font-size:11px">Split Total</div>
                        </v-flex>
                        <v-flex height48 :class="[$parent.Form.splitPayment ? 'xs6 sm6 md6' : 'xs12 sm12 md12']">
                            <v-btn color="primary" full-height nomargin block no-round-borders noshadow @click="fn_checkValidations">Done</v-btn>
                        </v-flex>

                        <!-- <v-flex pa-3 xs6 md6 sm6 :class="{'redcolor':paymentTotal>$parent.Form.amount}">
                            Total Payment <br>&#8377; {{paymentTotal}}
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                            <v-btn style="height:48px !important" color="primary" nomargin block no-round-borders noshadow @click="fn_checkValidations">Done</v-btn>
                        </v-flex> -->
                    </v-layout>
                </v-card-actions>

                <!-- <v-divider v-if="$parent.Form.splitPayment"></v-divider>
                <v-card-actions v-if="$parent.Form.splitPayment">
                    <v-layout row wrap>
                        <v-flex xs6 md6 sm6>
                            Bill Amount <br>&#8377; {{$parent.roundedAmount}}
                        </v-flex>
                        <v-flex xs6 md6 sm6 right-align :class="{'redcolor':totalSum>$parent.roundedAmount}">
                            Total Payment <br>&#8377; {{totalSum}}
                        </v-flex>
                    </v-layout>
                </v-card-actions> -->
            </v-card>   
        </v-dialog>
    </div>
</template>     

<script>

export default{
    props:['savePaymentOptions'],
    data(){ 
        return{
            dialog_payment_options: false,
            // $parent.Form.paymentLedgerId:null,
            // $parent.Form.splitPayment:null,
            // $parent.Form.paymentGroups:null
        }
    },
    methods:{
        openDialogWeb: function () {    
            // this.$validator.reset();
            // this.$parent.Form.splitPayment = this.$parent.Form.$parent.Form.splitPayment;
            // this.$parent.Form.paymentLedgerId = this.$parent.Form.$parent.Form.paymentLedgerId;
            // this.$parent.Form.paymentGroups = JSON.parse(JSON.stringify(this.$parent.Form.$parent.Form.paymentGroups));
            this.dialog_payment_options = true;
        },
        closeDialogWeb: function () {
            this.dialog_payment_options = false;
        },

        fn_isOnCredit: function(accountGroupId){
			if(this.$parent.Form.transactionData.transactionListId == 10003 || this.$parent.Form.transactionData.transactionListId == 20005){
				if(accountGroupId == 'creditAccountGroup' && this.$parent.Form.isCashTransaction){
					return false;
				}
				else{
					return true;
				}
			}
			else{
				return true;
			}
		},

        fn_newLedger: function(accountGroupId, accountGroupName, index){
            // setting up default account group to be selected
            let data = {};
            data.type = 'accountGroup';
            data.id = accountGroupId;
            data.name = accountGroupName;
            localStorage.setItem('newItem', JSON.stringify(data));

            this.$parent.backButtonPress = false;
            this.$parent.Form.paymentGroupIndex = index;

            let name = this.$parent.Form.selectedParty ? this.$parent.Form.selectedParty.itemName : '';
            this.$parent.fn_saveDraft(this.$parent.draftStorageId, name);

            this.$router.push({
                path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/ledger/items/new',
                query:{
                    tagId:this.guidWeb(), 
                    groupTypeId: 103, 
                    ls:1,
                    type:'ledger_other',
                    dlg:1
                }
            })  

            
        },

        fn_splitPayment: function(){
            // this.$parent.Form.splitPayment = !this.$parent.Form.splitPayment;
            if(this.$parent.Form.splitPayment){
                this.$parent.Form.paymentGroups[0].accountLedgers[0].selected = true;
                this.$parent.Form.paymentGroups[0].accountLedgers[0].amount = this.$parent.roundedAmount;
                // this.toggleAmount(this.$parent.Form.paymentGroups[0].accountLedgers[0], true);
            }
            else{
                // this.$parent.Form.paymentLedgerId = this.$parent.Form.paymentLedgerId ?  this.$parent.Form.paymentLedgerId : this.$parent.creditAccountLedgerId;
                if(this.$parent.Form.transactionData.transactionListId == 20005 && this.$parent.Form.isCashTransaction){
					this.$parent.Form.paymentLedgerId = this.$parent.Form.paymentLedgerId ?  this.$parent.Form.paymentLedgerId : this.$parent.fn_getPaymentCashAccountId();
				}
				else{
					this.$parent.Form.paymentLedgerId = this.$parent.Form.paymentLedgerId ?  this.$parent.Form.paymentLedgerId : this.$parent.creditAccountLedgerId;
				}
            }
        },
        // toggleAmount: function(accountItem, isCheckBox){
        toggleAmount: function(accountItem){
            // if(!isCheckBox){
            //  accountItem.selected = !accountItem.selected;
            // }

            if(!accountItem.selected){
                accountItem.amount = null;
            }
            else{
                if(this.paymentTotal < this.$parent.roundedAmount){
                    accountItem.amount = this.roundUpto2Decimals(this.$parent.roundedAmount - this.paymentTotal);
                }
                // accountItem.amount = this.$parent.Form.paymentGroups[0].accountLedgers[0].amount;
            }


            // this.fn_calculateCreditAmount(accountItem.amount);

        },

        // fn_calculateCreditAmount: function(amount){
        //  if(this.$parent.Form.paymentGroups[0].accountLedgers[0].amount > amount){
        //      this.$parent.Form.paymentGroups[0].accountLedgers[0].amount = this.roundUpto2Decimals(this.$parent.Form.paymentGroups[0].accountLedgers[0].amount - amount);
        //  }
        //  else{
        //      if(this.paymentTotal < this.$parent.roundedAmount){
        //          this.$parent.Form.paymentGroups[0].accountLedgers[0].amount = this.roundUpto2Decimals(this.$parent.roundedAmount - this.paymentTotal);
        //      }
        //      else{
        //          this.$parent.Form.paymentGroups[0].accountLedgers[0].amount = 0;
        //      }
        //  }
        // },

        fn_checkValidations: function(){
            // if(this.$parent.Form.splitPayment && this.paymentTotal != this.$parent.roundedAmount){
            if(this.$parent.Form.splitPayment && this.totalSum != this.$parent.roundedAmount){
                // this.$parent.snackbar = true;
                // this.$parent.snackbar_msg = "Total value should be equal to bill amount.";s
                this.$eventHub.$emit('snackbarAlert', {msg:'Total value should be equal to bill amount'});
                return;
            }

            if(!this.$parent.Form.splitPayment && !this.$parent.Form.paymentLedgerId){
                // alert("Select Mode");
                this.$eventHub.$emit('snackbarAlert', {msg:'Select Mode'});
                return;
            }

            // this.$parent.Form.splitPayment = this.$parent.Form.splitPayment;
            this.$parent.Form.paymentGroups = JSON.parse(JSON.stringify(this.$parent.Form.paymentGroups))
            this.savePaymentOptions(this.$parent.Form.paymentLedgerId); 
            // this.$parent.fn_closeDialog();
            this.closeDialogWeb();
        },

        fn_onCreditAmount: function(){
            let creditAmount = 0;
            if(!this.$parent.Form.isCashTransaction && this.paymentTotal < this.$parent.roundedAmount){
                creditAmount = this.roundUpto2Decimals(this.$parent.roundedAmount - this.paymentTotal);
            }

            if(this.$parent.Form.paymentGroups && this.$parent.Form.paymentGroups.length){
                this.$parent.Form.paymentGroups[0].accountLedgers[0].amount = creditAmount; 
            }

            if(this.$parent.Form.transactionData.transactionListId == 20005 && this.$parent.Form.isCashTransaction){
				return 0;
			}
			else{
				return creditAmount;
			}
            
            // return creditAmount;
        }
    },
    computed:{
        paymentTotal: function(){
            let totalPayment = 0;

            if(this.$parent.Form.paymentGroups){

                for(let i=0; i<this.$parent.Form.paymentGroups.length; i++){

                    let ledgers = this.$parent.Form.paymentGroups[i].accountLedgers;

                    for(let j=0; j<ledgers.length; j++){

                        if(ledgers[j].selected && ledgers[j].accountLedgerId != this.$parent.creditAccountLedgerId){
                            totalPayment += ledgers[j].amount ? ledgers[j].amount : 0;
                        }
                    
                    }   

                }

            }
            
            return totalPayment;
        },
        onCreditAmount: function(){
            return this.fn_onCreditAmount();
            // let creditAmount = 0;
            // if(this.paymentTotal < this.$parent.roundedAmount){
            //     creditAmount = this.roundUpto2Decimals(this.$parent.roundedAmount - this.paymentTotal);
            // }

            // if(this.$parent.Form.paymentGroups && this.$parent.Form.paymentGroups.length){
            //     this.$parent.Form.paymentGroups[0].accountLedgers[0].amount = creditAmount; 
            // }
            
            // return creditAmount;
        },
        totalSum: function(){
            return this.roundUpto2Decimals(this.paymentTotal + this.onCreditAmount);
        },
    }
}
</script>