<template>
    <div>

        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>

        <dialog-number-series ref="ref_dialogNumberSeries" :checkTransactionNumber="fn_checkTransactionNumber"></dialog-number-series>

        <dialog-payment-options ref="ref_dialogPaymentOptions" :savePaymentOptions="savePaymentOptions"></dialog-payment-options>

        <dialog-form ref="ref_dialogForm" :closeDialog="fn_closeDialogForm"></dialog-form>

        <dialog-dynamic-fields ref="ref_dialogDynamicFields" :saveFields="fn_saveFields" :openDatePicker="fn_openDatePicker"></dialog-dynamic-fields>

        <dialog-number-series ref="ref_dialogNumberSeries" :checkTransactionNumber="fn_checkTransactionNumber"></dialog-number-series>

        <dialog-transactions-list ref="ref_dialogPendingItems" :items="Form.pendingItemsList" :type="pendingType" :getPendingItems="fn_getPendingItems" formType="Purchase"></dialog-transactions-list>

        <dialog-return-form ref="ref_dialogReturnForm" :items="Form.pendingInvoices" type="invioceItems" :addPendingItems="fn_addPendingItems"></dialog-return-form>

        <dialog-confirmation ref="ref_dialogConfirmation" :header="confirmationHeader" :confirmationMessage="confirmationMessage" :actionMethod="confirmationAction" okBtnText="Yes" cancelBtnText="No" :displayView="true" :params="confirmationActionParam"></dialog-confirmation>

        <dialog-confirmation ref="ref_dialogBatchConfirmation" header="Batch Alert!" :confirmationMessage="batchConfirmationMessage"  :actionMethod="fn_closeDialogBatchConfirmation" :displayView='true' okBtnText="Okay"></dialog-confirmation>

        <dialog-discard ref="ref_dialogDiscard" :draftAction="fn_goBack" :save="fn_validate"></dialog-discard>

        <dialog-select-batch ref="ref_dialogSelectBatch" :productData="productDetails" :appendBatches="fn_appendBatches" :openDatePicker="fn_openDatePicker"></dialog-select-batch>
        <!-- <dialog-transaction-type-list ref="ref_dialogTransactionTypeList" :transactionTypeId="settings.transactionTypeId" :setTransactionType="fn_setTransactionType" :openConfirmationDialog="fn_openConfirmationDialog" :selectedTypeFormId="Form.transactionTypeFormId" :transactionTypeList="Form.transactionTypeList"></dialog-transaction-type-list>

        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>

        <dialog-note ref="ref_dialogNote"></dialog-note>
        <dialog-number-series ref="ref_dialogNumberSeries" :checkTransactionNumber="fn_checkTransactionNumber"></dialog-number-series>

        <dialog-tds ref="ref_dialogTds" :openAccountLedgers="fn_openAccountLedgers" :saveTdsDetails="fn_saveTdsDetails" :deleteTds="fn_removeTds"></dialog-tds>

        <dialog-product ref="ref_dialogProduct" :openProducts="fn_openProducts" :openTaxCategories="fn_openTaxCategories" :saveItem="fn_saveItem"></dialog-product>

        <dialog-tax-categories ref="ref_dialogTaxCategories" :taxCategories="taxCategories" :updateTaxCategory="fn_updateTaxCategory" 
        :selectedTaxCategoryId="Form.productDataModel.taxCategoryId"></dialog-tax-categories>

        <dialog-addons ref="ref_dialogAddOns" :openAddOnsList="fn_openAddOnsList" :addOnsProp="Form.addOnsProp" :saveAddOns="fn_saveAddOns"></dialog-addons>

        <dialog-dynamic-fields ref="ref_dialogDynamicFields" :saveFields="fn_saveFields" :openAccountLedgers="fn_openAccountLedgers" :openListItems="fn_openListItems" :openDatePicker="fn_openDatePicker"></dialog-dynamic-fields>

        <dialog-payment-options ref="ref_dialogPaymentOptions" :savePaymentOptions="savePaymentOptions"></dialog-payment-options>

        <dialog-discard ref="ref_dialogDiscard" :draftAction="fn_draftAction" :save="fn_checkValidations"></dialog-discard>

        <dialog-draft-items ref="ref_dialogDraftItems" :draftItems="Form.draftItems" :importDraft="fn_importDraft" :removeDraftItem="fn_removeDraftItem"></dialog-draft-items>

        <dialog-roundoff ref="ref_dialogRoundOff"></dialog-roundoff> -->

    </div>
</template>

<script>
// const dialogjs =  require('../../../masters/forms/dialog').dialogjs;

// const menuItem = require('../../../../config.js').configs.menuItem;
const transactionForms = require('../../../../config.js').configs.transactionForms;
const transactionTypes = require('../../../../config.js').configs.transactionTypes;

const ledgerGroupTypeId = require('../../../../config.js').configs.ledgerGroupTypeId;
const accountGroupIds = require('../../../../config.js').configs.accountGroupIds;
const accountGstType = require('../../../../config.js').configs.accountGstType;
// const productGroupTypeId = require('../../../../config.js').configs.productGroupTypeId;
const fixedGstAddOns = require('../../../../config.js').configs.fixedGstAddOns;
const fixedCessAddOns = require('../../../../config.js').configs.fixedCessAddOns;
const fixedAccountIds = require('../../../../config.js').configs.fixedAccountIds;
// const paymentAccountsAndLedger = require('../../../../config.js').configs.paymentAccountsAndLedger;

const transactionData = require('../../datamodel').data.transactionData;

// import dialogTransactionTypeList from '../dialogs/dialog_transaction_type_list'  //coming from outer dialogs folder
import dialogDatePicker from '../../../dialogs/dialog_datepicker'
// import dialogNote from '../dialogs/dialog_note'
import dialogNumberSeries from '../dialogs/dialog_number_series_manual'
// import dialogTds from '../dialogs/dialog_tds'

// import dialogProduct from './dialogs/dialog_product'

import dialogPaymentOptions from '../dialogs/dialog_payment_options'

import dialogPendingTransactions from '../dialogs/dialog_pending_transactions'

import dialogReturnForm from '../dialogs/dialog_return_form'

// import dialogTaxCategories from '../../../dialogs/dialog_tax_categories'

// import dialogAddOns from '../../../dialogs/dialog_addons'
import dialogConfirmation from '../../../dialogs/dialog_confirmation'

import dialogDynamicFields from '../dialogs/dialog_dynamic_fields'

import dialogDiscard from '../../../dialogs/dialog_discard'

import dialogSelectBatch from '../dialogs/dialog_select_batch'

// import dialogDiscard from '../../../dialogs/dialog_discard'
import {draftMixin} from '../../../draft_mixin'
import {transactionMixin} from '../../transaction_mixin'
// import dialogDraftItems from '../../../dialogs/dialog_draft_items'
// import dialogRoundOff from '../dialogs/dialog_roundoff'

import dialogForm from './dialogs/dialog_form'

    export default{
        props:['settings'],
        mixins: [draftMixin, transactionMixin],
        watch:{
            'Form': {
                handler: function (){
                     // this.fn_saveToLocalStorage(this);
                     let name = this.Form.selectedParty ? this.Form.selectedParty.itemName : '';
                     if(!this.unwatch){
                        this.fn_saveDraft(this.draftStorageId, name);
                        // console.log(name);
                     }
                     this.unwatch = false;
                 },
                 deep: true
            },
            'getDataCounter':{
                handler: function(val){
                    console.log('getcounter', val);

                    // if(val>=7){
                    if(val>=5){
                        this.fn_setNewItem();
                    }
                }
            }
        },
        data(){
            return{
                Form:{
                    "transactionData":JSON.parse(JSON.stringify(transactionData)),
                    "partyId":null, "partyName":null, "partylastConfig":null,
                    "currencyId":null, "currencyCode":null, "exchangeRate":null,
                    "taxMode":0,
                    "transactionTypeFormId":null, "transactionTypeFormName":null,

                    "items":[],

                    "productDataModel": {"groupId":null, "productId":null, "productName":null, "mrp":null, "rate":null, "productVariants":[], "taxValue":null, "taxAmount":0, "hsnCode":null, "orderNumber":null, "grossAmount":0, "grossAmountAsPerMRP":0, "netAmount":0, "addOnsItems":[], "cessValue":null, "cessAmount":0, "taxCategoryId": null, "taxCategoryName": null, 
                        "defaultTaxValue":null, "defaultCessValue":null, "defaultHsnCode":null, "defaultTaxCategoryId":null, "defaultTaxCategoryName": null, "isCessApplicable":false, "taxableValue":null, "description":null, "selectedTaxCategory":null
                    },

                    "addOnsDataModel": {"transactionTypeAddOnsId":null, "addOnsId":null, "name":null, "percentage":null, "value":null, "mode":null, "nature":null, "appliedOn":0, "adjustInTotal":true, "orderNumber":null, "addOnsType":null, "type":null},

                    "tdsDataModel":{
                        "transactionTdsId":null, "transactionId":null, "tdsAccountId":null, "tdsAccountName":null, "appliedOnAmount":null, "percentage":null, "amount":null
                    },

                    "transactionAddOns":[],

                    "tdsDetails":{"amount":0},

                    "addOnsProp":{},

                    "itemsSummary":{"grossAmount":0, "grossAmountAsPerMRP":0, "addOnsItems":[], "taxValue":0, "itemsQuanity":[]},

                    "settings":{"isInterState":false},

                    "transactionTypeData":null,
                    "dynamicFields":{"section":null, "items":[], "accountGroupId":null, "accountGroupName":null, "ref":null},

                    "dynamicFieldsAdditionalData":{},

                    "customFields":[],

                    "dynamicMenuItems":[],

                    "partyDetails":null,

                    "gstType":null,

                    "defaultTaxAddOns":[],

                    "gstAddOns":[],
                    "cessAddOns":null,

                    "savedItemData":null,
                    "isNew":true,
                    
                    "pendingItemsList":null,

                    "pendingOrders":[],
                    "pendingChallans":[],
                    "pendingInvoices":[],
                    "isPendingItems":false,

                    "pendingOrderAndChallan":null,
                    "pendingCount":0,

                    "transactionTypeList":[],
                    "draftItems":[],
                    "hideEwayOption": false,
                    "placeOfSupplyId":null,
                    "placeOfSupplyName":null,
                    "placeOfSupplyCountryId":null,
                    "placeOfSupplyCountryName":null,
                    // "partyGstType":null,
                    "netInvoiceValue":null,
                    "isRounded":this.$root.TransactionSettings.isAutoRoundOff ? true : false,
                    "itemType":0,
                    "roundOffValue":null,
                    "roundOffNature":{'title':'(+) Add', 'value':1},
                    "isRcmApplicable":false,
                    "attachments":[],

                    "onCredit":true,
                    "paymentLedgerId":null,
                    "paymentGroups":null,
                    "splitPayment":false,
                    "paymentOptions":[],
                    "balanceAsOn":0,
                    "description":null,
                    // "removeItemParams":null,
                    // "newSelection":false,
                    "paymentGroupIndex":null,

                    "selectedParty":null,
                    "selectedState":null,
                    "selectedTaxMode":{"title":"None", "value":0},
                    "selectedExpenseCategory":null,
                    "hasItemAddOns":false,

                    "paymentTerm":30,
                    "selectedPaymentTerm":{"title":"No Due Date", "value":-1},

                    "batches":[],
                    "selectedBatchType":null,
                    "hasOnlySeries":true,
                    "hasSeries":false,
                    "seriesFields":[],
                },

                toolbarTitle:null,
                loading:false,
                tagId:null,
                draftStorageId:null,
                dialogStorageId:null,

                backButtonPress: false,

                snackbar:false,
                snackbar_msg:null,

                unwatch:false,
                // loadedFromDraft:false,

                dateType:null,
                selectedDate:null,

                passStockEntry:false,
                passLedgerEntry:false,

                taxCategories:null,
                draftModifiedDate:null,
                transactionNumberSnackbar:false,
                disableSaveBtn:false,

                expenseCategories:null,
                itemSearchValue:'',
                tdsSearchValue:'',
                taxSearchValue:'',
                discountSearchValue:'',
                otherChargeSearchValue:'',
                pendingType:null,
                disableOnSave:false,
                batchConfirmationMessage:null,
                productDetails:null,
                bType:null
            }
        },
        components:{
            // 'dialog-currency-list': dialogCurrencyList,
            // 'dialog-transaction-type-list': dialogTransactionTypeList,
            'dialog-datepicker': dialogDatePicker,
            // 'dialog-note':dialogNote,
            // 'dialog-tds':dialogTds,
            // 'dialog-product':dialogProduct,
            // 'dialog-tax-categories':dialogTaxCategories,
            // 'dialog-addons':dialogAddOns,
            'dialog-confirmation':dialogConfirmation,
            'dialog-dynamic-fields':dialogDynamicFields,
            'dialog-discard': dialogDiscard,
            'dialog-number-series': dialogNumberSeries,
            // 'dialog-draft-items': dialogDraftItems,
            // 'dialog-roundoff':dialogRoundOff,
            'dialog-payment-options':dialogPaymentOptions,
            'dialog-form': dialogForm,
            // 'dialog-drive-alert':dialogDriveAlert
            // 'dialog-particular':dialogParticular,
            'dialog-transactions-list':dialogPendingTransactions,
            'dialog-return-form':dialogReturnForm,
            'dialog-select-batch':dialogSelectBatch
        },
        methods:{

            fn_openDialogSelectBatch: function(item, type){

                this.bType = type;

                this.productDetails = item;

                // this.Form.seriesFields = [];
                // if(this.$root.TransactionSettings.enableBatch && this.$root.TransactionSettings.batchFields && this.$root.TransactionSettings.batchFields.length){
                //     for(let i=0; i<this.$root.TransactionSettings.batchFields.length; i++){
                //         let field = this.$root.TransactionSettings.batchFields[i]
                //         if(field.id == 'field1' || field.id == 'field2' || field.id == 'field3'){
                //             this.hasSeries = true;
                //             this.Form.seriesFields.push(field);
                //         }
                //         if(field.id == 'batchNumber' || field.id == 'batchMRP' || field.id == 'purchaseRate' || field.id == 'batchSize' || field.id == 'mfgDate' || field.id == 'expDate' || field.id == 'field4'){
                //             this.Form.hasOnlySeries = false;
                //         }
                //     }
                //     if(!this.Form.selectedBatchType){
                //         this.Form.selectedBatchType = !this.Form.hasOnlySeries ? 1 : 2;
                //     }
                // }

                if(type == 'edit' && item.batches && item.batches.length){
                    for(let i=0; i<this.Form.batches.length; i++){
                        // this.Form.batches[i].productQty = null;
                        this.$set(this.Form.batches[i], 'selected', false);
                        if(item.batches && item.batches.length){
                            for(let j=0; j<item.batches.length; j++){
                                if(item.batches[j].batchId == this.Form.batches[i].batchId){
                                    if(item.batches[j].productQty){
                                        this.Form.batches[i].selected = true;
                                        this.Form.batches[i].productQty = item.batches[j].productQty;
                                        item.batches[j].billQty ? this.Form.batches[i].billQty = item.batches[j].billQty : '';
                                        
                                        item.batches[j].isAdded != undefined ? this.Form.batches[i].isAdded = item.batches[j].isAdded : '';
                                        item.batches[j].qty != undefined ? this.Form.batches[i].qty = item.batches[j].qty : '';
                                        item.batches[j].refTId != undefined ? this.Form.batches[i].refTId = item.batches[j].refTId : '';
                                        item.batches[j].refTpBatchId != undefined ? this.Form.batches[i].refTpBatchId = item.batches[j].refTpBatchId : '';
                                        // item.batches[j].transactionProductBatchId != undefined ? this.Form.batches[i].transactionProductBatchId = item.batches[j].transactionProductBatchId : '';
                                        if(item.batches[j].refTransactionProductBatchId){
                                            this.Form.batches[i].transactionProductBatchId = item.batches[j].refTransactionProductBatchId;
                                        }
                                        else{
                                            item.batches[j].transactionProductBatchId != undefined ? this.Form.batches[i].transactionProductBatchId = item.batches[j].transactionProductBatchId : '';
                                        }
                                        item.batches[j].transactionProductId != undefined ? this.Form.batches[i].transactionProductId = item.batches[j].transactionProductId : '';
                                    }
                                }
                            }
                        }					
                    }
                }

                this.$refs['ref_dialogSelectBatch'].openDialogWeb();
            },

            fn_closeDialogForm: function(){
                this.askToDiscard = true;
                if(this.fn_showDialogDiscard()){
                    this.$refs['ref_dialogDiscard'].openDialogWeb();
                }
                else{
                    this.fn_goBack();
                }
            },

            fn_openDialogRoundOff: function(){
                if(!this.Form.isRounded || !this.netAmount){
                    return;
                }
                this.Form.roundOffValue = this.roundOff;
                this.fn_openDialogWeb('web', 'RoundOff', 'ref_dialogRoundOff');
            },

            fn_setRoundOffValue: function(value){
                // alert(this.Form.roundOffNature.value);
                this.Form.roundOffValue = this.Form.roundOffNature.value ? Math.abs(value) : -Math.abs(value);
                // this.Form.roundOffValue = this.Form.roundOffNature.value === 0 ? -this.Form.roundOffValue : +this.Form.roundOffValue;
                // this.fn_closeDialog();
            },

            fn_openDialogNumberSeries: function(){
                // this.fn_openDialogWeb('web', 'NumberSeries', 'ref_dialogNumberSeries');
                this.$refs['ref_dialogNumberSeries'].openDialogWeb();
                this.transactionNumberSnackbar = false;
            },

            fn_updatePaymentTerm: function(obj){
                !obj.value ? this.Form.paymentTerm = null : '';
            },

            // fn_changeIsRounded: function(val, setFocus){
            //     let roundAmount = null;
            //     if(val){
            //         roundAmount = Math.round(this.totalAmount);

            //         if(setFocus){
            //             setTimeout(()=>{
            //                 document.getElementById("roundOffValueField").focus();
            //             },50);    
            //         }
            //     }
            //     else{
            //         this.Form.roundOffValue = null;
            //         roundAmount = this.totalAmount;
            //     }


            //     if(val && !this.Form.roundOffValue){
            //         this.Form.roundOffValue = this.roundUpto2Decimals(roundAmount - this.totalAmount);
            //     }

            //     if(this.Form.roundOffValue){
            //         this.Form.roundOffNature = this.Form.roundOffValue < 0 ? {'title':'(-) Subtract', 'value':0} : {'title':'(+) Add', 'value':1};
            //     }
            // },

            fn_openDialogDiscard: function(){
                this.fn_openDialogWeb('web', 'Discard', 'ref_dialogDiscard')
            },

            fn_partyLabel: function(){
                if(this.Form.placeOfSupplyCountryName){
                    return "Supplier (" + this.Form.placeOfSupplyCountryName + ")";
                }
                else{
                    return "Supplier";
                }
            },

            formType: function(){
                if(this.settings.transactionTypeId == transactionTypes.purchaseOrder.id)
                    return 'order';
                else if(this.settings.transactionTypeId == transactionTypes.purchaseChallan.id)
                    return 'challan';
                else if(this.settings.transactionTypeId == transactionTypes.purchaseInvoice.id)
                    return 'invoice';
                else if(this.settings.transactionTypeId == transactionTypes.purchaseReturn.id)
                    return 'return';
            },

            fn_getTransactionTypeList: function (setTFT) {
                let filterObject = {};
                filterObject.type = this.settings.transactionTypeId;
                // filterObject.accountGroupStatus = 1;
                filterObject.accountGroupsHidden = [2];
                // filterObject.inventory = [0, 1];

                let self = this;
                this.getItems("TransactionTypeList", filterObject, 
                  {
                    onSuccess: function (response) {
                        console.log(response);

                        self.getDataCounter += 1;

                        self.unwatch = true;

                        self.Form.transactionTypeList = JSON.parse(response);

                        setTFT ? self.fn_setLastSelectedTFT() : '';
                        // setTFT ? self.fn_setLastSelectedTFT() : self.fn_getSuppliers();

            //              if(!self.loadedFromDraft && self.Form.isNew){

            //                  if(self.Form.transactionTypeList.length && !self.Form.transactionTypeFormId){

                  //                if(localStorage.getItem(self.settings.transactionTypeId)){
                                    
                  //                    let transactionTypeFormId = localStorage.getItem(self.settings.transactionTypeId);

                  //                    for(let i=0; i<self.Form.transactionTypeList.length; i++){
                  //                        if(self.Form.transactionTypeList[i].transactionTypeFormId == transactionTypeFormId){
                  //                            self.fn_setTransactionType(self.Form.transactionTypeList[i]);
                  //                            break;
                  //                        }
                  //                    }                       
                  //                }
                  //                else{
                  //                    if(self.Form.transactionTypeList.length == 1){
                  //                        self.fn_setTransactionType(self.Form.transactionTypeList[0]);
                  //                    }
                  //                }

                  //           }
                    
                        // }     
                      
                    },
                    onError: function (error) {
                      console.log(error);
                    }
                  }
                )
            },

            fn_setLastSelectedTFT: function(){
                if(localStorage.getItem(this.settings.transactionTypeId) && this.Form.transactionTypeList.length){
                    let transactionTypeFormId = localStorage.getItem(this.settings.transactionTypeId);

                    for(let i=0; i<this.Form.transactionTypeList.length; i++){
                        if(this.Form.transactionTypeList[i].transactionTypeFormId == transactionTypeFormId){
                            // this.loading = true;
                            this.fn_setTransactionType(this.Form.transactionTypeList[i], true);
                            break;
                        }
                    }
                }
                else{
                    if(this.Form.transactionTypeList.length == 1){
                        // this.loading = true;
                        this.fn_setTransactionType(this.Form.transactionTypeList[0], true); 
                    }
                    else{
                        // this.fn_openTransactionTypeList();
                        //transacion mixin function
                        // this.loading = false
                        // this.fn_openTransacionTypeList_onFormLoad();

                        for(let i=0;i<this.Form.transactionTypeList.length; i++){
                            if(this.Form.transactionTypeList[i].isDefault){
                                this.fn_setTransactionType(this.Form.transactionTypeList[i], true);
                                break;
                            }
                        }
                    }
                }
            },

            fn_saveFields: function (fieldsObj) {
                let data = JSON.parse(JSON.stringify(fieldsObj));

                console.log(JSON.stringify(data));

                //finding if details already exist
                let itemIndex = this.Form.customFields.findIndex(item=>{
                    return item.section.sectionId == data.section.sectionId;
                })

                if(itemIndex==-1){
                    this.Form.customFields.push(data);
                }
                else{
                    this.Form.customFields[itemIndex] = data;
                    this.$forceUpdate();
                }

                let self = this;
                setTimeout(function () {
                    // self.$vuetify.goTo('#section_'+data.section.sectionId, self.scrollOptions);

                    if(data.section.sectionId == self.ewayBillDetailSection.sectionId){
                        self.Form.hideEwayOption = true;
                    }
                    else{
                        let index = itemIndex != -1 ? itemIndex : self.Form.customFields.length-1
                        self.fn_showHideDynamicFieldOption(index, true);
                    }
                },100);
                // this.fn_closeDialog();
                this.$refs['ref_dialogDynamicFields'].closeDialogWeb();
            },

            fn_removeFields: function (index, sectionId) {
                if(this.ewayBillDetailSection.sectionId == sectionId){
                    this.Form.hideEwayOption = false;
                }
                else{
                    this.fn_showHideDynamicFieldOption(index, false);
                }
                this.Form.customFields.splice(index, 1);
            },

            fn_showHideDynamicFieldOption: function(index, hideOption){
                for(let j=0; j<this.Form.dynamicMenuItems.length; j++){
                    if(this.Form.dynamicMenuItems[j].sectionId == this.Form.customFields[index].section.sectionId){
                        this.Form.dynamicMenuItems[j].hideOption = hideOption;
                        this.$forceUpdate();
                    }
                }
            },

            fn_openConfirmationDialog: function (msg) {
                this.confirmationMessage = msg;
                this.fn_openDialogWeb('web', 'confirmationDialog', 'ref_dialogChangeTransactionType');
            },

            // fn_onChange: function(tagId, ref, selectedValueId, selectedValueName, isRemoved){

            //     if(ref.indexOf('dialog') != -1){
            //         // if(ref=='ref_dialogAddOns_name' && this.Form.newSelection){
            //         if(ref=='ref_dialogAddOns_name' && localStorage.getItem('newSelection')){
            //             this.fn_closeDialog();
            //             let self = this;
            //             setTimeout(() => {
            //                 let dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
            //                 if(!dialogData || (dialogData && dialogData[0].ref != "ref_dialogAddOns")){
            //                     self.fn_openDialogWeb('web', 'AddOnsForm', 'ref_dialogAddOns')  
            //                 }
            //                 // self.fn_openDialogWeb('web', 'AddOnsForm', 'ref_dialogAddOns');
            //             }, 300);
            //             setTimeout(() => {
            //                 self.fn_updateDialogRef(tagId, ref, selectedValueId, selectedValueName, isRemoved);
            //             }, 600);
            //         }
            //         // else if(ref=="ref_dialogProduct_productName" && this.Form.newSelection){
            //         else if(ref=="ref_dialogProduct_productName" && localStorage.getItem('newSelection')){
            //             this.fn_closeDialog();
            //             let self = this;
            //             setTimeout(() => {
            //                 let dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
            //                 if(!dialogData || (dialogData && dialogData[0].ref != "ref_dialogProduct")){
            //                     // self.fn_openDialogWeb('web', 'Product', 'ref_dialogProduct') 
            //                     self.fn_openDialogProductForm();
            //                 }
            //                 // self.fn_openDialogWeb('web', 'Product', 'ref_dialogProduct');
            //             }, 300);
            //             setTimeout(() => {
            //                 self.fn_updateDialogRef(tagId, ref, selectedValueId, selectedValueName, isRemoved);
            //             }, 600);
            //         }
            //         else{
            //             this.fn_updateDialogRef(tagId, ref, selectedValueId, selectedValueName, isRemoved);
            //         }
            //         // let key = ref.substring(0, ref.lastIndexOf('_'));
            //         // let dialogRef = this.$refs[key];
        
            //         // var checkRefs = setInterval(function(){
            //         //   if(dialogRef.$refs.hasOwnProperty(ref)){
            //         //      clearInterval(checkRefs);
            //         //      if(Array.isArray(dialogRef.$refs[ref])){
            //         //          dialogRef.$refs[ref][0].$attrs.onUpdate(selectedValueId, selectedValueName, isRemoved, ref);
            //         //      }
            //         //      else{
            //         //          dialogRef.$refs[ref].$attrs.onUpdate(selectedValueId, selectedValueName, isRemoved, ref);
            //         //      }
            //         //   }
            //         // },50);
            //     }
            //     else{
            //         this.$refs[ref].$attrs.onUpdate(selectedValueId, selectedValueName, isRemoved);
            //     }
            // },

            fn_openStates: function(){
                this.fn_openStateDialog('android', 'state', 'ref_fieldState');
            },

            // fn_openStateDialog: function (platform, name, ref) {

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  dialogObj.platform = platform;

            //  var self = this;

            //  var viewDialogObject = {};
            //  viewDialogObject.ToolbarTitle = "Select State/Place of Supply";
            //  viewDialogObject.isBottomMenu = false;
            //  viewDialogObject.hideChip = true;

            //  var paramObject = {};
            //  paramObject.defaultValue = {"id":null, "name":null};

            //  if(this.Form.placeOfSupplyId && this.Form.placeOfSupplyName){
            //      paramObject.defaultValue = {"id":this.Form.placeOfSupplyId, "name":this.Form.placeOfSupplyName};
            //  }

            //  var self = this;
            //  dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, 'state', tagId, paramObject, ref);
            // },

            fn_updateState: function (selectedValueId, selectedValueName) {
                // if(!isRemoved){
                //  this.Form.placeOfSupplyId = selectedValueId;
          //        this.Form.placeOfSupplyName = selectedValueName;
          //        this.errors.remove('state');
                // }
                // else{
                //  this.Form.placeOfSupplyId = null;
          //        this.Form.placeOfSupplyName = null;
                // }
                // this.$forceUpdate();

                this.Form.selectedState = {"itemId":selectedValueId, "itemName":selectedValueName};

                let oldGstType = this.Form.gstType ? this.Form.gstType : null;

                if(this.Form.gstType != this.$root.fn_getGstType(selectedValueId, this.Form.partyDetails.gstType, this.Form.transactionTypeData.importExport)){
                    this.Form.gstType = this.$root.fn_getGstType(selectedValueId, this.Form.partyDetails.gstType, this.Form.transactionTypeData.importExport);
                    this.fn_bindGstAddOns();
                }

                if(oldGstType && oldGstType != this.Form.gstType){
                    this.fn_removeAddOnsOnPlaceOfSupplyChange(fixedGstAddOns);  
                }

                // if(this.Form.gstType != this.$root.fn_getGstType(this.Form.placeOfSupplyId, this.Form.partyGstType)){
          //            this.Form.gstType = this.$root.fn_getGstType(this.Form.placeOfSupplyId, this.Form.partyGstType);
          //            this.fn_bindGstAddOns();
          //       }
            },

            // fn_openSuppliers: function () {

            //  if(!this.Form.transactionTypeFormId){
            //      this.fn_openTransactionTypeList();
            //      alert("Please select transaction form");
            //      return
            //  }

            //  if(this.Form.items.length && (this.Form.pendingOrders.length || this.Form.pendingChallans.length || this.Form.pendingInvoices.length)){
            //      // this.snackbar_msg = 'Kindly remove dependent items.';
            //      // this.snackbar = true;
            //      alert("Please remove dependent items");
            //      return
            //  }

            //  let accountGroups = [];
            //  if(this.Form.transactionTypeData && !this.Form.transactionTypeData.isAllPartySelected){
            //      this.Form.transactionTypeData.transactionParties.forEach(function (item) {
            //          accountGroups.push(item.accountGroupId);
            //      });
            //  }
            //  this.fn_openAccountLedgers('android', 'Supplier', 'ref_fieldSupplier', this.Form.partyId, this.Form.partyName, accountGroups);
            // },

            // fn_openCurrencyList: function () {
            //  this.fn_openDialogWeb('web', 'CurrencyList', 'ref_dialogCurrencyList'); 
            // },

            fn_confirm: function () {
                this.fn_closeDialog();
                this.fn_openTransactionTypeList();
            },

            fn_openTransactionTypeList: function () {
                this.fn_openDialogWeb('web', 'TransactionTypeList', 'ref_dialogTransactionTypeList');   
            },

            fn_openTaxCategories: function(){
                this.fn_openDialogWeb('web', 'TaxCategories', 'ref_dialogTaxCategories');
            },

            // fn_getTaxCategories: function(){
            //   let filterObject = {};
            //   let self = this;
            //   this.getItems("TaxCategoryList", filterObject, 
            //     {
            //       onSuccess: function (response) {
            //         console.log(response);
            //         self.getDataCounter += 1;
            //         self.unwatch = true;
            //         self.taxCategories = JSON.parse(response);
            //       },
            //       onError: function (error) {
            //         console.log(error);
            //       }
            //     }
            //   )
            // },

            fn_addItem: function (qty) {
                if(qty<=0){
                    // alert("Quntity should be greater than zero");
                    this.$eventHub.$emit('snackbarAlert', {msg:'Quantity should be greater than zero'});
                    return;
                }

                if(this.Form.transactionTypeList && this.Form.transactionTypeList.length>1){
                    if(!this.Form.transactionTypeFormName){
                        // alert("Select transaction form");
                        this.$eventHub.$emit('snackbarAlert', {msg:'Select transaction form'});
                    }
                    else{
                        this.fn_addNewItem();
                    }
                }
                else{
                    this.fn_addNewItem();
                }
            },

            fn_addNewItem: function(){
                this.fn_bindTransactionTypeAddOns();
            },

            // fn_openDialogProductForm: function(){
            //     // if(this.Form.newSelection){
            //     if(localStorage.getItem('newSelection')){
            //         this.Form.productDataModel = {"groupId":null, "productId":null, "productName":null, "mrp":null, "rate":null, "productVariants":[], "taxValue":null, "taxAmount":0, "hsnCode":null, "orderNumber":null, "grossAmount":0, "grossAmountAsPerMRP":0, "netAmount":0, "addOnsItems":[], "cessValue":null, "cessAmount":0, "taxCategoryId": null, "taxCategoryName": null, "defaultTaxValue":null, "defaultCessValue":null, "defaultHsnCode":null, "defaultTaxCategoryId":null, "defaultTaxCategoryName": null, "isCessApplicable":false, "taxableValue":null, "description":null
            //         };

            //         this.fn_bindTransactionTypeAddOns();
            //     }
            //     this.fn_openDialogWeb('web', 'Product', 'ref_dialogProduct');
            // },

            // fn_openTdsForm: function () {
            //  if(this.Form.tdsDetails){
            //      this.Form.tdsDataModel = JSON.parse(JSON.stringify(this.Form.tdsDetails));
            //  }
            //  else{
            //      this.Form.tdsDataModel = {"transactionTdsId":null, "transactionId":null, "tdsAccountId":null, "tdsAccountName":null, "appliedOnAmount":null, "percentage":null, "amount":null
            //      }
            //  }
            //  this.fn_openDialogWeb('web', 'TDS', 'ref_dialogTds');
            // },

            fn_openAddOns: function (addOnsType, isNew) {

                // this.Form.addOnsProp.value = "addOns";
                // this.Form.addOnsProp.toolbarTitle = "Discounts/Taxes/Other Charges";

                // this.fn_openDialogWeb('web', 'AddOnsForm', 'ref_dialogAddOns');  

                if(isNew){
                    // this.Form.newSelection = true;
                    localStorage.setItem('newSelection', 1);
                    this.Form.addOnsDataModel = {"transactionTypeAddOnsId":null, "addOnsId":null, "name":null, "percentage":null, "value":null, "mode":null, "nature":null, "appliedOn":0, "adjustInTotal":true, "orderNumber":null, "addOnsType":addOnsType};

                    this.fn_openAddOnsList('android', 'AddOns', 'ref_dialogAddOns_name', null, null, addOnsType);
                }
                else{
                    this.fn_openDialogWeb('web', 'AddOnsForm', 'ref_dialogAddOns');
                }
            },

            fn_openDatePicker: function (type, date) {
                // let self = this;
                this.dateType = type;
                // this.selectedDate = date ? date : new Date();
                this.selectedDate = date ? date : this.$root.fn_getLocalDateFormat();
                setTimeout(()=>{
                    // self.fn_openDialogWeb('web', 'DatePicker', 'ref_dialogDatePicker');
                    this.$refs['ref_dialogDatePicker'].openDialogWeb();
                })
            },

            fn_updateDate: function (value) {
                if(this.dateType=="transactionDate"){
                    this.fn_updateTransactionDate(value, this.Form.isNew);
                }
                else if(this.dateType=="refDate"){
                    this.Form.transactionData.refDate = this.$root.fn_getLocalDateFormat(value);
                }
                else if(this.dateType.indexOf("dynamicFieldDate") != -1){
                    this.$refs['ref_dialogDynamicFields'].fn_updateDynamicDate(value, this.dateType);
                }
                else if(this.dateType == 'mfgDate' || this.dateType == 'expDate'){
                    this.$refs['ref_dialogSelectBatch'].fn_updateDate(value, this.dateType);
                }

                this.$refs['ref_dialogDatePicker'].disableBtn = false;
            },

            // fn_openDialogWeb: function (platform, name, ref) {

            //  let scroll_y = window.scrollY;
            //  this.fn_fixBackgroundScroll();

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  dialogObj.data = null;
            //  dialogObj.platform = platform;

            //  dialogObj.scrollPosition = {};
            //  dialogObj.scrollPosition.x = 0;
            //  dialogObj.scrollPosition.y = scroll_y;

            //  dialogjs.fn_pushDialogInStack(this.dialogStorageId, dialogObj, this);

            //  let self = this;

            //  this.$refs[ref].openDialogWeb();
            // },

            // fn_openAccountLedgers: function (platform, name, ref, itemId, itemName, accountGroups) {

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  dialogObj.platform = platform;

            //  var self = this;

            //  var viewDialogObject = {};
            //  viewDialogObject.ToolbarTitle = ref == 'ref_fieldSupplier' ? 'Select Supplier' : "Select Ledger";

            //  let action = "newledger";

            //  if(name == 'Supplier'){
            //      // viewDialogObject.groupTypeId = ledgerGroupTypeId.supplier;
            //      action = "newsupplier";
            //  }

            //  this.Form.dynamicFields.accountGroupId ? action = "dynamicAccountField" : "";

            //  let menuItemCustom = {"MenuID":null, "Icon":null, "Text":"New", "Action":action};
            //  viewDialogObject.TopMenu = [menuItemCustom];
            //  viewDialogObject.isBottomMenu = false;
            //  // viewDialogObject.accountGroups = [];
            //  // viewDialogObject.accountGroupIds = [];
            //  viewDialogObject.accountGstTypes = null;

            //  if(accountGroups && accountGroups.length){
            //      viewDialogObject.accountGroups = JSON.parse(JSON.stringify(accountGroups));
            //      // viewDialogObject.accountGroupIds = JSON.parse(JSON.stringify(accountGroups));
            //  }

            //  let isDynamicField = ref.indexOf("ref_dialogDynamicFields_accountLedger") == -1 ? false : true;

            //  if(this.Form.transactionTypeData.importExport && this.Form.transactionTypeData.importExportTransactionAccountId && !isDynamicField){
            //      viewDialogObject.accountGstTypes = [accountGstType.overseas.id, accountGstType.sez.id]
            //  }
            //  else{
            //      viewDialogObject.accountGstTypes = [accountGstType.registered.id, accountGstType.composition.id, accountGstType.unregistered.id, accountGstType.consumer.id, accountGstType.deemed.id];
            //  }

            //  ref == 'ref_dialogTds_AccountLedger' ? viewDialogObject.accountGstTypes = null : '';

            //  var paramObject = {};
            //  paramObject.defaultValue = {"id":itemId, "name":itemName};

            //  var self = this;
            //  dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, 'accounts', tagId, paramObject, ref);
            // },

            // fn_openProducts: function (platform, name, ref, itemId, itemName) {

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  //dialogObj.data = null;
            //  dialogObj.platform = platform;


            //  var self = this;

            //  var viewDialogObject = {};
            //  viewDialogObject.ToolbarTitle = "Select Item";
            //  // viewDialogObject.groupTypeId = productGroupTypeId.product;
            //  viewDialogObject.inventory = 1;
            //  viewDialogObject.hideChip = true;

            //  let menuItemCustom = {"MenuID":null, "Icon":null, "Text":"New", "Action":"newproduct"};
            //  viewDialogObject.TopMenu = [menuItemCustom];
            //  viewDialogObject.isBottomMenu = false;

            //  var paramObject = {};
            //  paramObject.defaultValue = {"id":itemId, "name":itemName};

            //  var self = this;
            //  dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, 'product', tagId, paramObject, ref);
            // },

            // fn_openListItems: function (platform, name, ref, itemId, itemName, listId, listName) {

            //  this.Form.dynamicFieldsAdditionalData.listId = listId;
            //  this.Form.dynamicFieldsAdditionalData.listName = listName;

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  //dialogObj.data = null;
            //  dialogObj.platform = platform;


            //  var self = this;

            //  var viewDialogObject = {};
            //  viewDialogObject.ToolbarTitle = "Select Item";
            //  let menuItemCustom = {"MenuID":null, "Icon":null, "Text":"New", "Action":"newListItem"};
            //  viewDialogObject.TopMenu = [menuItemCustom];

            //  viewDialogObject.isBottomMenu = false;
            //  viewDialogObject.listId = listId;

            //  var paramObject = {};
            //  paramObject.defaultValue = {"id":itemId, "name":itemName};

            //  var self = this;
            //  dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, 'listItems', tagId, paramObject, ref);
            // },


            // fn_openAddOnsList: function (platform, name, ref, itemId, itemName, addOnsType) {

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  //dialogObj.data = null;
            //  dialogObj.platform = platform;

            //  var self = this;

            //  var viewDialogObject = {};
            //  // viewDialogObject.ToolbarTitle = this.Form.addOnsProp.toolbarTitle;
            //  // let menuItemCustom = {"MenuID":null, "Icon":null, "Text":"New", "Action":this.Form.addOnsProp.value};
            //  // viewDialogObject.TopMenu = [menuItemCustom];
            //  this.Form.addOnsProp.addOnsType = addOnsType;

            //  // let actionValue = null;
            //  if(addOnsType == 1){
            //      this.Form.addOnsProp.toolbarTitle = "Discounts";
            //      viewDialogObject.TopMenu = [menuItem.addDiscount];
            //      // actionValue = "adddiscount";
            //  }
            //  else if(addOnsType == 2){
            //      this.Form.addOnsProp.toolbarTitle = "Taxes";
            //      viewDialogObject.TopMenu = [menuItem.addTaxes];
            //      // actionValue = "addtaxes";
            //  }
            //  else{
            //      this.Form.addOnsProp.toolbarTitle = "Other Charges";
            //      viewDialogObject.TopMenu = [menuItem.addOtherCharges];  
            //      // actionValue = "addothercharges";
            //  }

            //  viewDialogObject.ToolbarTitle = this.Form.addOnsProp.toolbarTitle;

            //  viewDialogObject.isBottomMenu = false;
            //  viewDialogObject.hideChip = true;
            //  viewDialogObject.addOnType = Number(addOnsType);

            //  var paramObject = {};
            //  paramObject.defaultValue = {"id":itemId, "name":itemName};

            //  var self = this;
            //  dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, "addOns", tagId, paramObject, ref);
            // },

            fn_saveAddOns: function (data) {

                // this.Form.newSelection = false;
                localStorage.removeItem('newSelection');
                if(data.mode==0 && (data.percentage==null || data.percentage=='')){
                    data.value = null;
                }

                if(!data.isDefault)
                    data.isDefault = false;

                !data.transactionAddOnsId ? data.transactionAddOnsId = this.guid() : '';

                // let itemIndex = this.Form.transactionAddOns.findIndex(item=>{
                //  return item.transactionAddOnsId == data.transactionAddOnsId;
                // });

                // if(itemIndex==-1){
                //  this.Form.transactionAddOns.push(data);
                // }
                // else{
                //  this.Form.transactionAddOns.splice(itemIndex, 1, data);
                // }

                // this.fn_closeDialog();

                if(data.addOnsType == 2){
                    this.fn_checkTaxAddOns(data);
                }
                else{
                    let itemIndex = this.Form.transactionAddOns.findIndex(item=>{
                        return item.transactionAddOnsId == data.transactionAddOnsId || item.addOnsId == data.addOnsId;
                    });

                    if(itemIndex==-1){
                        this.Form.transactionAddOns.push(data);
                    }
                    else{
                        this.Form.transactionAddOns.splice(itemIndex, 1, data);
                    }

                    // scroll and focus to element after add new item
                    let elementId = data.mode == 0 ? 'percentageField'+data.addOnsId : 'valueField'+data.addOnsId;
                    this.fn_scrollAndFocusToElement(elementId);

                    // this.fn_closeDialog();
                }
            },

            fn_checkTaxAddOns: function(data){
                let data2 = null
                if(this.Form.gstType == "IntraState" && (fixedGstAddOns.CGST.id == data.addOnsId || fixedGstAddOns.SGST.id == data.addOnsId)){
                    data2 = JSON.parse(JSON.stringify(data));
                    data2.transactionAddOnsId = this.guid();
                    if(fixedGstAddOns.CGST.id == data.addOnsId){
                        data2.addOnsId = fixedGstAddOns.SGST.id;
                        data2.purchaseAccountItemId = fixedAccountIds.sgst.id;
                        this.Form.isRcmApplicable ? data2.saleAccountItemId = fixedAccountIds.sgst.id : '';
                        data2.name = "SGST";
                        
                        // if(this.Form.isRcmApplicable){
                        //  data2.saleAccountItemId = fixedAccountIds.outputSgst.id;
                        //  data2.purchaseAccountItemId = fixedAccountIds.inputSgst.id;
                        // }
                        // else{
                        //  data2.purchaseAccountItemId = fixedAccountIds.inputSgst.id;
                        // }
                        // data2.name = "SGST";
                    }
                    else{
                        data2.addOnsId = fixedGstAddOns.CGST.id;
                        data2.purchaseAccountItemId = fixedAccountIds.cgst.id;
                        this.Form.isRcmApplicable ? data2.saleAccountItemId = fixedAccountIds.cgst.id : '';
                        data2.name = "CGST";

                        // if(this.Form.isRcmApplicable){
                        //  data2.saleAccountItemId = fixedAccountIds.outputCgst.id;
                        //  data2.purchaseAccountItemId = fixedAccountIds.inputCgst.id;
                        // }
                        // else{
                        //  data2.purchaseAccountItemId = fixedAccountIds.inputCgst.id;
                        // }
                        // data2.name = "CGST";
                    }
                }
                else if(this.Form.gstType == "UnionTerritory" && (fixedGstAddOns.CGST.id == data.addOnsId || fixedGstAddOns.UTGST.id == data.addOnsId)){
                    data2 = JSON.parse(JSON.stringify(data));
                    data2.transactionAddOnsId = this.guid();
                    if(fixedGstAddOns.CGST.id == data.addOnsId){
                        data2.addOnsId = fixedGstAddOns.UTGST.id;
                        data2.purchaseAccountItemId = fixedAccountIds.utgst.id;
                        this.Form.isRcmApplicable ? data2.saleAccountItemId = fixedAccountIds.utgst.id : '';
                        data2.name = "UTGST";

                        // if(this.Form.isRcmApplicable){
                        //  data2.saleAccountItemId = fixedAccountIds.outputUtgst.id;
                        //  data2.purchaseAccountItemId = fixedAccountIds.inputUtgst.id;
                        // }
                        // else{
                        //  data2.purchaseAccountItemId = fixedAccountIds.inputUtgst.id;
                        // }
                        // data2.name = "UTGST";
                    }
                    else{
                        data2.addOnsId = fixedGstAddOns.CGST.id;
                        data2.purchaseAccountItemId = fixedAccountIds.cgst.id;
                        this.Form.isRcmApplicable ? data2.saleAccountItemId = fixedAccountIds.cgst.id : '';
                        data2.name = "CGST";

                        // if(this.Form.isRcmApplicable){
                        //  data2.saleAccountItemId = fixedAccountIds.outputCgst.id;
                        //  data2.purchaseAccountItemId = fixedAccountIds.inputCgst.id;
                        // }
                        // else{
                        //  data2.purchaseAccountItemId = fixedAccountIds.inputCgst.id;
                        // }
                        // data2.name = "CGST";
                    }
                }

                let itemIndex = this.Form.transactionAddOns.findIndex(item=>{
                    return item.transactionAddOnsId == data.transactionAddOnsId || item.addOnsId == data.addOnsId;
                });

                if(itemIndex==-1){
                    this.Form.transactionAddOns.push(data);
                }
                else{
                    this.Form.transactionAddOns.splice(itemIndex, 1, data);
                }

                // scroll and focus to element after add new item
                let elementId = data.mode == 0 ? 'percentageField'+data.addOnsId : 'valueField'+data.addOnsId;

                if(data2){
                    let data2Index = this.Form.transactionAddOns.findIndex(item=>{
                        return item.addOnsId == data2.addOnsId;
                    });

                    if(data2Index==-1){
                        this.Form.transactionAddOns.push(data2);
                    }
                    else{
                        this.Form.transactionAddOns.splice(data2Index, 1, data2);
                    }
                }


                this.fn_scrollAndFocusToElement(elementId);

                // this.fn_closeDialog();

            },

            fn_removeAddOns: function(item){
                let addOnIndex = this.Form.transactionAddOns.findIndex(addOnItem=>{
                    return item.transactionAddOnsId == addOnItem.transactionAddOnsId;
                })

                // this.Form.transactionAddOns.splice(index, 1);
                this.Form.transactionAddOns.splice(addOnIndex, 1);

                if(item.addOnsId == fixedGstAddOns.CGST.id){
                    if(this.Form.gstType == "UnionTerritory"){
                        this.fn_removeAddOnsPair(fixedGstAddOns.UTGST.id)
                    }
                    else{
                        this.fn_removeAddOnsPair(fixedGstAddOns.SGST.id)    
                    }
                }
                else if(item.addOnsId == fixedGstAddOns.SGST.id || item.addOnsId == fixedGstAddOns.UTGST.id){
                    this.fn_removeAddOnsPair(fixedGstAddOns.CGST.id)
                }
            },

            fn_removeAddOnsPair: function(addOnId){
                let itemIndex = this.Form.transactionAddOns.findIndex(item=>{
                    return item.addOnsId == addOnId;
                })

                this.Form.transactionAddOns.splice(itemIndex, 1);
            },

            fn_editAddOns: function (item) {
                // this.Form.newSelection = false;
                localStorage.removeItem('newSelection');
                this.Form.addOnsDataModel = JSON.parse(JSON.stringify(item));
                this.fn_openAddOns(item.addOnsType);
            },

            // fn_setProductTaxValue: function (item) {
            //  this.Form.productDataModel.taxValue = item.value;
            //  this.fn_closeDialog();
            // },

            // fn_updateTaxCategory: function (taxCategory, itemIndex) {
            fn_updateTaxCategory: function (taxCategory, itemGroupId) {
                for(let i=0; i<this.Form.items.length; i++){
                    if(this.Form.items[i].groupId == itemGroupId){

                        if(this.Form.selectedTaxMode.value && taxCategory && taxCategory.taxValue >=0){
                            this.Form.items[i].taxValue = taxCategory.taxValue;
                            this.Form.items[i].cessValue = taxCategory.cessValue;
                            this.Form.items[i].taxCategoryId = taxCategory.taxCategoryId;
                            this.Form.items[i].taxCategoryName = taxCategory.taxCategoryName;
                            this.Form.items[i].isCessApplicable = taxCategory.isCessApplicable;  

                            this.Form.items[i].selectedTaxCategory = {"taxCategoryId":taxCategory.taxCategoryId, "taxCategoryName":taxCategory.taxCategoryName};
                        }

                        // calculating total
                        this.$refs['ref_dialogForm'].fn_computeValues(this.Form.items[i]);
                        break;
                    }
                }

                // if(this.Form.selectedTaxMode.value && taxCategory && taxCategory.taxValue >=0){
                //     this.Form.items[itemIndex].taxValue = taxCategory.taxValue;
                //     this.Form.items[itemIndex].cessValue = taxCategory.cessValue;
                //     this.Form.items[itemIndex].taxCategoryId = taxCategory.taxCategoryId;
                //     this.Form.items[itemIndex].taxCategoryName = taxCategory.taxCategoryName;
                //     this.Form.items[itemIndex].isCessApplicable = taxCategory.isCessApplicable;  

                //     this.Form.items[itemIndex].selectedTaxCategory = {"taxCategoryId":taxCategory.taxCategoryId, "taxCategoryName":taxCategory.taxCategoryName};
                // }
                
                // // calculating total
                // this.$refs['ref_dialogForm'].fn_computeValues(this.Form.items[itemIndex]);
            },

            fn_saveItem: function (data) {
                // let itemIndex = null;

                let itemData = JSON.parse(JSON.stringify(data));

                !itemData.mrp ? itemData.mrp = 0 : '';
                !itemData.rate ? itemData.rate = 0 : '';

                itemData.selectedBatchType = this.Form.selectedBatchType;
                // filtering batches as per selected batch type (batch/series)
                if(itemData.batches && itemData.batches.length){
                    itemData.batches = itemData.batches.filter(batch=>{
                        if(itemData.selectedBatchType == 1){
                            return batch.batchNumber || batch.batchMRP || batch.purchaseRate || batch.batchSize || batch.mfgDate || batch.expDate || batch.opening || batch.field4
                        }
                        else{
                            return  batch.field1 || batch.field2 || batch.field3
                        }
                    });
                }


                if(!itemData.groupId){

                    //baseTransactionListId and baseTransactionNumber using only for grouping items not saving anywhere
                    itemData.baseTransactionListId = this.settings.transactionTypeId;
                    // itemData.baseTransactionNumber = this.Form.transactionData.transactionNumber;
                    itemData.baseTransactionNumber = this.Form.transactionData.mergeTransactionNumber;

                    itemData.groupId = this.guid();

                    itemData.orderNumber = this.Form.items.length + 1;

                    this.Form.items.push(itemData);

                    let lastItemIndex = this.Form.items.length - 1;

                    // this.fn_updateTaxCategory(this.Form.items[lastItemIndex].selectedTaxCategory, lastItemIndex);
                    this.fn_updateTaxCategory(this.Form.items[lastItemIndex].selectedTaxCategory, itemData.groupId);

                    // clearing up item input row
                    this.Form.productDataModel.rate = null;
                    this.Form.productDataModel.productVariants = null;
                    this.$refs['ref_dialogForm'].selectedItem = null;

                    // this.fn_removeAllTransactionAddons();

                    // focusing selectitem field after item added
                    if(document.getElementById('fieldproducts')){
                        setTimeout(()=>{
                            document.getElementById('fieldproducts').focus();

                            // this.fn_scrollToElement('item_'+itemData.groupId);
                            document.getElementsByClassName('item_'+itemData.groupId)[0].scrollIntoView(false);

                        },50);
                    }
                }
                // else{
                //     itemIndex = this.Form.items.findIndex(item=>{
                //         return item.groupId == itemData.groupId;
                //     })
                //     this.Form.items.splice(itemIndex, 1, itemData);
                // }

                console.log(JSON.stringify(this.Form.items));

                // !doneAndNew ? this.fn_closeDialog() : '';
                // this.fn_closeDialog();

                this.Form.netInvoiceValue = null;
                // this.Form.newSelection = false;
                localStorage.removeItem('newSelection');
                this.fn_removeTaxAddons();
            },

            fn_removeTaxAddons: function(){
                this.Form.transactionAddOns = this.Form.transactionAddOns.filter(item=>{
                    return item.addOnsType != 2;
                });

                // if(doneAndNew){
                //     alert("Item added");
                //     this.fn_addNewItem(true, doneAndNew);
                // }
            },

            // fn_editItem: function (item, category, groupIndex) {

            //     // this.Form.newSelection = false;
            //     localStorage.removeItem('newSelection');
            //     let itemData = JSON.parse(JSON.stringify(item));
            //     this.Form.productDataModel.groupId = itemData.groupId;
            //     this.Form.productDataModel.productId = itemData.productId;
            //     this.Form.productDataModel.productName = itemData.productName;
            //     this.Form.productDataModel.mrp = itemData.mrp;
            //     this.Form.productDataModel.rate = itemData.rate;
            //     this.Form.productDataModel.productVariants = itemData.productVariants;
            //     this.Form.productDataModel.taxValue = itemData.taxValue;
            //     this.Form.productDataModel.taxAmount = itemData.taxAmount;
            //     this.Form.productDataModel.description = itemData.description;

            //     this.Form.productDataModel.cessValue = itemData.cessValue >= 0 ? itemData.cessValue : null;
            //     this.Form.productDataModel.cessAmount = itemData.cessAmount ? itemData.cessAmount : 0;

            //     this.Form.productDataModel.taxCategoryId = itemData.taxCategoryId;
            //     this.Form.productDataModel.taxCategoryName = itemData.taxCategoryName;

            //     this.Form.productDataModel.hsnCode = itemData.hsnCode;
            //     this.Form.productDataModel.orderNumber = itemData.orderNumber;
            //     this.Form.productDataModel.grossAmount = itemData.grossAmount;
            //     this.Form.productDataModel.grossAmountAsPerMRP = itemData.grossAmountAsPerMRP;
            //     this.Form.productDataModel.netAmount = itemData.netAmount;

            //     this.Form.productDataModel.defaultTaxValue = itemData.defaultTaxValue;
            //     this.Form.productDataModel.defaultCessValue = itemData.defaultCessValue;
            //     this.Form.productDataModel.defaultHsnCode = itemData.defaultHsnCode;
            //     this.Form.productDataModel.defaultTaxCategoryId = itemData.defaultTaxCategoryId;
            //     this.Form.productDataModel.defaultTaxCategoryName = itemData.defaultTaxCategoryName;
            //     this.Form.productDataModel.isCessApplicable = itemData.isCessApplicable;

            //     //baseTransactionListId and baseTransactionNumber using only for grouping items not saving anywhere
            //     this.Form.productDataModel.baseTransactionListId = itemData.baseTransactionListId;
            //     this.Form.productDataModel.baseTransactionNumber = itemData.baseTransactionNumber;

            //     // capturing params only for removing item directly from product dialog
            //     this.Form.removeItemParams = {};
            //     this.Form.removeItemParams.groupId = itemData.groupId;
            //     this.Form.removeItemParams.category = category;
            //     this.Form.removeItemParams.groupIndex = groupIndex;
            //     this.Form.removeItemParams.baseTransactionNumber = itemData.baseTransactionNumber;

            //     this.$refs['ref_dialogProduct'].fn_getProductData(true, itemData.productId, itemData.baseTransactionListId);

            //     this.fn_refreshAddons(itemData);


            //     if(!this.Form.isNew && this.Form.transactionData.transactionListId == transactionTypes.purchaseReturn.id && itemData.baseTransactionListId == transactionTypes.purchaseInvoice.id){
            //         this.fn_getBillQuantity(this.Form.productDataModel);
            //     }
            // },

            fn_getBillQuantity: function (itemData, itemIndex) {

                if(this.Form.transactionData.transactionListId == transactionTypes.purchaseReturn.id && itemData.baseTransactionListId == transactionTypes.purchaseInvoice.id){
                    let filterObject = {};
                    filterObject.formTransactionListId = [transactionTypes.purchaseReturn.id];

                    filterObject.transactionListId = [transactionTypes.purchaseInvoice.id];


                    let refTId = null;
                    if(itemData.productVariants[0].refTId){
                        refTId = itemData.productVariants[0].refTId;
                    }
                    else{
                        return
                    }

                    let self = this;
                    this.getFilterData('BillQuantity', refTId, filterObject, null,
                        {
                            onSuccess: function(response){

                                console.log(response);

                                let variants = JSON.parse(response);

                                for(let i=0; i<variants.length; i++){

                                    for(let j=0; j<itemData.productVariants.length; j++){

                                        if(itemData.productVariants[j].unitId == variants[i].unitId){

                                            // itemData.productVariants[j].billQty = itemData.productVariants[j].qty + variants[i].billQty;
                                            if(self.Form.isNew){
                                                // itemData.productVariants[j].billQty = variants[i].billQty;
                                                self.$set(itemData.productVariants[j], 'billQty', variants[i].billQty);
                                            }
                                            else{
                                                // !itemData.billQtyRef ? itemData.billQtyRef = itemData.productVariants[j].qty + variants[i].billQty : '';
                                                if(!itemData.billQtyRef){
                                                    let billQty = itemData.productVariants[j].qty + variants[i].billQty;
                                                    itemData.billQtyRef = Number(billQty.toFixed(3));
                                                }
                                                self.$set(itemData.productVariants[j], 'billQty', itemData.billQtyRef);
                                            }

                                            setTimeout(() => {
                                                document.getElementById('fieldQty'+itemIndex).focus();
                                            },50);

                                            self.$forceUpdate();

                                        }

                                    
                                    }

                                }

                            }, 
                            onError: function(error){
                            console.log(error);
                            }
                        }
                    ); 
                }
                else{
                    return;
                }
                 
            },

            fn_getBatchBillQuantity: function (itemData, callback) {

                // if(this.Form.transactionData.transactionListId == transactionTypes.salesReturn.id && itemData.baseTransactionListId == transactionTypes.salesInvoice.id){

                let filterObject = {};
                filterObject.transactionId = itemData.productVariants[0].refTId;

                let self = this;
                this.getFilterData('PendingBatchQuantity', itemData.productId, filterObject, null,
                    {
                        onSuccess: function(response){

                            console.log(response);

                            let pendingBatches = JSON.parse(response);

                            if(!pendingBatches || !pendingBatches.length){
                                pendingBatches = []
                            }

                            for(let i=0; i<itemData.batches.length; i++){
                                if(!pendingBatches.length){
                                    itemData.batches[i].billQty = itemData.batches[i].qty;
                                }
                                for(let j=0; j<pendingBatches.length; j++){
                                    if(itemData.batches[i].batchId == pendingBatches[j].batchId){
                                        itemData.batches[i].billQty = self.roundUpto2Decimals(itemData.batches[i].qty + pendingBatches[j].batchBillQty)
                                    }
                                }
                            }

                            if(self.Form.pendingInvoices && self.Form.pendingInvoices.length){
                                for(let i=0; i<self.Form.pendingInvoices[0].transactionProducts.length; i++){
                                    if(itemData.transactionProductId == self.Form.pendingInvoices[0].transactionProducts[i].transactionProductId){
                                        if(self.Form.pendingInvoices[0].transactionProducts[i].batches && self.Form.pendingInvoices[0].transactionProducts[i].batches.length){
                                            for(let j=0; j<self.Form.pendingInvoices[0].transactionProducts[i].batches.length; j++){
                                                for(let k=0; k<itemData.batches.length; k++){
                                                    if(self.Form.pendingInvoices[0].transactionProducts[i].batches[j].batchId == itemData.batches[k].batchId){
                                                        self.Form.pendingInvoices[0].transactionProducts[i].batches[j].billQty = itemData.batches[k].billQty;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            callback();
                            
                        }, 
                        onError: function(error){
                            console.log(error);
                        }
                    });
                // }
                // else{
                // 	return;
                // }

                },

            fn_refreshAddons: function (itemData) {

                let defaultAddons = this.Form.productDataModel.addOnsItems;

                for(let i=0; i<defaultAddons.length; i++){

                    let shared = false;

                    for(let j=0; j<itemData.addOnsItems.length; j++){
                        if (itemData.addOnsItems[j].addOnsId == defaultAddons[i].addOnsId) {
                            defaultAddons[i].percentage = itemData.addOnsItems[j].percentage;
                            defaultAddons[i].value = itemData.addOnsItems[j].value;

                            if(this.Form.transactionData.transactionId){
                                defaultAddons[i].transactionProductsAddOnsId = itemData.addOnsItems[j].transactionProductsAddOnsId;
                            }

                            shared = true;
                            break;
                       }
                    }

                    if(!shared){
                        defaultAddons[i].percentage = null;
                        defaultAddons[i].value = null;
                    }

                }

                this.fn_addItem();
            },

            // fn_removeItem: function (item_groupId, item_category, item_groupIndex, item_baseTransactionNumber) {
            fn_removeItem: function (groupId, category, groupIndex, baseTransactionNumber) {
                document.getElementsByClassName("item_"+groupId)[0].classList.add("removable_item");

                setTimeout(() => {
                    let itemIndex = this.Form.items.findIndex(item=>{
                        return item.groupId == groupId;
                    });

                    this.Form.items.splice(itemIndex, 1);

                    !this.Form.items.length ? this.Form.isRounded = false : '';

                    if(category=='order' || category=='challan' || category=='invoice'){

                        if(category=='order'){
                            this.fn_removeFromPendingItemslist(this.Form.pendingOrders, groupId, baseTransactionNumber);
                        }
                        else if(category=='challan'){
                            this.fn_removeFromPendingItemslist(this.Form.pendingChallans, groupId, baseTransactionNumber);  
                        }
                        else if(category=='invoice'){
                            this.fn_removeFromPendingItemslist(this.Form.pendingInvoices, groupId, baseTransactionNumber);  
                        }
                        
                        if(!this.filteredItems[category][groupIndex].items.length){
                            this.fn_removeGroup(category, baseTransactionNumber);
                        }
                    }
                }, 300);
            },

            fn_removeFromPendingItemslist: function (pendingItems, groupId, baseTransactionNumber) {
                let transactionIndex = pendingItems.findIndex(item=>{
                    return item.baseTransactionNumber == baseTransactionNumber;
                });

                let pItemIndex = pendingItems[transactionIndex].transactionProducts.findIndex(item=>{
                    return item.groupId == groupId;
                });

                pendingItems[transactionIndex].transactionProducts.splice(pItemIndex, 1);
            },

            fn_removeGroup: function (category, baseTransactionNumber) {
                let baseTransactionListId = null;
                let itemIndex = null;
                // let removedTransactionId = null;

                if(category=='order'){

                    baseTransactionListId = transactionTypes.purchaseOrder.id;

                    itemIndex = this.Form.pendingOrders.findIndex(item=>{
                        return item.baseTransactionNumber == baseTransactionNumber;
                    });

                    // removedTransactionId = this.Form.pendingOrders[itemIndex].transactionId;

                    this.Form.pendingOrders.splice(itemIndex, 1);

                }
                else if(category == 'challan'){

                    baseTransactionListId = transactionTypes.purchaseChallan.id;

                    itemIndex = this.Form.pendingChallans.findIndex(item=>{
                        return item.baseTransactionNumber == baseTransactionNumber;
                    });

                    // removedTransactionId = this.Form.pendingChallans[itemIndex].transactionId;

                    this.Form.pendingChallans.splice(itemIndex, 1);

                }
                else if(category == 'invoice'){

                    baseTransactionListId = transactionTypes.purchaseInvoice.id;

                    itemIndex = this.Form.pendingInvoices.findIndex(item=>{
                        return item.baseTransactionNumber == baseTransactionNumber;
                    });

                    // removedTransactionId = this.Form.pendingInvoices[itemIndex].transactionId;

                    this.Form.pendingInvoices.splice(itemIndex, 1);

                }

                this.Form.items = this.Form.items.filter(item => {
                    return !(item.baseTransactionListId == baseTransactionListId && item.baseTransactionNumber == baseTransactionNumber);
                });

                // !this.Form.items.length ? this.fn_changeIsRounded(false, false) : '';
                !this.Form.items.length ? this.Form.isRounded = false : '';
            },

            // fn_saveTdsDetails: function (data) {
            //  data.transactionTdsId = this.guid();
            //  this.Form.tdsDetails = JSON.parse(JSON.stringify(data));
            //  this.fn_closeDialog();
            // },

            fn_removeTds: function(){
                this.Form.tdsDataModel = {
                    "transactionTdsId":null, "transactionId":null, "tdsAccountId":null, "tdsAccountName":null, "appliedOnAmount":null, "percentage":null, "amount":null
                }
                // this.Form.tdsDetails = {"amount":null};
                // this.fn_closeDialog();
            },

            // calling from transaction mixin when making direct transaction for party
            fn_updateParty: function(partyId, partyName){
                this.fn_updateSupplier(partyId, partyName);
            },

            fn_updateSupplier: function (selectedValueId, selectedValueName) {
                this.Form.selectedState = null;
                this.Form.selectedParty = {"itemId":selectedValueId, "itemName":selectedValueName};
                this.fn_getPartyDetails(selectedValueId, true);
            },

            // fn_updateSupplier: function (selectedValueId, selectedValueName, isRemoved) {
            //  if(!isRemoved){
            //      this.Form.partyId = selectedValueId;
         //         this.Form.partyName = selectedValueName;
         //         this.fn_getPartyDetails(selectedValueId, true);

         //         if(this.Form.transactionTypeData){
         //             this.fn_validateParty(this.Form.transactionTypeData.transactionParties);
         //         }

         //         this.$forceUpdate();
         //         this.errors.remove('supplier');
            //  }
            //  else{
            //      this.Form.partyId = null;
         //         this.Form.partyName = null;
            //  }
            // },

            fn_getPartyDetails: function (partyId, bindPlaceofSupply, isEdit) {
                var self = this;
                this.getData('Ledger', partyId, 
                    {
                        onSuccess: function(response){

                            console.log(response);

                            // let partyDetails = JSON.parse(response);
                            self.Form.partyDetails = JSON.parse(response);

                            if(self.Form.transactionTypeData){
                                self.fn_validateParty(self.Form.transactionTypeData.transactionParties, isEdit);
                            }

                            // self.Form.partyGstType = partyDetails.gstType;

                            if(bindPlaceofSupply){
                                let placeOfSupplyId = self.Form.partyDetails.placeOfSupplyId ? self.Form.partyDetails.placeOfSupplyId : self.$root.CurrentBusiness.StateID;

                                let placeOfSupplyName = self.Form.partyDetails.placeOfSupply ? self.Form.partyDetails.placeOfSupply : self.$root.CurrentBusiness.State;

                                if(self.Form.partyDetails.countryId && self.Form.partyDetails.country){
                                    placeOfSupplyId = null;
                                    placeOfSupplyName = null;
                                }

                                // self.Form.placeOfSupplyCountryId = self.Form.partyDetails.countryId;
                                // self.Form.placeOfSupplyCountryName = self.Form.partyDetails.country;

                                if(isEdit){
                                    placeOfSupplyId = self.Form.selectedState.itemId;
                                    placeOfSupplyName = self.Form.selectedState.itemName;
                                }
                                else{
                                    self.Form.placeOfSupplyCountryId = self.Form.partyDetails.countryId;
                                    self.Form.placeOfSupplyCountryName = self.Form.partyDetails.country;    
                                }

                                self.fn_updateState(placeOfSupplyId, placeOfSupplyName, false);

                            }


                            self.Form.partyDetails.lastConfig ? self.Form.partyLastConfig = JSON.parse(self.Form.partyDetails.lastConfig) : '';

                            if(self.Form.partyLastConfig && self.Form.partyLastConfig.purchase){
                                self.Form.selectedTaxMode.value = self.Form.partyLastConfig.purchase.taxMode;

                                if(self.Form.items.length){
                                    self.fn_calculateTax();
                                }
                            }

                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            // fn_getDefaultGstAddOns: function () {
            //     let self = this;
            //     this.Form.defaultTaxAddOns = [];

            //     for(let key in fixedGstAddOns){
            //         (function (itemId) {
            //             self.getData('AddOns', itemId, 
            //                 {
            //                     onSuccess: function(response){
            //                       console.log(response);

            //                       if(fixedGstAddOns.length-1 == key){
            //                         self.getDataCounter += 1;
            //                       }

            //                       let itemIndex = self.Form.defaultTaxAddOns.findIndex(item=>{
            //                         return item.addOnsId == itemId;
            //                       });

            //                       if(itemIndex==-1){
            //                         self.unwatch = true;
            //                         self.Form.defaultTaxAddOns.push(JSON.parse(response));
            //                       }

            //                     }, 
            //                     onError: function(error){
            //                       console.log(error);
            //                     }
            //                 }
            //             );
            //         }(fixedGstAddOns[key].id));
            //     }
            // },

            // fn_getDefaultCessAddOns: function () {
            //     let self = this;

            //     self.getData('AddOns', fixedCessAddOns.CESS.id, {

            //         onSuccess: function(response){
            //           console.log(response);
            //           self.getDataCounter += 1;
            //           self.unwatch = true;
            //           self.Form.cessAddOns = JSON.parse(response);

            //         }, 
            //         onError: function(error){
            //           console.log(error);
            //         }
            //     });
            // },

            fn_bindGstAddOns: function () {

                // let self = this;
                this.Form.gstAddOns = [];

                if(this.Form.gstType === 'IntraState'){
                    this.Form.gstAddOns = this.Form.defaultTaxAddOns.filter(taxAddOnsItem=>{
                        return fixedGstAddOns.CGST.id === taxAddOnsItem.addOnsId || fixedGstAddOns.SGST.id === taxAddOnsItem.addOnsId;
                    })
                }
                else if(this.Form.gstType === 'InterState'){
                    this.Form.gstAddOns = this.Form.defaultTaxAddOns.filter(taxAddOnsItem=>{
                        return fixedGstAddOns.IGST.id === taxAddOnsItem.addOnsId;
                    })
                }
                else if(this.Form.gstType === 'UnionTerritory'){
                    this.Form.gstAddOns = this.Form.defaultTaxAddOns.filter(taxAddOnsItem=>{
                        return fixedGstAddOns.CGST.id === taxAddOnsItem.addOnsId || fixedGstAddOns.UTGST.id === taxAddOnsItem.addOnsId;
                    })
                }

                for(let i=0;i<this.Form.gstAddOns.length; i++){
                    this.Form.gstAddOns[i].percentage = 0;
                    this.Form.gstAddOns[i].value = 0;
                }

                // this.Form.defaultTaxAddOns.forEach(function (addOnsItem) {

                //     let taxAddOnsItem = JSON.parse(JSON.stringify(addOnsItem));
                                
                //     taxAddOnsItem.percentage = 0;
                //     taxAddOnsItem.value = 0;

                //     if(self.Form.gstType == 'IntraState' && 
                //         (fixedGstAddOns.CGST.id == taxAddOnsItem.addOnsId || fixedGstAddOns.SGST.id == taxAddOnsItem.addOnsId))
                //     {
                //         self.Form.gstAddOns.push(taxAddOnsItem);
                //     }
                //     else if(self.Form.gstType == 'InterState' && fixedGstAddOns.IGST.id == taxAddOnsItem.addOnsId){
                //         self.Form.gstAddOns.push(taxAddOnsItem);
                //     }
                //     else if(self.Form.gstType == 'UnionTerritory' &&
                //         (fixedGstAddOns.CGST.id == taxAddOnsItem.addOnsId || fixedGstAddOns.UTGST.id == taxAddOnsItem.addOnsId)){
                //         self.Form.gstAddOns.push(taxAddOnsItem);
                //     }
                // });

                if(this.Form.items.length){
                    this.fn_calculateTax();
                }
            },

            // fn_checkTransactionType: function (item) {
            //  if(this.Form.transactionAddOns.length || this.Form.items.length || this.Form.customFields.length){
            //      let msg = "In case of transaction type change, <b>Discounts/Taxes/Other Charges</b> and other details may change or remove."
            //      this.fn_openConfirmationDialog(msg);
            //  }
            //  else{
            //      this.fn_openTransactionTypeList();
            //  }
            // },

            fn_setTransactionType: function (item, callback, transactionTypeChange) {

                // let transactionTypeSelected = this.Form.transactionTypeFormId ?  true : false;
                // this.loading = true;
                if(this.Form.transactionTypeFormId != item.transactionTypeFormId){
                    this.loading = true;
                    this.fn_getTransactionTypeData(item.transactionTypeFormId, null, true, transactionTypeChange);
                }

                this.Form.transactionTypeFormId = item.transactionTypeFormId;
                this.Form.transactionTypeFormName = item.name;

                localStorage.setItem(this.settings.transactionTypeId, item.transactionTypeFormId);

                // this.$forceUpdate();
                // this.fn_closeDialog();

                // if(!this.$route.query.loadItems){
                //     if(!transactionTypeSelected && this.Form.draftItems && this.Form.draftItems.length){
                //         this.loading = false;
                //         this.fn_openDialogDraftItems();
                //     }
                //     // else{
                //     //  //transacion mixin function
                //     //  !this.Form.partyName ? this.fn_openParties_onFormLoad(openParty, this.fn_openSuppliers) : '';
                //     // }
                // }
                
                // this.fn_openParties_onFormLoad(openParty, this.fn_openSuppliers);

                // setTimeout(function(){

                //  (openParty && !self.$route.query.dt) || !self.Form.partyId ? self.fn_openSuppliers() : '';
                // },500)
            },

            //updateNS = update number series
            fn_getTransactionTypeData: function(itemId, callback, updateNS, callingFrom){
                // this.showLoading('Please wait...');
                // this.loading = true;
                var self = this;
                // let transactionDate = this.Form.transactionData.transactionDate ? this.Form.transactionData.transactionDate : new Date();
                let transactionDate = this.Form.transactionData.transactionDate ? this.Form.transactionData.transactionDate : this.$root.fn_getLocalDateFormat();
                let filterObject = {};
                filterObject.fiscalYear = JSON.stringify(this.fn_getFiscalYear(transactionDate));
                this.getFilterData('TransactionTypeForm', itemId, filterObject, null,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.Form.transactionTypeData = JSON.parse(response);

                            // self.fn_getSuppliers(callingFrom);
                            if(((!self.$route.query.loadItems && !self.$route.params.tagId) || self.settings.transactionTypeId == 20005) && callingFrom != 'transactionTypeChange'){
                                self.fn_addOns('');
                            }

                            self.Form.transactionData.paymentMode = self.Form.transactionTypeData.paymentMode;

                             if(self.Form.transactionData.paymentMode == 1){
                                self.fn_getPaymentGroups();
                            }
                            else{
                                self.Form.splitPayment = false;
                            }

                            if(callingFrom == 'draft'){
                                //refereshing transactiontype data if form is loaded from draft
                                self.fn_refreshTransactionTypeData(self.Form.transactionTypeData);
                            }
                            else{
                                self.fn_bindTransactionTypeData(callback, updateNS);
                            }
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            // fn_getSuppliers: function(callingFrom){
            //     let accountGstTypes = null;
            //     if(this.Form.transactionTypeData.importExport && this.Form.transactionTypeData.importExportTransactionAccountId){
            //         accountGstTypes = [accountGstType.overseas.id, accountGstType.sez.id]
            //     }
            //     else{
            //         accountGstTypes = [accountGstType.registered.id, accountGstType.composition.id, accountGstType.unregistered.id, accountGstType.consumer.id, accountGstType.deemed.id];
            //     }

            //     let accountGroups = [];
            //     if(this.Form.transactionTypeData && !this.Form.transactionTypeData.isAllPartySelected){
            //         this.Form.transactionTypeData.transactionParties.forEach(function (item) {
            //             accountGroups.push(item.accountGroupId);
            //         });
            //     }
                
            //     this.fn_accountLedgers('', ledgerGroupTypeId.supplier, null, null, null, accountGstTypes, accountGroups, callingFrom);
            // },

            //updateNS = update number series

            fn_bindTransactionTypeData: function (callback, updateNS) {
                updateNS && this.Form.isNew ? this.fn_bindTransactionNumber() : '';

                // this.fn_bindTransactionTypeAddOns(true, callback);

                this.fn_bindTransactionTypeAddOns(true);

                // if(this.Form.partyId && this.Form.isNew){
                if(this.Form.selectedParty && this.Form.selectedParty.itemId && this.Form.isNew){
                    this.fn_validateParty(this.Form.transactionTypeData.transactionParties);
                }

                this.Form.dynamicMenuItems = [];

                let self = this;
                if(this.Form.transactionTypeData && this.Form.transactionTypeData.transactionSections.length){
                    this.Form.transactionTypeData.transactionSections.forEach(function (item) {
                        let obj = {};
                        obj.MenuID = null;
                        obj.Icon = null;
                        obj.Text = item.sectionName;
                        obj.Action = 'section_'+item.sectionId;
                        obj.sectionId = item.sectionId;
                        obj.hideOption = false;
                        self.Form.dynamicMenuItems.push(obj);
                    });
                }

                //inserting eway bill details to transaction type fields manually
                //inserting eway bill details to transaction type fields manually
                this.fn_bindEwayBillSection();

                if(updateNS && this.Form.customFields.length){
                    this.Form.dynamicMenuItems.length ? this.fn_bindCustomFieldValues() : this.Form.customFields = [];
                }
                /*if(this.settings.transactionTypeId != transactionTypes.purchaseOrder.id){
                    this.Form.transactionTypeData.transactionSections.push(this.ewayBillDetailSection);

                    !this.Form.transactionTypeData.transactionTypeFields.length ? this.Form.transactionTypeData.transactionTypeFields = [] : '';

                    for(let i=0; i<this.ewayBillDetailFields.length; i++){
                        this.Form.transactionTypeData.transactionTypeFields.push(this.ewayBillDetailFields[i]);
                    }
                }
                else{
                    this.Form.hideEwayOption = true;
                }*/

                // callback != undefined ? callback(1) : '';

                if(callback != undefined){
                    callback(1);
                }
                else{
                    setTimeout(function(){
                        self.$root.fn_copyFormObject(self.Form, self.formId);
                    },500);
                }

                // this.hideLoading();
                this.loading = false;
            },

            fn_bindEwayBillSection: function(){
                if(this.settings.transactionTypeId != transactionTypes.purchaseOrder.id){
                    this.Form.transactionTypeData.transactionSections.push(this.ewayBillDetailSection);

                    !this.Form.transactionTypeData.transactionTypeFields.length ? this.Form.transactionTypeData.transactionTypeFields = [] : '';

                    for(let i=0; i<this.ewayBillDetailFields.length; i++){
                        this.Form.transactionTypeData.transactionTypeFields.push(this.ewayBillDetailFields[i]);
                    }
                }
                else{
                    this.Form.hideEwayOption = true;
                }
            },

            fn_bindCustomFieldValues: function(){
                for(let i=0; i<this.Form.customFields.length; i++){

                    // updating section key values on transaction type form change
                    let customFieldItem = this.Form.customFields[i];
                    customFieldItem.section.transactionTypeFormId = this.Form.transactionTypeData.transactionTypeFormId;
                    for(let j=0; j<this.Form.transactionTypeData.transactionSections.length; j++){
                        if(customFieldItem.section.sectionName == this.Form.transactionTypeData.transactionSections[j].sectionName){
                            customFieldItem.section.sectionId = this.Form.transactionTypeData.transactionSections[j].sectionId;
                        }
                    }

                    // updating section fields key values on transaction type form change
                    for(let k=0; k<customFieldItem.items.length; k++){
                        let fieldItem = customFieldItem.items[k];
                        fieldItem.transactionTypeFormId = this.Form.transactionTypeData.transactionTypeFormId;
                        for(let l=0; l<this.Form.transactionTypeData.transactionTypeFields.length; l++){

                            if(fieldItem.fieldId == this.Form.transactionTypeData.transactionTypeFields[l].fieldId){
                                fieldItem.sectionId = customFieldItem.section.sectionId;
                                fieldItem.transactionTypeFieldId = this.Form.transactionTypeData.transactionTypeFields[l].transactionTypeFieldId;
                            }
                            
                        }   
                    }

                    // hiding dynamic menu option if custom field exist on transaction type form change
                    for(let m=0; m<this.Form.dynamicMenuItems.length; m++){
                        if(customFieldItem.section.sectionId == this.Form.dynamicMenuItems[m].sectionId){
                            this.Form.dynamicMenuItems[m].hideOption = true;
                        }
                    }
                }
                
            },

            // fn_bindTransactionNumber: function () {

            //  this.transactionNumberSnackbar = false;

            //  let numberSeriesData = this.Form.transactionTypeData.numberSeries;

            //  this.Form.transactionData.prefix = numberSeriesData.prefix;
            //  this.Form.transactionData.suffix = numberSeriesData.suffix;

            //  //For reference use only as incrementing transaction number by numberSeries id in transaction number
            //  this.Form.transactionData.numberSeriesId = numberSeriesData.numberSeriesId;

            //  this.Form.transactionData.transactionNumberId = numberSeriesData.transactionNumberId

            //  let self = this;

            //  // this.getData('TransactionNumber', numberSeriesData.numberSeriesId, 
            //  this.getData('TransactionNumber', numberSeriesData.transactionNumberId, 
            //      {
            //          onSuccess: function(response){
            //              console.log(response);
            //              let data = JSON.parse(response);
            //              self.Form.transactionData.transactionNumber = Number(data.transactionNumber);
            //              self.Form.transactionData.mergeTransactionNumber = data.mergeTransactionNumber;
            //          }, 
            //          onError: function(error){
            //              console.log(error);
            //          }
            //      }
         //        );
                
            // },

            fn_bindTransactionNumber: function () {

                if(!this.Form.transactionTypeData){
                    return;
                }

                this.transactionNumberSnackbar = false;

                let numberSeriesData = this.Form.transactionTypeData.numberSeries;

                // this.Form.transactionData.prefix = numberSeriesData.prefix;
                this.Form.transactionData.prefix = numberSeriesData.otherPrefix !== null ? numberSeriesData.otherPrefix : numberSeriesData.prefix;
                this.Form.transactionData.suffix = numberSeriesData.suffix;

                //For reference use only as incrementing transaction number by numberSeries id in transaction number
                this.Form.transactionData.numberSeriesId = numberSeriesData.numberSeriesId;

                // this.Form.transactionData.transactionNumberId = numberSeriesData.transactionNumberId;

                let filterObject = {};
                filterObject.numberSeriesId = numberSeriesData.numberSeriesId;
                // filterObject.transactionNumberId = numberSeriesData.transactionNumberId;
                filterObject.fiscalYear = JSON.stringify(this.fn_getFiscalYear(this.Form.transactionData.transactionDate));

                filterObject.typeId = this.settings.transactionTypeId;

                let self = this;

                this.getFilterData('TransactionNumber', null, filterObject, null,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            let data = JSON.parse(response);
                            self.Form.transactionData.transactionNumber = Number(data.transactionNumber);
                            self.Form.transactionData.mergeTransactionNumber = data.mergeTransactionNumber;
                            self.Form.transactionData.transactionNumberId = data.transactionNumberId;
                            if(self.$root.appVersion >= 136){
                                self.Form.transactionData.prefix = data.otherPrefix !== null ? data.otherPrefix : data.prefix;
                            }
                        }, 
                        onError: function(error){
                            console.log(error);
                        }
                    }
                );
                
            },

            // fn_bindTransactionTypeAddOns: function (bindAll, callback) {
            fn_bindTransactionTypeAddOns: function (bindAll) {
                let self = this;

                // if(bindAll){
                //     this.Form.productDataModel.addOnsItems = [];
                //     // !dont remove as will be useful if transaction form open for user
                //     // this.Form.transactionAddOns = [];
                // }
                this.Form.productDataModel.addOnsItems = [];

                this.Form.transactionTypeData.transactionTypeAddOns.forEach(function (item) {

                    if(item.type==0){

                        if(bindAll){
                            self.Form.items.forEach(function (itemData) {

                                let addOnsItemIndex = itemData.addOnsItems.findIndex(addOnItem=>{
                                    return addOnItem.addOnsId === item.addOnsId;
                                })

                                if(addOnsItemIndex!==-1){
                                    itemData.addOnsItems[addOnsItemIndex] = JSON.parse(JSON.stringify(item));
                                }
                                else{
                                   itemData.addOnsItems.push(JSON.parse(JSON.stringify(item))); 
                                }
                                // itemData.addOnsItems.push(JSON.parse(JSON.stringify(item)));

                                (function (itemData) {
                                    self.$refs['ref_dialogForm'].fn_computeValues(itemData);
                                }(itemData));

                            });
                        }

                        self.Form.hasItemAddOns = true;

                        // alert('addon out')

                        // if(!self.Form.isNew && bindAll){

                        //     for(let i=0; i<self.Form.items.length; i++){

                        //         alert('addon in')

                        //         let itemIndex = self.Form.items[i].addOnsItems.findIndex(addOnItem => {
                        //             return addOnItem.addOnsId == item.addOnsId || (addOnItem.addOnsId == item.addOnsId && !addOnItem.value);
                        //         })
                                
                        //         if(itemIndex==-1){
                        //             self.Form.items[i].transactionProductAddOns.push(JSON.stringify(item));
                        //         }
                        //     }

                        // }

                        self.Form.productDataModel.addOnsItems.push(JSON.parse(JSON.stringify(item)));
                    }
                    else if(item.type==1 && bindAll){
                        let addOnsData = JSON.parse(JSON.stringify(item));
                        addOnsData.transactionAddOnsId = self.guid();
                        addOnsData.isDefault = true;
                        self.Form.transactionAddOns.push(addOnsData);
                    }
                });

                if(!bindAll){
                    this.fn_saveItem(this.Form.productDataModel);
                }
                else{
                    // focusing first field on form load
                    if(document.getElementById('fieldbillnumber')){
                        setTimeout(()=>{
                            document.getElementById('fieldbillnumber').focus();
                        },50);
                    }
                }

                // callback != undefined ? callback(1) : '';
            },

            fn_validateParty: function (partyGroups, isEdit) {
                if(!partyGroups && !this.Form.transactionTypeData.importExportTransactionAccountId){
                    return
                }

                let filterObject = {};
                filterObject.accountGroups = null;
                filterObject.accountGstTypes = null;

                if(partyGroups && partyGroups.length){
                    filterObject.accountGroups = [];
                    partyGroups.forEach(function (item) {
                        filterObject.accountGroups.push(item.accountGroupId);
                    });
                }

                if(this.Form.transactionTypeData.importExport && this.Form.transactionTypeData.importExportTransactionAccountId){
                    filterObject.accountGstTypes = [accountGstType.overseas.id, accountGstType.sez.id];
                }
                else{
                    filterObject.accountGstTypes = [accountGstType.registered.id, accountGstType.composition.id, accountGstType.unregistered.id, accountGstType.consumer.id, accountGstType.deemed.id];
                }

                let self = this;
                this.getFilterData('ValidateParty', this.Form.selectedParty.itemId, filterObject, null,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.isPartyValid = JSON.parse(response);
                            if(!self.isPartyValid){
                                if(!isEdit){
                                    self.Form.selectedParty = null;
                                    self.Form.selectedState = null;
                                    self.Form.placeOfSupplyCountryId = null;
                                    self.Form.placeOfSupplyCountryName = null;
                                    self.Form.partyDetails = null;
                                    self.$eventHub.$emit('snackbarAlert', {msg:'Not a valid supplier'});
                                }
                            }
                            else{
                                if(self.Form.isNew && (self.Form.transactionData.transactionListId != transactionTypes.purchaseOrder.id)){
                                    self.fn_checkPendingItemsCount();
                                }
                            }
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            fn_changeParty: function(){
                this.isPartyValid = true;
                this.Form.selectedParty = null;
                this.Form.selectedState = null;
                this.Form.placeOfSupplyCountryId = null;
                this.Form.placeOfSupplyCountryName = null;
                this.Form.partyDetails = null;
                setTimeout(() => {
                    let element = document.getElementById("party_dropdown");
                    element.focus();
                    element.click();
                }, 300);
            },

            fn_checkPendingItemsCount: function () {

                if(this.$root.appVersion < 109){
                    return;
                }

                let filterObject = {};

                filterObject.formTransactionListId = [transactionTypes.purchaseChallan.id, transactionTypes.purchaseInvoice.id];

                if(this.Form.transactionData.transactionListId == transactionTypes.purchaseChallan.id){
                    filterObject.transactionListId = [transactionTypes.purchaseOrder.id];	
                }
                else if(this.Form.transactionData.transactionListId == transactionTypes.purchaseInvoice.id){
                    filterObject.transactionListId = [transactionTypes.purchaseOrder.id, transactionTypes.purchaseChallan.id];	
                }
                else if(this.Form.transactionData.transactionListId == transactionTypes.purchaseReturn.id){
                    filterObject.transactionListId = [transactionTypes.purchaseInvoice.id];	
                    filterObject.formTransactionListId = [transactionTypes.purchaseReturn.id];
                }

                let self = this;
                this.getFilterData('PendingItemsCount', self.Form.selectedParty.itemId, filterObject, null,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.Form.pendingOrderAndChallan = JSON.parse(response);
                            if(self.Form.pendingOrderAndChallan && Object.keys(self.Form.pendingOrderAndChallan).length){
                                self.Form.pendingCount = 0;
                                for (const key in self.Form.pendingOrderAndChallan) {
                                    self.Form.pendingCount = self.Form.pendingCount + Number(self.Form.pendingOrderAndChallan[key]);
                                }
                            }

                            if(!self.Form.pendingOrderAndChallan || !Object.keys(self.Form.pendingOrderAndChallan).length){
                                self.Form.pendingCount = 0;
                            }
                        }, 
                        onError: function(error){
                            console.log(error);
                        }
                    }
                );
            },

            fn_getPendingAlertMsg: function(){
                if(this.Form.pendingOrderAndChallan && Object.keys(this.Form.pendingOrderAndChallan).length){
                    if(Object.keys(this.Form.pendingOrderAndChallan).length > 1){
                        return "Purchase Orders/Challans are pending.";
                    }
                    else{
                        if(Object.keys(this.Form.pendingOrderAndChallan)[0] == transactionTypes.purchaseOrder.id){
                            return this.Form.pendingOrderAndChallan[transactionTypes.order.id] > 1 ? "Purchase Orders are pending." : "Purchase Order is pending.";
                        }
                        else if(Object.keys(this.Form.pendingOrderAndChallan)[0] == transactionTypes.purchaseChallan.id){
                            return this.Form.pendingOrderAndChallan[transactionTypes.deliveryChallan.id] > 1 ? "Purchase Challans are pending." : "Purchase Challan is pending.";
                        }
                    }
                }
            },

            fn_loadItems: function (type) {

                if(!this.Form.selectedParty){
                    // alert("Select party");
                    this.$eventHub.$emit('snackbarAlert', {msg:'Select Party'});
                    return;
                }

                this.pendingType = type;

                let transactionListId = null;
                let pendingItemsData = null;

                let filterObject = {};

                if(type=='orders'){
                    transactionListId = transactionTypes.purchaseOrder.id;
                    pendingItemsData = this.Form.pendingOrders;
                    filterObject.formTransactionListId = [transactionTypes.purchaseChallan.id, transactionTypes.purchaseInvoice.id];
                }
                else{
                    filterObject.formTransactionListId = [this.Form.transactionData.transactionListId];
                }
                
                if(type=='challans'){
                    transactionListId = transactionTypes.purchaseChallan.id;
                    pendingItemsData = this.Form.pendingChallans;
                }
                
                if(type=='invoices'){
                    transactionListId = transactionTypes.purchaseInvoice.id;
                    pendingItemsData = this.Form.pendingInvoices;
                }

                // let filterObject = {};

                // if(ref == "ref_dialogOrders"){
                //     filterObject.formTransactionListId = [transactionTypes.purchaseChallan.id, transactionTypes.purchaseInvoice.id]
                // }
                // else{
                //     filterObject.formTransactionListId = [this.Form.transactionData.transactionListId];
                // }

                filterObject.transactionListId = [transactionListId];

                let self = this;
                this.getFilterData('PendingItems', self.Form.selectedParty.itemId, filterObject, null,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            let items = JSON.parse(response);

                            self.Form.pendingItemsList = items.filter(item=>{

                                item.selected = false;

                                for(let i=0; i<pendingItemsData.length; i++){
                                    if(item.transactionId == pendingItemsData[i].transactionId){
                                        item.selected = true;
                                        self.$forceUpdate();
                                        break;
                                    }
                                }
                                
                                return true;
                            });

                            // self.fn_openDialogPendingItemsList(ref);
                            self.$refs['ref_dialogPendingItems'].openDialogWeb();
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            fn_closeDialogBatchConfirmation: function(){
                this.$refs['ref_dialogBatchConfirmation'].dialog_confimation = false;
            },

            fn_getPendingItems: function (transactionIds, type) {
                let self = this;

                let filterObject = {};
                filterObject.transactionIds = transactionIds;

                
                this.getFilterData('PendingItemsData', null, filterObject, null,
                    {
                        onSuccess: function(response){
                            console.log(response);

                            // patch to find out if data consists batches until we implement batch on desktop
                            // if(type=='challans' || type=='invoices'){
                            //     let data = JSON.parse(response);
                            //     for(let i=0; i<data.length; i++){
                            //         for(let j=0; j<data[i].transactionProducts.length; j++){
                            //             if(data[i].transactionProducts[j].transactionProductBatches && data[i].transactionProducts[j].transactionProductBatches.length){
                            //                 self.batchConfirmationMessage =  "Transaction number <strong>" +data[i].baseTransactionNumber+ "</strong> consists batch.<br>Please load this transaction in BizOps App on mobile"
                            //                 self.$refs['ref_dialogBatchConfirmation'].dialog_confimation = true;
                            //                 return;
                            //             }
                            //         }
                            //     }
                            // }

                            if(type=='orders'){

                                self.Form.pendingOrders = JSON.parse(response);
                                self.fn_bindPendingItems(self.Form.pendingOrders, type);
                            }
                            else if(type=='challans'){

                                self.Form.pendingChallans = JSON.parse(response);
                                self.fn_bindPendingItems(self.Form.pendingChallans, type);
                            }
                            else if(type=='invoices'){

                                self.Form.pendingInvoices = JSON.parse(response);
                                self.fn_bindPendingItems(self.Form.pendingInvoices, type);
                            }

                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            // fn_checkPendingTransaction: function(transactionIds, callback){
            //     // let self = this;

            //     let filterObject = {};
            //     //draft last modifiedDate
            //     filterObject.transactionIds = transactionIds;
            //     filterObject.startDate = this.draftModifiedDate;
                
            //     this.getFilterData('CheckPendingTransaction', null, filterObject, null,
            //         {
            //             onSuccess: function(response){
            //                 console.log(response);
            //                 //if draft modifiedDate is less than entry date
            //                 callback(JSON.parse(response));
            //             }, 
            //             onError: function(error){
            //               console.log(error);
            //             }
            //         }
            //     );
            // },

            fn_bindPendingItems: function (pendingItems, type) {

                // checking only first item for isRounded flag and applying in current form
                if(!this.Form.isRounded && pendingItems[0].isRounded){
                    this.Form.isRounded = true;
                }

                if(pendingItems[0].roundOffValue){
                    this.Form.roundOffValue = pendingItems[0].roundOffValue;
                }

                let self = this;

                // self.fn_closeDialog();
                self.$refs['ref_dialogPendingItems'].closeDialogWeb();

                pendingItems.forEach(function (pendingItem) {

                    pendingItem.transactionProducts.forEach(function (itemData) {

                        // generating new transactionProductId for each pendingItem
                        // and
                        // assigning refTId and RefTpId
                        itemData.productVariants.forEach(function(vItem){
                            vItem.refTId = vItem.transactionId;
                            vItem.refTpId = vItem.transactionProductId;
                            vItem.transactionProductId = self.guid();

                            // if(self.settings.transactionTypeId == transactionTypes.purchaseReturn.id){
                            // 	for(let i=0; i<itemData.transactionProductBatches.length; i++){
                            // 		if(itemData.transactionProductBatches[i].refTpBatchId){
                            // 			itemData.transactionProductBatches[i].refTpBatchId = null;
                            // 		}
                            // 	}
                            // }
                            if(self.settings.transactionTypeId == transactionTypes.salesReturn.id){
                                if(itemData.transactionProductBatches && itemData.transactionProductBatches.length){
                                    for(let i=0; i<itemData.transactionProductBatches.length; i++){
                                        if(itemData.transactionProductBatches[i].refTpBatchId){
                                            itemData.transactionProductBatches[i].refTpBatchId = null;
                                        }
                                    }
                                }
                            }

                            if(itemData.transactionProductBatches && itemData.transactionProductBatches.length){
                                for(let i=0; i<itemData.transactionProductBatches.length; i++){
                                    if(itemData.transactionProductBatches[i].transactionProductBatchId){
                                        itemData.transactionProductBatches[i].refTransactionProductBatchId = itemData.transactionProductBatches[i].transactionProductBatchId;
                                        itemData.transactionProductBatches[i].transactionProductBatchId = null;
                                    }
                                }
                            }

                            if(vItem.secondaryUnitId){
                                vItem.selectedUnit = {};
                                vItem.selectedUnit.itemId = vItem.unitId;
                                vItem.selectedUnit.itemName = vItem.unitName;

                                if(vItem.unitId == vItem.secondaryUnitId){
                                    vItem.qty = self.roundUpto2Decimals((vItem.qty * vItem.secondaryUnitPackQuantity) / vItem.packQuantity);
                                    if(itemData.transactionProductBatches && itemData.transactionProductBatches.length){
                                        for(let i=0; i<itemData.transactionProductBatches.length; i++){
                                            itemData.transactionProductBatches[i].qty = self.roundUpto2Decimals((itemData.transactionProductBatches[i].qty * vItem.secondaryUnitPackQuantity) / vItem.packQuantity);
                                        }
                                    }
                                }
                                else{
                                    vItem.qty = self.roundUpto2Decimals(vItem.qty);
                                    if(itemData.transactionProductBatches && itemData.transactionProductBatches.length){
                                        for(let i=0; i<itemData.transactionProductBatches.length; i++){
                                            itemData.transactionProductBatches[i].qty = self.roundUpto2Decimals(itemData.transactionProductBatches[i].qty);
                                        }
                                    }
                                }
                            }
                        });

                        itemData.taxValue = 0;
                        itemData.taxAmount = 0;
                        // itemData.hsnCode = null;

                        itemData.cessValue = 0;
                        itemData.cessAmount = 0;

                        let tValue = 0;
                        let tAmount = 0;

                        if(pendingItem.taxMode){

                            itemData.transactionProductAddOns.filter(addOnsItem=>{

                                addOnsItem.mode == 1 ? addOnsItem.percentage = null : '';

                                if(addOnsItem.isGst==1){
                                    // itemData.taxValue += addOnsItem.percentage;
                                    // itemData.taxAmount += addOnsItem.value;
                                    tValue += addOnsItem.percentage;
                                    tAmount += addOnsItem.value;
                                    // itemData.hsnCode = addOnsItem.hsnCode;
                                }
                                else if(addOnsItem.isGst==2){
                                    itemData.cessValue = addOnsItem.percentage != null ? addOnsItem.percentage : null;
                                    itemData.cessAmount = addOnsItem.value;
                                    // itemData.hsnCode = addOnsItem.hsnCode;
                                }

                            });

                            itemData.taxValue = self.roundUpto2Decimals(tValue);
                            itemData.taxAmount = self.roundUpto2Decimals(tAmount);

                            itemData.selectedTaxCategory = {"taxCategoryId":itemData.taxCategoryId, "taxCategoryName":itemData.taxCategoryName};
                        }

                        
                        let addOns = null;

                        itemData.addOnsItems = [];

                        if(!self.Form.productDataModel.addOnsItems.length){
                            self.Form.transactionTypeData.transactionTypeAddOns.forEach(function (item) {
                                if(item.type==0){
                                    self.Form.productDataModel.addOnsItems.push(JSON.parse(JSON.stringify(item)));
                                }
                            });
                        }

                        self.Form.productDataModel.addOnsItems.forEach(function (defaultAddOns) {

                            let itemIndex = itemData.transactionProductAddOns.findIndex(item=>{
                                return defaultAddOns.addOnsId == item.addOnsId;
                            });

                            if(itemIndex!=-1){
                                addOns = JSON.parse(JSON.stringify(itemData.transactionProductAddOns[itemIndex]));
                                addOns.appliedOn = defaultAddOns.appliedOn;
                                addOns.adjustInTotal = defaultAddOns.adjustInTotal;
                            }
                            else{
                                addOns = JSON.parse(JSON.stringify(defaultAddOns));
                            }

                            addOns ? itemData.addOnsItems.push(addOns) : '';
                            
                        });

                        if(type == 'challans'){
                            itemData.productVariants[0].billQty = itemData.productVariants[0].qty;
                        }

                        if(itemData.transactionProductBatches && itemData.transactionProductBatches.length){
                            itemData.batches = [];
                            for(let i=0; i<itemData.transactionProductBatches.length; i++){
                                if(itemData.transactionProductBatches[i].qty){
                                    itemData.transactionProductBatches[i].productQty = self.roundUpto2Decimals(itemData.transactionProductBatches[i].qty);
                                    if(type == 'challans' || type == 'invoices'){
                                        itemData.transactionProductBatches[i].selected = false;
                                        itemData.transactionProductBatches[i].billQty = self.roundUpto2Decimals(itemData.transactionProductBatches[i].qty);
                                    }

                                    if(type=='invoices'){
                                        itemData.transactionProductBatches[i].productQty = null;
                                    }
                                    itemData.batches.push(itemData.transactionProductBatches[i]);
                                }
                                
                            }
                        }
                        
                    });

                    if(type!='invoices'){
                        (function (pendingItem) {
                            self.fn_addPendingItems(pendingItem.transactionProducts, pendingItem.transactionAddOns);
                        }(pendingItem));
                    }
                });

                if(type=='invoices'){
                    this.fn_editLoadedItems(pendingItems[0].transactionProducts);
                }
            },

            fn_addPendingItems: function (transactionProducts, transactionAddOns, type) {
                let self = this;

                if(type=='invioceItems'){
                    //this step will remove all the items from the item list who belongs to invoice as we will add fresh items to it after selection.
                    self.Form.items = self.Form.items.filter(item=>{
                        return item.baseTransactionListId != transactionTypes.purchaseInvoice.id;
                    });
                }

                transactionProducts.forEach(function (tItem) {

                    let itemIndex = self.Form.items.findIndex(item=>{
                        return item.groupId == tItem.groupId;
                    });

                    if(itemIndex!=-1)
                        self.Form.items.splice(itemIndex, 1, tItem);
                    else
                        self.Form.items.push(tItem);
                });
                
                self.fn_calculateTax(true);

                transactionAddOns && transactionAddOns.length ? this.fn_addPendingAddOns(transactionAddOns, type) : '';

                if(type=="invioceItems"){
                    // this.fn_closeDialog();
                    this.$refs['ref_dialogReturnForm'].closeDialogWeb();
                }
            },

            fn_addPendingAddOns: function (transactionAddOns) {
                //appending transaction addons of orders or challans
                // let self = this;

                transactionAddOns.forEach(item => {

                    let itemCopy = JSON.parse(JSON.stringify(item));
                    itemCopy.percentage = null;
                    itemCopy.transactionAddOnsId = this.guid();

                    let sharedItem = false;

                    for(let i=0; i<this.Form.transactionAddOns.length; i++){
                        if(this.Form.transactionAddOns[i].addOnsId == itemCopy.addOnsId && this.Form.transactionAddOns[i].transactionId){
                            this.Form.transactionAddOns[i].percentage = null;

                            let value = this.roundUpto2Decimals(this.Form.transactionAddOns[i].value + itemCopy.value);
                            this.Form.transactionAddOns[i].value = value;
                            sharedItem = true;
                        }
                    }

                    if(!sharedItem){
                        this.Form.transactionAddOns.push(itemCopy);
                    }

                });
            },

            fn_editLoadedItems: function (transactionProducts) {
                //updating items return qty for dialog_item_selection
                this.Form.items.forEach(function (item) {

                    let tProduct = transactionProducts.find(tItem=>{
                        return tItem.groupId == item.groupId;
                    });

                    if(tProduct){

                        tProduct.productVariants.forEach(function (tVariant) {
                        
                            item.productVariants.forEach(function (variant) {
                                
                                if(tVariant.productVariantId == variant.productVariantId){
                                    tVariant.returnQty = variant.qty;
                                }

                            });

                        });

                    }
                    

                    
                });

                // this.fn_openDialogReturnForm('ref_dialogReturnForm');
                this.$refs['ref_dialogReturnForm'].openDialogWeb();
            },

            // fn_getBottomMenu: function (tagId) {

            //     let menuItems = [];

            //     // !this.Form.isNew ? menuItems.unshift(menuItem.cancelTransaction) : '';

                
            //     if(!this.Form.isPendingItems){

            //         if(this.Form.transactionData.transactionListId == transactionTypes.purchaseChallan.id){
            //             menuItems.push(menuItem.loadPurchaseOrder);
            //         }
            //         else if(this.Form.transactionData.transactionListId == transactionTypes.purchaseInvoice.id){
            //             menuItems.push(menuItem.loadPurchaseOrder);
            //             menuItems.push(menuItem.loadPurchaseChallan);
            //         }
            //         else if(this.Form.transactionData.transactionListId == transactionTypes.purchaseReturn.id){
            //             menuItems.push(menuItem.loadPurchaseInvoice);
            //         }

            //     }

            //     // this.Form.dynamicMenuItems.forEach(function (item) {
            //     //  menuItems.push(item);
            //     // });

            //     var bottomMenu = {"BottomMenu": menuItems};

            //     this.setBottomMenu(tagId, bottomMenu, 
            //         {
            //             onSuccess: function (response) {
            //                 console.log(response);
            //             },
            //             onError: function (error) {
            //                 console.log(error);
            //             }
            //         }
            //     )
            // },

            // fn_getBottomMenuAddOns: function () {

            //  var bottomMenu = {"BottomMenu": [menuItem.addDiscount, menuItem.addTaxes, menuItem.addOtherCharges]};

            //  this.setBottomMenu(null, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            // fn_getBottomMenuAttachment: function () {

            //     if(!this.$root.CurrentBusiness.DataDrive){
            //         alert("Set up Backup Drive to attach documents");
            //         return;
            //     }

            //     var bottomMenu = {"BottomMenu": [menuItem.camera, menuItem.fileManager]};

            //     this.setBottomMenu(null, bottomMenu, 
            //         {
            //             onSuccess: function (response) {
            //                 console.log(response);
            //             },
            //             onError: function (error) {
            //                 console.log(error);
            //             }
            //         }
            //     )
            // },

            // fn_closeDialog: function (tagId) {
            //     dialogjs.fn_checkAndCloseOpenDialogs(this);
            // },

            fn_calculateTax: function (updateTaxMode) {
                let self = this;
                this.Form.selectedTaxMode.value == 0 ? this.Form.isRcmApplicable = false : '';
                this.Form.items.forEach(function (item) {

                    if(self.Form.selectedTaxMode.value && updateTaxMode){
                        !item.taxValue ? item.taxValue = item.defaultTaxValue : '';
                        //isCessApplicable is coming by using taxCategoryId saved in transactionProducts and using only to check cess is applicable or not at master level
                        // Rest all default keys are coming from taxCategory table via productId saved in transactionProducts

                        // !item.cessValue && item.isCessApplicable ? item.cessValue = item.defaultCessValue : '';
                        if(item.isCessApplicable){
                            !item.cessValue && !item.cessAmount ? item.cessValue = item.defaultCessValue : '';
                        }
                        

                        if(!item.taxCategoryId){
                            item.taxCategoryId = item.defaultTaxCategoryId;
                            item.taxCategoryName = item.defaultTaxCategoryName;
                        }

                        // item.addOnsItems.forEach(function (addOnsItem) {
                        //  addOnsItem.hsnCode = item.defaultHsnCode;
                        // });
                        if(!item.hsnCode){
                            item.hsnCode = item.defaultHsnCode;
                        }
                        // item.hsnCode = item.defaultHsnCode;
                    }

                    (function (item) {
                        // item.netAmount = self.$refs['ref_dialogProduct'].fn_computeValues(item);
                        self.$refs['ref_dialogForm'].fn_computeValues(item);
                    }(item))
                });
            },

            fn_checkValidations: function(){
                
                // if(this.roundedAmount <= 0){
                //     this.$eventHub.$emit('snackbarAlert', {msg:"Bill amount can't be zero or less then zero"});
                //     this.disableSaveBtn = false;
                //     this.saveBtnKey++;
                //     this.disableOnSave = false;
                //     return;
                // }
                if(this.settings.transactionTypeId == transactionTypes.purchaseOrder.id || this.settings.transactionTypeId == transactionTypes.purchaseChallan.id){
                    if(this.roundedAmount < 0){
                        this.$eventHub.$emit('snackbarAlert', {msg:"Bill amount can't be less then zero"});
                        this.disableSaveBtn = false;
                        this.saveBtnKey++;
                        this.disableOnSave = false;
                        return;
                    }
                }
                else{
                    if(this.roundedAmount <= 0){
                        this.$eventHub.$emit('snackbarAlert', {msg:"Bill amount can't be zero or less then zero"});
                        this.disableSaveBtn = false;
                        this.saveBtnKey++;
                        this.disableOnSave = false;
                        return;
                    }
                }

                if(this.splitPaymentTotal != this.roundedAmount){
                    // alert("Please adjust payment as per invoice total");
                    this.$eventHub.$emit('snackbarAlert', {msg:"Please adjust payment as per invoice total"});
                    this.disableSaveBtn = false;
                    this.saveBtnKey++;
                    this.disableOnSave = false;
                    this.fn_openDialog_PaymentOptions();
                    return
                }

                if(this.fileBufferValue){
                    this.$eventHub.$emit('snackbarAlert', {msg:"File upload in progress. Please wait."});
                    this.saveBtnKey++;
                    this.disableOnSave = false;
                    this.disableOnSave = false;
                    return;
                }

                this.loading = true;
                this.fn_checkTransactionNumber(()=>{
                    // this.loading = true;
                    this.fn_post();
                });
            },

            fn_checkTransactionNumber: function(callback){
                var self = this;
                let filterObject = {};
                filterObject.mergeTransactionNumber = this.Form.transactionData.mergeTransactionNumber;
                filterObject.fiscalYear = JSON.stringify(this.fn_getFiscalYear(this.Form.transactionData.transactionDate));
                let transactionId = this.Form.transactionData.transactionId ? this.Form.transactionData.transactionId : null;
                this.getFilterData('CheckTransactionNumber', transactionId, filterObject, null,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            callback ? callback() : '';
                        }, 
                        onError: function(error){
                            // self.disableBtn = false;
                            self.saveBtnKey++;
                            self.disableOnSave = false;
                            self.loading = false;
                            console.log(error);
                            // alert('Transaction number already exists');
                            // self.hideLoading();
                            self.transactionNumberSnackbar = true;
                        }
                    }
                );
            },

            fn_saveLocal: function(dataObj){
                // this.backButtonPress = false;
                var self = this;
                this.postData('Purchase', dataObj, 
                    {
                        onSuccess: function(response){
                          console.log(response);
                          self.fn_cleanUp();
                        },
                        onError: function(error){
                            console.log(error);
                            self.saveBtnKey++;
                            self.disableOnSave = false;
                            self.loading = false;

                            let response = JSON.parse(error);
                            if(response && response.numberExistsError){
                                self.transactionNumberSnackbar = true;
                            }

                            if(response && response.orderAlreadyCompleteError){
                                let type = null;
                                if(self.Form.pendingOrders.length){
                                    // alert("Order already cleared.")
                                    type = "Order";
                                }

                                if(self.Form.pendingChallans.length){
                                    // alert("Challan already cleared.")
                                    type = "Challan";
                                }

                                if(self.Form.pendingInvoices.length){
                                    // alert("Invoice already cleared.");
                                    type = "Invoice";
                                }

                                self.$eventHub.$emit('snackbarAlert', {msg:type + " already cleared"});
                            }

                            self.loading = false;
                        }
                    }
                );
            },

            fn_cleanUp: function () {
                let obj = {};
                obj.transactionTypeId = this.settings.transactionTypeId;
                setTimeout(()=>{
                    localStorage.removeItem(this.draftStorageId);
                    this.$eventHub.$emit('UpdatePurchaseList', obj); 
                    this.$eventHub.$emit('UpdateLedgerList', obj);
                },100);
                this.$refs['ref_dialogForm'].closeDialogWeb();
                this.backButtonPress = true;
                this.$root.viaKeyboardShortCut = false;
                // posting metrics on save
                let postObj = {"eventName":this.toolbarTitle.toLowerCase()+"_desktop_save","eventData":[{"eventKey":"transaction_number","eventValue":this.Form.transactionData.mergeTransactionNumber}]}
                this.$root.fn_postMetrics(postObj);
                this.fn_previewInvoice(false, 'purchase');
            },

            fn_post: function(){

                // let addOnsTotal = 0;
                let purchaseLedgerAmount = this.Form.itemsSummary.grossAmount;
                // let totalGst = 0;
                let totalDiscount = 0;
                let totalTax = 0;
                let totalOtherCharges = 0;
                let refTId = [];

                //only in case of new transaction
                if(!this.Form.transactionData.transactionId){
                    this.Form.transactionData.transactionId = this.guid();
                    // this.Form.transactionData.createdDate = new Date();
                    this.Form.transactionData.createdDate = this.$root.fn_getLocalDateFormat();
                }

                this.Form.transactionData.partyId = this.Form.selectedParty ? this.Form.selectedParty.itemId : null;
                this.Form.transactionData.grossAmount = this.Form.itemsSummary.grossAmount;
                this.Form.transactionData.netAmount = this.netAmount;
                this.Form.transactionData.amount = this.totalAmount;
                this.Form.transactionData.roundedAmount = this.roundedAmount//Math.round(this.totalAmount);
                this.Form.transactionData.taxMode = this.Form.selectedTaxMode.value;
                this.Form.transactionData.transactionTypeFormId = this.Form.transactionTypeFormId;
                // this.Form.transactionData.modifiedDate = new Date();
                this.Form.transactionData.modifiedDate = this.$root.fn_getLocalDateFormat();

                this.Form.transactionData.placeOfSupplyId = null;

                // if(this.$root.CurrentBusiness.GSTRegistrationStatus==1 && this.Form.selectedState){
                //     this.Form.transactionData.placeOfSupplyId = this.Form.selectedState.itemId;
                //     this.Form.transactionData.placeOfSupplyCountryId = this.Form.placeOfSupplyCountryId;
                // }
                if(this.Form.selectedState){
                    this.Form.transactionData.placeOfSupplyId = this.Form.selectedState.itemId;
                    this.Form.transactionData.placeOfSupplyCountryId = this.Form.placeOfSupplyCountryId;
                }

                if(this.Form.transactionData.paymentMode!=null){
                    this.Form.transactionData.splitPayment = this.Form.splitPayment;
                }

                this.Form.transactionData.isRounded = this.Form.isRounded;
                // this.Form.transactionData.itemType = !this.Form.items.length ? this.Form.itemType : null;
                if(!this.Form.items.length){
                    this.Form.transactionData.itemType = this.Form.itemType ? 1 : 0;
                    this.Form.transactionData.description = this.Form.description;
                }
                else{
                    this.Form.transactionData.itemType = null;
                    this.Form.transactionData.description = null;
                }

                this.Form.transactionData.roundOffValue = this.Form.roundOffValue;
                // this.Form.transactionData.roundOffValue = this.roundOff ? this.roundOff : null;
                this.Form.transactionData.isRcmApplicable = this.Form.isRcmApplicable ? 1 : 0;
                this.Form.transactionData.documentDetails = this.Form.attachments;

                this.Form.transactionData.paymentTerm = this.Form.selectedPaymentTerm && this.Form.selectedPaymentTerm.value ? this.Form.selectedPaymentTerm.value : this.Form.paymentTerm;

                // this.Form.transactionData.prefix = this.Form.transactionData.prefix ? this.Form.transactionData.prefix : null;
                this.Form.transactionData.suffix = this.Form.transactionData.suffix ? this.Form.transactionData.suffix : null;

                let postObj = {};
                postObj = JSON.parse(JSON.stringify(this.Form.transactionData));

                postObj.transactionSections = [];
                postObj.transactionFields = [];
                postObj.transactionProducts = [];
                postObj.transactionProductAddOns = [];
                postObj.transactionAddOns = [];
                postObj.transactionTds = [];
                postObj.ledgerAccounts = [];
                postObj.masterProductBatches = [];
                postObj.transactionProductBatches = [];

                let self = this;

                //Inserting 
                    // dynamic/custom section to transactionSections
                    // dynamic/custom fields to transactionFields
                if(this.Form.customFields.length){

                    this.Form.customFields.forEach(function (item) {

                        let sectionObj = {};
                        sectionObj.transactionSectionId = self.guid()
                        sectionObj.sectionId = item.section.sectionId;
                        sectionObj.sectionName = item.section.sectionName;
                        // sectionObj.orderNumber = index+1;
                        sectionObj.orderNumber = item.section.orderNumber;

                        postObj.transactionSections.push(sectionObj);

                        item.items.forEach(function (fieldItem, index) {

                            let fieldObj = {};
                            fieldObj.transactionFieldId = self.guid();
                            fieldObj.transactionSectionId = sectionObj.transactionSectionId;
                            fieldObj.transactionTypeFieldId = fieldItem.transactionTypeFieldId;
                            fieldObj.sectionId = item.section.sectionId;
                            fieldObj.orderNumber = index+1;
                            fieldObj.fieldId = fieldItem.fieldId;
                            fieldObj.fieldName = fieldItem.displayName;
                            // fieldObj.fieldValue = fieldItem.value;
                            if(fieldItem.selectedAccountItem && fieldItem.selectedAccountItem.itemId){
                                fieldObj.fieldValue = fieldItem.selectedAccountItem.itemName;
                            }
                            else if(fieldItem.selectedListItem && fieldItem.selectedListItem.itemId){
                                fieldObj.fieldValue = fieldItem.selectedListItem.itemName;
                            }
                            else{
                                fieldObj.fieldValue = fieldItem.value;
                            }
                            
                            fieldObj.fieldDataType = fieldItem.fieldType;

                            // fieldObj.accountLedgerId = fieldItem.accountLedgerId ? fieldItem.accountLedgerId : null;
                            // fieldObj.listItemId = fieldItem.listItemId ? fieldItem.listItemId : null;

                            if(fieldItem.selectedAccountItem){
                                fieldObj.accountLedgerId = fieldItem.selectedAccountItem.itemId;
                                fieldObj.fieldValue = fieldItem.selectedAccountItem.itemName;
                            }

                            if(fieldItem.selectedListItem){
                                fieldObj.listItemId = fieldItem.selectedListItem.itemId;
                                fieldObj.fieldValue = fieldItem.selectedListItem.itemName;
                            }

                            postObj.transactionFields.push(fieldObj);
                        })
                    })

                }

                //Inserting 
                    // products/items to transactionProducts 
                    // product's addOns to transactionProductAddOns
                if(this.Form.items.length){

                    this.Form.items.forEach(function(item){

                        item.groupId = self.guid();

                        //Transaction Products Entries
                        item.productVariants.forEach(function (vItem) {

                            let tpObj = {};

                            if(vItem.qty){

                                !vItem.transactionProductId ? tpObj.transactionProductId = self.guid() : tpObj.transactionProductId = vItem.transactionProductId;

                                tpObj.productId = vItem.productId;

                                tpObj.productVariantId = vItem.productVariantId;

                                tpObj.groupId = item.groupId;

                                tpObj.mrp = item.mrp ? item.mrp : 0;

                                tpObj.rate = item.rate ? item.rate : 0;

                                tpObj.description = item.description;

                                tpObj.additionalData = null;

                                // tpObj.unitId = vItem.unitId;
                                tpObj.unitId = vItem.selectedUnit && vItem.selectedUnit.itemId ? vItem.selectedUnit.itemId : vItem.unitId;

                                tpObj.primaryUnitId = vItem.primaryUnitId;

                                if(vItem.secondaryUnitId && vItem.selectedUnit && vItem.selectedUnit.itemId){
                                    tpObj.secondaryUnitId = vItem.secondaryUnitId;
                                    tpObj.secondaryUnitPackQuantity = vItem.secondaryUnitPackQuantity;
                                }

                                tpObj.qty = vItem.qty;

                                tpObj.packQuantity = vItem.packQuantity ? vItem.packQuantity : 1;

                                tpObj.transactionProductMode = 1;

                                if(self.settings.transactionTypeId == transactionTypes.purchaseReturn.id){
                                    tpObj.actualQty = -vItem.qty;
                                }
                                else{
                                    tpObj.actualQty = vItem.qty;
                                }

                                if(vItem.primaryUnitId && tpObj.unitId != vItem.primaryUnitId){
                                    tpObj.actualQty = self.roundUpto5Decimals((tpObj.actualQty * vItem.packQuantity) / vItem.secondaryUnitPackQuantity);
                                }

                                tpObj.netAmount = item.netAmount;
                                tpObj.taxableValue = item.taxableValue;

                                let packQty = vItem.packQuantity ? vItem.packQuantity : 1;

                                let amount = item.rate * vItem.qty * packQty;
                                tpObj.amount = self.roundUpto2Decimals(amount);


                                tpObj.transactionListId = self.Form.transactionData.transactionListId;
                                tpObj.stockInventory = self.passStockEntry;

                                if(item.baseTransactionListId != self.settings.transactionTypeId  && item.baseTransactionListId == transactionTypes.purchaseChallan.id){
                                    tpObj.stockInventory = false;                               
                                }

                                tpObj.refTId = null;
                                tpObj.refTpId = null;

                                //refTId and refTpId only will goes in case of invoice, challan and return only for the items are loaded from orders or challans;
                                if((self.settings.transactionTypeId == transactionTypes.purchaseInvoice.id || self.settings.transactionTypeId == transactionTypes.purchaseReturn.id || self.settings.transactionTypeId == transactionTypes.purchaseChallan.id) && item.baseTransactionListId != self.settings.transactionTypeId){

                                    tpObj.refTId = vItem.refTId ? vItem.refTId : vItem.transactionId;
                                    tpObj.refTpId = vItem.refTpId ? vItem.refTpId : vItem.transactionProductId;

                                    refTId.push(tpObj.refTId);

                                    //Generating new in case of order or challan item as old transactionProductId already saved while creating order or challan
                                    // tpObj.transactionProductId = self.guid();
                                    // self.Form.isNew ? tpObj.transactionProductId = self.guid() : '';

                                }

                                //saving tax category id in transaction products in case of tax mode exclusive/inclusive
                                // tpObj.taxCategoryId = item.taxCategoryId;
                                tpObj.taxCategoryId = item.selectedTaxCategory ? item.selectedTaxCategory.taxCategoryId : null;

                                tpObj.hsnCode = item.hsnCode;

                                // if(!self.Form.taxMode){
                                if(!self.Form.selectedTaxMode.value){
                                    tpObj.taxCategoryId = item.defaultTaxCategoryId ? item.defaultTaxCategoryId : '';
                                }

                                // tpObj.orderNumber = postObj.transactionProducts.length + 1;
                                if(!item.baseTransactionListId || (item.baseTransactionListId && item.baseTransactionListId == self.settings.transactionTypeId)){
                                    tpObj.orderNumber = postObj.transactionProducts.length + 1;
                                }
                                else{
                                    tpObj.orderNumber = item.orderNumber;
                                }

                                tpObj.selectedBatchType = item.selectedBatchType;

                                postObj.transactionProducts.push(tpObj);

                            }

                            if(item.batches && item.batches.length){
                                //adding batches to transaction
                                for(let i=0; i<item.batches.length; i++){
                                    let obj = {};
                                    obj.productId = tpObj.productId;
                                    obj.transactionProductBatchId = !self.Form.isNew && item.batches[i].transactionProductBatchId ? item.batches[i].transactionProductBatchId : self.guid();
                                    obj.batchId = item.batches[i].batchId;
                                    obj.transactionProductId = tpObj.transactionProductId;
                                    obj.qty = item.batches[i].productQty;
                                    obj.orderNumber = i + 1;
                                    obj.transactionId = self.Form.transactionData.transactionId;
                                    obj.transactionListId = self.Form.transactionData.transactionListId;
                                    if(tpObj.refTId){
                                        obj.refTId = tpObj.refTId;
                                        // obj.refTpBatchId = item.batches[i].refTpBatchId ? item.batches[i].refTpBatchId : item.batches[i].transactionProductBatchId;
                                        if(item.baseTransactionListId != transactionTypes.purchaseOrder.id){
                                            if(item.batches[i].refTpBatchId){
                                                obj.refTpBatchId = item.batches[i].refTpBatchId;
                                            }
                                            else if(item.batches[i].refTransactionProductBatchId){
                                                obj.refTpBatchId = item.batches[i].refTransactionProductBatchId;
                                            }
                                            else{
                                                obj.refTpBatchId = item.batches[i].transactionProductBatchId;
                                            }
                                        }
                                        
                                    }
                                    if(self.settings.transactionTypeId == transactionTypes.purchaseReturn.id){
                                        obj.actualQty = -item.batches[i].productQty;
                                    }
                                    else{
                                        obj.actualQty = item.batches[i].productQty;
                                    }
                                    if(item.productVariants[0].selectedUnit && item.productVariants[0].selectedUnit.itemId != item.productVariants[0].primaryUnitId){
                                        obj.actualQty = self.roundUpto5Decimals((obj.actualQty * item.productVariants[0].packQuantity) /item.productVariants[0].secondaryUnitPackQuantity);
                                    }
                                    postObj.transactionProductBatches.push(obj);
                                    if(item.batches[i].isNew){
                                        delete item.batches[i].isNew;
                                        item.batches[i].productId = tpObj.productId;
                                        item.batches[i].orderNumber = self.Form.batches.length + (i + 1);
                                        postObj.masterProductBatches.push(item.batches[i]);
                                    }
                                }
                            }

                        })

                        if(item.addOnsItems && item.addOnsItems.length){

                            item.addOnsItems.forEach(function (addOnsItem, index) {

                                let addOnsObj = {};

                                if(addOnsItem.value!==null && addOnsItem.value!==''){

                                    if(!addOnsItem.isGst){

                                        addOnsItem.addOnsType == 1 ? totalDiscount += addOnsItem.value : addOnsItem.addOnsType == 2 ? totalTax += addOnsItem.value : totalOtherCharges += addOnsItem.value;

                                        addOnsObj.transactionProductsAddOnsId = self.guid();
                                        addOnsObj.transactionProductGroupId = item.groupId;
                                        addOnsObj.orderNumber = index+1;
                                        addOnsObj.addOnsId = addOnsItem.addOnsId;

                                        //saving name, nature and mode for display purpose only
                                        addOnsObj.addOnsName = addOnsItem.name;
                                        addOnsObj.addOnsNature = addOnsItem.nature;
                                        addOnsObj.addOnsMode = addOnsItem.mode;

                                        addOnsObj.addOnsType = addOnsItem.addOnsType;
                                        addOnsObj.percentage = addOnsItem.percentage;
                                        addOnsObj.value = addOnsItem.value;
                                        addOnsObj.isGst = null;
                                        // addOnsObj.hsnCode = item.hsnCode ? item.hsnCode : null;

                                        // sending taxable value to transaction product addons as per puran request
                                        addOnsObj.fullTaxableValue = item.taxableValue;

                                        postObj.transactionProductAddOns.push(addOnsObj);

                                        //Passing ledger entry if purchase account selected

                                        let ledgerObj = {};

                                        if(addOnsItem.nature){
                                            ledgerObj.amountType = 0;//Credit
                                            ledgerObj.amount = -addOnsItem.value;
                                        }
                                        else{
                                            ledgerObj.amountType = 1;//Debit
                                            ledgerObj.amount = addOnsItem.value;
                                        }

                                        if(addOnsItem.purchaseAccountItemId && self.passLedgerEntry){
                                            ledgerObj.accountId = addOnsItem.purchaseAccountItemId;
                                            let data = self.fn_bindAndReturnSimilarValues(ledgerObj);
                                            postObj.ledgerAccounts.push(data);
                                        } 
                                        else{

                                            if(addOnsItem.nature){
                                                purchaseLedgerAmount -= addOnsItem.value;
                                            }
                                            else{
                                                purchaseLedgerAmount += addOnsItem.value;
                                            }

                                        }

                                    }
                                    
                                }
                            });
                        }

                        // adding tax as addons
                        self.Form.gstAddOns.forEach(function (addOnsItem) {
                            

                            let tpAddOnsId = self.fn_getSavedGstAddOns(item.groupId, addOnsItem.addOnsId);

                            if(self.Form.selectedTaxMode.value){

                                let addOnsObj = {};

                                !tpAddOnsId ? addOnsObj.transactionProductsAddOnsId = self.guid() : addOnsObj.transactionProductsAddOnsId = tpAddOnsId;

                                addOnsObj.transactionId = self.Form.transactionData.transactionId;
                                addOnsObj.transactionProductGroupId = item.groupId;
                                addOnsObj.orderNumber = postObj.transactionProductAddOns.length+1;
                                addOnsObj.addOnsId = addOnsItem.addOnsId;

                                //saving name, nature and mode for display purpose only
                                addOnsObj.addOnsName = addOnsItem.name;
                                addOnsObj.addOnsNature = addOnsItem.nature;
                                addOnsObj.addOnsMode = addOnsItem.mode;

                                addOnsObj.addOnsType = 2;

                                addOnsObj.percentage = item.taxValue / self.Form.gstAddOns.length;//addOnsItem.percentage;

                                addOnsObj.value = item.taxAmount / self.Form.gstAddOns.length;//addOnsItem.value;

                                addOnsObj.isGst = 1;

                                postObj.transactionProductAddOns.push(addOnsObj);

                                totalTax += addOnsObj.value;
                                // totalGst += addOnsObj.value;

                                //Passing ledger entry if purchase account selected
                                //purchaseAccountItemId is the account selected in gst taxes master
                                
                                // if(addOnsItem.purchaseAccountItemId && self.passLedgerEntry && self.$root.CurrentBusiness.GSTRegistrationStatus == 1){
                                if(addOnsItem.purchaseAccountItemId && self.passLedgerEntry && ((self.$root.CurrentBusiness.GSTRegistrationStatus == 1 && !self.Form.isRcmApplicable) || (self.$root.CurrentBusiness.GSTRegistrationStatus == 2 && self.Form.isRcmApplicable))){

                                    let ledgerObj = {};

                                    // addOnsTotal += addOnsObj.value;
                                    // ledgerObj.amountType = 1//debit;
                                    if(self.$root.CurrentBusiness.GSTRegistrationStatus == 2 && self.Form.isRcmApplicable){
                                        ledgerObj.amountType = 0//credit;
                                        ledgerObj.amount = -addOnsObj.value;
                                    }
                                    else{
                                        ledgerObj.amountType = 1//debit;
                                        ledgerObj.amount = addOnsObj.value;
                                    }

                                    // if(self.Form.isRcmApplicable){
                                    //  ledgerObj.accountId = addOnsItem.saleAccountItemId;

                                    //  // Rcm input tax entry
                                    //  let ledgerObjRcm = {};
                                    //  ledgerObjRcm.amountType = 0//credit;
                                    //  ledgerObjRcm.accountId = addOnsItem.purchaseAccountItemId;
                                    //  ledgerObjRcm.amount = -addOnsObj.value;
                                    //  let rcmData = self.fn_bindAndReturnSimilarValues(ledgerObjRcm);
                                    //  postObj.ledgerAccounts.push(rcmData);
                                    // }
                                    // else{
                                    //  ledgerObj.accountId = addOnsItem.purchaseAccountItemId;
                                    // }

                                    ledgerObj.accountId = addOnsItem.purchaseAccountItemId;

                                    // ledgerObj.amount = addOnsObj.value;
                                    let data = self.fn_bindAndReturnSimilarValues(ledgerObj);
                                    postObj.ledgerAccounts.push(data);


                                }

                            }
                        });

                        //adding cess as addons
                        if(item.isCessApplicable && self.Form.selectedTaxMode.value){

                            let addOnsItem = self.Form.cessAddOns;

                            let tpAddOnsId = self.fn_getSavedGstAddOns(item.groupId, addOnsItem.addOnsId);

                            let addOnsObj = {};

                            !tpAddOnsId ? addOnsObj.transactionProductsAddOnsId = self.guid() : addOnsObj.transactionProductsAddOnsId = tpAddOnsId;

                            addOnsObj.transactionId = self.Form.transactionData.transactionId;
                            addOnsObj.transactionProductGroupId = item.groupId;
                            addOnsObj.orderNumber = postObj.transactionProductAddOns.length+1;
                            addOnsObj.addOnsId = addOnsItem.addOnsId;

                            //saving name, nature and mode for display purpose only
                            addOnsObj.addOnsName = addOnsItem.name;
                            addOnsObj.addOnsNature = addOnsItem.nature;
                            addOnsObj.addOnsMode = addOnsItem.mode;

                            addOnsObj.addOnsType = 2;

                            // addOnsObj.percentage = self.roundUpto2Decimals(item.cessValue);//addOnsItem.percentage;
                            addOnsObj.percentage = item.cessValue!=null ? self.roundUpto2Decimals(item.cessValue) : null;

                            addOnsObj.value = self.roundUpto2Decimals(item.cessAmount);//addOnsItem.value;

                            addOnsObj.isGst = 2;
                            // addOnsObj.hsnCode = item.hsnCode;

                            postObj.transactionProductAddOns.push(addOnsObj);

                            totalTax += addOnsObj.value != null ? addOnsObj.value : 0;
                            // totalGst += addOnsObj.value != null ? addOnsObj.value : 0;

                            //Passing ledger entry if purchase account selected
                            //purchaseAccountItemId is the account selected in gst taxes master
                            // if(addOnsItem.purchaseAccountItemId && self.passLedgerEntry && self.$root.CurrentBusiness.GSTRegistrationStatus != 3){
                            if(addOnsItem.purchaseAccountItemId && self.passLedgerEntry && ((self.$root.CurrentBusiness.GSTRegistrationStatus == 1 && !self.Form.isRcmApplicable) || (self.$root.CurrentBusiness.GSTRegistrationStatus == 2 && self.Form.isRcmApplicable))){

                                let ledgerObj = {};
                                if(self.$root.CurrentBusiness.GSTRegistrationStatus == 2 && self.Form.isRcmApplicable){
                                    ledgerObj.amountType = 0//credit;
                                    ledgerObj.amount = -addOnsObj.value;
                                }
                                else{
                                    ledgerObj.amountType = 1//debit;
                                    ledgerObj.amount = addOnsObj.value;
                                }
                                // addOnsTotal += addOnsObj.value;
                                
                                ledgerObj.accountId = addOnsItem.purchaseAccountItemId;
                                let data = self.fn_bindAndReturnSimilarValues(ledgerObj);
                                postObj.ledgerAccounts.push(data);
                            }

                        }
                    })

                }

                //Inserting
                    // transaction's addOns to transactionAddOns
                if(this.Form.transactionAddOns.length){

                    this.Form.transactionAddOns.forEach(function (addOnsItem, index) {
                        
                        let addOnsObj = {};

                        if(addOnsItem.value!==null && addOnsItem.value!==''){

                            addOnsItem.addOnsType == 1 ? totalDiscount += addOnsItem.value : addOnsItem.addOnsType == 2 ? totalTax += addOnsItem.value : totalOtherCharges += addOnsItem.value;

                            addOnsObj.transactionAddOnsId = addOnsItem.transactionAddOnsId;
                            addOnsObj.orderNumber = index+1;
                            addOnsObj.addOnsId = addOnsItem.addOnsId;

                            //saving name, nature and mode for display purpose only
                            addOnsObj.addOnsName = addOnsItem.name;
                            addOnsObj.addOnsNature = addOnsItem.nature;
                            addOnsObj.addOnsMode = addOnsItem.mode;

                            addOnsObj.addOnsType = addOnsItem.addOnsType;
                            addOnsObj.percentage = addOnsItem.percentage;
                            addOnsObj.value = addOnsItem.value;
                            addOnsObj.appliedOn = addOnsItem.appliedOn;
                            addOnsObj.adjustInTotal = addOnsItem.adjustInTotal;

                            addOnsObj.isDefault = addOnsItem.isDefault;

                            if(addOnsItem.addOnsType==2 && (addOnsItem.addOnsId == fixedGstAddOns.CGST.id || addOnsItem.addOnsId == fixedGstAddOns.SGST.id || addOnsItem.addOnsId == fixedGstAddOns.UTGST.id || addOnsItem.addOnsId == fixedGstAddOns.IGST.id)){
                                addOnsObj.isGst = 1;
                            }

                            addOnsItem.addOnsId == fixedCessAddOns.CESS.id ? addOnsObj.isGst = 2 : '';

                            // sending taxable value to transaction addons as per puran request
                            addOnsObj.fullTaxableValue = self.Form.transactionData.taxableValue;

                            postObj.transactionAddOns.push(addOnsObj);

                            //Passing ledger entry if purchase account selected

                            if(addOnsItem.purchaseAccountItemId && self.passLedgerEntry && self.$root.CurrentBusiness.GSTRegistrationStatus != 3){
                                // if(self.Form.isRcmApplicable){
                                //  ledgerObj.accountId = addOnsItem.saleAccountItemId;

                                //  // Rcm input tax entry
                                //  let ledgerObjRcm = {};
                                //  ledgerObjRcm.amountType = 0//credit;
                                //  ledgerObjRcm.accountId = addOnsItem.purchaseAccountItemId;
                                //  ledgerObjRcm.amount = -addOnsObj.value;
                                //  let rcmData = self.fn_bindAndReturnSimilarValues(ledgerObjRcm);
                                //  postObj.ledgerAccounts.push(rcmData);
                                // }
                                // else{
                                //  ledgerObj.accountId = addOnsItem.purchaseAccountItemId;
                                // }
                                // let data = self.fn_bindAndReturnSimilarValues(ledgerObj);
                                // postObj.ledgerAccounts.push(data);

                                //Passing ledger entry if purchase account selected
                                if(self.$root.CurrentBusiness.GSTRegistrationStatus==1 && !self.Form.isRcmApplicable){
                                    let ledgerObj = {};

                                    if(addOnsItem.nature){
                                        ledgerObj.amountType = 0;//credit
                                        ledgerObj.amount = -addOnsItem.value;
                                    }
                                    else{
                                        ledgerObj.amountType = 1;//debit
                                        ledgerObj.amount = addOnsItem.value;
                                    }

                                    ledgerObj.accountId = addOnsItem.purchaseAccountItemId;
                                    let data = self.fn_bindAndReturnSimilarValues(ledgerObj);
                                    postObj.ledgerAccounts.push(data);
                                }

                                if(self.$root.CurrentBusiness.GSTRegistrationStatus==2 && self.Form.isRcmApplicable){
                                    let ledgerObj = {};

                                    ledgerObj.amountType = 0;//credit
                                    ledgerObj.amount = -addOnsItem.value;

                                    ledgerObj.accountId = addOnsItem.purchaseAccountItemId;
                                    let data = self.fn_bindAndReturnSimilarValues(ledgerObj);
                                    postObj.ledgerAccounts.push(data);
                                }
                            }
                            else{
                                if(addOnsItem.nature){
                                    purchaseLedgerAmount -= addOnsItem.value;
                                }
                                else{
                                    purchaseLedgerAmount += addOnsItem.value;
                                }

                            }
                        }

                    })
                }

                //Inserting
                    // transaction's tds to transactionTdsId
                // if(this.Form.tdsDetails.transactionTdsId){
                if(this.Form.tdsDataModel.transactionTdsId){

                    this.Form.tdsDataModel.amount = this.tdsAmount;

                    // postObj.transactionTds.push(this.Form.tdsDetails);
                    postObj.transactionTds.push(this.Form.tdsDataModel);

                    //Passing ledger entry if purchase account selected
                    if(this.passLedgerEntry){
                        let ledgerObj = {};

                        ledgerObj.amountType = 0//credit;
                        ledgerObj.accountId = this.Form.tdsDataModel.tdsAccountId;
                        ledgerObj.amount = -this.Form.tdsDataModel.amount;
                        let data = this.fn_bindAndReturnSimilarValues(ledgerObj);
                        postObj.ledgerAccounts.push(data);
                    }
                }

                
                if(this.passLedgerEntry){
                    //Inserting
                        // transaction's type entry ledgers to ledger accounts

                    let purchaseLedgerObj = {};
                    purchaseLedgerObj.amountType = 1;//debit

                    if(this.Form.gstType=='IntraState' || this.Form.gstType=='UnionTerritory'){
                        if(this.Form.transactionTypeData.importExport)
                            purchaseLedgerObj.accountId = this.Form.transactionTypeData.importExportTransactionAccountId;
                        else
                            purchaseLedgerObj.accountId = this.Form.transactionTypeData.localTransactionAccountId;
                    }
                    else{
                        if(this.Form.transactionTypeData.importExport)
                            purchaseLedgerObj.accountId = this.Form.transactionTypeData.importExportTransactionAccountId;
                        else
                            purchaseLedgerObj.accountId = this.Form.transactionTypeData.centralTransactionAccountId;
                    }



                    // if(this.Form.taxMode==2){
                    //  purchaseLedgerObj.amount  = purchaseLedgerAmount - this.roundUpto2Decimals(totalGst);
                    // }
                    // else{
                    //  purchaseLedgerObj.amount  = purchaseLedgerAmount;
                    // }

                    // if(this.$root.CurrentBusiness.GSTRegistrationStatus!=3){
                    // if(this.$root.CurrentBusiness.GSTRegistrationStatus==1){
                    // if(this.$root.CurrentBusiness.GSTRegistrationStatus!=2 || this.Form.isRcmApplicable){
                    if(this.$root.CurrentBusiness.GSTRegistrationStatus==1 || this.Form.isRcmApplicable){
                        purchaseLedgerObj.amount  = this.roundUpto2Decimals(purchaseLedgerAmount);
                    }
                    else{
                        // purchaseLedgerObj.amount  = this.roundUpto2Decimals(purchaseLedgerAmount + totalGst);
                        purchaseLedgerObj.amount  = this.roundUpto2Decimals(purchaseLedgerAmount + totalTax);
                    }

                    if(this.$root.CurrentBusiness.GSTRegistrationStatus==2 && this.Form.isRcmApplicable){
                        // purchaseLedgerObj.amount  = this.roundUpto2Decimals(purchaseLedgerAmount + totalGst);
                        purchaseLedgerObj.amount  = this.roundUpto2Decimals(purchaseLedgerAmount + totalTax);
                    }

                    // purchaseLedgerObj.amount  = this.roundUpto2Decimals(purchaseLedgerAmount);

                    let purchaseLedger = this.fn_bindAndReturnSimilarValues(purchaseLedgerObj);
                    postObj.ledgerAccounts.push(purchaseLedger);

                    postObj.transactionLedgers = [];

                    if(!this.Form.transactionData.paymentMode){
                        let partyLedgerObj = {};
                        partyLedgerObj.amountType = 0;//credit
                        partyLedgerObj.accountId = this.Form.selectedParty.itemId;
                        partyLedgerObj.amount = -this.roundedAmount;
                        // partyLedgerObj.amount = -this.roundUpto2Decimals(this.roundedAmount - totalTax);
                        let partyLedger = this.fn_bindAndReturnSimilarValues(partyLedgerObj);
                        postObj.ledgerAccounts.push(partyLedger);   
                    }
                    else{
                        this.fn_generatePaymentEntries(postObj.ledgerAccounts, postObj.transactionLedgers);
                    }
                    

                    // if(this.roundedAmount!=this.totalAmount){
                    // if(this.roundOff){
                    //  // let roundOffAmount = this.roundUpto2Decimals(Math.abs(this.roundedAmount - this.totalAmount));
                    //  let roundOffAmount = this.roundUpto2Decimals(Math.abs(this.roundOff));
                    //  let roundOffLedgerObj = {};
                    //  roundOffLedgerObj.amountType = this.totalAmount > this.roundedAmount ?  0 : 1;//credit/debit
                    //  roundOffLedgerObj.accountId = "9d58ac9d-bf1b-4093-919a-2d68849e6176"; //round off ledgeraccountid //static for now
                    //  roundOffLedgerObj.amount = roundOffLedgerObj.amountType == 0 ? -roundOffAmount : roundOffAmount;
                    //  // roundOffLedgerObj.amount = roundOffAmount;
                    //  let roundOffLedger = this.fn_bindAndReturnSimilarValues(roundOffLedgerObj);
                    //  postObj.ledgerAccounts.push(roundOffLedger);
                    // }

                    // if(this.roundedAmount!=this.totalAmount){
                    if(this.roundOff){
                        // let roundOffAmount = this.roundUpto2Decimals(Math.abs(this.roundedAmount - this.totalAmount));
                        let roundOffAmount = this.roundUpto2Decimals(Math.abs(this.roundOff));
                        let roundOffLedgerObj = {};
                        roundOffLedgerObj.amountType = this.totalAmount > this.roundedAmount ?  0 : 1;//credit/debit
                        roundOffLedgerObj.accountId = "9d58ac9d-bf1b-4093-919a-2d68849e6176"; //round off ledgeraccountid //static for now
                        roundOffLedgerObj.amount = roundOffLedgerObj.amountType == 0 ? -roundOffAmount : roundOffAmount;
                        let roundOffLedger = this.fn_bindAndReturnSimilarValues(roundOffLedgerObj);
                        postObj.ledgerAccounts.push(roundOffLedger);
                    }
                }

                //Swaping amountType from debit to credit and credit to debit in case of purchase return
                if(self.settings.transactionTypeId == transactionTypes.purchaseReturn.id){
                    postObj.ledgerAccounts.forEach(function (item) {
                        item.amount = -item.amount;
                        item.amountType == 1 ? item.amountType = 0 : item.amountType = 1;
                    });
                }

                postObj.discount = this.roundUpto2Decimals(totalDiscount);
                postObj.tax = this.roundUpto2Decimals(totalTax);
                postObj.otherCharges = this.roundUpto2Decimals(totalOtherCharges);

                if(this.Form.partyLastConfig && this.Form.partyLastConfig.purchase){
                    this.Form.partyLastConfig.purchase.taxMode = this.Form.selectedTaxMode.value;
                }
                else{
                    this.Form.partyLastConfig = {};
                    this.Form.partyLastConfig.purchase = {"taxMode":this.Form.selectedTaxMode.value};
                }

                postObj.partyLastConfig = JSON.stringify(this.Form.partyLastConfig);
                
                postObj.fiscalYear = JSON.stringify(this.fn_getFiscalYear(this.Form.transactionData.transactionDate));
                postObj.sortDate = this.$moment(this.Form.transactionData.transactionDate).format('YYYY-MM-DD');

                postObj.onCredit = this.onCreditValue;

                // adding tax in taxable value in case of unregister/composite business
                // if(this.$root.CurrentBusiness.GSTRegistrationStatus != 1){
                //     postObj.taxableValue = this.roundedAmount;
                // }

                postObj.isItcEligible = true;

                postObj.refTId = refTId && this.Form.isNew ? refTId : null;

                postObj.isNew = this.Form.isNew;

                console.log(JSON.stringify(postObj));
                // this.fn_saveLocal(postObj);
                let dataSize = 1024 * 32;
                if(JSON.stringify(postObj).length >= dataSize){
                    this.fn_breakDataIntoChunks(JSON.stringify(postObj))
                }
                else{
                    this.fn_saveLocal(postObj); 
                }
            },

            // fn_bindAndReturnSimilarValues: function (ledgerObj) {
            //  ledgerObj.ledgerAccountId = this.guid();
            //  ledgerObj.transactionListId = this.Form.transactionData.transactionListId;
            //  ledgerObj.transactionDate = this.Form.transactionData.transactionDate;  
            //  return ledgerObj;
            // },

            fn_get: function(itemId, copyData){
                var self = this;
                // this.Form.isNew = false;
                this.getData('Purchase', itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            let data = JSON.parse(response);

                            self.Form.savedItemData = JSON.parse(response);

                            self.fn_bindData(data, copyData);
                        }, 
                        onError: function(error){
                            console.log(error);
                            self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
                            self.fn_goBack();
                        }
                    }
                );
            },

            fn_bindData: function (data) {
                
                this.Form.transactionData.additionalData = data.additionalData;
                this.Form.transactionData.createdBy = data.createdBy;
                this.Form.transactionData.createdDate = data.createdDate;
                // this.Form.transactionData.currencyId = data.currencyId;
                this.Form.transactionData.notes = data.notes;
                // this.Form.partyId = data.partyId;
                // this.Form.partyName = data.partyName;
                this.Form.selectedParty = data.partyId ? {"itemId":data.partyId, "itemName":data.partyName} : null;

                // this.Form.placeOfSupplyId = data.placeOfSupplyId;
                // this.Form.placeOfSupplyName = data.placeOfSupplyName;
                this.Form.placeOfSupplyCountryId = data.placeOfSupplyCountryId;
                this.Form.placeOfSupplyCountryName = data.placeOfSupplyCountryName;

                this.Form.partyLastConfig = data.lastConfig ? JSON.parse(data.lastConfig) : data.lastConfig;

                //getting party details
                // this.fn_getPartyDetails(data.partyId);

                this.Form.transactionData.refDate = data.refDate;
                this.Form.transactionData.refNumber = data.refNumber;
                this.Form.transactionData.status = data.status;
                // this.Form.taxMode = data.taxMode;
                this.Form.selectedTaxMode = {"value":data.taxMode, "title":data.taxMode == 0 ? "None" : data.taxMode == 1 ? "Exclusive" : "Inclusive" };

                this.Form.transactionData.transactionDate = data.transactionDate;
                this.Form.transactionData.transactionId = data.transactionId;
                this.Form.transactionData.prefix = data.prefix;
                this.Form.transactionData.transactionNumber = data.transactionNumber;
                this.Form.transactionData.mergeTransactionNumber = data.mergeTransactionNumber;
                this.Form.transactionData.suffix = data.suffix;

                this.Form.transactionTypeFormId = data.transactionTypeFormId;
                this.Form.transactionTypeFormName = data.transactionTypeFormName;

                this.Form.transactionData.numberSeriesId = data.numberSeriesId;
                this.Form.transactionData.transactionNumberId = data.transactionNumberId;

                !data.transactionProducts ? this.Form.netInvoiceValue = data.netAmount : '';
                this.Form.isRounded = data.isRounded;
                this.Form.roundOffValue = data.roundOffValue;
                // this.Form.itemType = data.itemType;
                this.Form.itemType = data.itemType == 1 ? true : false;
                this.Form.isRcmApplicable = data.isRcmApplicable == 1 ? true : false;
                this.Form.attachments = data.documentDetails ?  data.documentDetails : [];

                this.Form.splitPayment = data.splitPayment;
                this.Form.description = data.description;

                this.Form.paymentTerm = data.paymentTerm;
                if(data.paymentTerm==7){
                    this.Form.selectedPaymentTerm = {"title":"7 Days", "value":7};
                }
                else if(data.paymentTerm==15){
                    this.Form.selectedPaymentTerm = {"title":"15 Days", "value":15};
                }
                else if(data.paymentTerm==21){
                    this.Form.selectedPaymentTerm = {"title":"21 Days", "value":21};
                }
                else if(data.paymentTerm==30){
                    this.Form.selectedPaymentTerm = {"title":"30 Days", "value":30};
                }
                else if(data.paymentTerm==-1){
                    this.Form.selectedPaymentTerm = {"title":"No Due Date", "value":-1};
                }
                else{
                    this.Form.selectedPaymentTerm = {"title":"Custom", "value":null};
                }

                let self = this;

                this.fn_getTransactionTypeData(data.transactionTypeFormId, function (args) {

                    // self.fn_updateState(data.placeOfSupplyId, data.placeOfSupplyName, false);
                    self.Form.selectedState = {"itemId":data.placeOfSupplyId, "itemName":data.placeOfSupplyName};

                    data.partyId ? self.fn_getPartyDetails(data.partyId, true, true) : '';
                    //getting party details
                    // let bindGstAddOns = self.$root.CurrentBusiness.GSTRegistrationStatus != 3 ? true : false;
                    // data.partyId ? self.fn_getPartyDetails(data.partyId, false) : '';
                    
                    console.log(args);

                    self.Form.items = [];

                    let obj = {};
                    obj.transactionProducts = [];

                    if(data.transactionProducts && data.transactionProducts.length){

                        data.transactionProducts.forEach(function (item) {
                    
                            let itemObj = {};
                            itemObj.groupId = item.groupId;
                            itemObj.mrp = item.mrp;
                            itemObj.rate = item.rate;
                            itemObj.description = item.description;
                            itemObj.productId = item.productId;
                            itemObj.productName = item.productName;
                            itemObj.taxValue = 0;
                            itemObj.taxAmount = 0;
                            itemObj.cessValue = 0;
                            itemObj.cessAmount = 0;
                            itemObj.addOnsItems = [];
                            itemObj.batches = [];

                            itemObj.baseTransactionListId = item.baseTransactionListId;
                            itemObj.baseTransactionNumber = item.baseTransactionNumber;

                            itemObj.hsnCode = item.hsnCode;

                            //getting default tax values from product master

                            itemObj.defaultTaxValue = item.defaultTaxValue;
                            itemObj.defaultCessValue = item.defaultCessValue;
                            itemObj.defaultHsnCode = item.defaultHsnCode;
                            itemObj.defaultTaxCategoryId = item.defaultTaxCategoryId;
                            itemObj.defaultTaxCategoryName = item.defaultTaxCategoryName;
                            itemObj.isCessApplicable = item.isCessApplicable;

                            // itemObj.taxCategoryId = item.taxCategoryId;
                            // itemObj.taxCategoryName = item.taxCategoryName;
                            itemObj.selectedTaxCategory = {"taxCategoryId":item.taxCategoryId, "taxCategoryName":item.taxCategoryName}

                            itemObj.productVariants = JSON.parse(JSON.stringify(item.productVariants));

                            for(let l=0; l<itemObj.productVariants.length; l++){
                                itemObj.productVariants[l].selectedUnit = {};
                                itemObj.productVariants[l].selectedUnit.itemId = itemObj.productVariants[l].unitId;
                                itemObj.productVariants[l].selectedUnit.itemName = itemObj.productVariants[l].unitName;
                            }

                            itemObj.selectedBatchType = item.selectedBatchType;

                            // removing order/challan/invoice reference in case of copy
                            if(self.$route.query.copy && item.baseTransactionListId != self.Form.transactionData.transactionListId){
                                item.baseTransactionListId = self.Form.transactionData.transactionListId;
                                item.baseTransactionNumber = self.Form.transactionData.mergeTransactionNumber;
                                itemObj.baseTransactionListId = item.baseTransactionListId;
                                itemObj.baseTransactionNumber = item.baseTransactionNumber;

                                for(let k=0; k<itemObj.productVariants.length; k++){
                                    itemObj.productVariants[k].refTId = null;
                                    itemObj.productVariants[k].refTpId = null;
                                }
                            }

                            let tValue = 0;
                            let tAmount = 0;

                            item.transactionProductAddOns.forEach(function (addOnsItem) {

                                addOnsItem.mode == 1 ? addOnsItem.percentage = null : '';
                                
                                if(addOnsItem.isGst==1){
                                    // itemObj.taxValue += addOnsItem.percentage;
                                    // itemObj.taxAmount += addOnsItem.value;
                                    tValue += addOnsItem.percentage;
                                    tAmount += addOnsItem.value;
                                    // itemObj.hsnCode = addOnsItem.hsnCode;
                                }
                                else if(addOnsItem.isGst==2){
                                    // itemObj.cessValue += addOnsItem.percentage;
                                    // itemObj.cessAmount += addOnsItem.value;
                                    itemObj.cessValue = addOnsItem.percentage != null ? addOnsItem.percentage : null;
                                    itemObj.cessAmount = addOnsItem.value;
                                    // itemObj.hsnCode = addOnsItem.hsnCode;
                                }

                            });

                            itemObj.taxValue = self.roundUpto2Decimals(tValue);
                            itemObj.taxAmount = self.roundUpto2Decimals(tAmount);

                            //doing this as default addons configuration can be changed from transactionType
                            self.Form.productDataModel.addOnsItems.forEach(function (defaultAddOns) {

                                let addOns = null;

                                let itemIndex = item.transactionProductAddOns.findIndex(item=>{
                                    return defaultAddOns.addOnsId == item.addOnsId;
                                });

                                if(itemIndex!=-1){
                                    addOns = JSON.parse(JSON.stringify(item.transactionProductAddOns[itemIndex]));
                                    addOns.appliedOn = defaultAddOns.appliedOn;
                                    addOns.adjustInTotal = defaultAddOns.adjustInTotal;
                                }
                                else{
                                    addOns = JSON.parse(JSON.stringify(defaultAddOns));
                                }

                                addOns ? itemObj.addOnsItems.push(addOns) : '';
                                
                            });

                            itemObj.orderNumber = item.orderNumber;

                            if(item.transactionProductBatches && item.transactionProductBatches.length){
                                for(let i=0; i<item.transactionProductBatches.length; i++){
                                    item.transactionProductBatches[i].productQty = item.transactionProductBatches[i].qty;
                                    itemObj.batches.push(item.transactionProductBatches[i]);
                                }
                            }

                            self.Form.items.push(itemObj);

                        }); 
                    }
                    

                    //adding order and challan items to pending orders and pending challans for data grouping

                    if(self.settings.transactionTypeId == transactionTypes.purchaseInvoice.id || self.settings.transactionTypeId == transactionTypes.purchaseReturn.id || self.settings.transactionTypeId == transactionTypes.purchaseChallan.id){

                        self.Form.isPendingItems = false;

                        self.Form.items.forEach(function (item) {

                            if(item.baseTransactionListId != self.Form.transactionData.transactionListId){

                                if(item.baseTransactionListId == transactionTypes.purchaseOrder.id){
                                    assignPendingItems(self.Form.pendingOrders);
                                }

                                else if(item.baseTransactionListId == transactionTypes.purchaseChallan.id){
                                    assignPendingItems(self.Form.pendingChallans);
                                }

                                else if(item.baseTransactionListId == transactionTypes.purchaseInvoice.id){
                                    assignPendingItems(self.Form.pendingInvoices);
                                }

                            }

                            function assignPendingItems(pendingItems) {
                                
                                self.Form.isPendingItems = true;

                                let itemIndex = pendingItems.findIndex(pItem=>{
                                    return pItem.baseTransactionNumber == item.baseTransactionNumber;
                                });

                                if(itemIndex==-1){
                                    let obj = {};
                                    obj.baseTransactionNumber = item.baseTransactionNumber;
                                    obj.baseTransactionListId = item.baseTransactionListId;
                                    obj.baseTransactionId = item.baseTransactionId;
                                    obj.transactionProducts = [];
                                    obj.transactionProducts.push(item);
                                    pendingItems.push(obj);
                                }
                                else{
                                    pendingItems[itemIndex].transactionProducts.push(item);
                                }
                            }
                            

                        });
                    }

                    self.Form.transactionAddOns = [];

                    if(data.transactionAddOns && data.transactionAddOns.length){

                        data.transactionAddOns.forEach(function (item) {

                            item.mode == 1 ? item.percentage = null : '';

                            let addOnsItem = JSON.parse(JSON.stringify(item));
                            
                            self.Form.transactionAddOns.push(addOnsItem);

                        });

                    }
                    

                    if(data.transactionTds && data.transactionTds.length){
                        // self.Form.tdsDetails = JSON.parse(JSON.stringify(data.transactionTds[0]));
                        self.Form.tdsDataModel = JSON.parse(JSON.stringify(data.transactionTds[0]));
                    }


                    if(data.customFields && data.customFields.length){
                        data.customFields.forEach(function (item) {

                            //manual eway bill insertion
                            if(self.settings.transactionTypeId != transactionTypes.purchaseOrder.id && item.section.sectionId == self.ewayBillDetailSection.sectionId){
                                item.items = [];
                                self.ewayBillDetailFields.forEach(function (eWayField){
                                    item.items.push(eWayField);
                                })  
                            }

                            let customFieldItems = [];

                            for(let i=0; i<self.Form.transactionTypeData.transactionTypeFields.length; i++){

                                let transactionTypeField = self.Form.transactionTypeData.transactionTypeFields[i];

                                for(let j=0; j<item.items.length; j++){

                                    if(transactionTypeField.sectionId == item.section.sectionId && transactionTypeField.fieldId == item.items[j].fieldId){
                                        //assigning display name from transaction type settings in case updated or not
                                        // item.items[j].displayName = transactionTypeField.displayName;
                                        customFieldItems.push(item.items[j]);
                                        break;
                                    }
                                }
                            }

                            item.items = JSON.parse(JSON.stringify(customFieldItems));

                            item.items.forEach(function (fieldItem) {
                                fieldItem.additionalData = JSON.parse(fieldItem.additionalData);

                                //manual eway bill insertion
                                if(self.settings.transactionTypeId != transactionTypes.purchaseOrder.id){
                                    for(let i=0; i<data.transactionFields.length; i++){
                                        if(data.transactionFields[i].fieldId == fieldItem.fieldId){
                                            fieldItem.value = data.transactionFields[i].fieldValue;
                                        }
                                    }
                                }

                            });
                        });

                        self.Form.customFields = JSON.parse(JSON.stringify(data.customFields));
                    }

                    //appending payment modes if applicable
                    if(self.Form.transactionTypeData.paymentMode == 1 && data.transactionLedgers && data.transactionLedgers.length){
                        if(self.Form.splitPayment){
                            self.Form.paymentOptions = JSON.parse(JSON.stringify(data.transactionLedgers)); 
                        }
                        else{
                            self.Form.paymentLedgerId = data.transactionLedgers[0].accountLedgerId;
                        }
                    }

                    // hide/show dynamic menu options
                    for(let i=0; i<self.Form.customFields.length; i++){
                        for(let j=0; j<self.Form.dynamicMenuItems.length; j++){
                            if(self.Form.customFields[i].section.sectionId == self.Form.dynamicMenuItems[j].sectionId){
                                self.Form.dynamicMenuItems[j].hideOption = true;
                                self.$forceUpdate();
                            }
                            else if(self.Form.customFields[i].section.sectionId == self.ewayBillDetailSection.sectionId){
                                self.Form.hideEwayOption = true;
                            }
                        }
                    }

                    self.fn_calculateTax();

                    // removing ids in case of copy transaction
                    if(self.$route.query.copy){
                        self.Form.transactionData.transactionId = null;
                        if(self.Form.attachments && self.Form.attachments.length){
                            for(let i=0; i<self.Form.attachments.length; i++){
                                self.Form.attachments[i].documentId = self.guid();
                            }
                        }

                        if(self.Form.items && self.Form.items.length){
                            for(let i=0; i<self.Form.items.length; i++){
                                let pItem = self.Form.items[i];
                                pItem.groupId = self.guid();

                                if(pItem && pItem.productVariants.length){
                                    for(let i2=0; i2<pItem.productVariants.length; i2++){
                                        pItem.productVariants[i2].transactionProductId = self.guid();
                                    }
                                }
                                
                                if(pItem.transactionProductAddOns && pItem.transactionProductAddOns.length){
                                    for(let j=0; j<pItem.transactionProductAddOns.length; j++){
                                        pItem.transactionProductAddOns[j].transactionProductsAddOnsId = self.guid();
                                        pItem.transactionProductAddOns[j].transactionProductGroupId = pItem.groupId;
                                    }
                                }
                            }
                        }

                        if(self.Form.transactionAddOns && self.Form.transactionAddOns.length){
                            for(let i=0; i<self.Form.transactionAddOns.length; i++){
                                self.Form.transactionAddOns[i].transactionAddOnsId = self.guid();
                            }
                        }

                        self.fn_bindTransactionNumber();
                    }

                    // setTimeout(function(){
                    //     // assigning at the end as making roundOffValue null while calculating net amount;
                    //     self.Form.roundOffValue = data.roundOffValue;
                    //     self.fn_changeIsRounded(self.Form.isRounded);
                    //     // self.$root.fn_copyFormObject(self.Form, self.formId);
                    // },500);

                });

            },

            fn_getSavedGstAddOns: function (groupId, gstAddOnsId) {
                if(!this.Form.isNew && this.Form.savedItemData && this.Form.savedItemData.transactionProducts){
                    let productItem = this.Form.savedItemData.transactionProducts.find(item=>{
                        return item.groupId == groupId;
                    });

                    if(productItem){
                        let gstAddOnsItem = productItem.transactionProductAddOns.find(addOnsItem=>{
                            return addOnsItem.addOnsId == gstAddOnsId && addOnsItem.isGst;
                        });

                        // if true function will break here and will return transactionProductsAddOnsId
                        if(gstAddOnsItem && gstAddOnsItem.transactionProductsAddOnsId)
                            return gstAddOnsItem.transactionProductsAddOnsId;
                    }
                }
                
                return null;
            },

            fn_clearForm: function(){
                this.unwatch = true;
                this.Form = {
                    "transactionData":JSON.parse(JSON.stringify(transactionData)),
                    "partyId":null, "partyName":null, "partylastConfig":null,
                    "currencyId":null, "currencyCode":null, "exchangeRate":null,
                    "taxMode":0,
                    "transactionTypeFormId":null, "transactionTypeFormName":null,

                    "items":[],

                    "productDataModel": {"groupId":null, "productId":null, "productName":null, "mrp":null, "rate":null, "productVariants":[], "taxValue":null, "taxAmount":0, "hsnCode":null, "orderNumber":null, "grossAmount":0, "grossAmountAsPerMRP":0, "netAmount":0, "addOnsItems":[], "cessValue":null, "taxCategoryId": null, "taxCategoryName": null, 
                        "defaultTaxValue":null, "defaultCessValue":null, "defaultHsnCode":null, "defaultTaxCategoryId":null, "defaultTaxCategoryName": null, "isCessApplicable":false, "taxableValue":null
                    },

                    "addOnsDataModel": {"transactionTypeAddOnsId":null, "addOnsId":null, "name":null, "percentage":null, "value":null, "mode":null, "nature":null, "appliedOn":0, "adjustInTotal":true, "orderNumber":null, "addOnsType":null, "type":null},

                    "tdsDataModel":{
                        "transactionTdsId":null, "transactionId":null, "tdsAccountId":null, "tdsAccountName":null, "appliedOnAmount":null, "percentage":null, "amount":null
                    },

                    "transactionAddOns":[],

                    "tdsDetails":{"amount":0},

                    "addOnsProp":{},

                    "itemsSummary":{"grossAmount":0, "grossAmountAsPerMRP":0, "addOnsItems":[], "taxValue":0, "itemsQuanity":[]},

                    "settings":{"isInterState":false},

                    "transactionTypeData":null,
                    "dynamicFields":{"section":null, "items":[], "accountGroupId":null, "accountGroupName":null, "ref":null},

                    "dynamicFieldsAdditionalData":{},

                    "customFields":[],

                    "dynamicMenuItems":[],

                    "partyDetails":null,

                    "gstType":null,

                    "defaultTaxAddOns":[],

                    "gstAddOns":[],
                    "cessAddOns":null,

                    "savedItemData":null,
                    "isNew":true,

                    "pendingItemsList":null,

                    "pendingOrders":[],
                    "pendingChallans":[],
                    "pendingInvoices":[],
                    "isPendingItems":false,
                    "transactionTypeList":[],
                    "draftItems":[],
                    "hideEwayOption": false,
                    "placeOfSupplyId":null,
                    "placeOfSupplyName":null,
                    "placeOfSupplyCountryId":null,
                    "placeOfSupplyCountryName":null,
                    // "partyGstType":null,
                    "netInvoiceValue":null,
                    "isRounded":true,
                    "itemType":0,
                    "isRcmApplicable":false
                };

                this.fn_initializeSettings();

                this.fn_getTransactionTypeList(true);
            },

            // fn_saveToLocalStorage: function(){
            //  if(!this.unwatch){
            //      var dataObj = {};
            //      dataObj.type = this.toolbarTitle;//formName
            //      dataObj.formType = "new";//eliminate
            //      dataObj.modifiedDate = new Date();
            //      dataObj.formId = this.formId;
            //      dataObj.draftId = this.draftStorageId;
            //      dataObj.uri = this.$route.path;//formPath
            //      dataObj.dataModel = JSON.parse(JSON.stringify(this.Form));//data
            //      dataObj.dataModel.name = this.Form.partyName ? this.Form.partyName : "no name";

            //      localStorage.setItem(this.draftStorageId, JSON.stringify(dataObj));
            //  }
            //  this.unwatch = false;
            // },

            fn_menuItemClick: function(tagId, menuId, menuAction){

                this.backButtonPress = false;
                if(menuAction.indexOf('section_')!=-1){
                    let sectionId = menuAction.substring(menuAction.indexOf('_')+1);
                    this.fn_bindDynamicFields(sectionId);
                }
                else if(menuAction=='save'){
                    this.fn_checkValidations();
                }
                else if(menuAction=='newledger'){
                    //Force save to local storage as dynamic fields need to be save in local storage
                    this.fn_saveDraft(this.Form.partyName);
                    this.$router.push({path:'/masters/accounts/ledgers/items/new', query:{tagId:this.guidWeb(), ls:1, type:'ledger'}})
                }
                else if(menuAction=='newsupplier'){
                    this.$router.push({path:'/masters/accounts/ledgers/items/new', query:{tagId:this.guidWeb(), groupTypeId: ledgerGroupTypeId.supplier, ls:1, type:'supplier'}})
                }
                else if(menuAction=='dynamicAccountField'){
                    this.fn_newDynamicAccount()
                }
                else if(menuAction=='newproduct'){
                    this.$router.push({path:'/masters/inventory/productandservices/items/new', query:{tagId:this.guidWeb(), ls:1}})
                }
                else if(menuAction=='newListItem'){
                    //Force save to local storage as dynamic fields need to be save in local storage
                    this.fn_saveDraft(this.Form.partyName);
                    this.$router.push({path:'/masters/lists/'+this.Form.dynamicFieldsAdditionalData.listId+'/items/new', query:{tagId:this.guidWeb(), listTitle:this.Form.dynamicFieldsAdditionalData.listName, ls:1, type:'listItem'}});
                }
                else if(menuAction=='reset'){
                    this.fn_resetForm();
                }
                else if(menuAction=='addnote'){
                    this.fn_addNote();  
                }
                // else if(menuAction=='attachdocuments'){
                //  if(!this.$root.CurrentBusiness.DataDrive){
                //      alert("Set up Backup Drive to attach documents");
                //      return;
                //  }
                //  this.fn_uploadFiles();
                // }
                else if(menuAction=='camera' || menuAction=='fileManager'){
                    let self = this;
                    this.$root.fn_checkStoragePermission(function(){
                        menuAction=='camera' ? self.fn_captureImage() : self.fn_uploadFiles(); 
                    });
                }
                else if(menuAction=='applytds'){
                    this.fn_openTdsForm();
                }
                else if(menuAction=='addonsmenu'){
                    this.fn_openAddOns(null, true);
                }
                // else if(menuAction=='addOns'){
                //  this.fn_getBottomMenuAddOns();
                // }
                else if(menuAction=='adddiscount'){
                    this.$router.push({path:'/addons/discounts/new', query:{tagId:this.guidWeb(), ls:1}});
                }
                else if(menuAction=='addtaxes'){
                    this.$router.push({path:'/addons/taxes/new', query:{tagId:this.guidWeb(), ls:1}});
                }
                else if(menuAction=='addothercharges'){
                    this.$router.push({path:'/addons/othercharges/new', query:{tagId:this.guidWeb(), ls:1}});
                }
                else if(menuAction=='loadpurchaseorder'){
                    this.fn_loadItems(transactionTypes.purchaseOrder.id, this.Form.pendingOrders, 'ref_dialogOrders');
                }
                else if(menuAction=='loadpurchasechallan'){
                    this.fn_loadItems(transactionTypes.purchaseChallan.id, this.Form.pendingChallans, 'ref_dialogChallans');
                }
                else if(menuAction=='loadpurchaseinvoice'){
                    this.fn_loadItems(transactionTypes.purchaseInvoice.id, this.Form.pendingInvoices, 'ref_dialogInvoices');
                }
                // else if(menuAction=='cancel'){
                //  this.fn_openDialogCancelTransaction();
                // }
            },

            fn_newDynamicAccount: function(index, sectionId){
                let groupTypeId = null;
                if(this.Form.dynamicFields.accountGroupId == accountGroupIds.debtors.id)
                    groupTypeId = 101;
                else if(this.Form.dynamicFields.accountGroupId == accountGroupIds.creditors.id || this.Form.dynamicFields.accountGroupId == accountGroupIds.transporter.id)
                    groupTypeId = 102;
                else if(this.Form.dynamicFields.accountGroupId == accountGroupIds.brandAndDivision.id)
                    groupTypeId = 104;
                else
                    groupTypeId = 103;

                // setting up default account group to be selected
                let data = {};
                data.type = 'accountGroup';
                data.id = this.Form.dynamicFields.accountGroupId;
                data.name = this.Form.dynamicFields.accountGroupName;
                localStorage.setItem('newItem', JSON.stringify(data));

                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/ledger/items/new', query:{tagId:this.guidWeb(), groupTypeId:groupTypeId, ls:1, type:'ledger', itemIndex:index, sectionId:sectionId}});

                // this.$router.push({
                //     path:'/masters/accounts/ledgers/items/new', 
                //     query:{
                //         tagId:this.guidWeb(), 
                //         groupTypeId: groupTypeId, 
                //         ls:1
                //     }
                // })
            },

            // fn_openDialogCancelTransaction: function(){
            //  this.confirmationMessage = "Cancelled transaction cannot be revoke."
            //  this.fn_openDialogWeb('web', 'Cancel Transaction', 'ref_dialogCancelTransaction');
            // },

            // fn_openDialogPendingItemsList: function (ref) {
            //     this.fn_openDialogWeb('web', 'PendingItemsList', ref);
            // },

            fn_openDialogReturnForm: function (ref) {
                this.fn_openDialogWeb('web', 'ReturnForm', ref);
            },

            fn_bindDynamicFields: function (sectionId) {

                this.Form.dynamicFields = {"section":null, "items":[], "additionalData":{}, "accountGroupId":null, "accountGroupName":null};

                let itemIndex = this.Form.customFields.findIndex(item=>{
                    return item.section.sectionId == sectionId;
                });

                this.Form.dynamicFields.section = this.Form.transactionTypeData.transactionSections.find(item=>{
                    return sectionId == item.sectionId;
                });

                this.Form.dynamicFields.items = this.Form.transactionTypeData.transactionTypeFields.filter(item=>{
                    if(sectionId == item.sectionId){
                        item.additionalData = typeof(item.additionalData) == 'string' ? JSON.parse(item.additionalData) : item.additionalData;
                        item.value = null;
                        item.listItemId = null;
                        item.accountLedgerId = null;

                        item.selectedAccountItem = null;
                        item.selectedListItem = null;

                        item.accountItems = [];
                        item.listItems = [];

                        if(item.fieldType == 'yes/no'){
                            item.value = item.additionalData.defaultValue;
                        }

                        if(item.fieldType == 'choice'){
                            item.value = item.additionalData.defaultChoice;
                            // item.value = item.additionalData.choices[0];
                        }

                        // if(item.fieldType == 'dateTime'){
                        //  item.value = new Date();
                        // }
                        
                        return true;
                    }
                });

                if(itemIndex!=-1){

                    for(let i=0; i<this.Form.dynamicFields.items.length; i++){

                        let defaultField = this.Form.dynamicFields.items[i];

                        for(let j=0; j<this.Form.customFields[itemIndex].items.length; j++){

                            let existingField = this.Form.customFields[itemIndex].items[j];

                            if(defaultField.fieldId == existingField.fieldId){
                                defaultField.value = existingField.value;

                                // if(defaultField.fieldType == 'linkedItem'){
                                //  defaultField.listItemId = existingField.listItemId;
                                // }
                                // else if(defaultField.fieldType == 'accountItem'){
                                //  defaultField.accountLedgerId = existingField.accountLedgerId;
                                // }
                                if(defaultField.fieldType == 'linkedItem'){
                                    defaultField.listItemId = existingField.listItemId;

                                    defaultField.selectedListItem = {"itemId":existingField.listItemId, "itemName":existingField.value};

                                    defaultField.listItems = [];                             
                                }
                                else if(defaultField.fieldType == 'accountItem'){

                                    // alert(JSON.stringify(existingField));
                                    
                                    defaultField.accountLedgerId = existingField.accountLedgerId;

                                    if(existingField.selectedAccountItem){
                                        defaultField.selectedAccountItem = existingField.selectedAccountItem;    
                                    }
                                    else{
                                        defaultField.selectedAccountItem = {"itemId":existingField.accountLedgerId, "itemName":existingField.value};   
                                    }
                                    // defaultField.selectedAccountItem = {"itemId":existingField.accountLedgerId, "itemName":existingField.value};
                                    // defaultField.selectedAccountItem = existingField.selectedAccountItem;

                                    defaultField.accountItems = [];

                                    this.$refs['ref_dialogDynamicFields'].fn_openAccountLedgers(defaultField)
                                }

                            }

                        }

                    }

                }
                

                console.log(JSON.stringify(this.Form.dynamicFields));

                // this.fn_openDialogDynamicFields();
                this.$refs['ref_dialogDynamicFields'].openDialogWeb();
            },

            fn_openDialogDynamicFields: function () {
                this.fn_openDialogWeb('web', 'DynamicFields', 'ref_dialogDynamicFields');
            },

            fn_resetForm: function () {
                this.unwatch = true;
                // this.loadedFromDraft = false;
                
                if(this.Form.transactionData.transactionId){
                    this.fn_get(this.Form.transactionData.transactionId);
                }
                else{
                    this.fn_clearForm();
                }

                let self = this;
                this.fn_removeDraft(this.draftStorageId, function(){
                    // self.snackbar = true;
                    // self.snackbar_msg = "Item deleted from draft";
                    // alert("Item deleted from draft");
                    self.$eventHub.$emit('snackbarAlert', {msg:"Item deleted from draft"});
                });
            },

            fn_initializeSettings: function () {
                // let self = this;
                this.formTypeName = "Purchase";
                transactionForms.filter(item => {
                    if(item.id == this.settings.transactionTypeId){
                        this.unwatch = true;
                        this.toolbarTitle = item.title;
                        this.Form.transactionData.transactionListId = this.settings.transactionTypeId;
                        // this.Form.transactionData.transactionDate = new Date();
                        // this.Form.transactionData.refDate = new Date();
                        if(new Date().getTime() < new Date(this.$root.CurrentBusiness.OpeningDate).getTime()){
                            this.Form.transactionData.transactionDate = this.$root.fn_getLocalDateFormat(this.$root.CurrentBusiness.OpeningDate);
                            // this.Form.transactionData.refDate = this.$root.fn_getLocalDateFormat(this.$root.CurrentBusiness.OpeningDate);
                        }
                        else{
                            this.Form.transactionData.transactionDate = this.$root.fn_getLocalDateFormat();
                            // this.Form.transactionData.refDate = this.$root.fn_getLocalDateFormat();
                        }
                    }
                });

                let typeId = this.settings.transactionTypeId;

                if(typeId == transactionTypes.purchaseChallan.id || typeId == transactionTypes.purchaseInvoice.id 
                    || typeId == transactionTypes.purchaseReturn.id){
                    this.passStockEntry = true;
                }

                if(typeId == transactionTypes.purchaseInvoice.id || typeId == transactionTypes.purchaseReturn.id){
                    this.passLedgerEntry = true;
                }

                if(this.$route.params.tagId){
                    // this.fn_products('');
                    this.fn_addOns('');
                }

                // this.fn_getDefaultGstAddOns();
                // this.fn_getDefaultCessAddOns();
                // this.fn_getTaxCategories();

                // this.fn_accountLedgers('', ledgerGroupTypeId.supplier);
                // this.fn_products('');
                // this.fn_addOns('');
                // this.fn_getDefaultGstAddOns();
                // this.fn_getDefaultCessAddOns();
                // this.fn_getTaxCategories();
            },

            fn_addNote: function () {
                this.$refs['ref_dialogNote'].noteValue = this.Form.transactionData.notes;
                this.fn_openDialogWeb('web', 'Note', 'ref_dialogNote');
                setTimeout(function(){
                    let noteField = document.getElementById('noteField');
                    noteField.focus();
                });
            },

            fn_saveNote: function () {
                this.Form.transactionData.notes = this.$refs['ref_dialogNote'].noteValue;
                this.fn_closeDialog();
            },

            // fn_showWebView: function () {
            //  var viewObject = {};

            //  var tagId = this.formId;
            //  var self = this;
            //  this.showWebView(tagId, viewObject, 
            //      {
            //          onSuccess: function(response){
            //            console.log(response);
            //          }, 
            //          onError: function(error){
            //            console.log(error);
            //          }, 
            //          // onChange: null,
            //          onMenuItemClick: function (tagId, menuId, menuAction) {
            //              self.fn_menuItemClick(tagId, menuId, menuAction)
            //          }
            //      }
            //  );
            // },

            // fn_setFormDataFromLocalStorage: function () {
            //  if(localStorage.getItem(this.draftStorageId)){
            //      var formData = JSON.parse(localStorage.getItem(this.draftStorageId));
            //      this.unwatch = true;
            //      this.Form = JSON.parse(JSON.stringify(formData.dataModel));

            //      if((this.$route.query && this.$route.query.draft) || (this.$route.params && this.$route.params.tagId)){
            //          this.loadedFromDraft = true;
            //          this.Form.isNew ? this.fn_getTransactionTypeData(this.Form.transactionTypeFormId, null, false, 'draft') : '';
            //      }
            //  }
            // },

            fn_refreshTransactionTypeData: function(transactionTypeData){

                this.fn_bindTransactionNumber();

                // if(this.Form.partyId && !transactionTypeData.isAllPartySelected){
                //  this.fn_validateParty(transactionTypeData.transactionParties);
                // }

                if(this.Form.selectedParty && this.Form.selectedParty.itemId){
                    this.fn_validateParty(transactionTypeData.transactionParties);
                }

                // this.Form.productDataModel.addOnsItems = [];

                // transactionTypeData.transactionTypeAddOns.forEach(item => {
                //     if(item.type==0){
                //         this.Form.productDataModel.addOnsItems.push(JSON.parse(JSON.stringify(item)));
                //     }
                // });


                // for(let i=0; i<this.Form.items.length; i++){

                //     let addOnsItems = [];

                //     for(let j=0; j<transactionTypeData.transactionTypeAddOns.length; j++){

                //         if(transactionTypeData.transactionTypeAddOns[j].type==0){

                //             for(let k=0; k<this.Form.items[i].addOnsItems.length; k++){
                //                 if(this.Form.items[i].addOnsItems[k].addOnsId == transactionTypeData.transactionTypeAddOns[j].addOnsId){
                //                     addOnsItems.push(this.Form.items[i].addOnsItems[k]);
                //                 }
                //             }

                //         }

                //     }

                //     this.Form.items[i].addOnsItems = JSON.parse(JSON.stringify(addOnsItems));
                // }

                //inserting eway bill details to transaction type fields manually
                this.fn_bindEwayBillSection();

                // this.hideLoading();
                this.loading = false;
            },

            fn_setNewItem: function(){
                let itemData = null;

                if(localStorage.getItem('newItem')){

                    itemData = JSON.parse(localStorage.getItem('newItem'));

                    let product = {};
                    let addon = {};
                    let dataObj = {};

                    switch (itemData.type) {
                        case "transactionTypeForm":
                            this.fn_setTransactionType({transactionTypeFormId:itemData.id, name:itemData.name});
                            break;
                        case "supplier":
                            this.fn_updateSupplier(itemData.id, itemData.name, false);
                            break;
                        case "product":
                            // this.fn_setNewProduct(itemData.id, itemData.name, false);
                            product.itemId = itemData.id;
                            product.itemName = itemData.name;
                            this.$refs['ref_dialogForm'].fn_updateProduct(product, true);
                            // this.$refs['ref_dialogProduct'].fn_updateProduct(itemData.id, itemData.name, false);
                            break;
                        case "taxCategory":
                            // this.fn_updateTaxCategory(itemData, itemData.itemIndex);
                            this.fn_updateTaxCategory(itemData, itemData.itemGroupId);
                            break;
                        case "discounts":
                        case "taxes":
                        case "otherCharges":
                            addon.itemId = itemData.id;
                            addon.itemName = itemData.name;
                            addon.addonGroupId = itemData.addonGroupId;
                            this.$refs['ref_dialogForm'].fn_updateAddOns(addon);
                            // this.fn_setNewAddOns(itemData.id, itemData.name, false);
                            // this.$refs['ref_dialogAddOns'].fn_updateAddOns(itemData.id, itemData.name, false);
                            break;
                        case "listItem":
                            this.$refs['ref_dialogDynamicFields'].fn_updateLinkedItem(itemData.id, itemData.name, false)
                            break;
                        case "expenseLedger":
                            this.fn_updateAccountLedger(itemData.id, itemData.name, false);
                            this.ledgerType = 'ledger';
                            break;
                        case "ledger":
                            dataObj.itemId = itemData.id;
                            dataObj.itemName = itemData.name;
                            if(itemData.tds){
                                this.$refs['ref_dialogForm'].fn_updateTdsAccount(dataObj);
                            }
                            else if(itemData.sectionId){
                                // this.Form.dynamicFields.items[itemData.itemIndex].selectedAccountItem = {"itemId":itemData.id, "itemName":itemData.name};
                                // this.fn_bindDynamicFields(itemData.sectionId);
                                this.$refs['ref_dialogDynamicFields'].openDialogWeb();
                                setTimeout(()=>{
                                    this.$refs['ref_dialogDynamicFields'].fn_openAccountLedgers(this.Form.dynamicFields.items[itemData.itemIndex], itemData);
                                },200)
                            }
                            // else{
                            //     this.$refs['ref_dialogDynamicFields'].fn_updateAccountLedger(itemData.id, itemData.name, false, null, itemData.accountGroupId)
                            // }
                            break;
                        case "expenseCategory":
                            this.fn_updateExpenseCategory(itemData.id, itemData.name, false)
                            break;
                        case "ledger_other":
                            this.fn_openDialog_PaymentOptions();
                            this.fn_getPaymentGroups();
                            break;
                            
                        default:
                            console.log("condition not matched");
                    }

                    localStorage.removeItem('newItem');
                }

                // if(this.$route.query && this.$route.query.dt && !itemData){
                //     this.fn_updateSupplier(this.$route.query.id, this.$route.query.name, false);
                // }
            },

            fn_calculateNetAmount: function(){
                let netAmount = 0;
                let grossAmount = 0;
                let grossAmountAsPerMRP = 0;
                let taxValue = 0;
                let cessValue = 0;
                let taxableValue = 0;
                let self = this;

                this.Form.itemsSummary.addOnsItems = [];
                this.Form.itemsSummary.itemsQuanity = [];

                this.Form.items.forEach(function (item) {
                    grossAmount += item.grossAmount;
                    grossAmountAsPerMRP += item.grossAmountAsPerMRP;

                    taxableValue += item.taxableValue;

                    item.addOnsItems.forEach(function (addOnsItem) {

                        if(addOnsItem.value!==null && addOnsItem.value!==''){
                            let value = addOnsItem.value;

                            let itemIndex = self.Form.itemsSummary.addOnsItems.findIndex(onsItem=>{
                                return onsItem.addOnsId == addOnsItem.addOnsId;
                            })

                            if(itemIndex!=-1){
                                let addOnsSum = self.Form.itemsSummary.addOnsItems[itemIndex].value;
                                addOnsSum += value;
                                self.Form.itemsSummary.addOnsItems[itemIndex].value = self.roundUpto2Decimals(addOnsSum);
                            }
                            else{
                                let obj = {};
                                obj.addOnsId = addOnsItem.addOnsId;
                                obj.name = addOnsItem.name;
                                obj.value = addOnsItem.value;
                                obj.mode = addOnsItem.mode;
                                obj.nature = addOnsItem.nature;
                                self.Form.itemsSummary.addOnsItems.push(obj);
                            }
                        }

                    })

                    if(self.Form.selectedTaxMode.value!=null){
                        taxValue += item.taxAmount;
                        cessValue += item.cessAmount;
                    }

                    netAmount += item.netAmount;

                    item.productVariants.forEach(function (variantItem) {

                        let qty = variantItem.qty ? variantItem.qty : 0;

                        console.log(variantItem);

                        let itemIndex = self.Form.itemsSummary.itemsQuanity.findIndex(vItem=>{
                            console.log(vItem);
                            return vItem.unitId == variantItem.unitId && vItem.packQuantity == variantItem.packQuantity;
                        });

                        if(itemIndex!=-1){
                            self.Form.itemsSummary.itemsQuanity[itemIndex].qty += qty;
                        }
                        else{
                            let vObj = {};
                            vObj.unitId = variantItem.unitId;
                            vObj.unitName = variantItem.unitName;
                            vObj.packQuantity = variantItem.packQuantity;
                            vObj.qty = qty;
                            self.Form.itemsSummary.itemsQuanity.push(vObj);
                        }
                    })

                });

                this.Form.itemsSummary.grossAmount = !this.Form.items.length ? this.Form.netInvoiceValue : this.roundUpto2Decimals(grossAmount);
                this.Form.itemsSummary.grossAmountAsPerMRP = !this.Form.items.length ? this.Form.netInvoiceValue : this.roundUpto2Decimals(grossAmountAsPerMRP);
                this.Form.itemsSummary.taxValue = this.roundUpto2Decimals(taxValue); 
                this.Form.itemsSummary.cessValue = this.roundUpto2Decimals(cessValue);

                // if(this.Form.items.length){
                //  this.Form.transactionData.taxableValue = taxableValue;
                // }
                // else{
                //  if(this.$root.CurrentBusiness.GSTRegistrationStatus==1){
                //      this.Form.transactionData.taxableValue = this.Form.netInvoiceValue;
                //  }
                //  else{
                //      this.Form.transactionData.taxableValue = this.roundedAmount;
                //  }
                // }
                this.Form.transactionData.taxableValue = !this.Form.items.length ? this.Form.netInvoiceValue : taxableValue;

                console.log(JSON.stringify(this.Form.itemsSummary));

                // this.Form.roundOffValue = null;

                // let netAmountAsPerType = null;
                // if(this.Form.items.length){
                //  netAmountAsPerType = this.roundUpto2Decimals(netAmount);    
                // }
                // else{
                //  netAmountAsPerType = this.roundUpto2Decimals(this.Form.netInvoiceValue);
                // }

                // if(this.Form.isRcmApplicable){
                //  return this.roundUpto2Decimals(netAmountAsPerType - taxValue - cessValue);  
                // }
                // else{
                //  return netAmountAsPerType;
                // }

                if(this.Form.items.length){
                    // putting these hack in case of rcm update just to recompute values
                    if(this.Form.isRcmApplicable){
                        return this.roundUpto2Decimals(netAmount);  
                    }
                    else{
                        return this.roundUpto2Decimals(netAmount);  
                    }
                }
                else{
                    return this.roundUpto2Decimals(this.Form.netInvoiceValue);
                }
            },

            fn_calculateRoundOffValue: function(){
                let roundAmount = null;
                if(this.Form.isRounded){
                    roundAmount = Math.round(this.totalAmount);
                }
                else{
                    this.Form.roundOffValue = null;
                    roundAmount = this.totalAmount;
                }

                if(this.Form.roundOffValue){
                    return this.Form.roundOffValue;
                }
                else{
                    return this.roundUpto2Decimals(roundAmount - this.totalAmount);
                }
            },

            // fn_updateLinkedItem: function(itemData){
            //  let self = this;
            //  setTimeout(function(){
            //      self.$refs['ref_dialogDynamicFields'].fn_updateLinkedItem(itemData.id, itemData.name, false)
            //  },1000)
            // },

            // fn_updateAccountLedger: function(itemData){
            //  let self = this;
            //  setTimeout(function(){
            //      self.$refs['ref_dialogDynamicFields'].fn_updateAccountLedger(itemData.id, itemData.name, false)
            //  },1000)
            // },

            fn_showSnackBar: function(msg, timeout){
                let self = this;
                setTimeout(function(){
                    // self.snackbar = true;
                    // self.snackbar_msg = msg;
                    // alert(msg);
                    self.$eventHub.$emit('snackbarAlert', {msg:msg});
                },timeout);
            },

            fn_openTdsAccounts: function () {
                this.tdsLedgers = this.$root.ledgers.filter(item => {
                    return item.accountGroupId == accountGroupIds.tdsPayable.id;
                })
                // console.log(this.$parent.tdsLedgers);
                setTimeout(() => {
                    this.fn_focusElement('tds_search');
                }, 100);
            },

            fn_appendBatches: function(batches, productData){
                if(batches.length){
                    // for(let i=0; i<batches.length; i++){
                    // 	if(!this.Form.productData.items[0].batches || !this.Form.productData.items[0].batches.length){
                    // 		this.Form.productData.items[0].batches = [];
                    // 	}
                    // 	let bIndex = this.Form.productData.items[0].batches.findIndex(bat=>{return bat.batchId === batches[i].batchId});
                    // 	if(bIndex != -1){
                    // 		this.Form.productData.items[0].batches[bIndex] = JSON.parse(JSON.stringify(batches[i]));
                    // 	}
                    // 	else{
                    // 		this.Form.productData.items[0].batches.push(JSON.parse(JSON.stringify(batches[i])));
                    // 	}
                    // 	this.$forceUpdate();
                    // }
                    this.$set(productData, 'batches', JSON.parse(JSON.stringify(batches)));
                    // this.Form.productData.items[0].batches = JSON.parse(JSON.stringify(batches));
                    let totalQty = 0;
                    let bat = null;
                    if(this.Form.selectedBatchType == 2){
                        bat = batches.filter(field=>{
                            return !field.batchNumber && !field.batchMRP && !field.purchaseRate && !field.batchSize && !field.mfgDate && !field.expDate && !field.field4
                        })

                        if(productData){
                            if(productData.productVariants[0].selectedUnit){
                                productData.productVariants[0].selectedUnit = {"itemId":productData.productVariants[0].primaryUnitId, "itemName":productData.productVariants[0].primaryUnitName}
                            }
                        }
                    }
                    else{
                        bat = batches.filter(field=>{
                            return !field.field1 && !field.field2 && !field.field3
                        })
                    }
                    
                    for(let i=0; i<bat.length; i++){
                        totalQty += Number(bat[i].productQty);
                    }
                    // this.Form.productData.items[0].productVariants[0].qty = Number(totalQty);
                    this.$set(productData.productVariants[0], 'qty', Number(totalQty));
                }
                this.$refs['ref_dialogSelectBatch'].closeDialogWeb();

                // this.fn_addItem(this.Form.productDataModel.productVariants[0].qty);
                if(this.bType == 'new'){
                    this.fn_addItem(productData.productVariants[0].qty);
                }
                else{
                    this.$refs['ref_dialogForm'].fn_computeValues(productData);
                }
            },
            fn_fieldVisibilty: function(field, selectedBatchType){
                if(field.status){
                    if(selectedBatchType == 2){
                        if(field.id == 'field1' || field.id == 'field2' || field.id == 'field3'){
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                    else{
                        if(field.id == 'batchNumber' || field.id == 'batchMRP' || field.id == 'purchaseRate' || field.id == 'batchSize' || field.id == 'mfgDate' || field.id == 'expDate' || field.id == 'opening' || field.id == 'field4'){
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                }
                else{
                    return false
                }
            },

            fn_openDialogBatchConfirmation: function(){
                this.confirmationHeader = "Remove Batch";
                this.confirmationMessage = "Are you sure you want to remove all batches?";
                this.confirmationAction = this.$refs['ref_dialogSelectBatch'].fn_removeSelectedItemBatches;
                this.$refs['ref_dialogConfirmation'].openDialogWeb();
            },

            fn_goBack: function(){
                this.$refs['ref_dialogForm'].closeDialogWeb();
                localStorage.removeItem(this.draftStorageId);
                this.backButtonPress = true;
                this.$root.viaKeyboardShortCut = false;
                setTimeout(() => {
                    if(this.nextRoute.path){
                        this.nextRoute.query ? this.$router.replace({path:this.nextRoute.path, query:this.nextRoute.query}) : this.$router.replace({path:this.nextRoute.path});
                    }
                    else{
                        this.$router.go(-1);
                    }
                }, 50);
            },

            fn_beforeRouteEnter: function(vm, to, from){
                let itemId = null;

                if(to.params && to.params.tagId){//from display view
                    vm.Form.isNew = false;
                    itemId = to.params.tagId;
                    vm.formId = to.params.tagId;
                    vm.draftStorageId = 'draft_transaction_'+vm.formId;
                }
                else if(to.query && to.query.tagId){
                    vm.formId = to.query.tagId;
                    vm.draftStorageId = 'draft_transaction_'+vm.formId;
                }

                vm.fn_initializeSettings();

                // posting metrics on save
                let postObj = {"eventName":vm.toolbarTitle.toLowerCase() + '_desktop_open',"eventData":[{"eventKey":"form_type","eventValue":vm.$route.params && vm.$route.params.tagId && !vm.$route.query.copy ? "edit" : "new"}]}
                vm.$root.fn_postMetrics(postObj);

                if(localStorage.getItem(vm.draftStorageId)){
                    let dataObj = JSON.parse(localStorage.getItem(vm.draftStorageId));
                    vm.Form = JSON.parse(dataObj.formData);

                    if(vm.Form.isNew){
                        vm.fn_getTransactionTypeData(vm.Form.transactionTypeFormId, null, false, 'draft');
                    }
                    else{
                        if(from.name == 'purchaseInvoiceDisplay' || from.name == 'purchaseOrderDisplay' || from.name == 'purchaseChallanDisplay' || from.name == 'purchaseReturnDisplay'){
                            vm.fn_openDialogDraftConfirmation();
                        }
                        vm.loading = false;
                    }
                }

                let isDraft = localStorage.getItem(vm.draftStorageId) ? true : false;

                let setTFT = !itemId && !isDraft;

                setTFT ? vm.loading = false : '';

                vm.fn_getTransactionTypeList(setTFT);
            }
        },
        mounted: function(){

            // this.fn_initializeSettings();

            // if(this.$route.query && this.$route.query.tagId){//in case of new form
            //     this.formId = this.$route.query.tagId;
            //     this.draftStorageId = 'draft_transaction_'+this.formId;
            // }
            // else if(this.$route.params && this.$route.params.tagId){//in case of edit form
            //     this.formId = this.$route.params.tagId;
            //     this.draftStorageId = 'draft_transaction_'+this.formId;
            // }

            // if(localStorage.getItem(this.draftStorageId)){
            //     let dataObj = JSON.parse(localStorage.getItem(this.draftStorageId));
            //     this.Form = JSON.parse(dataObj.formData);

            //     if(this.Form.isNew){
            //         this.fn_getTransactionTypeData(this.Form.transactionTypeFormId, null, false, 'draft');
            //     }
            //     else{
            //         this.loading = false;
            //     }
            // }

            // setTimeout(()=>{
            //     this.fn_setNewItem();
            // },300);

            this.$refs['ref_dialogForm'].openDialogWeb();

            this.$eventHub.$on('select_party', () => {
                let element = document.getElementById("party_dropdown");
                element.focus();
                element.click();
            });

            this.$eventHub.$on('select_item', () => {
                let element = document.getElementById("fieldproducts");
                element.focus();
                element.click();
            });

            this.$eventHub.$on('save', () => {
                this.$refs['ref_dialogForm'].fn_validateForm();
            });

            this.$eventHub.$on('escape', () => {
                this.fn_closeDialogForm();
            });
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {

                let isCalled = false;

                // reloading form if connection got disconnected and reconnected again before loading all resources
                vm.$eventHub.$on('connected', () => {
                    setTimeout(() => {
                        if(!isCalled){
                            isCalled = true;
                            vm.saveBtnKey++;
                            vm.disableOnSave = false;
                            vm.loading = false;

                            // vm.getDataCounter < 7 ? vm.fn_beforeRouteEnter(vm, to, from) : '';
                            vm.getDataCounter < 5 ? vm.fn_beforeRouteEnter(vm, to, from) : '';

                            if(vm.Form.selectedParty && vm.Form.selectedParty.itemName && !vm.Form.selectedState){
                                vm.fn_updateSupplier(vm.Form.selectedParty.itemId, vm.Form.selectedParty.itemName);
                            }

                            if(vm.$refs['ref_dialogForm'] && vm.$refs['ref_dialogForm'].selectedItem){
                                vm.$refs['ref_dialogForm'].fn_updateProduct(vm.$refs['ref_dialogForm'].selectedItem);
                            }
                        }

                        setTimeout(() => {
                            isCalled = false;
                        }, 3000);
                        
                    }, 1000);
                });

                vm.fn_beforeRouteEnter(vm, to, from);

            })
        },
        beforeRouteLeave (to, from, next) {
           if(this.$root.viaKeyboardShortCut){
                this.nextRoute.path = to.path;
                this.nextRoute.query = to.query;
                this.fn_closeDialogForm();
            }
            else{
                this.nextRoute.path = null;
                this.nextRoute.query = null;
                if(!this.backButtonPress){
                    let name = this.Form.selectedParty ? this.Form.selectedParty.itemName : '';
                    this.fn_saveDraft(this.draftStorageId, name);
                }
                this.$eventHub.$off('save');
                this.$eventHub.$off('select_party');
                this.$eventHub.$off('select_item');
                this.$eventHub.$off('escape');
                this.$eventHub.$off('newTransaction');
                this.$eventHub.$off('connected');
                next();
            }
        },
      //   beforeRouteLeave (to, from, next) {
            // var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
            // if(dialogData && dialogData.length){

            //  if(this.backButtonPress){
            //      dialogjs.fn_checkAndCloseOpenDialogs(this);
            //      next(false);
            //  }
            //  else{
            //      localStorage.removeItem("SetDataDrive");
            //      // this.$root.fn_deleteFormObject();
            //      next(true);
            //  }
            // }
            // else{
            //  // if(this.askToDiscard){
            //  //  this.fn_openDialogDiscard();
            //  //  next(false);
            //  // }
            //  if(this.fn_showDialogDiscard()){
            //      this.fn_openDialogDiscard();
            //      next(false);
            //  }
            //  else{
            //      this.fn_clearData('purchaseforms', function(){
            //          localStorage.removeItem("SetDataDrive");
            //          next();
            //      })
            //  }
            //  // if(this.loadedFromDraft ||  (!this.quitForm && this.Form.partyName && (this.$root.fn_getFormObject(this.formId) && !this.$root.fn_deepEqual(this.Form, JSON.parse(this.$root.fn_getFormObject(this.formId)))))){
            //  //  this.fn_openDialogDiscard();
            //  //  next(false);
            //  // }
            //  // else{
            //  //  this.$root.fn_deleteFormObject(this.formId);
            //  //  !this.quitForm ? this.fn_removeDraft(this.draftStorageId, null, -1) : '';
            //  //  localStorage.removeItem('purchaseforms');
            //  //  localStorage.removeItem("SetDataDrive");
            //  //  next(); 
            //  // }
            //  // localStorage.removeItem('purchaseforms')
            //  // next();
            // }
      //   },
        computed:{
            netAmount: function () {
                return this.fn_calculateNetAmount();
            },
            totalAmount: function () {
                let grossAmount = 0;
                let grossAmountAsPerMRP = 0;
                let netAmount = this.netAmount;
                if(this.Form.items.length){
                    grossAmount = this.Form.itemsSummary.grossAmount;
                    grossAmountAsPerMRP = this.Form.itemsSummary.grossAmountAsPerMRP
                }
                else{
                    grossAmount = this.Form.netInvoiceValue;
                    grossAmountAsPerMRP = this.Form.netInvoiceValue;
                }
                // let grossAmount = !this.Form.items.length ? this.Form.netInvoiceValue : this.Form.itemsSummary.grossAmount;
                // let grossAmountAsPerMRP = !this.Form.items.length ? this.Form.netInvoiceValue : this.Form.itemsSummary.grossAmountAsPerMRP;
                // let netAmount = this.netAmount;

                let totalAmount = 0;

                let self = this;

                let addOnsTotalValue = 0;

                this.Form.transactionAddOns.forEach(function (item) {

                    let applicableAmount = 0;
                    let addOnsValue = 0;

                    //on net value
                    if(item.appliedOn == 0){
                        applicableAmount = netAmount;
                    }//value of goods
                    else if(item.appliedOn == 1){
                        applicableAmount = grossAmount;
                    }//on mrp
                    else{
                        applicableAmount = grossAmountAsPerMRP;
                    }

                    //of value or percentage
                    if(item.mode){//value
                        addOnsValue = item.value;
                    }
                    else{//percentage
                        if(item.percentage!==null && item.percentage!==''){
                            addOnsValue = (applicableAmount*item.percentage)/100;
                            item.value = self.roundUpto2Decimals(addOnsValue);
                        }
                        else if(item.value!==null && item.value!==''){
                            addOnsValue = item.value;
                        }
                    }

                    addOnsValue = self.roundUpto2Decimals(addOnsValue);

                    //Final calculation
                    if(item.adjustInTotal){
                        if(item.nature==0){
                            if(self.Form.isRcmApplicable){
                                addOnsTotalValue += item.addOnsType != 2 ? addOnsValue : 0;
                            }
                            else{
                                addOnsTotalValue += addOnsValue;
                            }
                        }
                        else{
                            addOnsTotalValue -= addOnsValue;
                        }
                    }
                    // if(item.adjustInTotal){
                    //  if(item.nature==0){
                    //      addOnsTotalValue += addOnsValue;
                    //  }
                    //  else{
                    //      addOnsTotalValue -= addOnsValue;
                    //  }
                    // }

                });

                // totalAmount = this.netAmount + addOnsTotalValue - this.Form.tdsDetails.amount;
                totalAmount = this.netAmount + addOnsTotalValue - this.tdsAmount;

                return this.roundUpto2Decimals(totalAmount);
            },

            // roundedAmount: function(){
            //  if(this.Form.isRounded){
            //      return Math.round(this.totalAmount);    
            //  }
            //  else{
            //      return this.totalAmount;
            //  }
            // },


            roundOff: function(){
                return this.fn_calculateRoundOffValue();
            },

            roundedAmount: function(){
                return this.roundUpto2Decimals(this.totalAmount + this.roundOff);
                // return this.roundUpto2Decimals(this.totalAmount + this.Form.roundOffValue);
            },

            expandCustomFields: function(){
                let expantionModel = [];
                for(let i in this.Form.customFields){
                    console.log(i);
                    expantionModel.push(true);
                }
                return expantionModel;
            },
            
            filteredItems: function () {

                let self = this;

                let groups = {
                    order: [],
                    challan: [],
                    invoice: [],
                    self: [],
                }

                let objSelf = {};
                objSelf.header = "Items";
                // objSelf.baseTransactionNumber = self.Form.transactionData.transactionNumber;
                objSelf.baseTransactionNumber = self.Form.transactionData.mergeTransactionNumber;
                objSelf.baseTransactionId = null;
                objSelf.items = [];

                this.Form.items.forEach(function (itemData) {
                    // if(itemData.baseTransactionListId == self.settings.transactionTypeId && itemData.baseTransactionNumber == self.Form.transactionData.transactionNumber){
                    // if(itemData.baseTransactionListId == self.settings.transactionTypeId && itemData.baseTransactionNumber == self.Form.transactionData.mergeTransactionNumber){
                    if(itemData.baseTransactionListId == self.settings.transactionTypeId){
                        itemData.baseTransactionNumber = self.Form.transactionData.mergeTransactionNumber;
                        objSelf.items.push(itemData);
                    }
                });

                // sorting items as per order number
                objSelf.items.sort(function(a, b){
                    return a.orderNumber - b.orderNumber;
                });

                groups.self.splice(0, 1, objSelf);

                if(this.Form.transactionData.transactionListId == transactionTypes.purchaseInvoice.id || this.Form.transactionData.transactionListId == transactionTypes.purchaseChallan.id){

                    //purchase orders inside purchase invoice and purchase challans
                    self.Form.pendingOrders.forEach(function (item) {

                        let objOrder = {};
                        objOrder.items = [];

                        objOrder.header = "Purchase Order";
                        objOrder.baseTransactionNumber = item.baseTransactionNumber;
                        objOrder.baseTransactionId = item.transactionId;

                        objOrder.items = self.Form.items.filter(itemData=>{
                            return item.baseTransactionNumber == itemData.baseTransactionNumber && itemData.baseTransactionListId == transactionTypes.purchaseOrder.id;
                        });

                        // sorting order items as per order number
                        objOrder.items.sort(function(a, b){
                            return a.orderNumber - b.orderNumber;
                        });
                        
                        groups.order.push(objOrder);
                    });

                    // if(this.Form.pendingOrders.length){
                    //  if(this.loadedFromDraft){
                    //      let transactionIds = [];
                    //      for(let i=0; i<this.Form.pendingOrders.length; i++){
                    //          transactionIds.push(self.Form.pendingOrders[i].transactionId);
                    //      }

                    //      self.fn_checkPendingTransaction(transactionIds, function(response){
                    //          if(!response){
                    //              pushPendingItems(self.Form.pendingOrders, 'Order');
                    //          }
                    //          else{
                    //              // self.fn_removeGroup('order', self.Form.pendingOrders[i].baseTransactionNumber);
                    //              self.Form.isNew ? self.fn_resetForm() : self.fn_get(self.Form.transactionData.transactionId);
                    //              self.fn_showSnackBar("Transaction resets as last tagged item(s) have been used already.", 400);
                    //          }
                    //      })
                            
                    //  }
                    //  else{
                    //      pushPendingItems(this.Form.pendingOrders, 'Order');
                    //  }
                    // }


                    //purchase challans inside purchase invoice
                    if(this.Form.transactionData.transactionListId == transactionTypes.purchaseInvoice.id){

                        self.Form.pendingChallans.forEach(function (item) {

                            let objChallan = {};
                            objChallan.items = [];

                            objChallan.header = "Purchase Challan";
                            objChallan.baseTransactionNumber = item.baseTransactionNumber;
                            objChallan.baseTransactionId = item.transactionId;

                            objChallan.items = self.Form.items.filter(itemData=>{
                                return item.baseTransactionNumber == itemData.baseTransactionNumber && itemData.baseTransactionListId == transactionTypes.purchaseChallan.id;
                            });

                            // sorting challan items as per order number
                            objChallan.items.sort(function(a, b){
                                return a.orderNumber - b.orderNumber;
                            });
                            
                            groups.challan.push(objChallan);
                        });

                        // if(this.Form.pendingChallans.length){
                        //  if(this.loadedFromDraft){
                        //      for(let i=0; i<this.Form.pendingChallans.length; i++){
                        //          (function(i){
                        //              self.fn_checkPendingTransaction(self.Form.pendingChallans[i].transactionId, function(response){
                        //                  if(!response){
                        //                      pushPendingItems(self.Form.pendingChallans, 'Purchase Challan');
                        //                  }
                        //                  else{
                        //                      // self.fn_removeGroup('challan', self.Form.pendingChallans[i].baseTransactionNumber);
                        //                      self.Form.isNew ? self.fn_resetForm() : self.fn_get(self.Form.transactionData.transactionId);
                        //                      self.snackbar = true;
                        //                      self.snackbar_msg = "Transaction resets as last tagged item(s) have been used already.";
                        //                  }
                        //              })
                        //          }(i))
                        //      }
                                
                        //  }
                        //  else{
                        //      pushPendingItems(this.Form.pendingChallans, 'Purchase Challan');
                        //  }
                        // }

                    }

                }

                else if(this.Form.transactionData.transactionListId == transactionTypes.purchaseReturn.id){

                    //purchase invoice inside purchase return
                    self.Form.pendingInvoices.forEach(function (item) {

                        let objInvoice = {};
                        objInvoice.items = [];

                        objInvoice.header = "Purchase Invoice";
                        objInvoice.baseTransactionNumber = item.baseTransactionNumber;
                        objInvoice.baseTransactionId = item.transactionId;

                        objInvoice.items = self.Form.items.filter(itemData=>{
                            return item.baseTransactionNumber == itemData.baseTransactionNumber && itemData.baseTransactionListId == transactionTypes.purchaseInvoice.id;
                        });

                        // sorting invoice items as per order number
                        objInvoice.items.sort(function(a, b){
                            return a.orderNumber - b.orderNumber;
                        });
                        
                        groups.invoice.push(objInvoice);
                    });

                    // if(this.Form.pendingInvoices.length){
                    //  if(this.loadedFromDraft){
                    //      for(let i=0; i<this.Form.pendingInvoices.length; i++){
                    //          (function(i){
                    //              self.fn_checkPendingTransaction(self.Form.pendingInvoices[i].transactionId, function(response){
                    //                  if(!response){
                    //                      pushPendingItems(self.Form.pendingInvoices, 'Purchase invoice');
                    //                  }
                    //                  else{
                    //                      // self.fn_removeGroup('invoice', self.Form.pendingInvoices[i].baseTransactionNumber);
                    //                      self.Form.isNew ? self.fn_resetForm() : self.fn_get(self.Form.transactionData.transactionId);
                    //                      self.snackbar = true;
                    //                      self.snackbar_msg = "Transaction resets as last tagged item(s) have been used already.";
                    //                  }
                    //              })
                    //          }(i))
                    //      }
                            
                    //  }
                    //  else{
                    //      pushPendingItems(this.Form.pendingInvoices, 'Purchase invoice');
                    //  }
                    // }

                }

                // function pushPendingItems(pendingItems, header){
                    
                //  pendingItems.forEach(function (item) {

                //      let itemObj = {};
                //      itemObj.items = [];

                //      itemObj.header = header;
                //      itemObj.baseTransactionNumber = item.baseTransactionNumber;
                //      itemObj.baseTransactionId = item.transactionId;
                //      //purchase orders inside purchase invoice and purchase challans
                //      if(header=='Order'){

                //          itemObj.items = self.Form.items.filter(itemData=>{
                //              return item.baseTransactionNumber == itemData.baseTransactionNumber && itemData.baseTransactionListId == transactionTypes.purchaseOrder.id;
                //          });
                            
                //          groups.order.push(itemObj);

                //      }
                //      //purchase challans inside purchase invoice
                //      else if(header=='Purchase Challan'){

                //          itemObj.items = self.Form.items.filter(itemData=>{
                //              return item.baseTransactionNumber == itemData.baseTransactionNumber && itemData.baseTransactionListId == transactionTypes.purchaseChallan.id;
                //          });
                            
                //          groups.challan.push(itemObj);

                //      }
                //      //purchase invoice inside purchase return
                //      else if(header=='Purchase Invoice'){

                //          itemObj.items = self.Form.items.filter(itemData=>{
                //              return item.baseTransactionNumber == itemData.baseTransactionNumber && itemData.baseTransactionListId == transactionTypes.purchaseInvoice.id;
                //          });
                            
                //          groups.invoice.push(itemObj);

                //      }
                //  });

                // }

                for(let key in groups){

                    groups[key].sort(function(a, b){
                        return a.baseTransactionNumber - b.baseTransactionNumber;
                    });

                }
                

                console.log(JSON.stringify(groups));

                return groups;

            },

            // minTransactionDate: function(){
            //  if(!this.Form.isNew){
            //      let defaultMinDate = new Date(this.$root.minDateSelection);
            //      let month = ("0" + (defaultMinDate.getMonth() + 1)).slice(-2);
            //      // let date = defaultMinDate.getDate();
            //      let date = ("0" + defaultMinDate.getDate()).slice(-2);
            //      return this.fn_getFiscalYear(this.Form.transactionData.transactionDate) + "-" + month + "-" + date;
            //  }
            //  else{
            //      return null;
            //  }
            // },
            // maxTransactionDate: function(){
            //  if(!this.Form.isNew){
            //      return (this.fn_getFiscalYear(this.Form.transactionData.transactionDate)+1) + "-03-31";
            //  }
            //  else{
            //      return null;
            //  }
            // },
            otherChargeItems: function(){
                let items = [];
                items = this.Form.transactionAddOns.filter(item=>{
                    return item.addOnsType == 3;
                });
                return items;
            },
            discountItems: function(){
                let items = [];
                items = this.Form.transactionAddOns.filter(item=>{
                    return item.addOnsType == 1;
                });
                return items;
            },
            taxItems: function(){
                let items = [];
                items = this.Form.transactionAddOns.filter(item=>{
                    return item.addOnsType == 2;
                });
                return items;
            },
            splitPaymentTotal: function(){
                let splitPaymentTotal = 0;
                if(this.Form.splitPayment){
                    for(let i=0; i<this.Form.paymentOptions.length; i++){
                        splitPaymentTotal += this.Form.paymentOptions[i].amount;
                    }
                    return this.roundUpto2Decimals(splitPaymentTotal);
                }
                else{
                    return this.roundedAmount;
                }
            },
            totalQty: function(){
                let totalQty = 0;
                if(this.Form.itemsSummary && this.Form.itemsSummary.itemsQuanity && this.Form.itemsSummary.itemsQuanity.length){
                    for(let i=0; i<this.Form.itemsSummary.itemsQuanity.length; i++){
                        totalQty += this.Form.itemsSummary.itemsQuanity[i].qty;
                    }   
                }
                return totalQty;
            },
            filteredItemList: function(){
                let searchText = this.itemSearchValue ? this.itemSearchValue.toLowerCase() : '';

                return this.products.filter(item => {
                    let itemName = item.itemName.toLowerCase();
                    return itemName.match(searchText);
                })
            },
            filteredTds: function(){
                let searchText = this.tdsSearchValue ? this.tdsSearchValue.toLowerCase() : '';

                return this.tdsLedgers.filter(item => {
                    let itemName = item.itemName.toLowerCase();
                    return itemName.match(searchText);
                })
            },
            tdsAmount: function () {
                let amount = 0;
                amount = (this.Form.tdsDataModel.appliedOnAmount * this.Form.tdsDataModel.percentage) / 100;
                return this.roundUpto2Decimals(amount);
            },
            discounts: function(){
                // let searchText = '';
                let searchText = this.discountSearchValue ? this.discountSearchValue.toLowerCase() : '';

                return this.addOns.filter(item => {
                    let itemName = item.itemName.toLowerCase();
                    return item.addonGroupId === 1 && itemName.match(searchText);
                })
            },
            taxes: function(){
                // let searchText = '';
                let searchText = this.taxSearchValue ? this.taxSearchValue.toLowerCase() : '';

                return this.addOns.filter(item => {
                    let itemName = item.itemName.toLowerCase();
                    return item.addonGroupId === 2 && itemName.match(searchText);
                })
            },
            charges: function(){
                // let searchText = '';
                let searchText = this.otherChargeSearchValue ? this.otherChargeSearchValue.toLowerCase() : '';

                return this.addOns.filter(item => {
                    let itemName = item.itemName.toLowerCase();
                    return item.addonGroupId === 3 && itemName.match(searchText);
                })
            },
        }
    }

</script>