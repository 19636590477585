<template>
    <div>
        <!-- <v-toolbar flat fixed>
            <v-toolbar-side-icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-toolbar-side-icon>

            <v-toolbar-title class="white--text">Ledgers</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="fn_new"><v-icon>add</v-icon></v-btn>

            <div full-width slot="extension" class="search-filter" :class="{'bottom-shadow':scrollPositionTop!=0}">
                <v-text-field clearable elevation-10  flat solo placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch"></v-text-field>

                <div v-ripple class="filter-btn" @click="fn_openDialogFilterItems">
                    <v-icon>filter_list</v-icon>
                    <span style="font-size: 48px;position: absolute;bottom: -6px;right: 6px;color: #4b77be;" v-if="selectionItems.selectedFilterItemValue != null || selectionItems.selectedSortValue">&bull;</span>
                </div>
            </div>
        </v-toolbar> -->

        <v-toolbar :height="56" flat extended>
            <!-- <v-btn icon @click="fn_goBack">
                <v-icon>arrow_back</v-icon>
            </v-btn> -->

            <v-toolbar-title>Ledgers</v-toolbar-title>

            <v-spacer></v-spacer>

            <!-- <v-menu dense>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small icon v-bind="attrs" v-on="on"><v-icon>more_vert</v-icon></v-btn>
                </template>
                <v-list nopadding dense>
                    <v-list-item @click="fn_exportItems">
                        <v-icon font20 mr-4>file_download</v-icon>
                        <v-list-item-title>Export Items</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu> -->

            <v-btn icon color="primary" @click="fn_searchItem"><v-icon>autorenew</v-icon></v-btn>

            <v-btn icon @click="fn_importItems" v-if="$root.appVersion>=117"><v-icon>upload_file</v-icon></v-btn>

            <v-btn icon @click="fn_new"><v-icon>add</v-icon></v-btn>

            <v-btn icon @click="fn_openFilterNav">
                <v-icon>filter_list</v-icon>
                <span style="font-size: 48px;position: absolute;bottom: -10px;right: 6px;color: #4b77be;" v-if="selectionItems.selectedFilterItemValue != null || selectionItems.selectedSortValue">&bull;</span>
            </v-btn>

            <v-text-field grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch" slot="extension"></v-text-field>
        </v-toolbar>

        <v-divider></v-divider>


        <div class="data-container grey-back hasExtention" v-scroll.self="onScroll">


            <v-list grey-back nopadding>

                <v-subheader white-back subheader-dense>
                    Count: {{$root.numberToLocale(listItemObject.totalCount, 1)}}
                    <v-spacer></v-spacer>

                    <!-- <div class="font11 mr-2" style="padding-left: 8px;display: flex;align-items: center;border: 1px #ddd solid;border-radius: 5px;" @click="fn_openGroupTypes" v-ripple>
                        <div style="max-width: 60px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{selectionItems.groupTypeName ? selectionItems.groupTypeName : 'All'}}</div>
                        <v-icon>arrow_drop_down</v-icon>

                        

                    </div>
 -->
                    <v-autocomplete
                        class="mr-2 autocomplete-small"
                        solo
                        dense
                        :items="groupTypes"
                        v-model="selectedGroupType"
                        return-object
                        item-value="id"
                        item-text="name"
                        hide-details
                        flat
                        outlined
                        style="width:60px;max-height:24px"
                        @input="fn_searchItem"
                      ></v-autocomplete>

                      <v-autocomplete
                        class="autocomplete-small"
                        :class="{'item-selected':selectedAccountGroup}"
                        solo
                        dense
                        :items="accountGroups"
                        v-model="selectedAccountGroup"
                        return-object
                        item-value="itemId"
                        item-text="itemName"
                        hide-details
                        flat
                        outlined
                        placeholder="Group"
                        style="width:60px;max-height:24px"
                        @input="fn_searchItem"
                        @focus="fn_accountGroups"
                        clearable
                      ></v-autocomplete>

                    <!-- <div class="font11" style="padding-left: 8px;display: flex;align-items: center;border: 1px #ddd solid;border-radius: 5px;" @click="fn_openAccountGroups" v-ripple>
                        <div style="max-width: 60px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{selectionItems.accountGroupName ? selectionItems.accountGroupName : 'Group'}}</div>
                        <v-icon>arrow_drop_down</v-icon>
                    </div> -->
                </v-subheader>

                <v-divider></v-divider>

                <div white-back v-for="(item, index) in listItemObject.items" :key="index" :class="{'mt-2':index!=0}" :id="'item_'+item.partyId">

                    <v-layout ma-0 row wrap pt-2 pb-2 pr-3 pl-3 cursor-pointer v-ripple @click="fn_itemClick(item.partyId, null, index)">
                        <v-flex font16 xs12 sm12 md12>{{item.partyName}}</v-flex>
                        <v-flex xs4 sm4 md4 pt-2 light-opacity-color>
                            {{item.groupTypeId == 101 ? 'Customer' : item.groupTypeId == 102 ? 'Supplier' : item.groupTypeId == 103 ? 'Other' : ''}}
                        </v-flex>
                        <v-flex xs8 sm8 md8 right-align pt-2 font16 v-if="item.partyId != '2e87f316-d0bc-4880-83b6-f050e9ffa5da'">
                            <!-- <div v-if="item.groupTypeId != 103 || (item.groupTypeId == 103 && item.amount)"> -->
                            <template v-if="item.groupTypeId != 103">
                                <div>
                                    <v-icon class="greencolor circle_arrow_icon" v-if="item.balance > 0">arrow_downward</v-icon>
                                    <v-icon class="redcolor circle_arrow_icon" v-if="item.balance < 0">arrow_upward</v-icon>
                                    &#8377; {{$root.numberToLocale(roundUpto2Decimals(item.amount))}}
                                </div>
                                <div class="font12">
                                    <span class="greencolor" v-if="item.balance > 0">To Recieve</span>
                                    <span class="redcolor" v-if="item.balance < 0">To Pay</span>
                                </div>
                            </template>
                            <template v-else>
                                <div v-if="item.amount">
                                    <v-icon class="greencolor circle_arrow_icon" v-if="item.balance > 0">arrow_downward</v-icon>
                                    <v-icon class="redcolor circle_arrow_icon" v-if="item.balance < 0">arrow_upward</v-icon>
                                    &#8377; {{$root.numberToLocale(roundUpto2Decimals(item.amount))}}
                                    <div class="font12">
                                        <span class="greencolor" v-if="item.balance > 0">Debit</span>
                                        <span class="redcolor" v-if="item.balance < 0">Credit</span>
                                    </div>
                                </div>
                            </template>
                        </v-flex>
                    </v-layout>


                    <!-- <template v-if="item.groupTypeId==101 || item.groupTypeId==102"> -->
                    <v-divider></v-divider>
                    <div right-align style="padding:2px 6px">
                        <v-btn nomargin icon text @click="fn_itemClick(item.partyId, 1, index)" v-if="item.groupTypeId==101 || item.groupTypeId==102">
                            <v-icon>picture_as_pdf</v-icon>
                        </v-btn>

                        <v-menu dense left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small icon v-bind="attrs" v-on="on"><v-icon>more_vert</v-icon></v-btn>
                            </template>
                            <v-list nopadding dense>
                                <v-list-item @click="fn_copy(item.partyId)">
                                <v-list-item-title>Copy</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>  
                    <!-- </template> -->
                    
                </div>

            </v-list>

            <v-row class="ma-0" align-content="center" justify="center" v-if="loadMore">
              <v-col cols="6">
                <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
              </v-col>
            </v-row>

            <div center-align class="pa-6" v-if="loadTimeOut">
                <p font12 light-opacity-color>Unable to load the items!</p>
                <v-btn capitalize text color="primary" @click="fn_searchItem">
                    <v-icon left>refresh</v-icon>
                    Retry
                </v-btn>
            </div>


        </div>

        <!-- <dialog-filter-items ref="ref_dialogFilterItems" type="Ledgers" :filterData="filterData" :setFilter="fn_setFilter" :selectedFilterItemValue="selectionItems.selectedFilterItemValue" :selectedSortValue="selectionItems.selectedSortValue"></dialog-filter-items> -->

        <!-- <canvas id="canvas-due-tab" width="400" height="200" class="payment-reminder-canvas" style="max-width:100%"></canvas> -->

        <v-navigation-drawer v-model="filter_nav" absolute right temporary>
          <filter-items ref="ref_filterItems" type="Ledgers" :filterData="filterData" :setFilter="fn_setFilter" :selectedFilterItemValue="selectionItems.selectedFilterItemValue" :selectedSortValue="selectionItems.selectedSortValue" :closeFilterNav="fn_closeFilterNav"></filter-items>
        </v-navigation-drawer>

        <dialog-loading ref="ref_dialogLoading" message="Please wait..."></dialog-loading>
    </div>
</template>

<script>
// const menuItem = require('../../../../config.js').configs.menuItem;
// const bottomSheetMenu = require('../../../../config.js').configs.bottomSheetMenu;
import {masterMixin} from '../../master_mixin.js'

// const dialogjs =  require('../../../masters/forms/dialog').dialogjs;

const accountGroupIds = require('../../../../config.js').configs.accountGroupIds;

import filterItems from '../../../activity/dialogs/filter_items';
import dialogLoading from '../../../dialogs/dialog_loading.vue';

    export default{
        mixins: [masterMixin],
        data(){
            return{
                formId:null,
                dialogStorageId:null,

                groupTypes:[
                    {"id":100, "name":"All"},
                    {"id":101, "name":"Customer"},
                    {"id":102, "name":"Supplier"},
                    {"id":103, "name":"Other"}
                ],

                accountGroups:[],

                selectedGroupType:{"id":100, "name":"All"},
                selectedAccountGroup:null,

                selectionItems:{
                    accountGroupId:null,
                    accountGroupName:null,
                    groupTypeId:100,
                    groupTypeName:"All",
                    selectedFilterItemValue:null,
                    selectedSortValue:0

                },

                backButtonPress:false,

                filter_nav:false,

                filterData:{
                    filterItems:[
                        {"filterItemId":1, "name":"All", "value":null},
                        {"filterItemId":2, "name":"To Receive", "value":1},
                        {"filterItemId":3, "name":"To Pay", "value":0}
                    ],
                    sortItems:[
                        {"sortId":1, "name":"Party Name", "value":0},
                        {"sortId":2, "name":"Amount - High to Low", "value":1},
                        {"sortId":3, "name":"Amount - Low to High", "value":2}
                    ],
                }

            }
        },
        components: {
            'filter-items': filterItems,
            'dialog-loading':dialogLoading
        },
        methods:{

            fn_importItems: function(){
                this.$router.push('/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/ledgers_import');
            },

            fn_openFilterNav: function(){
                this.filter_nav = true;
                this.$refs['ref_filterItems'].fn_setCurrentFilterValues();
            },

            fn_closeFilterNav: function(){
                this.filter_nav = false;
            },

            fn_goBack: function(){
                this.backButtonPress = true;
                this.$router.replace("/"+this.$root.CurrentBusiness.BusinessID+"/master_items");
            },

            fn_new: function(){
                // let obj = {};
                // obj.itemId = null;
                // obj.lastScrollPos = window.pageYOffset;
                // obj.itemIndex = this.listItemObject.items.length;
                // localStorage.setItem("ledgers_items_state", JSON.stringify(obj));

                // let groupTypeId = this.selectionItems.groupTypeId && this.selectionItems.groupTypeId != 100 ? this.selectionItems.groupTypeId : 101;
                // this.$router.push({path:'/masters/accounts/ledgers/items/new', query:{tagId:this.guidWeb(), routeToDisplay:true, groupTypeId:groupTypeId}});
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/ledger/items/new', query:{tagId:this.guidWeb(), groupTypeId:this.$route.query.groupTypeId ? this.$route.query.groupTypeId : 101}});
            },

            // fn_copy: function(itemId){
            //     this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/ledger/items/new', query:{tagId:this.guidWeb(), itemId:itemId, copy:true}});
            // },
            fn_copy: function(itemId){
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/ledger/items/new', query:{tagId:this.guidWeb(), itemId:itemId, copy:true}});
            },

            fn_paymentReminder: function(item){
                let self = this;
                this.$root.fn_checkStoragePermission(function(){
                    let obj = {};
                    obj.amount = item.amount;
                    obj.primaryMobileNumber = item.primaryMobileNumber;
                    obj.email = item.email;
                    self.$root.fn_sendPaymentReminder(obj, "canvas-due-tab");
                });
            },

            fn_setFilter: function(filterItemId, sortId){
                this.selectionItems.selectedFilterItemValue = filterItemId;
                this.selectionItems.selectedSortValue = sortId;
                // this.fn_closeDialog();
                this.fn_closeFilterNav();
                this.fn_searchItem();
            },

            fn_menuItemClick: function(tagId, menuId, menuAction, itemId){
                if(menuAction=='draft'){
                    this.$router.push({path:'/drafts', query:{tabIndex:0}});
                }
                else if(menuAction=='delete'){
                    let self = this;
                    this.fn_deleteMasterItem('accounts', itemId, function(response){
                        if(response){
                            self.showToast('Item deleted');
                        }
                        else{
                            self.showToast("Item cannot be deleted as already in use");
                        }
                    })
                }
                else if(menuAction == 'clearallfilters'){
                    this.fn_clearAll();
                }
            },

            // fn_openGroupTypes: function(){
            //     let obj = {};
            //     obj.functionName = 'ledgerGroupType';
            //     obj.toolbarTitle = 'Select Ledger Type';
            //     obj.itemId = this.selectionItems.groupTypeId;
            //     obj.itemName = this.selectionItems.groupTypeName;
            //     this.fn_openDialogAndroid('android', 'DialogFilter', 'ref_groupType', obj);
            // },

            // fn_openAccountGroups: function(){
            //     let obj = {};
            //     obj.functionName = 'accountgroups';
            //     obj.toolbarTitle = 'Select Group';
            //     obj.itemId = this.selectionItems.accountGroupId;
            //     obj.itemName = this.selectionItems.accountGroupName;
            //     this.fn_openDialogAndroid('android', 'DialogFilter', 'ref_accountGroup', obj);
            // },

            fn_accountGroups: function(val){
                console.log(val);
                let filterObject = {};
                // filterObject.search = val ? val.toLowerCase() : '';
                filterObject.search = '';

                if(this.selectedGroupType.id == 101 || this.selectedGroupType.id == 102){
                    let accountGroupId = this.selectedGroupType.id == 101 ? accountGroupIds.debtors.id : accountGroupIds.creditors.id;
                    filterObject.accountGroupIds = [accountGroupId];
                }

                filterObject.startIndex = 0;
                filterObject.limit = 1000;
                
                let self = this;
                this.getDialogItems("AccountGroupDialog", filterObject, 
                    {
                        onSuccess: function (response) {
                        console.log(response);
                        self.accountGroups = JSON.parse(response);
                    },
                    onError: function (error) {
                        console.log(error);
                    }
                })
            },

            // fn_clearAll: function(){
            //  this.selectionItems.groupTypeId = null;
            //  this.selectionItems.accountGroupId = null;
            //  localStorage.removeItem('item_filter');
            //  this.fn_filterData();
            // },

            fn_itemClick: function (itemId, shareStatement) {
                // console.log(itemId, index);
                // let obj = {};
                // obj.itemId = null;
                // obj.lastScrollPos = window.pageYOffset;
                // obj.itemIndex = index + 1;
                // localStorage.setItem("ledgers_items_state",JSON.stringify(obj));

                if(shareStatement){
                    // this.$router.push({path:'/masters/accounts/ledgers/items/'+itemId, query:{statementType:1}});
                    this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/ledger/items/'+itemId+'/statement', query:{type:1}});
                }
                else{
                    // this.$router.push('/masters/accounts/ledgers/items/'+itemId);
                    this.$router.push('/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/ledger/items/'+itemId);
                }
                // this.$router.push('/masters/accounts/ledgers/items/'+itemId);
            },


            // fn_openDialogAndroid: function (platform, name, ref, obj) {

            //     let tagId = this.guidWeb();

            //     var dialogObj = {};
            //     dialogObj.tagId = tagId;
            //     dialogObj.formId = this.formId;
            //     dialogObj.name = name;
            //     dialogObj.ref = ref;
            //     dialogObj.platform = platform;

            //     var viewDialogObject = {};
            //     viewDialogObject.ToolbarTitle = obj.toolbarTitle;
            //     viewDialogObject.isBottomMenu = false;

            //     if(ref == 'ref_groupType'){
            //         viewDialogObject.groupTypes = [
            //             {"id":100, "name":"All"},
            //             {"id":101, "name":"Customer"},
            //             {"id":102, "name":"Supplier"},
            //             {"id":103, "name":"Other"}
            //         ]

            //         this.$root.TransactionSettings.branchDivision ? viewDialogObject.groupTypes.push({"id":104, "name":"Branch/Division"}) : '';
            //     }

            //     if(ref=="ref_accountGroup" && (this.selectionItems.groupTypeId==101 || this.selectionItems.groupTypeId==102)){
            //         let accountGroupId = this.selectionItems.groupTypeId == 101 ? accountGroupIds.debtors.id : accountGroupIds.creditors.id
            //         viewDialogObject.accountGroupIds = [accountGroupId];
            //     }

            //     var paramObject = {};
            //     paramObject.defaultValue = {"id":null, "name":null};

            //     if(obj.itemId && obj.itemName){
            //         paramObject.defaultValue = {"id":obj.itemId, "name":obj.itemName};
            //     }

            //     dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, obj.functionName, tagId, paramObject, ref);
            // },

            // fn_openDialogFilterItems: function(){
            //     this.fn_openDialogWeb('web', 'FilterItems', 'ref_dialogFilterItems');
            // },

            // fn_openDialogWeb: function (platform, name, ref) {

            //     let scroll_y = window.scrollY;
            //     this.fn_fixBackgroundScroll();

            //     var tagId = this.guidWeb();

            //     var dialogObj = {};
            //     dialogObj.tagId = tagId;
            //     dialogObj.formId = this.formId;
            //     dialogObj.name = name;
            //     dialogObj.ref = ref;
            //     dialogObj.data = null;
            //     dialogObj.platform = platform;

            //     dialogObj.scrollPosition = {};
            //     dialogObj.scrollPosition.x = 0;
            //     dialogObj.scrollPosition.y = scroll_y;

            //     dialogjs.fn_pushDialogInStack(this.dialogStorageId, dialogObj, this);

            //     let self = this;

            //     this.$refs[ref].openDialogWeb();
            // },

            // fn_closeDialog: function () {
            //     dialogjs.fn_checkAndCloseOpenDialogs(this);
            // },

            fn_onChange: function(tagId, ref, selectedValueId, selectedValueName, isRemoved){
                if(ref == 'ref_groupType'){
                    this.selectionItems.groupTypeId = !isRemoved ? selectedValueId : null;
                    this.selectionItems.groupTypeName = !isRemoved ? selectedValueName : null;
                }
                else if(ref == 'ref_accountGroup'){
                    this.selectionItems.accountGroupId = !isRemoved ? selectedValueId : null;
                    this.selectionItems.accountGroupName = !isRemoved ? selectedValueName : null;
                }

                // if(ref == 'ref_accountGroup'){
                //  this.selectionItems.accountGroupId = !isRemoved ? selectedValueId : null;
                //  this.selectionItems.accountGroupName = !isRemoved ? selectedValueName : null;
                // }

                isRemoved ? this.fn_closeDialog() : '';

                this.fn_searchItem();
                // this.fn_filterData(true);
            },

           

            fn_loadItems: function() {
                this.loadMore = true;
                let filterObject = {};
                filterObject.limit = 50;
                filterObject.startIndex = this.startIndex;
                filterObject.search = this.searchValue ? this.searchValue.toLowerCase() : '';

                // filterObject.groupTypeIds = this.selectionItems.groupTypeId && this.selectionItems.groupTypeId != 100 ? [this.selectionItems.groupTypeId] : [];
                filterObject.groupTypeIds = this.selectedGroupType.id && this.selectedGroupType.id != 100 ? [this.selectedGroupType.id] : [];
                // filterObject.accountGroupIds = this.selectionItems.accountGroupId ? [this.selectionItems.accountGroupId] : [];
                filterObject.accountGroupIds = this.selectedAccountGroup && this.selectedAccountGroup.itemId ? [this.selectedAccountGroup.itemId] : [];

                filterObject.balanceType = this.selectionItems.selectedFilterItemValue;
                filterObject.sortByAmount = this.selectionItems.selectedSortValue;
                // filterObject.sortByAmount = this.selectedSortValue;

                let pageState = null;
                if(localStorage.getItem("ledgers_items_state")){
                    pageState = JSON.parse(localStorage.getItem("ledgers_items_state"));
                    filterObject.limit = 50 - (pageState.itemIndex % 50) + pageState.itemIndex;
                }

                console.log(filterObject.limit);

                localStorage.setItem('item_filter', JSON.stringify(this.selectionItems));

                this.fn_loadTimeOut();

                let self = this;
                this.getItems("LedgerList", filterObject, 
                    {
                        onSuccess: function (response) {
                            self.loadTimeOut = false;
                            console.log(response);
                            let responseJson = JSON.parse(response);
                            self.listItemObject.totalCount = responseJson.totalCount;
                            // self.listItemObject.totalReceivables = responseJson.totalReceivables ? responseJson.totalReceivables : 0;
                            // self.listItemObject.totalPayables = responseJson.totalPayables ? responseJson.totalPayables : 0;

                            let itemFound = false;

                            for(let i in responseJson.items){

                                if(pageState && pageState.itemId){
                                    if(responseJson.items[i].partyId == pageState.itemId){
                                        itemFound = true;
                                    }
                                }

                                let itemIndex = self.listItemObject.items.findIndex(item => item.partyId==responseJson.items[i].partyId);

                                if(itemIndex==-1){
                                    self.listItemObject.items.push(responseJson.items[i]);  
                                }
                                // self.listItemObject.items.push(responseJson.items[i]);
                            }

                            console.log(pageState);
                            if(pageState && pageState.itemId){
                                setTimeout(function(){
                                    if(itemFound){
                                        let scrollOptions = {"duration":0, "offset":-112, "easing":"linear"};
                                        self.$vuetify.goTo('#item_'+pageState.itemId, scrollOptions);
                                        localStorage.removeItem("ledgers_items_state");
                                    }
                                    else{
                                        self.$vuetify.goTo(99999, self.scrollOptions);
                                    }
                                },100);
                            }

                            if(pageState && !pageState.itemId){
                                setTimeout(function(){
                                    let scrollOptions = {"duration":300, "offset":0, "easing":"linear"};
                                    self.$vuetify.goTo(pageState.lastScrollPos, scrollOptions);
                                    localStorage.removeItem("ledgers_items_state");
                                },100);
                            }

                            console.log(self.listItemObject.items);

                            self.startIndex = self.listItemObject.items.length;
                            self.loadMore = false;

                            setTimeout(function(){
                                self.showPlaceHolder = true;
                            },300)
                        },
                        onError: function (error) {
                            console.log(error);
                            // self.loadMore = false;
                        }
                    }
                )
            },

            fn_searchItem: function () {
                this.startIndex = 0;
                this.listItemObject.items = [];
                this.fn_loadItems();
            },

            fn_exportItems: function(){
                var self = this;
                this.$refs['ref_dialogLoading'].openDialogWeb();
                this.getData('GetExportAccounts', null, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            let data = JSON.parse(response);
                            if(data && data.items && data.items.length){
                                self.fn_processItems(data.items);	
                            }
                        }, 
                        onError: function(error){
                            console.log(error);
                            this.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong. Try again.'});
                            this.$refs['ref_dialogLoading'].closeDialogWeb();
                        }
                    }
                );
            },

            fn_processItems: function(items){
                let sheetArray = [];
                let headerRow = ["Name", "Type", "Group", "Mobile Number", "Email", "Opening", "GST Number", "Place Of Supply", "PAN Number", "Country", "Billing Address",	"Billing City", "Billing State", "Billing Pincode", "Shipping Address",	"Shipping City", "Shipping State", "Shipping Pincode"]
                sheetArray.push(headerRow);
                for(let i=0; i<items.length; i++){
                    let row = [];
                    row.push(items[i].name);
                    row.push(items[i].groupTypeId == 101 ? 'Customer': items[i].groupTypeId == 102 ? 'Supplier' : 'Other');
                    row.push(items[i].accountGroupName);
                    row.push(items[i].primaryMobileNumber);
                    row.push(items[i].email);
                    row.push(items[i].openingValue);
                    row.push(items[i].gstNumber);
                    row.push(items[i].placeOfSupply);
                    row.push(items[i].panNumber);
                    row.push(items[i].country);
                    row.push(items[i].billingAddress);
                    row.push(items[i].billingCity);
                    row.push(items[i].billingState);
                    row.push(items[i].billingPincode);
                    row.push(items[i].shippingAddress);
                    row.push(items[i].shippingCity);
                    row.push(items[i].shippingState);
                    row.push(items[i].shippingPincode);
                    sheetArray.push(row);
                }

                const XlsxPopulate = require('xlsx-populate');
                XlsxPopulate.fromBlankAsync().then(workbook => {
                    workbook.sheet(0).cell("A1").value(sheetArray);
                    workbook.sheet(0).name('Ledgers');
                    workbook.sheet(0).range("A1:R1").style({"fill": "92D050", "bold": true});
                    let fileName = "ledgers_"+this.$root.fn_getCurrentDateTimeString();

                    workbook.outputAsync().then(blob=> {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.href = url;
                        a.download = fileName + ".xlsx";
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                        this.$refs['ref_dialogLoading'].closeDialogWeb();
                    });
                })
            },

        },
        mounted: function(){

            // if(localStorage.getItem('item_filter')){
            //     let data = JSON.parse(localStorage.getItem('item_filter'));
            //     this.selectionItems.accountGroupId = data.accountGroupId ? data.accountGroupId : null;
            //     this.selectionItems.accountGroupName = data.accountGroupName ? data.accountGroupName : null;
            //     this.selectionItems.groupTypeId = data.groupTypeId ? data.groupTypeId : 0;
            //     this.selectionItems.groupTypeName = data.groupTypeName ? data.groupTypeName : null;

            //     this.selectionItems.selectedFilterItemValue = data.selectedFilterItemValue ? data.selectedFilterItemValue : null;
            //     this.selectionItems.selectedSortValue = data.selectedSortValue ? data.selectedSortValue : 0;
            // }
            // else{
            //  localStorage.setItem('item_filter', JSON.stringify(this.selectionItems));
            // }

            if(this.$route.query.groupTypeId){
                if(this.$route.query.groupTypeId == 101){
                    this.selectedGroupType = {"id":101, "name":"Customer"};
                    // this.selectionItems.groupTypeId = 101;
                    // this.selectionItems.groupTypeName = "Customer"; 
                }
                else if(this.$route.query.groupTypeId == 102){
                    this.selectedGroupType = {"id":102, "name":"Supplier"};
                    // this.selectionItems.groupTypeId = 102;
                    // this.selectionItems.groupTypeName = "Supplier"; 
                }
            }

            // this.selectionItems.groupTypeId ? viewObject.groupTypeIds = [this.selectionItems.groupTypeId] : '';
            // this.selectionItems.accountGroupId ? viewObject.accountGroupIds = [this.selectionItems.accountGroupId] : '';

            // viewObject.filter = {};
            // viewObject.filter.groupTypeIds = [];
            // if(this.selectionItems.groupTypeId){
            //  viewObject.filter.groupTypeIds.push(this.selectionItems.groupTypeId);
            // }
            // viewObject.filter.groupTypeIds = []
            
            // let tagId = this.$route.query.tagId;
            this.formId = this.$route.query.tagId;
            // this.dialogStorageId = 'dialog_'+this.formId;

            this.fn_loadItems();

            this.$eventHub.$on('UpdateLedgerList', dataObj => {
                console.log(dataObj);
                this.fn_searchItem();
            });

            

            //setting up group types to filter as per group type
            // if(this.$route.query && this.$route.query.groupType){
            //  let self = this;
            //  setTimeout(function(){
            //      self.fn_setGroupType()
            //  },300)
            // }
        },
        // beforeRouteLeave (to, from, next) {
        //     var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //     if(dialogData && dialogData.length){
        //         dialogjs.fn_checkAndCloseOpenDialogs(this);
        //         this.backButtonPress = false;
        //         next(false);
        //     }
        //     else{
        //         // this.fn_closeView(this.$route.query.tagId);
        //         this.backButtonPress ? localStorage.removeItem('item_filter') : '';
        //         next();
        //     }
        //     // else if(!this.viewClosed){
        //     //  this.fn_closeView(this.$route.query.tagId);
        //     //  this.viewClosed = true;
        //     //  next();
        //     // }
        //     // else{
        //     //  next();
        //     // }
        // }
    }
</script>