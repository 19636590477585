<template>
    <!-- <div class="ledger-statement white-back" :class="[statementView ? 'statement-view font6' : 'font10']"> -->

    <div class="ledger-statement template-pdf white-back" style="font-size:11px;font-family:roboto,sans-serif;line-height:16px;">

        <!-- <div class="statement-header" style="position:relative">
            <v-layout row wrap style="padding:8px;margin:0">
                <v-flex xs6 sm6 md6>
                    <div v-if="$root.CurrentBusiness" class="details-business">
                        <img :src="$root.CurrentBusiness.businessLogo" alt="" v-if="$root.CurrentBusiness.businessLogo" style="width:64px;height:64px">
                        <div style="font-size:11px" :style="{'marginLeft':$root.CurrentBusiness.businessLogo ? '72px' : '0px'}">
                            <div style="padding-bottom:4px" font16>{{$root.CurrentBusiness.BusinessName}}</div>
                            <div v-if="$root.CurrentBusiness.Address">{{$root.CurrentBusiness.Address}}</div>
                            <div v-if="$root.CurrentBusiness.GSTNumber">GSTIN: {{$root.CurrentBusiness.GSTNumber}}</div>
                        </div>
                    </div>
                </v-flex>

                <v-flex xs6 sm6 md6 right-align>
                    <div style="font-size:11px">{{statementType == 1 ? 'Ledger Statement' :  'Ledger Activity'}}</div>

                    <div white-back class="branding" style="position:absolute;right:8px;bottom:8px;padding:0 !important;border-radius:5px;">
                        <div style="background:#f0f0f0!important;padding:4px 8px;border-radius:5px;">
                            <div class="dark-opacity-color" style="font-size:9px !important;line-height:12px">Powered By</div>
                            <div class="font16 title primary-color" style="line-height:18px;">
                                <img class="brand_logo" src="../../../../assets/BizOpsApp_Icon_24x24.png" alt="bizops_logo" style="width:20px;margin-right:4px;margin-bottom:0px">BizOps App
                            </div>
                        </div>
                    </div>
                </v-flex>
            </v-layout>
        </div> -->

        <div white-back>

            <!-- <div>
                <div class="party-details" style="padding:8px">

                    <div style="word-spacing:1px"><strong>Period:</strong> {{statementData.accountDetails.startDate | moment("DD-MMM-YYYY")}} To {{statementData.accountDetails.endDate | moment("DD-MMM-YYYY")}}</div>
                    <div style="padding-top:8px;word-spacing:1px"><strong>{{statementData.accountDetails.accountName}}</strong></div>
                    <div style="word-spacing:1px" v-if="statementData.accountDetails.primaryMobileNumber"><strong>Mobile:</strong> +91-{{statementData.accountDetails.primaryMobileNumber}}</div>
                    <div style="word-spacing:1px" v-if="statementData.accountDetails.email"><strong>Email:</strong> {{statementData.accountDetails.email}}</div>
                    <div style="word-spacing:1px" v-if="statementData.accountDetails.gstNumber"><strong>GSTIN:</strong> {{statementData.accountDetails.gstNumber}}</div>
                    
                </div>

                <v-layout row wrap class="statement-summary" v-if="statementType == 1" style="border-left:1px #ddd solid;margin:8px 0px">

                  <v-flex xs4 sm4 md4 class="left-box">
                    <div style="padding:8px">
                        <div>
                            <span v-if="statementData.accountDetails.opening">
                                <strong>&#8377; {{$root.numberToLocale(Math.abs(statementData.accountDetails.opening))}}</strong>
                            </span>
                            <span v-else><strong>&#8377; 0</strong></span>
                        </div>
                        <div style="word-spacing:2px"><strong>Opening Balance</strong></div>
                    </div>
                  </v-flex>

                  <v-flex xs4 sm4 md4 class="middle-box" style="padding:0px 8px">
                    <div style="border-bottom:1px #ddd solid;padding:8px 0px">
                        <div greencolor>
                            <span v-if="statementData.accountDetails.debitTotal">
                                <strong>&#8377; {{$root.numberToLocale(statementData.accountDetails.debitTotal)}}</strong>
                            </span>
                            <span v-else><strong>&#8377; 0</strong></span>
                        </div>
                        <div style="word-spacing:2px"><strong>To Receive</strong></div>
                    </div>

                    <div style="padding:8px 0px">
                        <div redcolor>
                            <span v-if="statementData.accountDetails.creditTotal">
                                <strong>&#8377; {{$root.numberToLocale(statementData.accountDetails.creditTotal)}}</strong>
                            </span>
                            <span v-else><strong>&#8377; 0</strong></span>
                        </div>
                        <div style="word-spacing:2px"><strong>To Pay</strong></div>
                    </div>
                  </v-flex>

                  <v-flex xs4 sm4 md4 class="right-box">
                    <div style="padding:8px">
                        <div primary-color>
                            <span v-if="statementData.accountDetails.balance">
                                <strong>&#8377; {{$root.numberToLocale(Math.abs(statementData.accountDetails.balance))}}</strong>
                            </span>
                            <span v-else><strong>&#8377; 0</strong></span>
                        </div>
                        <div style="word-spacing:2px"><strong>Closing Balance</strong></div>
                        <div style="margin-top:8px;" v-if="statementData.data.footerRow">
                            {{statementData.data.footerRow.creditDebitType}} as on:<br> {{statementData.accountDetails.endDate | moment("DD-MMM-YYYY")}}
                        </div>
                    </div>
                  </v-flex>

                </v-layout>
            </div> -->

            <div>
                <table border="0" id="statement_table">

                    <!-- <thead>
                        <tr>
                            <th left-align>Number</th>
                            <th left-align>Date</th>
                            <th left-align>Voucher</th>
                            <th left-align>Particulars</th>
                            <th right-align v-if="statementType == 2"><span style="word-spacing:2px">Amount (&#8377;)</span></th>
                            <th right-align v-if="statementType == 1"><span style="word-spacing:2px">To Receive (&#8377;)</span></th>
                            <th right-align v-if="statementType == 1"><span style="word-spacing:2px">To Pay (&#8377;)</span></th>
                            <th right-align v-if="statementType == 1"><span style="word-spacing:2px">Balance (&#8377;)</span></th>
                        </tr>
                    </thead> -->
                    
                    <tbody>
                        <tr>
                            <td style="padding:0 !important" :colspan="fn_getColSpanVal()">
                                <div class="statement-header" style="position:relative">
                                    <v-layout row wrap style="padding:8px;margin:0">
                                        <v-flex xs6 sm6 md6>
                                            <div v-if="$root.CurrentBusiness" class="details-business">
                                                <img :src="$root.CurrentBusiness.businessLogo" alt="" v-if="$root.CurrentBusiness.businessLogo">
                                                <!-- <div style="font-size:11px" :style="{'marginLeft':$root.CurrentBusiness.businessLogo ? '72px' : '0px'}"> -->
                                                <div style="font-size:11px;display: grid;">
                                                    <div style="padding-bottom:4px" font16>{{$root.CurrentBusiness.BusinessName}}</div>
                                                    <div v-if="$root.CurrentBusiness.Address">{{$root.CurrentBusiness.Address}}</div>
                                                    <div v-if="$root.CurrentBusiness.GSTNumber">GSTIN: {{$root.CurrentBusiness.GSTNumber}}</div>
                                                </div>
                                            </div>
                                        </v-flex>

                                        <v-flex xs6 sm6 md6 right-align>
                                            <div style="font-size:14px">{{statementType == 1 ? 'Ledger Statement' :  'Ledger Activity'}}</div>

                                            <div white-back class="branding" style="position:absolute;right:8px;bottom:8px;padding:0 !important;border-radius:5px;" v-if="!$root.TransactionSettings.removeLogoFromStatement">
                                                <a href="https://bizopsapp.com/" target="_blank">
                                                    <div style="background:#f0f0f0!important;padding:4px 8px;border-radius:5px;">
                                                        <div class="dark-opacity-color" style="font-size:9px !important;line-height:12px">Powered By</div>
                                                        <div class="font16 title primary-color" style="line-height:18px;">
                                                            <img class="brand_logo" src="../../../../assets/BizOpsApp_Icon_24x24.png" alt="bizops_logo" style="width:20px;margin-right:4px;margin-bottom:0px">BizOps App
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td style="padding:0 !important" :colspan="fn_getColSpanVal()">
                                <div>
                                    <div class="party-details" style="padding:8px 4px">

                                        <div style="word-spacing:1px"><strong>Period:</strong> {{statementData.accountDetails.startDate | moment("DD-MMM-YYYY")}} To {{statementData.accountDetails.endDate | moment("DD-MMM-YYYY")}}</div>
                                        <div style="padding-top:8px;word-spacing:1px"><strong>{{statementData.accountDetails.accountName}}</strong></div>
                                        <div style="word-spacing:1px" v-if="statementData.accountDetails.primaryMobileNumber"><strong>Mobile:</strong> +91-{{statementData.accountDetails.primaryMobileNumber}}</div>
                                        <div style="word-spacing:1px" v-if="statementData.accountDetails.email"><strong>Email:</strong> {{statementData.accountDetails.email}}</div>
                                        <div style="word-spacing:1px" v-if="statementData.accountDetails.gstNumber"><strong>GSTIN:</strong> {{statementData.accountDetails.gstNumber}}</div>
                                        
                                    </div>

                                    <v-layout row wrap class="statement-summary" v-if="statementType == 1" style="border-left:1px #ddd solid;margin:8px 0px">

                                    <v-flex xs4 sm4 md4 class="left-box">
                                        <div style="padding:8px">
                                            <div>
                                                <span v-if="statementData.accountDetails.opening">
                                                    <strong>&#8377; {{$root.numberToLocale(Math.abs(statementData.accountDetails.opening))}}</strong>
                                                </span>
                                                <span v-else><strong>&#8377; 0</strong></span>
                                            </div>
                                            <div style="word-spacing:2px"><strong>Opening Balance</strong></div>
                                        </div>
                                    </v-flex>

                                    <v-flex xs4 sm4 md4 class="middle-box" style="padding:0px 8px">
                                        <div style="border-bottom:1px #ddd solid;padding:8px 0px">
                                            <div greencolor>
                                                <span v-if="statementData.accountDetails.debitTotal">
                                                    <strong>&#8377; {{$root.numberToLocale(statementData.accountDetails.debitTotal)}}</strong>
                                                </span>
                                                <span v-else><strong>&#8377; 0</strong></span>
                                            </div>
                                            <div style="word-spacing:2px"><strong>To Receive</strong></div>
                                        </div>

                                        <div style="padding:8px 0px">
                                            <div redcolor>
                                                <span v-if="statementData.accountDetails.creditTotal">
                                                    <strong>&#8377; {{$root.numberToLocale(statementData.accountDetails.creditTotal)}}</strong>
                                                </span>
                                                <span v-else><strong>&#8377; 0</strong></span>
                                            </div>
                                            <div style="word-spacing:2px"><strong>To Pay</strong></div>
                                        </div>
                                    </v-flex>

                                    <v-flex xs4 sm4 md4 class="right-box">
                                        <div style="padding:8px">
                                            <div primary-color>
                                                <span v-if="statementData.accountDetails.balance">
                                                    <strong>&#8377; {{$root.numberToLocale(Math.abs(statementData.accountDetails.balance))}}</strong>
                                                </span>
                                                <span v-else><strong>&#8377; 0</strong></span>
                                            </div>
                                            <div style="word-spacing:2px"><strong>Closing Balance</strong></div>
                                            <div style="margin-top:8px;" v-if="statementData.data.footerRow">
                                                {{statementData.data.footerRow.creditDebitType}} as on:<br> {{statementData.accountDetails.endDate | moment("DD-MMM-YYYY")}}
                                            </div>
                                        </div>
                                    </v-flex>

                                    </v-layout>
                                </div>
                            </td>
                        </tr>

                        <tr class="theader">
                            <th left-align>Number</th>
                            <th left-align>Date</th>
                            <th left-align>Voucher</th>
                            <th left-align>Bill Number</th>
                            <th left-align>Particulars</th>
                            <th right-align v-if="statementType == 2"><span style="word-spacing:2px">Amount (&#8377;)</span></th>
                            <th right-align v-if="statementType == 1"><span style="word-spacing:2px">To Receive (&#8377;)</span></th>
                            <th right-align v-if="statementType == 1"><span style="word-spacing:2px">To Pay (&#8377;)</span></th>
                            <th right-align v-if="statementType == 1"><span style="word-spacing:2px">Balance (&#8377;)</span></th>
                            <th left-align v-if="$root.TransactionSettings.ledgerStatementNotes" style="max-width: 68px;">Notes</th>
                        </tr>

                        <tr class="body" v-for="(row, index) in statementData.data.detailRows" :key="index">
                            
                            <td left-align>{{row.cols.mergeTransactionNumber ? row.cols.mergeTransactionNumber : ''}}</td>
                            <td left-align>
                                <span v-if="row.cols.transactionDate">{{row.cols.transactionDate | moment("DD-MMM-YYYY")}}</span>
                                <!-- <span v-else>-</span> -->
                            </td>
                            <td left-align>{{row.cols.transactionListId ? row.cols.transactionListId : ''}}</td>
                            <td left-align>{{row.cols.refNumber ? row.cols.refNumber : ''}}</td>
                            <td left-align>{{row.cols.party ? row.cols.party : ''}}</td>

                            <td right-align v-if="statementType == 2">
                                <span>{{$root.numberToLocale(row.cols.roundedAmount)}}</span>
                            </td>
                            <!-- <td right-align v-if="statementType == 2">
                                {{row.cols.debit ? 'Dr' : row.cols.credit ? 'Cr' : '-'}}
                            </td> -->
                            

                            <td right-align v-if="statementType == 1">
                                <span v-if="row.cols.debit">{{$root.numberToLocale(row.cols.debit)}}</span>
                                <!-- <span v-else>-</span> -->
                            </td>
                            <td right-align v-if="statementType == 1">
                                <span v-if="row.cols.credit">{{$root.numberToLocale(row.cols.credit)}}</span>
                                <!-- <span v-else>-</span> -->
                            </td>
                            <td right-align v-if="statementType == 1">
                                <span v-if="row.cols.partyBalance">{{$root.numberToLocale(row.cols.partyBalance)}}</span>
                                <!-- <span v-else>-</span> -->
                            </td>

                            <td left-align v-if="$root.TransactionSettings.ledgerStatementNotes" style="max-width: 68px;">{{row.cols.notes}}</td>
                        </tr>

                        <tr class="table-footer white-back" v-if="statementData.data.footerRow && statementType == 1">
                            <td left-align>Total</td>
                            <!-- <td left-align></td> -->
                            <td left-align></td>
                            <td left-align></td>
                            <td left-align></td>
                            <td left-align></td>

                            <td right-align v-if="statementType == 2">
                                <span v-if="statementData.data.footerRow && statementData.data.footerRow.amount">{{$root.numberToLocale(statementData.data.footerRow.totalAmount)}}</span>
                                <!-- <span v-else>-</span> -->
                            </td>
                            <!-- <td right-align v-if="statementType == 2">
                                {{statementData.data.footerRow.debit ? 'Dr' : statementData.data.footerRow.credit ? 'Cr' : '-'}}
                            </td> -->

                            <td right-align v-if="statementType == 1">
                                <span v-if="statementData.data.footerRow && statementData.data.footerRow.debit">{{$root.numberToLocale(statementData.data.footerRow.debit)}}</span>
                                <!-- <span v-else>-</span> -->
                            </td>
                            <td right-align v-if="statementType == 1">
                                <span v-if="statementData.data.footerRow && statementData.data.footerRow.credit">{{$root.numberToLocale(statementData.data.footerRow.credit)}}</span>
                                <!-- <span v-else>-</span> -->
                            </td>
                            <td right-align v-if="statementType == 1">
                                <span v-if="statementData.data.footerRow && statementData.data.footerRow.partyBalance">{{$root.numberToLocale(statementData.data.footerRow.partyBalance)}}</span>
                                <!-- <span v-else>-</span> -->
                            </td>

                            <td v-if="$root.TransactionSettings.ledgerStatementNotes" style="max-width: 68px;"></td>
                        </tr>
                    </tbody>

                </table>
            </div>

        </div>
    </div>
</template> 

<script>
    export default{
        // props:['statementData', 'statementType', 'opening']
        props:['statementData', 'statementType', 'statementView'],
        methods:{
            fn_getColSpanVal: function(){
                if(this.$root.TransactionSettings.ledgerStatementNotes){
                    if(this.statementType == 1){
                        return 9;
                    }
                    else{
                        return 7;
                    }
                }
                else{
                    if(this.statementType == 1){
                        return 9;
                    }
                    else{
                        return 6;
                    }
                }
            }
        }
    }
</script>

<style>
    .pa-2{
        padding:8px;
    }
    .ledger-statement.template-pdf table{
        width: 100%;
        border-collapse:  collapse;
    }
    .ledger-statement.template-pdf table .theader{
        margin-bottom: 10px;color:rgba(0,0,0,0.87) !important;border-bottom:1px #ddd solid;border-top:1px #ddd solid;
    }
    .ledger-statement.template-pdf table .theader{
        height: 36px;
    }
    .ledger-statement.template-pdf.statement-view table .theader{
        height: 28px;
    }
    .ledger-statement.template-pdf table tbody tr{
        height: 32px;
    }
    .ledger-statement.template-pdf.statement-view table tbody tr{
        height: 24px;
    }
    .ledger-statement.template-pdf table tr td, table tr th{
        padding: 0px 4px;
    }
    /*.ledger-statement.template-pdf tr.body:nth-child(odd) {
        background-color: rgba(75,119,190,0.2) !important;
    }*/

    .ledger-statement.template-pdf .divider-heavy{
        border-width: thin 4px 2px;margin-top: 16px;border-color: #999;
    }

    /*.ledger-statement.template-pdf .statement-summary{
        margin-bottom: 16px;
    }
    .ledger-statement.template-pdf .statement-summary .flex{
        border: 1px #ddd solid;
    }*/
    .ledger-statement.template-pdf .statement-summary .flex.middle-box{
        border-right: 1px #ddd solid;
    }
    .ledger-statement.template-pdf .statement-summary .flex.left-box{
        border-right: 1px #ddd solid;
    }
    .ledger-statement.template-pdf .statement-summary .header{
        padding: 8px 0px;background: ghostwhite;font-weight:600;
    }
    .ledger-statement.template-pdf .table-footer{
        font-weight:600;border-top:1px #ddd solid;height:42px;
    }
    .ledger-statement.template-pdf .watermark{
        width:auto;padding:4px 0px;
    }
    .ledger-statement.template-pdf .watermark.custom{
        border-radius:4px;
        padding:4px 10px !important;
        background:#fff !important;
        width:fit-content;
    }
    .ledger-statement.template-pdf .statement-header{
        min-height: 92px;
        background-color:#4b77be !important;
        color:#fff !important;
        -webkit-print-color-adjust: exact !important;
    }
    .ledger-statement.template-pdf.statement-view .statement-header{
        min-height: 72px;
    }
    .ledger-statement.template-pdf .party-details{
        width:240px;
        float:left;
    }
    .ledger-statement.template-pdf.statement-view .party-details{
        width:152px !important;
    }
    .details-business img{
        /* width:72px;
        height:72px; */
        max-width: 60px;
        max-height: 60px;
        float:left;
        margin-right: 8px;
    }
    /* .statement-view .details-business img{
        width:48px;height:48px;
    } */
</style>