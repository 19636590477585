<template>
    <div>

        <!-- <v-subheader>Reports</v-subheader> -->
        <v-toolbar flat :height="56">
            <v-toolbar-title>Reports</v-toolbar-title>
        </v-toolbar>

        <v-divider></v-divider>

        <div class="data-container grey-back">

            <v-alert type="info" icon="info_outline" font12 dense :value="true">Select an item to get details</v-alert>

            <!-- <v-list class="pa-0" dense v-for="(group, index) in filteredItems" :key="index" :class="{'mt-2':index!=0}">
                <v-subheader class="pl-4 pr-4">{{group.groupName}}</v-subheader>
                <v-divider></v-divider> -->
            <v-list class="pa-0" dense grey-back>
                <!-- <v-subheader class="pl-4 pr-4">{{group.groupName}}</v-subheader>
                <v-divider></v-divider> -->

                <v-list-item-group v-model="selectedItem" color="primary">

                    <template v-for="(group, index) in filteredItems">
                        
                        <div :class="{'mt-2':index!=0}" :key="'wrapper'+index" v-if="group.items && group.items.length && fn_hideReportGroup(group)">
                            <v-subheader class="pl-4 pr-4 white-back" :key="'subheader'+index">{{group.groupName}}</v-subheader>
                            <v-divider :key="group.groupName"></v-divider>
                        
                            <template v-for="(item, itemIndex) in group.items">
                                <v-list-item white-back :key="'listitem'+itemIndex" @click="fn_loadReport(item.reportId)" v-if="fn_hideReport(item.reportId)">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.name"></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <v-icon>chevron_right</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                                <!-- <v-divider v-if="group.items.length-1 != itemIndex" :key="item.name"></v-divider>     -->
                            </template>
                        </div>

                    </template>

                </v-list-item-group>
            </v-list>
        </div>
    </div>
</template>

<script>

let tagId = 'reports'

    export default{
        name: 'report_list',
        data(){
            return{
                reportGroups:require('../../config.js').configs.reportList,
                scrollPositionTop:0,
                error:null,
                show:false,
                loading:false,
                searchValue:'',
                selectedItem:null
            }
        },
        methods:{

            fn_loadReport: function(reportId){
                localStorage.setItem("clearfilters", 1);
                this.$router.push({path:"/"+this.$root.CurrentBusiness.BusinessID+"/reports/"+reportId});
            },

            fn_hideReportGroup: function(group){
                if(this.$root.CurrentBusiness.GSTRegistrationStatus==3 && group.groupName == 'GST Reports')
                    return false;
                else
                    return true;
            },
            fn_hideReport: function(reportId){
                if(this.$root.CurrentBusiness.GSTRegistrationStatus==1 && (reportId == 804))
                    return false;
                else if(this.$root.CurrentBusiness.GSTRegistrationStatus==2 && (reportId == 303 || reportId == 403 || reportId == 801 || reportId == 802 || reportId == 803))
                    return false;
                else if(this.$root.CurrentBusiness.GSTRegistrationStatus==3 && (reportId == 303 || reportId == 403))
                    return false;
                else if(!this.$root.TransactionSettings.branchDivision && (reportId == 306 || reportId == 307 || reportId == 407 || reportId == 408))
                    return false;
                else if(!this.$root.TransactionSettings.enableBatch && (reportId == 105 || reportId == 109))
					return false;
                else if(this.$root.appVersion < 209 && (reportId == 108 || reportId == 109))
					return false;
                else
                    return true;
            },

            fn_goto: function(reportid){
                this.$router.push({path:'/reports/'+this.$root.CurrentBusiness.BusinessID+'/'+reportid});
            },

            fn_showBottomNavigation: function () {

                let self = this;

                let navigationObject = {activeIndex: 1001};

                console.log('show navigation');

                this.showBottomNavigation(tagId, navigationObject, 
                  {
                    onSuccess: function(response){
                      console.log(response);
                    }, 
                    onError: function(error){
                      console.log(error);
                    }, 
                    // onChange: null, 
                    onMenuItemClick: function (tagId, menuId, menuAction) {
                      self.$root.fn_bottomNavigationItemClick(tagId, menuId, menuAction)
                    }
                  }
                );
            },

            fn_hideBottomNavigation: function () {

                this.hideBottomNavigation({
                    onSuccess: function(response){
                      console.log(response);
                    }, 
                    onError: function(error){
                      console.log(error);
                    }
                  }
                );

            },

            fn_showWebView: function () {
                this.showWebView(tagId, {}, 
                  {
                    onSuccess: function(response){
                      console.log(response);
                    }, 
                    onError: function(error){
                      console.log(error);
                    }, 
                  }
                );
            }
        },
        mounted: function(){
            console.log('load');
        },
        computed:{
            filteredItems: function () {
                let searchText = this.searchValue.toLowerCase();

                let groups = JSON.parse(JSON.stringify(this.reportGroups));

                return groups.filter(group => {
                    return group.items = group.items.filter(item => {
                        let fieldName = item.name.toLowerCase();
                        return fieldName.match(searchText);
                    })
                })
                
             }
        }
    }
</script>