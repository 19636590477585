<template>
    <div>
        <v-dialog v-model="dialog_gst" no-click-animation persistent lazy transition="slide-y-transition" max-width="460px">
          <v-card>
            <v-toolbar flat :height="56">
                <v-btn icon @click="closeDialogWeb"><v-icon>close</v-icon></v-btn>
                <v-toolbar-title>GST Details</v-toolbar-title>
            </v-toolbar>

            <v-divider></v-divider>

            <v-card-text class="pt-4 pl-2 pr-2" dialog-card-content>

                <ValidationObserver ref="observer">

                    <v-form @submit.prevent="submit">
                    
                        <!-- <v-layout row wrap white-back ma-0 pt-4 pb-3 v-if="selectedGstType && selectedGstType.id && (selectedGstType.id != 104 || (selectedGstType.id == 104 && selectedGstState && selectedGstState.itemId))"> -->
                        <v-layout row wrap white-back ma-0>

                            <!-- {{selectedGstType}} -->

                                <v-flex xs6 sm6 md6 pl-2 pr-2>

                                    <ValidationProvider name="GST Type" rules="required" v-slot="{ errors }">

                                        <v-autocomplete
                                            :items="$parent.gstTypes"
                                            v-model="Form.selectedGstType"
                                            label="Select GST Type"
                                            item-value="id"
                                            item-text="title"
                                            return-object
                                            autocomplete="null"
                                            required
                                            :error-messages="errors">
                                        </v-autocomplete>

                                    </ValidationProvider>

                                </v-flex>

                                <v-flex xs6 sm6 md6 pl-2 pr-2 v-if="Form.selectedGstType && Form.selectedGstType.id!=105">

                                    <ValidationProvider name="State" rules="required" v-slot="{ errors }">

                                        <v-autocomplete
                                            :items="$parent.states ? $parent.states : []"
                                            v-model="Form.selectedGstState"
                                            label="State/Place of Supply"
                                            item-value="itemId"
                                            item-text="itemName"
                                            return-object
                                            required
                                            autocomplete="null"
                                            :error-messages="errors">
                                        </v-autocomplete>

                                    </ValidationProvider>

                                </v-flex>

                                <v-flex xs6 sm6 md6 pl-2 pr-2 v-if="Form.selectedGstType && (Form.selectedGstType.id==101 || Form.selectedGstType.id==102 || Form.selectedGstType.id==106 || Form.selectedGstType.id==107)">

                                    <ValidationProvider name="GST Number" rules="required|verify_gstnumber" v-slot="{ errors }">
                                        <v-text-field 
                                            uppercase  
                                            label="GST Number (GSTIN)" 
                                            @input="fn_gstNumberInUpperCase"
                                            placeholder=" " 
                                            v-model="Form.gstNumber"
                                            autocomplete="null"
                                            :hide-details="!errors || !errors.length"
                                            :error-messages="errors">
                                        </v-text-field>
                                    </ValidationProvider>
                                    <div class="pt-1" right-align font12 light-opacity-color>Example: 12ABCDE1234A9Z9</div>
                                </v-flex>

                                <v-flex xs6 sm6 md6 pl-2 pr-2 v-if="Form.selectedGstType && Form.selectedGstType.id!=105">

                                <ValidationProvider name="Pan No" rules="verify_pannumber" v-slot="{ errors }">
                                    <v-text-field 
                                        uppercase  
                                        label="PAN No" 
                                        @input="fn_panNumberInUpperCase"
                                        placeholder=" " 
                                        autocomplete="null"
                                        v-model="Form.panNumber"
                                        required
                                        :error-messages="errors">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-flex>

                                <v-flex xs6 sm6 md6 pl-2 pr-2 v-if="Form.selectedGstType && Form.selectedGstType.id==105">

                                    <ValidationProvider name="Country" rules="required" v-slot="{ errors }">

                                        <v-autocomplete
                                            :items="$parent.countries ? $parent.countries : []"
                                            v-model="Form.selectedGstCountry"
                                            label="Country"
                                            item-value="itemId"
                                            item-text="itemName"
                                            autocomplete="null"
                                            return-object
                                            required
                                            :error-messages="errors">
                                        </v-autocomplete>

                                    </ValidationProvider>

                                </v-flex>

                        </v-layout>

                    </v-form>

                </ValidationObserver>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDialogWeb">close</v-btn>
              <v-btn color="blue darken-1" text @click="fn_checkValidations('setdetails')">Done</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

extend('verify_gstnumber', value => {

//   var strongRegex = new RegExp("^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$");
    var strongRegex = new RegExp("^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1})+$");
    var strongRegexGov = new RegExp("^[0-9]{2}[A-Za-z]{4}[0-9]{5}[A-Za-z]{1}[0-9]{1}[A-Za-z]{2}$");
    let isValid = strongRegex.test(value) || strongRegexGov.test(value);

  if(isValid){
    return true;
  }

  return 'GST Number is not valid.';
});
export default{
    props:['saveGstDetails'],
    data(){	
        return{
            "Form":{
                "gstNumber":null, 
                "panNumber":null, 
                "currency":null, 
                "country":null,
                "selectedGstType":null,
                "selectedGstState":null,
                "selectedGstCountry":null,
            },
            "dialog_gst": false,
        }
    },
    components:{
        ValidationProvider,
        ValidationObserver
    },
    methods:{
        openDialogWeb: function () {
            this.Form.gstNumber = this.$parent.Form.gstNumber;
            // this.Form.placeOfSupplyId = this.$parent.Form.placeOfSupplyId;
            this.Form.panNumber = this.$parent.Form.panNumber;
            this.Form.currency = this.$parent.Form.currency;
            // this.Form.countryId = this.$parent.Form.countryId;
            this.Form.country = this.$parent.Form.country;
            this.Form.selectedGstType = this.$parent.Form.gstType ?  {"id":this.$parent.Form.gstType, "title":this.$parent.fn_getGstTypeName(this.$parent.Form.gstType)} : JSON.parse(JSON.stringify(this.$parent.Form.selectedGstType));
            this.Form.selectedGstState = this.$parent.Form.placeOfSupplyId ? {"itemId":this.$parent.Form.placeOfSupplyId, "itemName":this.$parent.Form.placeOfSupply} : null;
            this.Form.selectedGstCountry = this.$parent.Form.country ?  {"itemId":this.$parent.Form.countryId, "itemName":this.$parent.Form.country} : null;
            this.dialog_gst = true;
        },
        closeDialogWeb: function () {
            this.dialog_gst = false;
        },
        fn_gstNumberInUpperCase: function(){
            this.Form.gstNumber = this.Form.gstNumber.toUpperCase();
        },
        fn_panNumberInUpperCase: function(){
            this.Form.panNumber = this.Form.panNumber.toUpperCase();
        },
        fn_checkValidations: function () {
            this.$refs.observer.validate().then((result) => {
                if(result){
                    this.saveGstDetails(this.Form);
                }
            })
            
            // this.$validator.validateAll('fields').then((result) => {
            // 	result ? this.checkAndUpdateGstType(this.accountGstFieldTemp) : '';
            // });
        },
    }
}
</script>