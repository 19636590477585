<template>
    <div>
        <v-dialog v-model="dialog_form" content-class="half-screen-dialog" no-click-animation scrollable persistent transition="slide-y-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card no-border-radius grey-back>
                <v-toolbar :height="56" flat>
                    <v-btn icon @click="closeDialog"><v-icon>arrow_back</v-icon></v-btn>
                    <v-toolbar-title>{{$parent.toolbarTitle}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="primary" text @click="$parent.fn_checkValidations"><v-icon left>save</v-icon> Save</v-btn> -->
                   <!--  <div>
                        <div style="float: left;margin-right: 12px;margin-top: 6px;color: rgba(0,0,0,0.57);">Primary Group</div>
                        <v-switch class="hide-margins" hide-details v-model="$parent.$parent.$parent.Form.isPrimaryAccountGroup"></v-switch>
                    </div> -->
                </v-toolbar>

                <v-divider class="noprint"></v-divider>

                <div class="data-container grey-back">

                    <ValidationObserver ref="observer">

                        <v-form @submit.prevent="submit">
                            <v-layout row wrap ma-0 pb-4>

                                <v-layout ma-0 wrap pt-2 white-back class="section-wrapper">

                                    <v-flex xs12 sm12 md12 form-item pb-3>
                                        <!-- <v-radio-group v-model="$parent.Form.groupTypeId" row @change="$parent.fn_updateUnit('865cf461-99b6-4b4f-9678-4cd11c3aab69', 'Nos')"> -->
                                        <!-- <v-radio-group v-model="$parent.Form.groupTypeId" row @change="$parent.fn_updateUnit($event, 'change')"> -->
                                        <v-radio-group v-model="$parent.Form.groupTypeId" row @change="$parent.fn_changeDefaultUnit">
                                          <v-radio label="Goods" :value="101"></v-radio>
                                          <v-radio label="Service" :value="102"></v-radio>
                                        </v-radio-group>
                                    </v-flex>

                                    <!-- <v-flex form-item :class="[$parent.Form.groupTypeId == 101 ? 'xs6 sm6 md6' : 'xs12 sm12 md12']"> -->
                                    <v-flex form-item xs6 sm6 md6 mb-2>
                                        <ValidationProvider name="Item Name" rules="required|max250" v-slot="{ errors }">
                                             <v-text-field 
                                                 v-model="$parent.Form.name" 
                                                 label="Item Name"
                                                 required
                                                 autocomplete="null"
                                                 :error-messages="errors">
                                             </v-text-field>
                                        </ValidationProvider>
                                     </v-flex>

                                    <v-flex form-item xs6 sm6 md6 mb-2>
                                       <v-text-field  v-model="$parent.Form.productCode" label="Item Code" placeholder=" "></v-text-field>
                                    </v-flex>

                                    <v-flex form-item xs6 sm6 md6 v-if="$parent.Form.groupTypeId==102">
                                        <ValidationProvider name="Charges" rules="min_value:0|decimal2" v-slot="{ errors }">
                                            <v-text-field 
                                                v-model.number="$parent.Form.charges" 
                                                prefix="₹"
                                                type="number" 
                                                label="Charges"
                                                autocomplete="null"
                                                :error-messages="errors">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-flex>
                                    
                                </v-layout>

                                <v-alert class="pa-2 full-width no-border-radius mb-0 font12" :height="40" :value="true" color="info" transition="slide-y-transition" v-if="$parent.lastItemConfig && ($parent.lastItemConfig.hsnCode || $parent.lastItemConfig.taxCategoryName)">
                                  <v-layout ma-0 row wrap style="align-items:center">
                                    <v-flex xs8 sm8 md8>Copy recent GST details</v-flex>
                                    <v-flex xs4 sm4 md4 right-align>
                                      <v-btn icon small @click="$parent.fn_copyLastConfigs"><v-icon font16>content_copy</v-icon></v-btn>
                                    </v-flex>
                                  </v-layout>
                                </v-alert>

                                <v-layout full-width ma-0 wrap pt-2 white-back class="section-wrapper" v-if="$root.CurrentBusiness && $root.CurrentBusiness.GSTRegistrationStatus==1">

                                    <v-flex form-item xs6 sm6 md6 pr-4>

                                        <v-autocomplete
                                            :items="$parent.taxCategories ? $parent.taxCategories : []"
                                            v-model="$parent.Form.selectedTaxCategory"
                                            label="GST"
                                            item-value="taxCategoryId"
                                            item-text="taxCategoryName"
                                            autocomplete="null"
                                            return-object
                                            append>

                                            <v-list-item slot="prepend-item" class="primary--text" @click="fn_newTaxCategory">
                                                <v-list-item-title>Create New</v-list-item-title>
                                            </v-list-item>

                                        </v-autocomplete>

                                    </v-flex>

                                    <v-flex form-item xs6 sm6 md6 pl-4 v-if="$root.CurrentBusiness.HSNApplicable">

                                        <ValidationProvider name="HSN Code" rules="verify_hsn" v-slot="{ errors }">
                                           <v-text-field 
                                                id="hsnCode"  
                                                v-model="$parent.Form.hsnCode" 
                                                label="HSN Code"
                                                autocomplete="null"
                                                :error-messages="errors">
                                            </v-text-field>
                                        </ValidationProvider>
                                        
                                    </v-flex>
                                </v-layout>

                                <!-- <v-layout ma-0 wrap pt-2 pb-3 white-back v-if="$parent.Form.groupTypeId==101" class="section-wrapper"> -->
                                <v-layout ma-0 wrap pt-4 pb-3 mt-2 white-back class="section-wrapper">

                                    <v-flex form-item :class="[$parent.Form.inventory == 1 && $root.appVersion >= 209 ? 'xs6 sm6 md6' : 'xs12 sm12 md12']">

                                        <ValidationProvider name="Unit" rules="required" v-slot="{ errors }">

                                            <v-autocomplete
                                                :items="$parent.units ? $parent.units : []"
                                                :disabled="$parent.Form.unitInUse && $root.appVersion >= 209"
                                                v-model="$parent.Form.selectedUnit"
                                                @input="$parent.fn_updateUnit($parent.Form.selectedUnit.itemId, $parent.Form.selectedUnit.itemName)"
                                                label="Primary Unit"
                                                item-value="itemId"
                                                item-text="itemName"
                                                autocomplete="null"
                                                return-object
                                                append
                                                required
                                                :error-messages="errors">

                                                <v-list-item slot="prepend-item" class="primary--text" @click="fn_newUnit('primaryunit')">
                                                    <v-list-item-title>Create New</v-list-item-title>
                                                </v-list-item>

                                            </v-autocomplete>

                                        </ValidationProvider>

                                    </v-flex>

                                    <v-flex form-item xs6 sm6 md6 v-if="$parent.Form.inventory == 1 && $root.appVersion >= 209">

                                        <!-- <ValidationProvider name="Unit" rules="required" v-slot="{ errors }"> -->

                                            <v-autocomplete
                                                :items="$parent.units ? $parent.units : []"
                                                v-model="$parent.Form.selectedSecondaryUnit"
                                                @input="$parent.fn_updateSecondaryUnit($parent.Form.selectedSecondaryUnit.itemId, $parent.Form.selectedSecondaryUnit.itemName)"
                                                label="Secondary Unit"
                                                item-value="itemId"
                                                item-text="itemName"
                                                autocomplete="null"
                                                :hide-details="true"
                                                return-object
                                                :append-icon="$parent.Form.selectedSecondaryUnit ? 'clear' : 'arrow_drop_down'"
                                                @click:append="$parent.fn_checkClearSecondary">

                                                <v-list-item slot="prepend-item" class="primary--text" @click="fn_newUnit('secondaryunit')">
                                                    <v-list-item-title>Create New</v-list-item-title>
                                                </v-list-item>

                                            </v-autocomplete>

                                            <!-- <v-select
                                                :items="$parent.units ? $parent.units : []"
                                                v-model="$parent.Form.selectedSecondaryUnit"
                                                @input="$parent.fn_updateSecondaryUnit($parent.Form.selectedSecondaryUnit.itemId, $parent.Form.selectedSecondaryUnit.itemName)"
                                                label="Secondary Unit"
                                                item-value="itemId"
                                                item-text="itemName"
                                                autocomplete="null"
                                                :hide-details="true"
                                                return-object
                                                :append-icon="$parent.Form.selectedSecondaryUnit ? 'clear' : 'arrow_drop_down'"
                                                @click:append="$parent.fn_checkClearSecondary">

                                                <v-list-item slot="prepend-item" class="primary--text" @click="fn_newUnit('secondaryunit')">
                                                    <v-list-item-title>Create New</v-list-item-title>
                                                </v-list-item>

                                            </v-select> -->

                                        <!-- </ValidationProvider> -->

                                        </v-flex>

                                        <v-flex xs12 sm12 md12 right-align pr-4 light-opacity-color v-if="$parent.Form.selectedSecondaryUnit && $parent.Form.primaryUnitPackQuantity && $parent.Form.secondaryUnitPackQuantity">{{ $parent.Form.primaryUnitPackQuantity }} {{ $parent.Form.selectedUnit.itemName }} = {{ $parent.Form.secondaryUnitPackQuantity }} {{ $parent.Form.selectedSecondaryUnit.itemName }}</v-flex>

                                    <!-- <v-flex xs12 sm12 md12>
                                        <v-flex xs12 sm12 md12 form-item @click="$parent.fn_openDialogAddUnit">
                                            <v-text-field  
                                                    readonly 
                                                    v-model="$parent.Form.unitName" 
                                                    label="Primary Unit" 
                                                    placeholder=" " 
                                                    append-icon="arrow_drop_down"
                                                    required>
                                                </v-text-field>
                                            </v-flex>
                                    </v-flex> -->
                                </v-layout>

                                <!-- <v-layout row wrap ma-0 v-if="$parent.Form.selectedSecondaryUnit">
                                    <template>
                                            <v-flex xs12 sm12 md12 pa-3>Conversion Rate</v-flex>

                                            <v-flex xs5 sm5 md5 pl-3>
                                                <v-text-field
                                                    :label="$parent.Form.selectedUnit.itemName"
                                                    placeholder=" "
                                                    v-model="$parent.Form.primaryUnitPackQuantity"
                                                    required
                                                ></v-text-field>
                                            </v-flex>

                                            <v-flex xs2 sm2 md2 center-align style="display: flex; align-items: center;justify-content: center;">=</v-flex>

                                            <v-flex xs5 sm5 md5 pr-3>
                                                <v-text-field
                                                    :label="$parent.Form.selectedSecondaryUnit.itemName"
                                                    placeholder=" "
                                                    v-model="$parent.Form.secondaryUnitPackQuantity"
                                                    required
                                                ></v-text-field>
                                            </v-flex>

                                            
                                        </template>
                                </v-layout> -->

                                <v-layout ma-0 wrap pt-4 pb-3 white-back class="section-wrapper mt-2">

                                    <template v-if="$parent.Form.groupTypeId==101">

                                        <v-flex form-item xs4 sm4 md4 sibling-form-field v-if="$parent.Form.inventory && $root.TransactionSettings.mrpWiseStock">
                                            <ValidationProvider name="MRP" rules="min_value:0|decimal2" v-slot="{ errors }">
                                            <v-text-field 
                                                    v-model.number="$parent.Form.mrp" 
                                                    type="number" 
                                                    label="MRP"
                                                    autocomplete="null"
                                                    :error-messages="errors">
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-flex>

                                        <v-flex form-item xs4 sm4 md4>
                                            <ValidationProvider name="Selling Price" rules="min_value:0|decimal2" v-slot="{ errors }">
                                            <v-text-field 
                                                    prefix="₹"
                                                    v-model.number="$parent.Form.sellingPrice" 
                                                    type="number" 
                                                    autocomplete="null"
                                                    :label="$parent.Form.inventory ? 'Sale Price' : 'Price'"
                                                    :error-messages="errors">
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-flex>

                                        <v-flex form-item xs4 sm4 md4 v-if="$parent.Form.inventory">

                                            <ValidationProvider name="Wholesale Price" rules="min_value:0|decimal2" v-slot="{ errors }">
                                            <v-text-field 
                                                    prefix="₹"
                                                    v-model.number="$parent.Form.wholeSalePrice" 
                                                    type="number" 
                                                    label="Wholesale Price"
                                                    autocomplete="null"
                                                    :error-messages="errors">
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-flex>

                                        <v-flex form-item xs12 sm12 md12 v-if="$parent.Form.inventory">
                                            <ValidationProvider name="Purchase Rate" rules="min_value:0|decimal2" v-slot="{ errors }">
                                                <v-text-field 
                                                    prefix="₹"
                                                    v-model.number="$parent.Form.purchaseRate" 
                                                    type="number" 
                                                    label="Purchase Rate"
                                                    autocomplete="null"
                                                    :error-messages="errors">
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-flex>

                                    </template>


                                </v-layout>

                                <v-layout white-back ma-0 mt-2 pt-4 pb-3 row wrap v-if="$parent.Form.groupTypeId==101 && $parent.Form.inventory == 1">

                                    <v-flex xs6 sm6 md6 form-item>
                                        <ValidationProvider name="opening" rules="min_value:0" v-slot="{ errors }">
                                            <v-text-field   
                                              label="Opening"
                                              type="number"
                                              autocomplete="null"
                                              clearable
                                              @input="$root.fn_boundDecimalLength($parent.Form, 'openingQty')"
                                              v-model="$parent.Form.openingQty"
                                              :suffix="$parent.Form.selectedOpeningUnitId && $parent.Form.selectedSecondaryUnit && $parent.Form.selectedOpeningUnitId == $parent.Form.selectedSecondaryUnit.itemId ? $parent.Form.selectedSecondaryUnit.itemName : $parent.Form.unitName"
                                              :error-messages="errors">
                                            </v-text-field>
                                        </ValidationProvider>

                                        <!-- :suffix="$parent.Form.selectedUnit && $parent.Form.selectedUnit.itemName ? $parent.Form.selectedUnit.itemName : ''" -->

                                        <v-radio-group v-model="$parent.Form.selectedOpeningUnitId" v-if="$parent.Form.selectedSecondaryUnit && $parent.Form.selectedSecondaryUnit.itemId" row @change="fn_unitChange">
                                          <v-radio :label="$parent.Form.unitName" :value="$parent.Form.unitId"></v-radio>
                                          <v-radio :label="$parent.Form.selectedSecondaryUnit.itemName" :value="$parent.Form.selectedSecondaryUnit.itemId"></v-radio>
                                        </v-radio-group>
                                        
                                    </v-flex>

                                  <v-flex xs6 sm6 md6 form-item prepend-text>
                                          
                                    <ValidationProvider name="rate" rules="min_value:0|decimal2" v-slot="{ errors }">
                                      <v-text-field   
                                          label="Rate"
                                          type="number"
                                          prefix="₹"
                                          autocomplete="null"
                                          v-model.number="$parent.Form.openingRate"
                                          :hide-details="!errors || !errors.length"
                                          :error-messages="errors">
                                        </v-text-field>

                                        <div right-align class="pt-2" font12>Value: &#8377; {{$root.numberToLocale($parent.totalAmount)}}</div>
                                    </ValidationProvider>

                                  </v-flex>

                                </v-layout>

                                <div class="margin-bottom-ten full-width" v-if="$root.TransactionSettings.enableBatch && $parent.Form.inventory && $parent.Form.groupTypeId == 101">

                                    <template v-if="$parent.hasSeries && !$parent.hasOnlySeries">
                                        <v-radio-group class="pt-3 pl-3 pr-3 pb-1" white-background hide-details v-model="$parent.Form.selectedBatchType" row>
                                            <v-radio label="Batch" :value="1"></v-radio>
                                            <v-radio label="Series" :value="2"></v-radio>
                                        </v-radio-group>
                                        <v-divider></v-divider>
                                    </template>

                                    <!-- <v-subheader class="pr-0 white-background">Batches <v-spacer></v-spacer> <v-btn color="primary"  capitalize flat class="mr-0" @click="fn_addBatch">Add Batch <v-icon color="primary" class="ml-1">add</v-icon></v-btn> </v-subheader> -->
                                    <v-subheader class="white-background">
                                        <!-- <template v-if="$parent.Form.selectedBatchType == 2"><span v-for="(field, index) in $root.TransactionSettings.batchFields" :key="field.title" v-if="field.id == 'field1' || field.id == 'field2' || field.id == 'field3'">{{ field.title }}<span v-if="$root.TransactionSettings.batchFields.length - 1 != index">/</span></span></template> -->
                                        <template>{{ $parent.Form.selectedBatchType == 2 ? 'Series' : 'Batches'}}</template>
                                        <v-spacer></v-spacer> 
                                        <v-btn color="primary" capitalize text outlined small @click="$parent.fn_addBatch">Add<v-icon color="primary" class="ml-1" font16>add</v-icon></v-btn> 
                                    </v-subheader>

                                    <v-divider v-if="$parent.Form.batches && $parent.Form.batches.length"></v-divider>
                                    <template v-for="(batch, batchIndex) in $parent.Form.batches">
                                        <v-card flat :key="batchIndex">
                                            <v-card-text class="pa-4">
                                                <v-layout class="batch_layout" ma-0 row wrap>
                                                    <v-flex xs3 sm3 md3 mb-2 v-if="batch.batchNumber">
                                                        <div light-opacity-color>Batch No.</div>
                                                        <div>{{ batch.batchNumber }}</div>
                                                    </v-flex>
                                                    <v-flex xs3 sm3 md3 mb-2 v-if="batch.batchSize">
                                                        <div light-opacity-color>Size</div>
                                                        <div>{{ batch.batchSize }}</div>
                                                    </v-flex>
                                                    <v-flex xs3 sm3 md3 mb-2 v-if="batch.batchMRP">
                                                        <div light-opacity-color>MRP</div>
                                                        <div>{{ batch.batchMRP }}</div>
                                                    </v-flex>
                                                    <v-flex xs3 sm3 md3 mb-2 v-if="batch.mfgDate">
                                                        <div light-opacity-color>Mfg. Date</div>
                                                        <div v-if="batch.mfgDate">{{ batch.mfgDate | moment('DD-MMM-YYYY') }}</div>
                                                    </v-flex>
                                                    <v-flex xs3 sm3 md3 mb-2 v-if="batch.expDate">
                                                        <div light-opacity-color>Exp. Date</div>
                                                        <div v-if="batch.expDate">{{ batch.expDate | moment('DD-MMM-YYYY') }}</div>
                                                    </v-flex>
                                                    <v-flex xs3 sm3 md3 mb-2 v-if="!batch.field1 && !batch.field2 && !batch.field3">
                                                        <div light-opacity-color>Opening</div>
                                                        <div>{{ batch.opening ? batch.opening : 0  }}</div>
                                                    </v-flex>
                                                    <v-flex xs3 sm3 md3 mb-2 v-if="batch.purchaseRate">
                                                        <div light-opacity-color>Purchase Rate</div>
                                                        <div>{{ batch.purchaseRate }}</div>
                                                    </v-flex>
                                                    <v-flex xs3 sm3 md3 mb-2 v-if="batch.field1">
                                                        <div light-opacity-color>Serial Number</div>
                                                        <div>{{ batch.field1 }}</div>
                                                    </v-flex>
                                                    <v-flex xs3 sm3 md3 mb-2 v-if="batch.field2">
                                                        <div light-opacity-color>IMEI Number</div>
                                                        <div>{{ batch.field2 }}</div>
                                                    </v-flex>
                                                    <v-flex xs3 sm3 md3 mb-2 v-if="batch.field3">
                                                        <div light-opacity-color>Part Number</div>
                                                        <div>{{ batch.field3 }}</div>
                                                    </v-flex>
                                                    <v-flex xs3 sm3 md3 mb-2 v-if="batch.field4">
                                                        <div light-opacity-color>Model Number</div>
                                                        <div>{{ batch.field4 }}</div>
                                                    </v-flex>

                                                    <v-flex xs12 sm12 md12 redcolor pb-2 v-if="batch.expDate && $parent.fn_checkDate(batch)">
                                                        <v-icon class="mr-1 font16 redcolor">info_outline</v-icon> Expired {{ $parent.fn_getDate(batch) }}
                                                    </v-flex>
                                                    <!-- <v-flex xs4 sm4 md4 mb-2 v-if="item.selectedBatchType == 1 && (item.batches.length > 1 || item.batches.length == 1 && batch.productQty != item.productVariants[0].qty)">
                                                        <div light-opacity-color>Quantity</div>
                                                        <div>{{ batch.productQty }}</div>
                                                    </v-flex> -->
                                                </v-layout>
                                                <!-- <v-layout row wrap>
                                                    <v-flex xs4 sm4 md4 mb-2 v-for="(field, fieldIndex) in $root.TransactionSettings.batchFields" :key="fieldIndex" v-if="batch[field.id]">
                                                        <div light-opacity-color>{{ field.title }}</div>
                                                        <div v-if="field.type == 'date'">{{ batch[field.id] | moment('DD-MMM-YYYY') }}</div>
                                                        <div v-else>{{ batch[field.id] }}</div>
                                                    </v-flex>
                                                </v-layout> -->
                                            </v-card-text>
                                            <v-card-actions class="pa-0 ma-0">
                                                <v-spacer></v-spacer>
                                                <template v-if="$root.appVersion >= 196">
                                                    <v-btn color="red" capitalize text small @click="$parent.fn_removeBatches(batch, batchIndex)">Remove</v-btn>
                                                </template>
                                                <template v-else>
                                                    <v-btn color="red" capitalize text small v-if="$parent.Form.isNew" @click="$parent.Form.batches.splice(batchIndex, 1)">Remove</v-btn>
                                                </template>
                                            </v-card-actions>
                                        </v-card>
                                        <v-divider :key="'divider'+batchIndex" v-if="$parent.Form.batches.length - 1 != batchIndex"></v-divider>
                                    </template>

                                    <!-- <v-list two-line nopadding v-if="Form.batches && Form.batches.length">
                                        <v-list-tile v-for="(batch, batchIndex) in Form.batches" :key="batchIndex">
                                            <v-list-tile-content>
                                                <v-list-tile-sub-title>Batch Number: {{batch.batchNumber}}</v-list-tile-sub-title>
                                                <v-list-tile-sub-title>Opening: {{batch.opening ? batch.opening : 0}} {{ Form.unitName }}</v-list-tile-sub-title>
                                            </v-list-tile-content>
                                        </v-list-tile>
                                    </v-list> -->
                                    </div>

                                <v-layout mt-2 ma-0 white-back full-width row wrap>

                                    <v-expansion-panels nomargin flat v-model="$parent.isExpanded">

                                        <v-expansion-panel>

                                            <v-expansion-panel-header>
                                                <template>
                                                    <v-subheader dense class="pa-0">Other Fields</v-subheader>
                                                </template>
                                            </v-expansion-panel-header>

                                          <v-divider></v-divider>

                                            <v-expansion-panel-content>

                                                <v-layout ma-0 row wrap pt-3>
                                                
                                                    <v-flex form-item xs12 sm4 md4 pr-4>

                                                        <v-autocomplete
                                                            :items="$parent.productCategories ? $parent.productCategories : []"
                                                            v-model="$parent.Form.selectedProductCategory"
                                                            label="Category"
                                                            item-value="itemId"
                                                            item-text="itemName"
                                                            autocomplete="null"
                                                            return-object
                                                            append>

                                                            <v-list-item slot="prepend-item" class="primary--text" @click="fn_newCategory">
                                                                <v-list-item-title>Create New</v-list-item-title>
                                                            </v-list-item>

                                                        </v-autocomplete>

                                                    </v-flex>

                                                    <v-flex form-item xs12 sm4 md4 pr-4 pl-4 v-if="$parent.Form.groupTypeId==101 && $parent.Form.inventory">
                                                        
                                                        <v-autocomplete
                                                            :items="$parent.brands ? $parent.brands : []"
                                                            v-model="$parent.Form.selectedBrand"
                                                            label="Brand"
                                                            item-value="itemId"
                                                            item-text="itemName"
                                                            autocomplete="null"
                                                            return-object
                                                            append>

                                                            <v-list-item slot="prepend-item" class="primary--text" @click="$parent.fn_newBrand">
                                                                <v-list-item-title>Create New</v-list-item-title>
                                                            </v-list-item>

                                                        </v-autocomplete>

                                                    </v-flex>

                                                    <v-flex form-item xs12 sm4 md4 pl-4 v-if="$parent.Form.groupTypeId==101 && $parent.Form.inventory">

                                                        <v-autocomplete
                                                            :items="$parent.companies ? $parent.companies : []"
                                                            v-model="$parent.Form.selectedCompany"
                                                            label="Company"
                                                            item-value="itemId"
                                                            item-text="itemName"
                                                            autocomplete="null"
                                                            return-object
                                                            append>

                                                            <v-list-item slot="prepend-item" class="primary--text" @click="$parent.fn_newCompany">
                                                                <v-list-item-title>Create New</v-list-item-title>
                                                            </v-list-item>

                                                        </v-autocomplete>

                                                    </v-flex>

                                                    <v-flex form-item xs12 sm12 md12>
                                                       <!-- <v-text-field  v-model="$parent.Form.description" label="Description" placeholder=" " autocomplete="null"></v-text-field> -->
                                                       <ValidationProvider name="Description" rules="max:255" v-slot="{ errors }">
                                                        <v-textarea 
                                                            v-model="$parent.Form.description" 
                                                            label="Description"
                                                            rows="2"
                                                            :counter="255"
                                                            :error-messages="errors">
                                                        </v-textarea>
                                                    </ValidationProvider>
                                                    </v-flex>

                                                </v-layout>
                                            </v-expansion-panel-content>

                                        </v-expansion-panel>

                                    </v-expansion-panels>

                                </v-layout>

                            </v-layout> 
                        </v-form>

                    </ValidationObserver>
                </div>


                <v-card-actions white-back>
                    <v-layout row wrap ma-0>

                        <v-flex form-item xs12 sm12 md12 height48>
                            <v-btn class="save_btn" no-border-radius color="primary" full-height nomargin block no-round-borders noshadow :disabled="$parent.disableOnSave" :key="$parent.saveBtnKey" @click.once="fn_validateForm">Save</v-btn>
                        </v-flex>

                    </v-layout>
                </v-card-actions>
                  

            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import { min_value } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

// extend('min_value:0|decimal2', value=>{
//     if(value>=0){
//         return true;
//     }

//     return 'This field must be 0 or more.'
// });

extend('min_value', {
    ...min_value,
    message: 'This field must be 0 or more.'
})

extend('decimal2', value =>     {

  let decimalCount = 0;
  if(Math.floor(value) != value){
     decimalCount = value.toString().split(".")[1].length || 0;
  }

  if(decimalCount <= 2){
    return true;
  }

  return 'This field must be numeric and may contain 2 decimal points.';

})

extend('verify_hsn', value => {
    if(value.length == 4 || value.length == 6 || value.length == 8){
        return true;
    }

    return 'HSN should be either 4, 6 or 8 digits.';
});

// extend('max', max);

extend('max250', value => {
    if(value.length <= 250){
        return true;
    }
    
    return 'This field can not be greater than 250 characters.'
})

    export default{
        props:['closeDialog'],
        data(){
            return{
                dialog_form: false
            }
        },
        components:{
            // 'gstr-sub-report':gstrSubReport
            ValidationProvider,
            ValidationObserver
        },
        methods:{
            openDialogWeb: function () {
                this.dialog_form = true;
                this.$parent.disableOnSave = false;
            },
            closeDialogWeb: function () {
                this.dialog_form = false;
            },
            fn_validateForm: function(){
                this.$parent.disableOnSave = true;
                this.$refs.observer.validate().then((result) => {
                    if(result) {

                        if(this.$root.TransactionSettings.enableBatch && this.$parent.Form.batches && this.$parent.Form.batches.length){
							let batchTotal = 0;
							for(let i=0; i<this.$parent.Form.batches.length; i++){
								batchTotal += this.$parent.Form.batches[i].opening;
							}
							let openingQty = this.$parent.Form.openingQty ? this.$parent.Form.openingQty : 0; 
							if(openingQty != batchTotal){
                                this.$parent.saveBtnKey++;
                                this.$parent.disableOnSave = false;
                                this.$eventHub.$emit('snackbarAlert', {msg:"Opening quantity not matched with batch total opening"});
								return;
							}
						}

                        this.$parent.fn_checkValidations();
                    }
                    else{
                        this.$parent.saveBtnKey++;
                        this.$parent.disableOnSave = false;
                        this.fn_scrollToElement('error--text');
                    }
                });
            },
            fn_new: function(){
              this.closeDialogWeb();
              setTimeout(()=>{
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/account_group/items/new', query:{tagId:this.guidWeb(), ls:1}});
              },100);
            },
            fn_focusAutocomplete: function(ref){
                setTimeout(()=>{
                    document.getElementById(ref).click();
                },100)
                // val ? setTimeout(()=>this.$refs[ref].focus(),100) : '';
            },

            fn_newTaxCategory: function(){
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/tax_category/items/new', query:{tagId:this.guidWeb(), ls:1}});
            },

            fn_newUnit: function(type){
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/unit/items/new', query:{tagId:this.guidWeb(), type:type, ls:1}});
            },

            fn_newCategory: function(){
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/product_category/items/new', query:{tagId:this.guidWeb(), ls:1}});
            },

            fn_unitChange: function(value){
                console.log('jsdjs');
                if(value == this.$parent.Form.selectedSecondaryUnit.itemId){
                    this.$parent.Form.openingRate = this.roundUpto2Decimals((this.$parent.Form.openingRate * this.$parent.Form.primaryUnitPackQuantity) / this.$parent.Form.secondaryUnitPackQuantity);
                }
                else{
                    this.$parent.Form.openingRate = this.roundUpto2Decimals((this.$parent.Form.openingRate * this.$parent.Form.secondaryUnitPackQuantity) / this.$parent.Form.primaryUnitPackQuantity);
                }
            },
        }
    }
</script>